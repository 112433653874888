<template>
	<div class="add-contioner" ref="addContioner" >
	<div class="join">
		<div class="pic-wrapper" :style="'transform: translateY('+pic_height+'px);'">
			<div class="pic">
				<img :src="imgUrl.img" alt="">
			</div>
			<div class="pic">
				<img :src="imgUrl1.img" alt="">
			</div>
		</div>
		
	</div>
	<div class="job" ref="job">
        <div class="table" ref="table">
			<div class="title wow animate__fadeInUp">{{lang.title}}</div>
			<div class="info wow animate__fadeInUp" v-html="lang.info"></div>
			<div class="from">
				<div :class="{product:true,active:active,wow:true,animate__fadeInUp:true}" v-on:click.stop="productClick">
					<span class="hint">{{lang.product}}</span>
					<span class="value">{{selectProduct.name}}</span>
					<div class="icon pic">
						<img src="data:image/svg+xml;base64,PHN2ZyANCiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciDQogeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiDQogd2lkdGg9IjI2cHgiIGhlaWdodD0iMTRweCI+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBmaWxsPSJyZ2IoMTAyLCAxMDIsIDEwMikiDQogZD0iTTEzLjAwMCwtMC4wMTQgQzYuMjc3LC0wLjAxNCAwLjg2MywwLjE0OSAwLjk4MiwwLjM0OCBDMS42NjAsMS40NzkgMTIuNjgyLDE0LjAxMyAxMy4wMDAsMTQuMDEzIEMxMy4zMTcsMTQuMDEzIDI0LjM0MCwxLjQ3OSAyNS4wMTcsMC4zNDggQzI1LjEzNywwLjE0OSAxOS43MjIsLTAuMDE0IDEzLjAwMCwtMC4wMTQgTDEzLjAwMCwtMC4wMTQgWiIvPg0KPC9zdmc+"
							alt="">
					</div>
					<div class="contioner">
						<ul class="products">
							<li v-for="(item,index) in product" :key="index" @click="selectClick(index)">{{item.name}}
							</li>
						</ul>
					</div>
					<input type="hidden" v-model="fromData.product">

				</div>
				<div class="name wow animate__fadeInUp">
					<span class="hint">{{lang.name}}</span>
					<input type="text" class="value" v-model="fromData.name" :placeholder="lang.iptName">

				</div>
				<div class="name tel wow animate__fadeInUp">
					<span class="hint">{{lang.tel}}</span>
					<input type="text" v-model="fromData.tel" class="value" :placeholder="lang.iptTel">
				</div>
				<div class="name adds wow animate__fadeInUp">
					<span class="hint">{{lang.adds}}</span>
					<input type="text" v-model="fromData.adds" class="value" :placeholder="lang.iptAdds">
				</div>
				<div class="textere wow animate__fadeInUp">
					<textarea name="" v-model="fromData.textarea" :placeholder="lang.idear"></textarea>
				</div>
				<button class="submit wow animate__fadeInUp" @click="submitBtn">
					{{lang.btnText}}
				</button>
			</div>
		</div>
        <div class="jobs" ref="jobsE">
            <h1 class="title animate__fadeInUp">{{ lang.jobTitle }}</h1>
            <div v-for="(item, index) in jobs" ref="jobDetail" :class="{'job-detail':true,wow:false,animate__fadeInUp:true}" @click="setJobMover.moverenter(index)"   :key="index">
                <div class="job-title">
                    <div class="postion">{{ item.postion }}</div>
                    <div class="number">{{ item.number }}人</div>
                    <div class="address">{{ item.address }}</div>
                    <div class="date">{{ item.date }}</div>
                    <!-- <div class="add-icon" @click="setJobMover.mouseleave(index)" ></div> -->
                    <div class="add-icon" ></div>
                </div>

                <div class="desc">
                    <div class="text" v-html="item.desc"></div>
                    <div class="line"></div>
                    <div class="email">
                        {{ lang.jobemail }}：<a :href="'mailto:'+item.mail">{{ item.mail }}</a>
                    </div>
                </div>
            </div>
            <newsMoreEleVue :desc="lang.jobBtnText" class="newsMoreEleVue animate__fadeInUp" type="more">
            </newsMoreEleVue>
        </div>
    </div>
	</div>
	
</template>

<script>
	import {
    onMounted,
		ref
	} from 'vue';
	import {
		useStore
	} from 'vuex';
	import ajax from '@/ajax/index.js';
	import newsMoreEleVue from '@/components/public/newsMoreEle.vue';

	export default {
		components:{newsMoreEleVue},
		setup() {
			const store = useStore();
			const baseUrl = store.state.baseUrl
			const lang = ref(store.state.langPack[store.state.lang].add);
			const imgUrl = ref({
				img: '../../assets/add/02.jpg'
			})
			const product = ref([{
					name: 'LSB系列'
				},
				{
					name: '难燃系列'
				},
				{
					name: 'OSB系列'
				},
				{
					name: '难燃系列'
				},
			])
				ajax(baseUrl + 'web/brandbannerService').then(res => {
					product.value = res
					selectProduct.value = product.value[0]
				})
			// 表单提交
			const fromData = ref({
				product: "",
				name: "",
				tel: "",
				adds: "",
				textarea: ""
			})


			// 记录下拉状态
			const active = ref(false)
			// 记录当前选择产品
			const selectProduct = ref(product.value[0])

			// 产品选择下拉
			const productClick = function() {
				active.value = !active.value
			}
			// 产品选择
			const selectClick = function(index) {
				selectProduct.value = product.value[index]
			}
			// 关闭下拉
			const closeSelect = function(e){
				if(e.target != e.currentTarget){
					active.value = false;	
				}
			}
			document.body.addEventListener('click',closeSelect)
			//图片
			
			ajax(baseUrl + "web/applicationageService").then(res => {
				let result = res;
				let applicationage = [];
				result.forEach(item => {
				
					applicationage.push({
						img: baseUrl+item.imgurl,
					});
				});

				imgUrl.value = applicationage[0];
			});

			// 提交数据
			const submitBtn = function() {
				for (const key in fromData.value) {
					if (key == 'tel') {
						if (!fromData.value[key]) {
							alert("请输入电话");
							return false;
						} else {
							const regex = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
							if (!regex.test(fromData.value[key])) {
								alert("手机号格式错误");
								return false;
							}
						}
					}
					if (key == 'adds') {
						if (!fromData.value[key]) {
							alert("请输入地址")
							return false;
						}

					}


				}

				let Product = selectProduct.value.name;
				let name = fromData.value['name'];
				let tel = fromData.value['tel'];
				let adds = fromData.value['adds'];
				let textarea = fromData.value['textarea'];

				ajax(baseUrl + "web/applicationsubService", {
					'productIne': Product,
					'name': name,
					'phone': tel,
					'address': adds,
					'age': textarea
				}).then(res => {

					if (res == '200') {
						alert("信息提交成功");
					}

				});

			}
			// const store = useStore();
			// const lang = ref(store.state.langPack[store.state.lang].add);
			// const baseUrl = store.state.baseUrl
			const jobs = ref([
				{
					postion: "业务经理",
					number: "2人",
					address: "上海",
					date: "2022-10-10",
					mail:"service@treezogroup.com",
					desc: `
					<h3>工作职责</h3>
					<ol>
						<li>1、家具设计：能够独立完成产品方案设计、说明阐述、效果图以及图纸绘制；</li>
						<li>2、产品设计：能够发觉生活中的痛点，优化现有生活方式。设计创造出美观、实用，具有巧思的产品；</li>
						<li>3、制作跟踪：跟踪家具及产品的制作、打样过程。保证设计方案的完整还原，及时发现设计方案的不足，思考更佳的解决方案；</li>
						<li>4、对外合作设计：制定对外合作的产品数量、主题及具体内容，把握设计进度，与合作设计师保持沟通交流，参与团队对合作设计的产品的讨论，将团队讨论意见回复给合作设计师。跟踪制作、打样直到产品最后实现；</li>
						<li>5、把握设计与创意的流行趋势，对品牌产品设计及用户体验提供新的想法和观点。</li>
					</ol>

					<h3>任职要求</h3>
					<ol>
						<li>1、本科及以上学历，工业/产品/家具或相关专业，3年以上家具设计工作经验；</li>
						<li>2、熟练操作Rhyno,AutoCAD,3DMax,Adobephoeoshop等相关设计及工具软件，能手绘图及素描；</li>
						<li>3、熟悉国内外各种风格的家具设计及实木，板材材质应用；</li>
						<li>4、掌握家具材料性能及生产基本生产工艺；</li>
						<li>5、熟练绘制各类风格家具图纸与开发工作；</li>
						<li>6、对工作项目流程，进度计划及相关管理工作熟练；</li>
						<li>7、有团队合作精神及责任心。</li>
					</ol>

					`,
				},
				{
					postion: "设计师",
					number: "2人",
					address: "上海",
					date: "2022-09-10",
					mail:"service@treezogroup.com",
					desc: `
					<h3>工作职责</h3>
					<ol>
						<li>1、家具设计：能够独立完成产品方案设计、说明阐述、效果图以及图纸绘制；</li>
						<li>2、产品设计：能够发觉生活中的痛点，优化现有生活方式。设计创造出美观、实用，具有巧思的产品；</li>
						<li>3、制作跟踪：跟踪家具及产品的制作、打样过程。保证设计方案的完整还原，及时发现设计方案的不足，思考更佳的解决方案；</li>
						<li>4、对外合作设计：制定对外合作的产品数量、主题及具体内容，把握设计进度，与合作设计师保持沟通交流，参与团队对合作设计的产品的讨论，将团队讨论意见回复给合作设计师。跟踪制作、打样直到产品最后实现；</li>
						<li>5、把握设计与创意的流行趋势，对品牌产品设计及用户体验提供新的想法和观点。</li>
					</ol>

					<h3>任职要求</h3>
					<ol>
						<li>1、本科及以上学历，工业/产品/家具或相关专业，3年以上家具设计工作经验；</li>
						<li>2、熟练操作Rhyno,AutoCAD,3DMax,Adobephoeoshop等相关设计及工具软件，能手绘图及素描；</li>
						<li>3、熟悉国内外各种风格的家具设计及实木，板材材质应用；</li>
						<li>4、掌握家具材料性能及生产基本生产工艺；</li>
						<li>5、熟练绘制各类风格家具图纸与开发工作；</li>
						<li>6、对工作项目流程，进度计划及相关管理工作熟练；</li>
						<li>7、有团队合作精神及责任心。</li>
					</ol>

					`,
				},
				{
					postion: "业务经理",
					number: "2人",
					address: "上海",
					date: "2022-10-10",
					mail:"service@treezogroup.com",
					desc: `
					<h3>工作职责</h3>
					<ol>
						<li>1、家具设计：能够独立完成产品方案设计、说明阐述、效果图以及图纸绘制；</li>
						<li>2、产品设计：能够发觉生活中的痛点，优化现有生活方式。设计创造出美观、实用，具有巧思的产品；</li>
						<li>3、制作跟踪：跟踪家具及产品的制作、打样过程。保证设计方案的完整还原，及时发现设计方案的不足，思考更佳的解决方案；</li>
						<li>4、对外合作设计：制定对外合作的产品数量、主题及具体内容，把握设计进度，与合作设计师保持沟通交流，参与团队对合作设计的产品的讨论，将团队讨论意见回复给合作设计师。跟踪制作、打样直到产品最后实现；</li>
						<li>5、把握设计与创意的流行趋势，对品牌产品设计及用户体验提供新的想法和观点。</li>
					</ol>

					<h3>任职要求</h3>
					<ol>
						<li>1、本科及以上学历，工业/产品/家具或相关专业，3年以上家具设计工作经验；</li>
						<li>2、熟练操作Rhyno,AutoCAD,3DMax,Adobephoeoshop等相关设计及工具软件，能手绘图及素描；</li>
						<li>3、熟悉国内外各种风格的家具设计及实木，板材材质应用；</li>
						<li>4、掌握家具材料性能及生产基本生产工艺；</li>
						<li>5、熟练绘制各类风格家具图纸与开发工作；</li>
						<li>6、对工作项目流程，进度计划及相关管理工作熟练；</li>
						<li>7、有团队合作精神及责任心。</li>
					</ol>

					`,
				},
				{
					postion: "设计师",
					number: "2人",
					address: "上海",
					date: "2022-09-10",
					mail:"service@treezogroup.com",
					desc: `
					<h3>工作职责</h3>
					<ol>
						<li>1、家具设计：能够独立完成产品方案设计、说明阐述、效果图以及图纸绘制；</li>
						<li>2、产品设计：能够发觉生活中的痛点，优化现有生活方式。设计创造出美观、实用，具有巧思的产品；</li>
						<li>3、制作跟踪：跟踪家具及产品的制作、打样过程。保证设计方案的完整还原，及时发现设计方案的不足，思考更佳的解决方案；</li>
						<li>4、对外合作设计：制定对外合作的产品数量、主题及具体内容，把握设计进度，与合作设计师保持沟通交流，参与团队对合作设计的产品的讨论，将团队讨论意见回复给合作设计师。跟踪制作、打样直到产品最后实现；</li>
						<li>5、把握设计与创意的流行趋势，对品牌产品设计及用户体验提供新的想法和观点。</li>
					</ol>

					<h3>任职要求</h3>
					<ol>
						<li>1、本科及以上学历，工业/产品/家具或相关专业，3年以上家具设计工作经验；</li>
						<li>2、熟练操作Rhyno,AutoCAD,3DMax,Adobephoeoshop等相关设计及工具软件，能手绘图及素描；</li>
						<li>3、熟悉国内外各种风格的家具设计及实木，板材材质应用；</li>
						<li>4、掌握家具材料性能及生产基本生产工艺；</li>
						<li>5、熟练绘制各类风格家具图纸与开发工作；</li>
						<li>6、对工作项目流程，进度计划及相关管理工作熟练；</li>
						<li>7、有团队合作精神及责任心。</li>
					</ol>

					`,
				},
				{
					postion: "业务经理",
					number: "2人",
					address: "上海",
					date: "2022-10-10",
					mail:"service@treezogroup.com",
					desc: `
					<h3>工作职责</h3>
					<ol>
						<li>1、家具设计：能够独立完成产品方案设计、说明阐述、效果图以及图纸绘制；</li>
						<li>2、产品设计：能够发觉生活中的痛点，优化现有生活方式。设计创造出美观、实用，具有巧思的产品；</li>
						<li>3、制作跟踪：跟踪家具及产品的制作、打样过程。保证设计方案的完整还原，及时发现设计方案的不足，思考更佳的解决方案；</li>
						<li>4、对外合作设计：制定对外合作的产品数量、主题及具体内容，把握设计进度，与合作设计师保持沟通交流，参与团队对合作设计的产品的讨论，将团队讨论意见回复给合作设计师。跟踪制作、打样直到产品最后实现；</li>
						<li>5、把握设计与创意的流行趋势，对品牌产品设计及用户体验提供新的想法和观点。</li>
					</ol>

					<h3>任职要求</h3>
					<ol>
						<li>1、本科及以上学历，工业/产品/家具或相关专业，3年以上家具设计工作经验；</li>
						<li>2、熟练操作Rhyno,AutoCAD,3DMax,Adobephoeoshop等相关设计及工具软件，能手绘图及素描；</li>
						<li>3、熟悉国内外各种风格的家具设计及实木，板材材质应用；</li>
						<li>4、掌握家具材料性能及生产基本生产工艺；</li>
						<li>5、熟练绘制各类风格家具图纸与开发工作；</li>
						<li>6、对工作项目流程，进度计划及相关管理工作熟练；</li>
						<li>7、有团队合作精神及责任心。</li>
					</ol>

					`,
				},
				{
					postion: "设计师",
					number: "2人",
					address: "上海",
					date: "2022-09-10",
					mail:"service@treezogroup.com",
					desc: `
					<h3>工作职责</h3>
					<ol>
						<li>1、家具设计：能够独立完成产品方案设计、说明阐述、效果图以及图纸绘制；</li>
						<li>2、产品设计：能够发觉生活中的痛点，优化现有生活方式。设计创造出美观、实用，具有巧思的产品；</li>
						<li>3、制作跟踪：跟踪家具及产品的制作、打样过程。保证设计方案的完整还原，及时发现设计方案的不足，思考更佳的解决方案；</li>
						<li>4、对外合作设计：制定对外合作的产品数量、主题及具体内容，把握设计进度，与合作设计师保持沟通交流，参与团队对合作设计的产品的讨论，将团队讨论意见回复给合作设计师。跟踪制作、打样直到产品最后实现；</li>
						<li>5、把握设计与创意的流行趋势，对品牌产品设计及用户体验提供新的想法和观点。</li>
					</ol>

					<h3>任职要求</h3>
					<ol>
						<li>1、本科及以上学历，工业/产品/家具或相关专业，3年以上家具设计工作经验；</li>
						<li>2、熟练操作Rhyno,AutoCAD,3DMax,Adobephoeoshop等相关设计及工具软件，能手绘图及素描；</li>
						<li>3、熟悉国内外各种风格的家具设计及实木，板材材质应用；</li>
						<li>4、掌握家具材料性能及生产基本生产工艺；</li>
						<li>5、熟练绘制各类风格家具图纸与开发工作；</li>
						<li>6、对工作项目流程，进度计划及相关管理工作熟练；</li>
						<li>7、有团队合作精神及责任心。</li>
					</ol>

					`,
				},
			])
			const imgUrl1 = ref({
				img: require('@/assets/add/03.jpg')
			})
			
			//图片
			
			ajax(baseUrl + "web/applicationageService").then(res => {
				let result = res;
				let applicationage = [];
				result.forEach(item => {
				
					applicationage.push({
						img: baseUrl+item.brief,
					});
				});
			
				imgUrl.value = applicationage[0];
			});
			
			
			
			ajax(baseUrl + "web/recruitmentService").then(res => {
				
				let result = res;
				let recruitment = [];
				
				
				/* postion: "业务经理",
				number: "2人",
				address: "上海",
				date: "2022-10-10",
				desc: ` */
				
			
				result.forEach(item => {
					let {year,month,day} = getTime(item.dateRe)
					recruitment.push({
						postion: item.jobTitle,
						number: item.employ,
						address: item.site,
						date: year+'-'+month+'-'+day,
						mail: item.mailbox,
						desc: item.particulars,
					});
				});
				
				jobs.value = recruitment;
				function getTime(str){ // eslint-disable-line
					let date = new Date(str)
						const year =date.getFullYear()
						const month =date.getMonth()
						const day =date.getDate()
						const hour =date.getHours()
						const min = date.getMinutes()
						const sec =date.getSeconds()
				
						return {
							year,month,day,hour,min,sec
						}
				}
				
			})
			
			
			// 获取工作Dom
			const jobDetail = ref(null)
			// 展开工作详情
			const setJobMover = (function(){
				let state = {}
				return {
					moverenter(index){

						if(state[index] == undefined){
							state[index]=true
						}
						if(state[index]){
							jobDetail.value[index].classList.add("active")
							state[index] = false
						}else{
							jobDetail.value[index].classList.remove("active")
							state[index] = true

						}
					},
					mouseleave(index){
						if(state[index]){
							jobDetail.value[index].classList.remove("active")
							state[index] = false
						}

					}
				}
			})()
			// 关闭工作详情
			const setJobOut = function(index){
				jobDetail.value[index].classList.remove("active")
			}

			// 元素滚动

			const table = ref(null)
			const jobsE = ref(null)

			const pic_height = ref(0)

			const addContioner = ref(null)
			const job = ref(null)
			onMounted(()=>{
				// window.addEventListener("scroll",function(e){
				// })
				let scrollTop_ele = [0,table.value.offsetHeight]
				job.value.addEventListener("scroll",function(){
					let jobScroll = job.value.scrollTop
					// if(scrollTop_ele[1]){}
					for (let index = 1; index < scrollTop_ele.length; index++) {
						const item = scrollTop_ele[index];
						if(jobScroll <= 0){
							pic_height.value = 0;
							break;

						}
						if(jobScroll>=item){
							pic_height.value = index * window.innerHeight * (-1);
							break;
						}
					}
				})
			})


			return {
				lang,
				product,
				active,
				selectProduct,
				fromData,
				imgUrl,
				productClick,
				selectClick,
				submitBtn,closeSelect,
				// 人才招聘
				jobs,jobDetail,
				setJobMover,setJobOut,
				imgUrl1,
				addContioner,job,
				table,
				jobsE,
				pic_height
			}
		}
	}
</script>

<style lang="less" scoped>
.add-contioner{
	width: 100%;
	height: 100vh;
	display: flex;
}
	.join {
		width: 50%;
		height: 100vh;
		.pic-wrapper{
			width: 100%;
			transition: transform .7s;
			// height: 100%;
			&>.pic {
				position: relative;
				width: 100%;
				height: 100vh;
			}
		}
		
	}

	@media (any-hover:hover) {
		.join {
			
		}
		.table {
				.from {
					.product {
						.contioner {
							.products {
								li:hover {
									box-sizing: border-box;
									background-color: #ccc;
								}
							}
						}
					}
				}
			}
	}

	@media all and (max-width:989px) {
		.join {
			&>.pic {
				display: none;
			}

			
		}
		.table {
			.from {
				flex-wrap: wrap;

				.product,
				.name {
					width: 100%;
					margin-top: 15px;
				}
			}
		}

	}

	.table {
			padding: 12.4% 4.3% 3.7% 5.2%;
			flex: 1;
			box-sizing: border-box;

			.title {
				font-size: 3.0rem;
			}

			.info {
				margin-top: 30px;
				font-size: 1.5rem;
				line-height: 1.5;
				letter-spacing: 1px;
				color: #999;
			}

			.from {
				margin-top: 75px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.product {
					width: calc(50% - 8px);
					border: 1px solid #ccc;
					font-size: 1.5rem;
					line-height: 45px;
					position: relative;
					box-sizing: border-box;
					padding-left: 15px;
					cursor: pointer;
					z-index: 9;
					display: block;

					justify-items: flex-end;

					.hint {
						color: #999;
						letter-spacing: 2px;
						line-height: 45px;
						float: left;

					}

					.value {
						color: #333;
						letter-spacing: 2px;
						padding-left: 15px;
						line-height: 45px;
						white-space: nowrap;
						width: 40%;
						display: block;
						overflow: hidden;
						float: left;
					}

					.icon {
						width: 20px;
						height: 100%;
						float: right;
						margin-right: 15px;

						img {
							object-fit: contain;
						}
					}

					.contioner {
						font-size: inherit;
						line-height: inherit;
						position: absolute;
						right: 0;
						width: 75%;
						z-index: 9;
						// border-radius: 5px;
						margin-top: 10px;
						overflow: hidden;
						padding: 10px;
						padding-top: 0;

						top: 90%;
						opacity: 0;
						pointer-events: none;
						transition: .3s;

						.products {
							list-style: none;
							font-size: inherit;
							line-height: inherit;
							background-color: #fff;
							border-radius: 8px;
							cursor: pointer;
							overflow: hidden;
							z-index: 9999;
							position: relative;

							box-shadow: 0px 0px 15px #dfdfdf;

							// padding: 15px;
							li {
								padding: 0 15px;
								transition: .3s;
								cursor: pointer;
							}
						}
					}

					&.active {
						.contioner {
							top: 100%;
							opacity: 1;
							pointer-events: all;
						}

					}

				}

				&>.name {
					width: calc(50% - 8px);
					border: 1px solid #ccc;
					font-size: 1.5rem;
					line-height: 45px;
					position: relative;
					box-sizing: border-box;
					padding-left: 15px;
					cursor: pointer;

					.hint {
						color: #999;
					}

					.value {
						line-height: 45px;
						border: none;
						margin: 0 0 0 0;
						padding: 0 0 0 0;
						background-color: transparent;
						text-indent: 1.5rem;
						font-size: inherit;

						&::placeholder {
							color: #333;
							text-indent: 1.5rem;
							font-size: inherit;

						}
					}
				}

				.adds,
				.tel {
					margin-top: 30px;
				}

				.textere {
					margin-top: 30px;
					width: 100%;
					border: 1px solid #ccc;
					box-sizing: border-box;
					padding: 10px;

					textarea {
						width: 100%;
						border: none;
						font-size: 1.5rem;
						height: 150px;
						resize: none;

						&::placeholder {
							color: #333;
							font-size: inherit;
							font-size: 1.5rem;

						}
					}
				}

				.submit {
					font-size: 1.6rem;
					padding: 10px 45px;
					margin-top: 30px;
					background-color: #00543e;
					border: none;
					border-radius: 30px;
					color: #fff;
					cursor: pointer;
					transition: .7s;
					&:hover{
						background-color: #b9985e;
					}
				}

			}
	}
</style>

<style lang="less" >
.job {
    .jobs {
        .newsMoreEleVue {
            padding: 13.3px 20px;

            p {
                font-size: 1.6rem;
            }

            .icon {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}
</style>
<style lang="less" scoped>
.job {
	width: 50%;
	height: 100vh;
	overflow-y: scroll;
    &>.pic {
        width: 50%;
        height: 100vh;
    }

    .jobs {
        // flex: 1;
        box-sizing: border-box;
        padding: 6.2% 0% 0% 5.1%;
        background-color: #f2f2f2;
		width: 100%;
		min-height: 100vh;

        .title {
            font-size: 3.0rem;
            font-weight: normal;
        }

        .job-detail {
            display: flex;
            flex-wrap: wrap;
            background-color: #fff;
            width: 90.4%;
            margin-top: 33px;
            // margin-top: 99px;
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            max-height: 55px;
            cursor: pointer;
            transition: 1s;

            .job-title {
                width: 100%;
                display: flex;
                padding-right: 30px;
                position: relative;
				transition: .7s;
				&:hover{
					background-color: #00543e;
					*{
						color: #fff;
					}
				}
            }

            .postion,
            .number,
            .address,
            .date,
            .desc {
                color: #666666;
                line-height: 55px;
                color: #fff;
                text-align: center;
                color: #666;
                font-size: 1.5rem;
            }

            .postion {
                width: 16.4%;

            }

            .number {
                width: 21%;
            }

            .address {
                width: 11%;
            }

            .date {
                width: 30%;
            }

            .desc {
                width: 100%;
                text-align: left;
                background-color: #fff;

                .text {
                    margin: 30px;
                    line-height: 1.4;
                    max-height: 320px;
                    overflow-y: scroll;
                    padding-right: 30px;
                    box-sizing: border-box;

					// font-size: 1.4rem;


                    // border-bottom: #cccccc 1px solid;
                    * {
                        font-size: 1.4rem;
                    }

                    // 火狐滚动条
                    scrollbar-color: #00543e #cccccc;
                    scrollbar-width: thin;

                    // -webkit- 滚动条 谷歌 edge
                    &::-webkit-scrollbar {
                        width: 2px;
                    }

                    // 滚动条两端的按钮
                    &::-webkit-scrollbar-button {
                        display: none;
                    }

                    // 外层轨道
                    &::-webkit-scrollbar-track {}

                    // 内层轨道，滚动条中间部分(除去)
                    &::-webkit-scrollbar-track-piece {
                        background-color: #cccccc;

                    }

                    // 滚动条里面可以拖动的那个
                    &::-webkit-scrollbar-thumb {
                        background-color: #00543e;
                    }

                    // 边角
                    &::-webkit-scrollbar-corner {}

                    // 定义右下角拖动块的样式
                    &::-webkit-resizer {}

                }

                .line {
                    width: calc(100% - 60px);
                    height: 1px;
                    margin: 0 auto;
                    background-color: #cccccc;
                }

                .email {
                    width: calc(100% + 30px);
                    font-size: 1.8rem;
                    color: #666;
                    text-indent: 30px;

                    a {
                        font-size: 2.4rem;
                        color: #666;
                    }
                }
            }

            .add-icon {
                position: absolute;
                width: 1.8rem;
                height: 1.8rem;
                display: inline-block;
                margin-left: 5px;
                transform: translateY(2px);
                right: 15px;
                top: calc((55px - 1.8rem) / 2);
                transition: .7s;

                &::before {
                    content: "";
                    display: block;
                    width: 1.8rem;
                    height: 1px;
                    // background: #fff;
                    background: #666;

                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &::after {
                    content: "";
                    display: block;
                    height: 1.8rem;
                    width: 1px;
                    // background: #fff;
                    background: #666;

                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

            }

            .newsMoreEleVue {
                font-size: 1.6rem;
            }
        }
    }
}

.job {
    min-height: 100vh;
        .jobs {
            // overflow-y: scroll;
            .job-detail.active {
                background-color: #00543e;
                max-height: 2000px;

                .postion,
                .number,
                .address,
                .date {
                    color: #fff;
                }

                .add-icon {
                    transform: translateY(2px) rotate(45deg);

                    &::after,
                    &::before {
                        background-color: #fff;
                    }
                }
            }
        }
    }

@media (any-hover:hover) {
    // .job {
    //     .jobs {
    //         .job-detail:hover {
    //             background-color: #00543e;
    //             max-height: 2000px;

    //             .postion,
    //             .number,
    //             .address,
    //             .date {
    //                 color: #fff;
    //             }

    //             .add-icon {
    //                 transform: translateY(2px) rotate(45deg);

    //                 &::after,
    //                 &::before {
    //                     background-color: #fff;
    //                 }
    //             }
    //         }
    //     }
    // }
}

@media all and (max-width:989px) {
    .job {
        .jobs {
            .job-detail {
                .postion {
                    width: 43.4%;
                }

                .number,
                .date {
                    display: none;
                }

                .address {
                    width: 45%;
                    text-align: right;
                }

                .desc {
                    .email {
                        a {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    }

    .job {
        .jobs {
            .job-detail:hover {
                background-color: #00543e;
                max-height: 2000px;

                .postion,
                .number,
                .address,
                .date {
                    color: #fff;
                }

                .add-icon {
                    transform: translateY(2px) rotate(45deg);

                    &::after,
                    &::before {
                        background-color: #fff;
                    }
                }
            }
        }
    }
}

@media all and (max-width:769px){
	.add-contioner{
		height: auto;
		max-height: 10000px;
		overflow-y: visible;
		.join{
			display: none;
		}
		.job{
			width: 100%;
			height: auto;
			max-height: 10000px;
			overflow-y: visible;
			overflow: visible;
			.table{
				padding: 15px;
				.title{
					font-size: 2.4rem;
				}
				.info{
					font-size: 1.4rem;
					margin-top: 15px;
				}
				.from{
					margin-top: 30px;

					.product{
						width: 100%;
						.hint{
							font-size: 1.4rem;
						}
						.value{
							font-size: 1.4rem;
						}
						.contioner{
							.products{
								li{
									font-size: 1.4rem;
								}
							}
						}
					}
					.name{
						width: 100%;
						margin-top: 15px;
						.hint{
							font-size: 1.4rem;
						}
						input{
							font-size: 1.4rem;

							&::placeholder{
								font-size: 1.4rem;
							}
						}
					}
					.textere {
						margin-top: 15px;
						textarea{
							font-size: 1.4rem;
						}
					}
					.submit {
						margin-top: 15px;
					}
				}
			}
			.jobs{
				padding: 15px;
				.title{
					font-size: 2.4rem;
				}
				.job-detail{
					margin-top: 15px;
					width: 100%;
					.postion{
						text-indent: 15px;
						text-align: left;
					}
				}
				.desc{
					.text{
						margin: 15px;
						padding-right: 15px;
					}
					.line{
						width: calc(100% - 30px);
					}
					.email{
						font-size: 1.4rem;
					}
				}
			}
		}
	}
}
</style>