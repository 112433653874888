<template>
    <!-- <div class="in-more">
        <router-link class="imore" :to="url">
            <span>Read More </span>
            <div class="add-icon"></div>
        </router-link>
    </div> -->
    <router-link class="imore" :to="url">
            <span>{{moreTitle}} </span>
            <div class="add-icon"></div>
    </router-link>
</template>
<script>
export default {
    props: {
        url: {
            type: String,
            default: "/"
        },
        moreTitle:{
            typeof:String,
            default: "Read More"
        }
    }
}
</script>
<style lang="less" scoped>
.imore{
    padding: 10px 25px;
    border: solid 1px rgba(0, 84, 61, 1);
    font-size: 1.8rem;
    .add-icon{
        position: relative;
        width: 1.8rem;
        height: 1.8rem;
        display: inline-block;
        margin-left: 5px;
        transform: translateY(2px);
        &::before{
            content: "";
            display: block;
            width: 1.8rem;
            height: 1px;
            // background: #fff;
            background: rgba(255, 255, 255, 1);

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
        &::after{
            content: "";
            display: block;
            height: 1.8rem;
            width: 1px;
            // background: #fff;
            background: rgba(255, 255, 255, 1);

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }
}
.in-more {
    // margin: 200px;
    border: solid 1px rgba(0, 84, 61, 1);
    width: fit-content;
    position: relative;
    padding-right: 20px;
    .imore {
        line-height: 39px;
        padding: 0 25px;
        font-size: 1.8rem;
        color: #fff;
    }
    
}
</style>