<template>
  <div class="home">
    <navigationEle :isActive="endView"></navigationEle>
    <videoEle :option="video"></videoEle>
    <rightButtons></rightButtons>
    <!-- <div class="backTop">
      <button @click="backTop"></button>
    </div> -->
    <div
      :class="activeClassName"
      @mousedown="setMouseDown"
      @mouseup="setMouseUp"
    >
      <!-- banner -->
      <!-- <div :class="slideAni[0]? 'index-slide index-slide0 slideAnimate' : 'index-slide index-slide0 slideAnimateReverse' "> -->
      <div refs="slide" data-key="0" class="index-slide index-slide0">
        <OneEle :isActive="slideCoreAni[0]" @video="setVideo"></OneEle>
      </div>
      <!-- 产品 -->
      <!-- <div :class="slideAni[1]? 'index-slide index-slide1 slideAnimate' : 'index-slide index-slide1 slideAnimateReverse' "> -->
      <div refs="slide" data-key="1" class="index-slide index-slide1">
        <TwoEle :isActive="slideCoreAni[1]"></TwoEle>
      </div>
      <!-- 案例 工厂 -->
      <!-- <div :class="slideAni[2]? 'index-slide index-slide2 slideAnimate' : 'index-slide index-slide2 slideAnimateReverse' "> -->
      <div refs="slide" data-key="2" class="index-slide index-slide2">
        <ThereEle :isActive="slideCoreAni[2]"></ThereEle>
      </div>
      <!-- 公司信息 -->
      <!-- <div :class="slideAni[3]? 'index-slide index-slide3 slideAnimate' : 'index-slide index-slide3 slideAnimateReverse' "> -->
      <div refs="slide" data-key="3" class="index-slide index-slide3">
        <FourEle :isActive="slideCoreAni[3]"></FourEle>
      </div>
      <!-- 底部信息 -->
      <!-- <div :class="slideAni[4]? 'index-slide index-slide4 slideAnimate' : 'index-slide index-slide4 slideAnimateReverse' "> -->
      <div refs="slide" data-key="4" class="index-slide index-slide4">
        <FiveEle :isActive="slideCoreAni[4]"></FiveEle>
      </div>
    </div>
    <div class="qiehuan">
      <div class="prev">
        <i class="fa fa-angle-up" @click="setPrev" aria-hidden="true"></i>
      </div>
      <div class="next">
        <i class="fa fa-angle-down" @click="setNext" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getBrowser } from "../js/getBrowser.js";

import navigationEle from "../components/public/navigationEle.vue";
import videoEle from "../components/public/videoEle.vue";
import rightButtons from "../components/public/rightButtons.vue";

import { ref, onUpdated } from "vue";
import { useStore } from "vuex";
import ajax from '@/ajax';
// import {Swiper, SwiperSlide} from 'swiper/vue';
// import { Mousewheel , EffectFade  } from "swiper";

import OneEle from "../components/index/OneEle.vue";
import TwoEle from "../components/index/TwoEle.vue";
import ThereEle from "../components/index/ThereEle.vue";
import FourEle from "../components/index/FourEle.vue";
import FiveEle from "../components/index/FiveEle.vue";
export default {
  name: "HomeView",
  components: {
    navigationEle,
    OneEle,
    TwoEle,
    ThereEle,
    FourEle,
    FiveEle,
    videoEle,
    rightButtons,
    // SwiperSlide,
    // Swiper
  },
  data() {
    return {
      slideAni: [false, false, false, false, false],
      slideCoreAni: [false, false, false, false, false],
      prevIndex: -1,
      activeIndex: 0,
      nextIndex: 1,
      activeClassName: "wrapper",
      pageYStart: 0,
      pageYEnd: 0,
      endView: true,
      video: null,
    };
  },
  methods: {
    setWheel: (function () {
      let interTime = 0;
      // let _this = this

      return function (e, self) {
        // let doms = document.querySelectorAll(".index-slide")
        let _this = self;

        // console.log(doms)
        if (window.innerWidth > 769) {
          let slide = e.deltaY || e.detail;
          // console.log(slide)
          let time = new Date().getTime();
          if (time - interTime > 2000) {
            if (time - interTime < 2000) {
              return;
            }
            interTime = time;

            if (slide < 0) {
              // indexSwiepr.value.slidePrev(10000);
              if (_this.activeIndex < 0) {
                return;
              }
              if (_this.prevIndex < 0) {
                _this.prevIndex = -1;
                return;
              }
              _this.slideAni[_this.activeIndex] = true;
              // this.$refs.slide[_this.activeIndex].classList.add('slideAnimate')
              _this.slideAni[_this.activeIndex - 1] = false;
              // doms[_this.activeIndex].classList.add('slideAnimateReverseN')
              // if(_this.slideCoreAni[_this.activeIndex - 1] != undefined || _this.slideCoreAni[_this.activeIndex + 1] ){
              if (_this.activeIndex == _this.slideCoreAni.length - 1) {
                _this.slideCoreAni[_this.activeIndex] = false;
              }
              _this.slideCoreAni[_this.activeIndex - 1] = true;
              _this.slideCoreAni[_this.activeIndex] = false;

              _this.slideCoreAni[_this.activeIndex + 1] = false;
              // }
              _this.activeClassName = "wrapper wrapperN" + _this.activeIndex;

              _this.activeIndex--;
              console.log(_this.activeIndex);
              if (_this.activeIndex == 3) {
                _this.endView = true;
              }
              _this.nextIndex--;
              _this.prevIndex--;
            } else if (slide > 0) {
              // indexSwiepr.value.slideNext(10000);

              if (_this.activeIndex + 1 > _this.slideAni.length) {
                return;
              }
              if (_this.nextIndex + 1 > _this.slideAni.length) {
                _this.nextIndex = 5;
                return;
              }
              // doms[_this.activeIndex].classList.add('slideAnimate')
              // doms[_this.activeIndex].classList.remove('slideAnimateN')
              // doms[_this.activeIndex + 1].classList.add('slideAnimateN')

              // _this.slideAni[_this.activeIndex] = true
              _this.slideAni[_this.activeIndex + 1] = true;
              // if(_this.slideCoreAni[_this.activeIndex - 1] != undefined && _this.slideCoreAni[_this.activeIndex + 1] !== undefined){
              if (_this.nextIndex + 1 == _this.slideCoreAni.length) {
                _this.slideCoreAni[_this.nextIndex - 1] = false;
              }
              _this.slideCoreAni[_this.activeIndex - 1] = false;
              _this.slideCoreAni[_this.activeIndex] = false;
              _this.slideCoreAni[_this.activeIndex + 1] = true;
              // }
              // _this.slideAni[_this.activeIndex+1]=true
              _this.activeClassName = "wrapper wrapper" + _this.activeIndex;

              _this.activeIndex++;
              _this.nextIndex++;
              _this.prevIndex++;
              if (_this.nextIndex == _this.slideAni.length) {
                _this.endView = false;
              }
            }
          }
        }
      };
    })(),
    setPrev: function () {
      this.setWheel({ deltaY: -100 }, this);
    },
    setNext: function () {
      this.setWheel({ deltaY: 100 }, this);
    },
    setVideo(option) {
      this.video = option;
    },
  },
  mounted() {
    let _this = this;
    window.onresize = function () {
      if (
        document.body.clientWidth > 769 &&
        (_this.$route.name == "m" || _this.$route.name == "home")
      ) {
        console.log(_this.$route);
        _this.$router.push("/");
      }
    };
    window.addEventListener("resize", function () {
      if (
        document.body.clientWidth > 769 &&
        (_this.$route.name == "m" || _this.$route.name == "home")
      ) {
        _this.$router.push("/");
      }
    });
    if (
      document.body.clientWidth > 769 &&
      (_this.$route.name == "m" || _this.$route.name == "home")
    ) {
      this.$router.push("/");
    }

    if (getBrowser() == "gecko") {
      let time = 0;
      document
        .querySelector("#app")
        .addEventListener("DOMMouseScroll", function (e) {
          let now = new Date().getTime();
          if (now - time > 1000) {
            _this.setWheel(e, _this);
          }
        });
    } else {
      let timea = 0;

      document
        .querySelector("#app")
        .addEventListener("mousewheel", function (e) {
          let nowa = new Date().getTime();
          if (nowa - timea > 1000) {
            timea = nowa;
            _this.setWheel(e, _this);
          }
          // _this.setWheel(e,_this)})
        });
    }
  },
  setup() {
    const store = useStore();
    const baseUrl = store.state.baseUrl
    const title = ref("title");
    const keyword = ref("keyword");
    const description = ref("description");

    onUpdated(async () => {
      let url = "首页";
      let data = await ajax(baseUrl + `web/seoyhService?title=${url}`);
      console.log('123');
      title.value = data[0].title;
      keyword.value = data[0].keyword;
      description.value = data[0].description;
    });
    return {
      title,
      keyword,
      description,
    };
  },
  metaInfo() {
    return {
      title: this.title, // set a title
      meta: [
        {
          // set meta
          name: "keywords",
          content: this.keyword,
        },
        {
          name: "Description",
          content: this.description,
        },
      ],
    };
  },
};
</script>
<style lang="less">
// .index-swiper{
//   width: 100%;
//   height: 100vh;
.home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.qiehuan {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 999;
  // width: 30px;
  // height: 80px;
  transform: translateY(-50%);
  // border-radius: 20px;
  // overflow: hidden;
  @media all and (max-width: 769px) {
    display: none;
  }
  .prev,
  .next {
    color: #fff;
    text-align: center;
    font-size: 1.8rem;
    cursor: pointer;
    margin: 5px 0;
    transition: 0.7s;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0 30px 30px 0;
    padding: 5px;
    box-sizing: border-box;

    .fa {
      width: 35px;
      height: 35px;
      background: #00543d;
      border-radius: 50%;
      line-height: 35px;
    }

    &:hover {
      background: #ccc;
    }
  }
  .prev {
    width: 100%;
  }
  .next {
    width: 100%;
  }
}
@media all and (max-width: 769px) {
  html,
  body,
  .home {
    height: auto !important;
    overflow: auto !important;
    overflow-x: hidden !important;
  }
}
.index-slide {
  width: 100%;
  height: 100vh;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  background: #fff;

  position: static !important;
  top: 0;
  left: 0;
  //   transform: translateY(100vh);
  //   transform: translateY(100vh);
  overflow: hidden;
}
@media all and (max-width: 769px) {
  .index-slide {
    position: static;
    height: auto;
  }
}

.index-slide0 {
  transform: translateY(0vh);

  z-index: 5;
}

.index-slide1 {
  z-index: 4;
  height: auto;
}

.index-slide2 {
  z-index: 3;
}

.index-slide3 {
  z-index: 2;
}

.index-slide4 {
  z-index: 1;
  height: auto;
}

// }
.wrapper0 {
  animation-name: slideAnimate;
}
.wrapper1 {
  animation-name: slideAnimate1;
}
.wrapper2 {
  animation-name: slideAnimate2;
}
.wrapper3 {
  animation-name: slideAnimate3;
}
.wrapper4 {
  animation-name: slideAnimate4;
}
.wrapperN0 {
  animation-name: slideAnimate;
}
.wrapperN1 {
  animation-name: slideAnimateN1;
}
.wrapperN2 {
  animation-name: slideAnimateN2;
}
.wrapperN3 {
  animation-name: slideAnimateN3;
}
.wrapperN4 {
  animation-name: slideAnimateN4;
}
// .slideAnimateN {
//   // animation-name: slideAnimate;
//   // animation-duration: 1s;
//   // animation-name: slideAnimate;
//   animation-name: slideAnimateN;
// }

// .slideAnimateReverse {
//   animation-name: slideAnimateReverse;
// }

@keyframes slideAnimate {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  30% {
    opacity: 1;
    transform: scale(0.96) translateY(0px);
  }
  70% {
    opacity: 1;
    transform: scale(0.96) translateY(-100vh);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(-100vh);
  }
}

@keyframes slideAnimate1 {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-100vh);
  }

  30% {
    opacity: 1;
    transform: scale(0.96) translateY(-100vh);
  }
  70% {
    opacity: 1;
    transform: scale(0.96) translateY(-200vh);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(-200vh);
  }
}

@keyframes slideAnimate2 {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-200vh);
  }

  30% {
    opacity: 1;
    transform: scale(0.96) translateY(-200vh);
  }
  70% {
    opacity: 1;
    transform: scale(0.96) translateY(-300vh);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(-300vh);
  }
}
@keyframes slideAnimate3 {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-300vh);
  }

  30% {
    opacity: 1;
    transform: scale(0.96) translateY(-300vh);
  }
  70% {
    opacity: 1;
    transform: scale(0.96) translateY(-400vh);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(-400vh);
  }
}
@keyframes slideAnimate4 {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-400vh);
  }

  30% {
    opacity: 1;
    transform: scale(0.96) translateY(-400vh);
  }
  70% {
    opacity: 1;
    transform: scale(0.96) translateY(-500vh);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(-500vh);
  }
}
@keyframes slideAnimateN4 {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-400vh);
  }

  30% {
    opacity: 1;
    transform: scale(0.96) translateY(-400vh);
  }
  70% {
    opacity: 1;
    transform: scale(0.96) translateY(-300vh);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(-300vh);
  }
}
@keyframes slideAnimateN3 {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-300vh);
  }

  30% {
    opacity: 1;
    transform: scale(0.96) translateY(-300vh);
  }
  70% {
    opacity: 1;
    transform: scale(0.96) translateY(-200vh);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(-200vh);
  }
}
@keyframes slideAnimateN2 {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-200vh);
  }

  30% {
    opacity: 1;
    transform: scale(0.96) translateY(-200vh);
  }
  70% {
    opacity: 1;
    transform: scale(0.96) translateY(-100vh);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(-100vh);
  }
}
@keyframes slideAnimateN1 {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-100vh);
  }

  30% {
    opacity: 1;
    transform: scale(0.96) translateY(-100vh);
  }
  70% {
    opacity: 1;
    transform: scale(0.96) translateY(0vh);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0vh);
  }
}

// @keyframes slideAnimate {
//   0% {
//     opacity: 1;
//     transform: scale(1);
//   }

//   30% {
//     opacity: 1;
//     transform: scale(0.96) translateY(0px);
//   }
//   70%{
//     opacity: 1;
//     transform: scale(0.96) translateY(-100vh);
//   }
//   100% {
//     opacity: 1;
//     transform: scale(1) translateY(-100vh);
//   }
// }

// @keyframes slideAnimateN {
//   0% {
//     opacity: 1;
//     transform: scale(1);
//   }

//   30% {
//     opacity: 1;
//     transform: scale(0.96) translateY(100vh);
//   }
//   70%{
//     opacity: 1;
//     transform: scale(0.96) translateY(0vh);
//   }

//   100% {
//     opacity: 1;
//     transform: scale(1) translateY(0vh);
//   }
// }

// @keyframes slideAnimateReverse {
//   0% {
//     opacity: 1;
//     transform: scale(1) translateY(-100vh);
//   }

//   30% {
//     opacity: 1;
//     transform: scale(0.96) translateY(-100vh);
//   }
//   70%{
//     opacity: 1;
//     transform: scale(0.96) translateY(0vh);
//   }

//   100% {
//     opacity: 1;
//     transform: scale(1) translateY(0vh);
//   }
// }
// @keyframes slideAnimateReverseN {
//   0% {
//     opacity: 1;
//     transform: scale(1) translateY(0);
//   }

//   30% {
//     opacity: 1;
//     transform: scale(0.96) translateY(0);
//   }
//   70%{
//     opacity: 1;
//     transform: scale(0.96) translateY(-100vh);
//   }

//   100% {
//     opacity: 1;
//     transform: scale(1) translateY(-100vh);
//   }
// }
.wrapper {
  animation-duration: 2s;
}
</style>
