<template>
	<NavigationEle :isActive="true"></NavigationEle>
	<!-- <bannerEleVue :item="bannerData"></bannerEleVue> -->
	<bannerEleVue id="7"></bannerEleVue>
	<catalogueEleVue ref="catalogueEleVue" @slide="setSlide" class="catalogueEleVue" :items="catalogue">
	</catalogueEleVue>

	<div class="video-view">
		<!-- <img src="../assets/demo/202211241712.jpg" alt=""> -->
		<hotEleVue :hot="hot" class="hotEleVue" type="hot"></hotEleVue>
		<div class="videos">
			<div class="video wow animate__pulse pic" v-for="(item, index) in videoData" :key="index">
				<img class="img" :src="item.imgurl" alt="">
				<swiperButtonEleVue @click="videoFn.play($event, index)" class="swiperButtonEleVue" :type="'play'">
				</swiperButtonEleVue>
				<p class="title">{{ item.title }}</p>
				<div class="pause">
					<swiperButtonEleVue @click="videoFn.pause($event, index)" class="swiperButtonEleVue-pause"
						:type="'stop'"></swiperButtonEleVue>
				</div>

				<video ref="video" :src="item.video"></video>
			</div>

			<newsMoreEleVue desc="点击查看更多" type="more"></newsMoreEleVue>

		</div>
	</div>
	<skipEleVue :id="8"></skipEleVue>
	<footerEleVue></footerEleVue>
</template>

<script>
import {
	ref, onMounted
} from 'vue';
import {
	useRoute,
	useRouter
} from 'vue-router';
import ajax from '@/ajax/index.js';
import {
	useStore
} from 'vuex'
import bannerEleVue from '@/components/public/bannerEle.vue'
import hotEleVue from '@/components/media/hotEle.vue';
import swiperButtonEleVue from '@/components/public/swiperButtonEle.vue';
import newsMoreEleVue from '@/components/public/newsMoreEle.vue';
import catalogueEleVue from '@/components/media/calgEle.vue';
import skipEleVue from '@/components/public/skipEle.vue';
import footerEleVue from '@/components/public/footerEle.vue';
import NavigationEle from '@/components/public/navigationEle.vue';

import { getTime } from '@/js/uitl';



export default {
	components: {
		bannerEleVue,
		hotEleVue,
		swiperButtonEleVue,
		newsMoreEleVue,
		catalogueEleVue,
		skipEleVue,
		footerEleVue,
		NavigationEle
	},
	metaInfo() {
            let slideInfo = [
          {
            description: "媒体中心",
            keyword: "媒体中心",
            title: "媒体中心",
          },
        ];
        const store = useStore();
        const baseUrl = store.state.baseUrl;
        ajax(baseUrl + "web/seoyhService?title=媒体中心").then((res) => {
          slideInfo = res;
        });
        return {
          title: slideInfo&&slideInfo[0].title, // set a title
          meta: [
            {
              // set meta
              name: "keywords",
              content: slideInfo&&slideInfo[0].keyword,
            },
            {
              name: "Description",
              content: slideInfo&&slideInfo[0].description,
            },
          ],
        };
    },
	setup() {
		const router = useRouter();
		const bannerData = {
			titleCn: "媒体中心",
			titleEn: "Media Center",
			desc: "定义高端制造的生活方式",
			imgurl: require('../assets/media/m1.jpg'),
			video: {
				cover: "require('../assets/about/video.jpg')",
				url: ""
			}
		}
		const catalogueEleVue = ref(null)
		const store = useStore();
		const baseUrl = store.state.baseUrl;
		const videoData = ref([{
			video: require('@/video/97.mp4'),
			imgurl: require('../assets/media/v1.jpg'),
			title: '强强联合·携手同行 | 千年舟集团与北京三好同创装饰集团签署战略合作协议'
		},
		{
			video: "",
			imgurl: require('../assets/media/v2.jpg'),
			title: '余杭区政协党组副书记、副主席黄德伟一行莅临千年舟集团考察交流'
		},
		{
			video: "",
			imgurl: require('../assets/media/v2.jpg'),
			title: '南京林业大学党委副书记、校长勇强一行莅临千年舟集团考察交流'
		},
		])
		const route = useRoute();
		onMounted(() => {
			if (route.params.id) {
				catalogueEleVue.value.setSlide(route.params.id)
			}
		})

		ajax(baseUrl + "web/mediavodeService").then(res => {

			let result = res;
			let mediavode = [];
			/* video:require('@/video/97.mp4'),
			imgurl:require('../assets/media/v1.jpg'),
			title:'强强联合·携手同行 | 千年舟集团与北京三好同创装饰集团签署战略合作协议'/*  */

			result.forEach(item => {
				mediavode.push({
					video: baseUrl + item.videoFile,
					imgurl: baseUrl + item.imgurl,
					title: item.title
				});
			});

			videoData.value = mediavode;


		})

		const hot = ref(null);
		// const news = [{
		// 	id: 0,
		// 	date: ["2022", '05', "09"],
		// 	title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
		// 	desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。<br /><br />签约现场，双方负责人均表示对此次战略合作充满了期待和信心。广州轩怡装饰集团供应链总经理谢新仁表示：轩怡装饰坚持“客户满意是我们最大的幸福”为企业使命。在质量参差、劣质材料、假冒名牌、收费混乱等负面信息频出的家装行业，真正要把客户的满意度做好并不容易，此次希望通过与千年舟的合作，把绿色健康环保板材推荐给更多的消费者，期望后续双方加强沟通交流，全面赋能整装行业。`,
		// 	imgurl: require("../assets/media/01.jpg"),
		// 	type: 1,
		// },
		// {
		// 	id: 0,
		// 	date: ["2022", '05', "09"],
		// 	title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
		// 	desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。<br /><br />签约现场，双方负责人均表示对此次战略合作充满了期待和信心。广州轩怡装饰集团供应链总经理谢新仁表示：轩怡装饰坚持“客户满意是我们最大的幸福”为企业使命。在质量参差、劣质材料、假冒名牌、收费混乱等负面信息频出的家装行业，真正要把客户的满意度做好并不容易，此次希望通过与千年舟的合作，把绿色健康环保板材推荐给更多的消费者，期望后续双方加强沟通交流，全面赋能整装行业。`,
		// 	imgurl: require("../assets/media/01.jpg"),
		// 	type: 1,
		// },
		// {
		// 	id: 0,
		// 	date: ["2022", '05', "09"],
		// 	title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
		// 	desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
		// 	imgurl: require("../assets/media/01.jpg"),
		// 	type: 2,
		// },
		// {
		// 	id: 0,
		// 	date: ["2022", '05', "09"],
		// 	title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
		// 	desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
		// 	imgurl: require("../assets/media/01.jpg"),
		// 	type: 2,
		// },
		// {
		// 	id: 0,
		// 	date: ["2022", '05', "09"],
		// 	title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
		// 	desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
		// 	imgurl: require("../assets/media/01.jpg"),
		// 	type: 2,
		// },
		// {
		// 	id: 0,
		// 	date: ["2022", '05', "09"],
		// 	title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
		// 	desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
		// 	imgurl: require("../assets/media/01.jpg"),
		// 	type: 2,
		// },
		// {
		// 	id: 0,
		// 	date: ["2022", '05', "09"],
		// 	title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
		// 	desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
		// 	imgurl: require("../assets/media/01.jpg"),
		// 	type: 2,
		// },
		// {
		// 	id: 0,
		// 	date: ["2022", '05', "09"],
		// 	title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
		// 	desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
		// 	imgurl: require("../assets/media/01.jpg"),
		// 	type: 2,
		// },
		// ]
		// news.forEach(item => {
		// 	if (item.type == 1) {
		// 		hot.push(item)
		// 	}
		// })

		ajax(baseUrl + "web/newsService",{id:1,elite:1}).then(res => {
			console.log(res)
			
			let arr = []
			res.forEach((item)=>{
				let datey = [];
				let {
					year,
					month,
					day
				} = getTime(item.newsDate)
				datey.push(year, month, day);

				arr.push({
					id: item.id,
					date: datey,
					title: item.title,
					desc: item.brief,
					imgurl: baseUrl + item.imgurl,
					type: item.elite,
				})
			})
			hot.value = arr
		})

		

		// 视频
		// (播放 或 暂停)视频
		const video = ref(null)
		const videoFn = (function () {
			let state = {}

			return {
				play: function (e, index) {
					if (!state[index] || !state[index].state) {
						state[index] = {
							state: true,
							playEle: {
								btn: e.currentTarget,
								img: e.currentTarget.parentElement.querySelector("img")
							},
						}
					}

					state[index].playEle.btn.style.opacity = 0
					state[index].playEle.img.style.opacity = 0
					state[index].playEle.btn.style.pointerEvents = 'none'
					state[index].playEle.img.style.pointerEvents = 'none'

					video.value[index].play();
				},
				pause: function (e, index) {
					if (!state[index] || !state[index].state) {
						state[index].pauseEle = {
							btn: e.currentTarget,
							img: e.currentTarget.parentElement
						}
					}
					state[index].playEle.btn.style.opacity = 1
					state[index].playEle.img.style.opacity = 1
					state[index].playEle.btn.style.pointerEvents = 'auto'
					state[index].playEle.img.style.pointerEvents = 'auto'
					video.value[index].pause();
				}
			}
		})()



		// 左侧
		const catalogue = ref([{
			name: '工厂动态',
			url: '/news/0',
			id: '1'
		},
		{
			name: '产品动态',
			url: '/news/1',
			id: '2'

		},
		{
			name: '新闻动态',
			url: '/news/2',
			id: '3'
		},
		{
			name: '媒体中心',
			url: '/video/3'
		}
		])

		if (store.state.lang == 'en') {
			catalogue.value = [{
				name: 'Factory dynamics',
				url: '/news/0',
				id: '1'
			},
			{
				name: 'Product dynamics',
				url: '/news/1',
				id: '2'

			},
			{
				name: 'News and trends',
				url: '/news/2',
				id: '3'
			},
			{
				name: 'media center',
				url: '/video/3'
			}
			]
		}
		// 底部上面的板块
		const skip = {
			imgurl: require('../assets/brandDetail/db.jpg'),
			url: "/contact",
			title: "对我们的产品感兴趣<br />可以联系我们哦!"
		}
		const setSlide = function (item) {
			router.push(item.url)
		}
		return {
			bannerData,
			hot,
			videoData,
			catalogue,
			setSlide,
			skip,
			video,
			videoFn,
			catalogueEleVue
		}
	}
}
</script>
<style lang="less">
.catalogueEleVue {
	.line {
		.slide {}
	}

	&>ul {
		li {
			&.active {
				span {
					color: #00543D;
				}
			}

			&:hover {
				span {
					color: #00543D;
				}
			}

			color: #fff;
		}

	}
}
</style>
<style lang="less" scoped>
.catalogueEleVue {
	background-color: #B9985E;
}

.video-view {
	&>img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: -99;
	}

	.videos {
		padding: 4.2% 2.6% 0% 8.9%;

		.video {
			width: 100%;
			padding-top: 49.7%;
			height: 0;
			margin-bottom: 40px;

			.img {
				transition: .7s;
				z-index: 9;
			}

			.swiperButtonEleVue {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 9;
			}

			.pause {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 8;

				.swiperButtonEleVue-pause {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 8;
					opacity: 0;
					pointer-events: none;
					transition: .7s;
				}

				&:hover {
					.swiperButtonEleVue-pause {
						opacity: 1;
						pointer-events: auto;
					}
				}
			}

			video {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 7;
				background: #000;
			}

			.title {
				width: 100%;
				position: absolute;
				bottom: 0;
				color: #fff;
				z-index: 9;
				font-size: 3rem;
				// line-height: 3;
				// text-indent: 1em;
				letter-spacing: 2.7px;
				background-color: rgba(0, 0, 0, .2);
				box-sizing: border-box;
				padding: 30px 15px;
				
			}
		}
	}
}

@media all and (max-width:1200px) {
	.video-view {

		.videos {
			padding: 4.2% 2.6% 0% 8.9%;

			.video {

				.img {}

				.swiperButtonEleVue {}

				video {}

				.title {
					font-size: 2.2rem;
				}
			}
		}
	}
}

@media all and (max-width:989px) {
	.video-view {

		.videos {
			padding: 4.2% 2.6% 0% 8.9%;

			.video {

				.img {}

				.swiperButtonEleVue {}

				video {}

				.title {
					font-size: 2.2rem;
					line-height: 1.6;
					text-indent: 0;
					box-sizing: border-box;
					padding: 0 15px;
				}
			}
		}
	}
}

@media all and (max-width:769px) {
	.video-view {
		.hotEleVue {
			display: none;
		}

		.videos {
			padding: 30px 15px;

			.video {

				.img {}

				.swiperButtonEleVue {}

				video {}

				.title {
					font-size: 1.6rem;
					line-height: 1.6;
					text-indent: 0;
					box-sizing: border-box;
					padding: 0 15px;
				}
			}
		}
	}
}
</style>
