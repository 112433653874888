<template>
    <div class="container" id="container">
    </div>
</template>
<script>

export default {
    props:['coord'],
    data(){
        return {
            map:null,
            infoWindow:null,
            infoWindow1:null
        }
    },
    methods:{
        clickFn:function(){
            this.map.setDisplayOptions({
                poiText:true,
                poiIcon:true
            })
        },
    },
     mounted:function(){
        // 119.214726,35.174553 日照市
        // 119.213433,35.193259 山东
        // 118.010102,34.458175 江苏
        // 120.129837,30.356689 浙江杭州

        // 118.353474,34.488331 山东
        // 120.129837,30.356689 总部
        // 创建地图实例
        let map = new BMapGL.Map("container",{ // eslint-disable-line
            enableRotate: false,
            enableTilt: true,
            poiText:true,
            poiIcon:true
        });
            
        // 开启滚轮缩放
        map.enableScrollWheelZoom(true); 

        // 创建点坐标 
        var point = new BMapGL.Point(120.129837,30.356689);  //  eslint-disable-line
        map.centerAndZoom(point, 18);                 // 初始化地图，设置中心点坐标和地图级别
        
        // 创建添加点标记
        var marker = new BMapGL.Marker(new BMapGL.Point(120.129837,30.356689)); // eslint-disable-line
        // 添加到地图
        map.addOverlay(marker);

        // 创建图文信息窗口
        var sContent = `
            <div class="content">
                <img class="logo" src="${require('@/assets/logoicon.png')}" >
                <div class="line"></div>
                <span>澳思柏恩装饰材料有限公司</span>    
            </div>
            
            <img class="zhiyin" src="${require('@/assets/content/xiaoxiejiao.png')}" >
        `;
        this.infoWindow = new BMapGL.InfoWindow(sContent); // eslint-disable-line
        this.infoWindow.disableCloseOnClick()

        marker.openInfoWindow(this.infoWindow);

        this.$emit("map",map)

     },
}
</script>
<style lang="less">
    .container{
        width: 100%;
        // width: 100%;
        height: 100%;
    }
    .BMap_bubble_pop{
        border-radius: 0 !important;
        background: rgba(0, 82, 63, 1) !important;
        border: none !important;
        padding: 0 !important; 
        position: relative;
        height: auto !important;
        transform: translate(-5%, 18px);
        width: 400px !important;

        .BMap_bubble_top{
            display: none !important;
        }
        .BMap_bubble_center{
            width: 100% !important;
            background: rgba(0, 82, 63, 1) !important;
            height: auto !important;
        }
        .BMap_bubble_content{
            width: 100% !important;
            height: auto !important;
            overflow: visible !important;
            .content{
                background: rgba(0, 82, 63, 1) !important;
                padding: 20px;
                display: flex;
                align-items: center;
                span{
                    display: block;
                    color: #fff;
                    vertical-align:middle;
                    font-size: 1.6rem;
                    letter-spacing: 1.5px;
                }
                .line{
                    background: #fff;
                    height: 45px;
                    width: 1px;
                    margin: 0 15px;
                }
            }
            .logo{
                width: 60px !important;
                vertical-align:middle;

            }
        }
        &>img{
            display: none;
        }
        img{
            width: 100% !important;
        }
        .zhiyin{
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 30px !important;
            transform: translateY(63%);
        }
    }
    .shadow{
        display: none;
    }
</style>