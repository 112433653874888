<template>
    <div class="catalogue">
        <div class="line">
            <div class="slide" :style="'top:'+topHeight+'px'" ref="slide"></div>
        </div>
        <ul>
            <!-- v-on:click.stop="setSlide(item,index);" -->
            <li v-for="(item,index) in items" ref="slideitems" @mousemove="setSlide(item,index)" :key="index" :class="{active:index == last,brand:type}">
                <span>{{item.name}}</span>
                <div v-if="type=='brand'" class="add-icon"></div>
                <ul class="product">

                    <li v-for="(i,index) in item.child" :key="index" :class="{active:i.active}" @click="setSkip(item,i.id)" >
                    {{i.name}}
                </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
// import {defineEmits } from 'vue'
export default {
    props:["items",'id','type'],
    data(){
        return {
            last:0,
            topHeight:0,
            once:true
        }
    },
    methods:{
        setSlide:function(item,index){
            this.last = index
            setTimeout(()=>{
                if(this.$refs.slide){
                    // this.$refs.slide.style.top = (this.$refs.slideitems[index].offsetTop-17) +"px"
                    this.topHeight = (45 * index);

                }       
            })  
            this.$emit('slide',item)
        },
        setSkip:function(item,index){
            this.$emit('skip',item,index)
            
        },
        setOncePostion(items){
            for (let index = 0; index < items.value.length; index++) {
                const item = items.value[index];
                if(item.id == this.$route.params.id){
                    this.last = index;
                    this.topHeight = (45 * index);
                    // this.$refs.slide.style.top = (this.$refs.slideitems[index].offsetTop-17) +"px"
                } 
            }
        }
    },
    mounted:function(){
        
    },
}
</script>
<style lang="less" scoped>
    .catalogue{
        position: fixed;
        z-index: 99;
        top: 21%;
        left: 25px;
        background: rgba(0, 0, 0, 1);
        background:#00543e;
        padding: 30px;
        padding-top: 17px;
        padding-bottom: 22px;
        padding-left: 22px;
        border-radius: 8px;
        display: flex;
        width: fit-content;
        .line{
            position: relative;
            // flex: 1;
            width: 2px;
            background:rgba(0, 0, 0, 0.2);
            margin-right: 10px;
            .slide{
                width: 100%;
                height: calc(2.8 * 1.6rem);
                background-color: rgba(255, 255, 255, .5);
                position:absolute;
                top: 0;
                left: 0;
                transition: 1s;
            }
        }
        & > ul{
            width: calc(100% - 12px);
            list-style: none;
            li{
                width:calc(1.6rem * 5);
                color: #fff;
                font-size: 1.6rem;
                line-height: 2.8;
                white-space: nowrap;
                display: flex;
                justify-content: space-between;
                align-content: center;
                align-items: center;
                cursor: pointer;
                flex-wrap: wrap;
                overflow: hidden;
                &.brand{
                    width: 120px;
                }
                &.active{
                    color: #B9985E;
                    .add-icon{
                        &::before{
                            background-color: #B9985E;
                        }
                        &::after{
                            opacity: 0;
                        }

                    }
                    .product{
                        max-height: 2000px;
                    }
                }
                transition: .3s;
                &:hover{
                    color: #B9985E;
                }
                .add-icon{
                    position: static;
                    width: 1.8rem;
                    height: 1.8rem;
                    display: inline-block;
                    margin-left: 5px;
                    transform: translateY(2px);
                    right: 15px;
                    top: calc((55px - 1.8rem) / 2);
                    transition: .7s;
                    // margin-right: 30px;

                    &::before{
                        content: "";
                        display: block;
                        width: 1.8rem;
                        height: 1px;
                        // background: #fff;
                        background: rgb(255, 255, 255);

                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                    }
                    &::after{
                        content: "";
                        display: block;
                        height: 1.8rem;
                        width: 1px;
                        // background: #fff;
                        background: rgb(255, 255, 255);


                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                    }
                    
                }
                .product{
                    width: 100%;
                    display: block;
                    background: transparent;
                    // list-style-type: disc;
                    list-style: disc outside none;
                    overflow: hidden;
                    max-height: 0;
                    li{
                        font-size: 1.4rem;
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 2rem;
                        margin: 8px 0;
                        line-height: 1.2;
                        width: 100%;
                        
                        white-space: break-spaces;
                        
                        &::before{
                            content: "";
                            width: 3px;
                            height: 3px;
                            background: #ffffff;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left:0.7rem;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }
    @media all and (max-width:769px){
        .catalogue{
            display: none;
        }
    }
    
</style>