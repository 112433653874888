<template>
  <navigationEleVue isActive="true"></navigationEleVue>
  <div class="reso">
    <!-- <img src="../assets/demo/202211221623.jpg" alt=""> -->
    <bannerEleVue id="3"></bannerEleVue>
    <div class="contioner">
      <Swiper :modules="modules" @slideChangeTransitionStart="slideChangeTransitionStart"
        @slideChangeTransitionEnd="slideChangeTransitionStart" :parallax="true" :effect="'fade'" class="title-swiper"
        :direction="'horizontal'" @swiper="setTitle" :controller="{ control: contSwiper }">
        <!-- <template v-slot:container-start>
          <div class="parallax-bg" data-swiper-parallax="-75%"></div>
        </template> -->
        <SwiperSlide class="title-slide" v-for="(item, index) in data" :key="index">
          <!-- <h3 class="classify" :title="item.classify">澳思柏恩——核心优势——{{item.classify}}</h3>
          <h2 class="title" :title="item.title">{{item.title}}</h2>
          <h2 class="en" :title="item.en">{{item.en}}</h2> -->
          <daina :item="item" :index="index" huxiang="aosiboen"></daina>
        </SwiperSlide>
        <div class="swiper-ele">
          <swiperButtonEleVue type="prev" :class="{ prev: true, active: actcve }" @click="prev"></swiperButtonEleVue>
          <!-- {{ actcve }} -->
          <swiperButtonEleVue type="next" :class="{ next: true, active: actcve }" @click="next"></swiperButtonEleVue>
        </div>

      </Swiper>
      <div class="cont-contioner">
        <!-- :mousewheel="{releaseOnEdges: true,}" -->
        <Swiper class="cont-swiper" :modules="modules" slidesPerView="1" :spaceBetween="15" :direction="direction"
          :noSwiping="false" :controller="{ control: titleSwiper }" @swiper="setCont">
          <SwiperSlide class="cont-slide swiper-no-swiping" v-for="(item, index) in data" :key="index">

            <!-- <Swiper class="info-swiper" slidesPerView="auto" :noSwiping="false" :direction="'vertical'" @swiper="setInfo" >
              <SwiperSlide class="info-slide" >
              </SwiperSlide>
            </Swiper> -->
            <div class="hidden">
              <dijia class="dijia" v-for="(i, index) in item.info" :key="index" :item="i"></dijia>
            </div>


          </SwiperSlide>
          <!-- <SwiperSlide class="cont-slide" v-for="(i,index) in item.info" :key="index">
            <dijia :item="i"></dijia>
          </SwiperSlide> -->
        </Swiper>
      </div>

    </div>
  </div>
  <skipEleVue :id="4"></skipEleVue>
  <footerEleVue></footerEleVue>
</template>

<script>

import { ref, onMounted, onUpdated } from 'vue';
import { Swiper, SwiperSlide, } from 'swiper/vue';
import { Parallax, EffectFade, Mousewheel, Controller } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";


import bannerEleVue from '@/components/public/bannerEle.vue';
import dijia from '../components/public/dijiaEle.vue'
import daina from '../components/public/dainaEle.vue'
import footerEleVue from '@/components/public/footerEle.vue';
import skipEleVue from '@/components/public/skipEle.vue';
import { useStore } from 'vuex';
import ajax from '@/ajax';
import { editor, setImg } from '@/js/uitl.js';
import navigationEleVue from '@/components/public/navigationEle.vue';
import swiperButtonEleVue from '@/components/public/swiperButtonEle.vue';

export default {
  components: {
    Swiper, SwiperSlide,
    bannerEleVue, dijia, daina,
    footerEleVue, skipEleVue, navigationEleVue, swiperButtonEleVue
  },
  setup() {

    const titleData = {
      titleCn: "资源优势",
      titleEn: "Resource Advances",
      desc: "定义高端制造的生活方式",
      imgurl: require('../assets/core/b2.jpg'),
      video: {
        cover: "require('../assets/about/video.jpg')",
        url: ""
      }
    }

    const data = ref([
      {
        classify: "资源优势",
        title: "原材优势",
        en: "Raw material advantages",
        info: [
          {
            title: "优质进口松木",
            desc: `
            新西兰进口大径松木，芯材黄红色，边材黄白色，结构粗而均匀<br />
            新西兰松可塑性强，稳定性好，木材质地柔韧<br />
            新西兰松油性小，收缩效率平均<br />
            <br />
            <br />
            <img src="${require('../assets/core/c01.jpg')}" alt="优质进口松木" />
            `
          },
          {
            title: "MDI生态胶",
            desc: `
            无醛添加环保生态胶-优异的稳定性和安全性<br />
            高精尖科技-全球掌握核武器的有7个国家 全球掌握MDI技术的只有4个国家<br />
            `
          }
        ]
      },
      {
        classify: "资源优势",
        title: "资源优势",
        en: "Resource advantages",
        info: [
          {
            title: "全球化采购",
            desc: `
              澳思柏恩整合国内外优质木业资源，从源头为稳定、强硬的产品品质打下坚实的基础。
            `
          },
          {
            title: "港口基地",
            desc: `
            生产基地分别位于山东日照、山东郯城、江苏邳州，依托岚山港港口优势，以优质松木为主要原材料，整合国内外优质木业资源，建立海外采购体系，保证稳定供应，实现了生产效率与效能的全面提升。
            `
          },
          {
            title: "产学研强强结合",
            desc: `
            公司拥有自主产权的研发中心，并且与南京林业大学、浙江农林大学等国内知名学府建立产学研战略合作关系，将国际先进技术与国内一流研发人员相结合，通过完善的产品研发体系，持续加强产品创新力和竞争力。
            `
          },
          {
            title: "工业4.0设备",
            desc: `
            引进德国辛北尔康普完整生产线、德国帕尔曼长材刨片机、意大利意玛帕尔三层超级筛、德国EWK环保设备，皆为国际一线水准，保障了澳思柏恩产品的品质和环保性与国际接轨，实现了自动化、智能化绿色生产。
            `
          },
          {
            title: "全面质量溯源",
            desc: `
            公司强效推行“全工序质检流程”、“全面质量追溯系统”等安全生产管理制度，实现产品成本和品质控制上的国际领先水平。
            `
          },
          {
            title: "全面质量溯源",
            desc: `
            公司强效推行“全工序质检流程”、“全面质量追溯系统”等安全生产管理制度，实现产品成本和品质控制上的国际领先水平。
            `
          },
          {
            title: "全面质量溯源",
            desc: `
            公司强效推行“全工序质检流程”、“全面质量追溯系统”等安全生产管理制度，实现产品成本和品质控制上的国际领先水平。
            `
          },
        ]
      },
      {
        classify: "资源优势",
        title: "原材优势",
        en: "Raw material advantages",
        info: [
          {
            title: "优质进口松木",
            desc: `
            新西兰进口大径松木，芯材黄红色，边材黄白色，结构粗而均匀<br />
            新西兰松可塑性强，稳定性好，木材质地柔韧<br />
            新西兰松油性小，收缩效率平均<br />

            <img src="" alt="优质进口松木" />

            `
          },
          {
            title: "MDI生态胶",
            desc: `
            无醛添加环保生态胶-优异的稳定性和安全性<br />
            高精尖科技-全球掌握核武器的有7个国家 全球掌握MDI技术的只有4个国家<br />

            `
          }
        ]
      },
      {
        classify: "资源优势",
        title: "原材优势",
        en: "Raw material advantages",
        info: [
          {
            title: "优质进口松木",
            desc: `
            新西兰进口大径松木，芯材黄红色，边材黄白色，结构粗而均匀<br />
            新西兰松可塑性强，稳定性好，木材质地柔韧<br />
            新西兰松油性小，收缩效率平均<br />

            <img src="" alt="优质进口松木" />

            `
          },
          {
            title: "MDI生态胶",
            desc: `
            无醛添加环保生态胶-优异的稳定性和安全性<br />
            高精尖科技-全球掌握核武器的有7个国家 全球掌握MDI技术的只有4个国家<br />

            `
          }
        ]
      },
    ])
    const store = useStore();
    const baseUrl = store.state.baseUrl

    // swiper 
    const actcve = ref(true)
    const titleSwiper = ref(null)
    const contSwiper = ref(null)
    const infoSwiper = ref(null)
    const swiperData = ref({ allNum: data.value.length, activeIndex: 0 })
    const slideChangeTransitionStart = function () {

    }
    const slideChangeTransitionEnd = function () {
      swiperData.value.activeIndex = contSwiper.value.activeIndex
      if (titleSwiper.value.activeIndex % 2 == 0) {
        actcve.value = false
      } else {
        actcve.value = true
      }
    }

    const prev = function () {
      titleSwiper.value.slidePrev();
    }
    const next = function () {
      titleSwiper.value.slideNext();
    }

    const setTitle = swiper => {
      titleSwiper.value = swiper
    }
    const setCont = swiper => {
      contSwiper.value = swiper
    }
    const setInfo = swiper => {
      infoSwiper.value = swiper
    }

    const init = async function () {
      const aAdv = await ajax(baseUrl + "web/advantageService")
      let rData = []
      for (let index = 0; index < aAdv.length; index++) {
        const item = aAdv[index];
        const aList = await ajax(baseUrl + "web/advantagelistService", { adId: item.id })
        aList.forEach(item => {
          item.title = item.name
          item.desc = new editor(item.particulars).r
          delete item.particulars
          delete item.name
        });
        rData.push({
          classify: item.name,
          title: item.name,
          en: item.ename,
          info: aList
        })
      }
      data.value = rData
    }
    init();


    const direction = ref('vertical')
    // 更改swiper
    const setSwiper = function () {
      if (document.body.clientWidth < 769) {
        direction.value = 'horizontal'
      }
    }


    // 进入页面向左滑动部分提示为可滑动
    onMounted(() => {
      console.log(document.querySelector('.cont-swiper').querySelector('.swiper-wrapper').classList.add("wow"))
      document.querySelector('.cont-swiper').querySelector('.swiper-wrapper').classList.add("showSwiper")

    })
    // onUpdated(() => {
    //   setImg();
    // })
    // 最下方跳转板块
    const skip = {
      imgurl: require('../assets/brandDetail/db.jpg'),
      url: "/contact",
      title: "对我们的产品感兴趣<br />可以联系我们哦!"
    }
    const title = ref('title');
    const keyword = ref('keyword');
    const description = ref('description');
    onUpdated(async () => {
      setImg();
      let url = store.state.langPack[store.state.lang].navigationSec.core[1]
      let data = await ajax(baseUrl + `web/seoyhxqService?title=${url}`)
      title.value =  data[0].title;
      keyword.value =  data[0].keyword;
      description.value =  data[0].description;
    })


    return {
      data, titleData, skip,title,keyword,description,
      modules: [Parallax, EffectFade, Mousewheel, Controller], setTitle, setCont, setInfo, titleSwiper, contSwiper, infoSwiper, setSwiper,
      swiperData, slideChangeTransitionStart, slideChangeTransitionEnd, next, prev, actcve
    }

  },
  metaInfo() {
    return {
      title: this.title, // set a title
      meta: [
        {
          // set meta
          name: "keywords",
          content: this.keyword,
        },
        {
          name: "Description",
          content: this.description,
        },
      ],
    };
  },
}
</script>

<style lang="less">
  .reso {
    .contioner {
      .title-swiper {
        & > .swiper-wrapper{
          height: 100%;
        }
      }
    }
  }
@media all and (max-width:769px) {
  [data-xiaoma] {
    max-width: 100%;
  }

  .reso {
    .contioner {
      .title-swiper {
        .daina {
          padding: 30px 15px;

          .en {
            display: none;
          }
        }
      }
    }
  }
}

.cont-slide {
  img {
    width: 100%;
  }
}
</style>

<style lang="less" scoped>
.reso {
  &>img {
    width: 100%;
    position: absolute;
    z-index: -1;
    // background: #fff;
  }

  .contioner {
    display: flex;
    width: 100%;
    height: 100vh;
    position: fixed;
    position: static;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .title-swiper {
    box-sizing: border-box;
    width: 28.3%;
    height: 100vh;
    margin: 0;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #000;
    .parallax-bg {
      width: 100%;
      height: 1000%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      // background-image: linear-gradient(to bottom ,#00543e 0% ,#f4f4f4 25%);
      background-image: repeating-linear-gradient(to bottom, #00543e 0%, #00543e 23%, #00543e 23%, #f4f4f4 27%, #f4f4f4 50%);
    }

    .title-slide {
      // box-sizing: border-box;
      // background-color: #00543e;
      // padding: 27.9% 0% 0% 3.7%;
      // padding: 14.5% 0% 0% 3.7%;
      width: 100%;
      height: 100%;


      &:nth-child(2n) {
        background-color: #f4f4f4;

        .classify {
          color: #999;
        }

        .title {
          color: #00543e;
        }

        .en {
          color: #999;

        }
      }

      .classify {
        font-size: 1.8rem;
        font-weight: normal;
        margin-bottom: 10px;
        color: #b9985e;
      }

      .title {
        font-size: 3rem;
        letter-spacing: 2px;
        color: #ffffff;
      }

      .en {
        text-transform: uppercase;
        font-size: 6rem;
        letter-spacing: -5px;
        color: #b9985e;
      }
    }
  }

  .cont-contioner {
    flex: 1;
    // padding: 8.1% 0 0 8%;
    padding: calc(4.1% - 30px) 0 0 8%;
    overflow: hidden;
    background: #ffffff;


    .cont-swiper {
      width: 100%;
      height: 100%;

      // box-sizing: border-box;
      // padding: 8.1% 0 0 8%;
      // padding: calc(4.1% - 30px) 0 0 8%;
      // overflow: hidden;
      // background: #ffffff;
      .cont-slide {
        width: 100%;
        height: 100% !important;
        max-height: 100%;
        // padding-right: 30px;
        box-sizing: border-box;
        overflow: hidden;

        .hidden {
          width: calc(100% + 5px);
          height: calc(100% - 30px);
          padding-bottom: 30px;

          overflow-y: scroll;
          overflow-x: hidden;
        }

        .dijia {
          margin-top: 30px;
        }

        .dijia:last-child {
          margin-bottom: 30px;
        }

        .info-swiper {
          width: 100%;
          height: 96%;
          // padding-bottom: 30px;
          box-sizing: border-box;
          overflow: hidden;

          .info-slide {
            width: auto;
            height: auto;
            margin-top: 30px;
          }
        }

        img {
          width: 100%;
        }
      }
    }

  }

}

.swiper-ele {
  position: absolute;
  bottom: 10%;
  width: 40%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;

  .prev.active {
    background-color: #b9985e;
    flex-shrink: 0;
  }

  .next.active {
    background-color: #b9985e;
    flex-shrink: 0;

  }

  .pagination {
    position: relative;
    // position: absolute;
    // bottom: 10%;
    // left: 50%;
    // transform: translateX(-50%);
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: 99;


    span {
      color: #b9985e;
      font-size: 2.0rem;

    }

    .line {
      position: absolute;
      width: calc(100% - 2rem * 4);
      height: 2px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: rgba(255, 255, 255, .5);
      overflow: hidden;

      .jin {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: #b9985e;
        transform: translateX(-100%);
        // animation-name: leftSlide;
        animation-duration: 3s;
      }
    }
  }
}


@media all and (max-width:769px) {
  .reso {
    .contioner {
      flex-wrap: wrap;
      flex-direction: column;

      .title-swiper {
        width: 100%;
        flex: 0 1 auto;
        height: auto;

        .en {
          display: none;
        }
      }

      .cont-swiper {
        width: 100%;
        height: 86vh;
        // flex: 0 1 auto;
        // flex: 1;
      }
    }

    .cont-contioner {
      padding: 30px 15px;
      box-sizing: border-box;
      width: 100%;
      // flex: 0 1 auto;

    }
  }

  .swiper-ele {
    display: none;
  }
}
</style>
<style lang="less">

</style>