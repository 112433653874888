<template>
  <div :class="{daina:true,back:index % 2 == 0}" :index="index">
    <h3 class="classify wow animate__fadeInLeft" :title="item.classify">{{breakTag.aosi}}——{{plant?breakTag.plant:breakTag.core}}——{{item.classify}}</h3>
    <h2 class="title wow animate__fadeInLeft" :title="item.title">{{item.title}}</h2>
    <h2 class="en wow animate__fadeInLeft" :title="item.en">{{item.en}}</h2>

    <div v-if="item.address" :title="item.address" class="address wow animate__fadeInLeft">
        地址：{{item.address}}
    </div>
    <div v-if="item.tel" :title="item.tel" class="tel wow animate__fadeInLeft">
        电话：{{item.tel}}
    </div>
    <div v-if="item.desc" class="desc wow animate__fadeInLeft" v-html="item.desc">
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'

export default {
    props:['item','index','plant'],
    setup(){
      const store = useStore();
      const breakTag = store.state.langPack[store.state.lang].reso
      
      return {
        breakTag
      }

    }
}
</script>

<style lang="less" scoped>
.daina{
      box-sizing: border-box;
      background-color: #00543e;
      padding: 27.9% 0% 0% 3.7%;
      padding: 14.5% 0% 0% 3.7%;
      width: 100%;
      height: 100%;
    &.back{
        background-color: #f4f4f4;
        .classify{
          color: #999;
        }
        .title{
          color: #00543e;
        }
        .en{
          color: #999;
        
        }
    }
      
      &:nth-child(2n){
        background-color: #f4f4f4;
        .classify{
          color: #999;
        }
        .title{
          color: #00543e;
        }
        .en{
          color: #999;
        
        }
      }
      .classify{
        font-size: 1.8rem;
        font-weight: normal;
        margin-bottom: 10px;
        color: #b9985e;
      }
      .title{
        font-size: 3rem;
        letter-spacing: 2px;
        color: #ffffff;
      }
      .en{
        text-transform: uppercase;
        font-size: 6rem;
        letter-spacing: -5px;
        color: #b9985e;
        width: 90%;
      }
      .address,.tel,.desc{
        // font-size: 1.8rem;
        color: #b9985e;
        font-weight: bold;
        line-height: 1.5;
      }
      .address{
        margin-top: 145px;
    
      }
      .tel{
        
      }
      .desc{
        margin-top: 100px;
        width: 90%;
        letter-spacing: 2px;
        font-weight: 400;

      }
    }

@media all and (max-width:1441px){
  .daina {.en{
    font-size: 4.5rem;
    letter-spacing: 0.5px;
  }}
}
</style>