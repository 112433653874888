<template>
    <div>
        <div class="circle wow scale" @mouseenter="setMouseover($event)" @mouseleave="setMoverLeave($event)">
        <!-- <div class="circle"> -->
            <div :id="'circle'+index">
            </div>
            <div ref="rotateCircle" v-for="(item,index) in pic" :style="'animation-delay: '+index * 0.1+'s;'" :key="index" class="pic">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAACSCAYAAADB5g3mAAAE3ElEQVR4nO2dQZLbNhBFf0OTmegI2eUArvhQOYBP4iMkOUIqR/AilXWSnTfeOFVeuOxFShY1JNFZjDSRVaQIig2wAfRbTmla1Buy8QFiKGJmnGBmT0SEC/q2+7D55u67y58b09CZYL72wtPrIx5LkTgAYOY28PUhfwTjDNc2ze9EdDfjd/iw272KdkSFQXzehGfAzC0R3UsfUGncLBgAmJmJyEkeUGksEnxeR6BGkUidfTb4jSB5eZvkAVzbNL8K1jPJFxAzj87gltQVrJU1DgCIyAkNdifsTD7y3IOJyHnvd4K12XvfCNbLEho5ccXOwNqz8phgQP4yr7IvXzuzpIVU2ZenLl2TvJCQ3miSFxA6+JjkG5kzukstDJ2oQvKs+HTMyo+C7899378TrKeO2fnUOfdw2O1eSB3AZrP5npm9VD1tXMvBIVhWnmDpDMsGvwkkprAm+QpSawQmeQTJRRiTPID0Kpdl5QvElxFjLN4f9vsfBOslJco6LRG57nAQu9f3sN3+mWtWXpqDQ6g6K6e401D14JfqVk61klPeK6tScuqbkdVJXuNub1VZeZXb6TVtdFltvwIRub7v3wqWZO/9QbCeCClycAjFbnTRIhgodEKi5i+NQhOGJsFAgZK1CQYKk6xRMFCQZK2CgUImJJoFnza6/CtYkvuu+0Ow3iSaYtoUWWblnAQDGWZl1S1igOwGv9wEA5lJzlEwkJHkXAUDmUjOWTCQQVbOXfApK4c+EicEUcm5xbQp1GXl0gQDyrJy9i1iAFWDX4mCAUWSSxUMKJFcsmBAgeTSBQMrZ+UaBK+60aUKwcCT5LZtXwuWZO/958n3LTAHh5BsQlKrYCDRhKSaFjFAkoRRs2AggeTaBQORJZvgJ6JlZRN8JFZWNsFnHDeF/y1YkmuOaVOIiLEzeByRwc8ER8YEj2MtIgZ93/8DwWm0DXJnxHgSuAn+nyiLP9Yinoi2smaCIy9b1i44+ppwzYJtwT0ioreMcGXWV5Xgrut+g6Dcvu/fTm0QrCamrfVtN7UIXm3HZQ0tYtXtrKULXn2vcMmCV5cLlCtYhVygTMHJMm4IpQkWk+u939s/wRzR/I2OJQhW02+HyL1FqJYL5C1YvVwgX8FZyAXyFJyNXCA/waoybgg5CZaU61M9FEm94OOjCsTkdo/tL0S0kao3heocrHkCEfyGigVnNZiNobVFFCEX0Cm4GLmAPsFFyQV0Cc4u44agRbC6hxlJseqBHP9bXXIjyHtNcoEVY1oJGTeEtQQXN5iNscblVI1cIL3gquQCaQVXJxdIJ7hKuUAawUVOIEKJJrjvujeQ3QjSaMu4IUSJadIZ93G/f3m/3f4lVS8lMQRX22+HkL7kTO4FkoJN7gBSgk3uCBKCTe4VlgquOuOGcJPgx/3+BTLdCJKa2R+Kmf39div26Ku+696k3AiSmrk52PrtTOacwSb3BkIFm9wbCRFschcwJdjkLuSaYMu4AowJLnYjSGq++uDMLLrZ2Xv/sWa5wNc52PptBBwAMHMvXNfkHnFd0/wsfBmb3DOkHw5vci+wMzcyUoJN7giLBNc8gQjlZsHe+0PtGTcE573/NPeX2i9ffnTOfRvjgEqDmHnuThxrCTNwwPPXzIRMNkzuTJ57KBHddU3z09CLbDC7nf8A/rQ0d7hgfDgAAAAASUVORK5CYII=" alt="">
            </div>
            <slot name="cont" class="cont"></slot>
        </div>
    </div>
</template>
<script>
import '../../js/circles.js' 

export default {
    props:['r','index'],
    data(){
        return {
            // pic:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
            pic:[0]
        }
    },
    methods:{
        // 鼠标移入 重新实例circle达到鼠标悬浮动效
        setMouseover:(function(){
            // let time = 0
            return function(){
                // let now = new Date().getTime();
                let _this = this
                // if(now - time > 1000){
                    // this.pic = []

                    // e.currentTarget.classList.add("scale")
                    this.$refs.rotateCircle[0].classList.add("rotateCircle")
                    Circles.create({ // eslint-disable-line
                        id: 'circle' + this.$props.index,
                        radius: this.$props.r,
                        value: 100,
                        maxValue: null,
                        width: 1,
                        text: null,
                        colors: ['#f1f1f1', '#FF9E27'],
                        duration: 500,
                        wrpClass: 'circles-wrp',
                        textClass: 'circles-text',
                        styleWrapper: true,
                        styleText: true
                    });  
                    setTimeout(function(){
                        if([].indexOf.call(_this.$refs.rotateCircle[0].classList,"rotateCircle") != -1){
                            _this.$refs.rotateCircle[0].classList.remove("rotateCircle")
                        }
                    },800)
                    // time = now,


                // }
                // else{
                //     if(now-time > 10){
                //         this.pic.push(time)
                //         if(this.pic.length > 20){
                //             console.log("为了您的身心健康请不要快速滑动")
                //         }
                //     }
                // }

            }
        })(),
        // 鼠标移开 删除pic动画
        setMoverLeave:function(e){
            e.currentTarget.classList.remove("scale")
        }
    },
    components:{

    },
    mounted:function(){
        // let allcount = 100
        Circles.create({ // eslint-disable-line
            id: 'circle' + this.$props.index,
            radius: this.$props.r,
            value: 100,
            maxValue: null,
            width: 1,
            text: null,
            colors: ['#f1f1f1', '#FF9E27'],
            duration: 500,
            wrpClass: 'circles-wrp',
            textClass: 'circles-text',
            styleWrapper: true,
            styleText: true
        }); 
        
    },
    watch:{
        'r':function( newr,oldr){ 
            oldr
            if(typeof newr == 'number'){
                Circles.create({ // eslint-disable-line
                    id: 'circle' + this.$props.index,
                    radius: this.$props.r,
                    value: 100,
                    maxValue: null,
                    width: 1,
                    text: null,
                    colors: ['#f1f1f1', '#FF9E27'],
                    duration: 500,
                    wrpClass: 'circles-wrp',
                    textClass: 'circles-text',
                    styleWrapper: true,
                    styleText: true
                }); 
            }
           
        }
    }
}
</script>
<style scoped lang="less">

.circle{
    // position: absolute;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    padding: 10px;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%,-50%) ;
    transition: .3s;
    
    &:hover{
        // transform: scale(1.2) !important;
    }
    .pic{
        position:absolute;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        opacity: 0;
        // left: -10px;
        // right: -10px;
        // top: -10px;
        // bottom: -10px;
        /* width: 410px; */
        /* height: 410px; */
        display: flex;
        /* left: 0%;
        top: 0%; */
        /* left: 50%; */
        /* top: 50%; */
        justify-content: center;
        align-items: center;
        align-content: center;
        overflow: revert;
        
        border-radius: 50%;
        /* background-color: black; */

        transform: rotate(0deg);
        /* transform: translate(-50%,-50%) rotate(0deg); */
        /* background-color: rgba(255, 255, 255, .5); */
        

        // animation-name: rotateCircle;
        animation-duration: 800ms;
        // /* animation-fill-mode: both; */
        animation-timing-function: cubic-bezier(0, 0, 0, 0);
        // animation-iteration-count: infinite;
        &.rotateCircle{
            img{
                opacity: 1;       
            }
        }
        img{
            position: absolute;
            z-index: 9;
            width: 20px;
            height: 20px;
            top: 0px;

            // opacity: 0;
            // transform: .3s;
            /* transform: rotate(0deg);
            
            animation-name: rotateCircle;
            animation-duration: 10s;
            animation-fill-mode: both;
            animation-timing-function: cubic-bezier(0, 0, 0, 0); */
            /* transform: translateY(-50%); */
        }
    }

    
}
.rotateCircle{
    animation-name: rotateCircle;
}
@keyframes rotateCircle{
    0%{
        transform: rotate(0deg);
        opacity: 0;
        /* transform: translate(-50%,-50%) rotate(0deg); */
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(360deg);
        /* transform: translate(-50%,-50%) rotate(360deg); */
    }
}

.scale{
    animation-name: scale;
}

@keyframes scale{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
</style>