<template>
	<div v-if="ResizeValue" class="cirles">
		<div class="circle-Items" ref="circleItems">
			<circleItemEleVue v-for="(item,index) in circleData" :key="index" :r="radius" :index="index"
				class="circleItemEleVue">
				<template v-slot:cont>
					<div class="cont">
						<span class="h1 num">{{'0' + (index+1)}}</span>
						<h1 class="h1 cn">{{item.cn}}</h1>
						<h1 class="h1 en">{{item.en}}</h1>
					</div>
				</template>
			</circleItemEleVue>
		</div>
		<div class="imgs">
			<div class="pic">
				<!-- <img src="../../assets/cooperation/03.jpg" alt=""> -->
			</div>
		</div>
	</div>
	<div v-if="!ResizeValue" class="cirles">
			<div class="circle-Items" ref="circleItems">
				<div v-for="(item,index) in mCircleData0" :key="index" class="cont">
					<span class="h1 num">{{'0' + (index+1)}}</span>
					<h1 class="h1 cn">{{item.cn}}</h1>
					<h1 class="h1 en">{{item.en}}</h1>
					<div class="clear"></div>
				</div>
			</div>
			<div class="circle-Items" ref="circleItems">
				<div v-for="(item,index) in mCircleData1" :key="index" class="cont">
					<span class="h1 num">{{'0' + (index+1)}}</span>
					<h1 class="h1 cn">{{item.cn}}</h1>
					<h1 class="h1 en">{{item.en}}</h1>
					<div class="clear"></div>
				</div>
			</div>
	</div>
</template>

<script>
	import {
		ref,
		onMounted,
	} from 'vue';

	import circleItemEleVue from './circleItemEle.vue';
	import {
		mapState
	} from 'vuex';
	import {
		useStore
	} from 'vuex';
	import ajax from '@/ajax/index.js';
	import { useRoute } from 'vue-router';
	
	export default {
		components: {
			circleItemEleVue
		},
		computed: {
			...mapState(["baseUrl"])
		},
		setup() {
			const circleItems = ref(null)
			const radius = ref(null)
			const store = useStore()
			const circleData = ref(
				[{
						cn: "洽谈意向",
						en: "Negotiation intention",
						
					},
					{
						cn: "资格审核",
						en: "Qualifica tion review",
						
					},
					{
						cn: "交保证金",
						en: "Deposit",
						
					},
					{
						cn: "签订合同",
						en: "Sign the contract",
						
					},
					{
						cn: "首批进货",
						en: "First batch purchase",
						
					},
					{
						cn: "店面出样",
						en: "Shop appearance",
					
					},
					{
						cn: "系统培训",
						en: "System training",
						
					},
					{
						cn: "共创财富",
						en: "Create Wealth Together",
						
					}
				]

			)
			// 小屏幕渲染数据
			const mCircleData0 = ref([])
			const mCircleData1 = ref([])


			const baseUrl = store.state.baseUrl;
			/* 首页产品分类 */
			ajax(baseUrl + "web/joinprocessService").then(res => {
				let result = res;
				let eightsupport = [];
				/* cn: "洽谈意向",
				en: "Negotiation intention" */


				result.forEach((item,index) => {
					let cn = item.title;
					let en = item.titleen
					if(store.state.lang  == 'en'){
						cn =  undefined
						en =  item.titleen
					}
					if(index % 2 == 0){
						mCircleData0.value.push({
							cn: cn,
							en: en,
						})
					}else{
						mCircleData1.value.push({
							cn: cn,
							en: en,
						})
					}
					if(store.state.lang  == 'en'){
						eightsupport.push({
							cn: item.titleen,
							en:  undefined,
						});
					}else if(store.state.lang  == 'cn'){
						eightsupport.push({
							cn: item.title,
							en: item.titleen,
						});
					}
				});
				circleData.value = eightsupport;
			});


			// 监听屏幕宽度变化
			/**
			 * ResizeValue ==> true ==> 大于769
			 *                ==> false => 小于769
			 */
			const ResizeValue = ref(document.body.clientWidth > 769)
			window.addEventListener('resize', () => {
				if (document.body.clientWidth < 769) {
					ResizeValue.value = false
				} else {
					ResizeValue.value = true
				}
			})
			const route = useRoute();

			onMounted(() => {
				let leftPadding = circleItems.value.clientWidth * 0.095
				let RightPadding = circleItems.value.clientWidth * 0.023
				let r = (circleItems.value.clientWidth - leftPadding - RightPadding) * 0.25 / 2
				radius.value = r

				setTimeout(()=>{
					if(route.params.id == '1'){	
						document.querySelector(".cirles").scrollIntoView({behavior: "smooth"})
					}
				},0)
			})

			return {
				circleItems,
				radius,
				circleData,
				ResizeValue,
				mCircleData0,
				mCircleData1
			}
		}

	}
</script>

<style lang="less" scoped>
	.cirles {
		width: 100%;
		height: 91.7vh;
		position: relative;
		display: flex;
		background-color: #00543e;

		.circle-Items {
			width: 72%;
			height: 100%;
			box-sizing: border-box;
			padding: 4.4% 2.3% 4.8% 9.5%;
			padding: 4.4% 2.3% 4.8% 12.5%;
			display: flex;
			flex-wrap: wrap;

			.circleItemEleVue {
				// width: 25%;
				// height: 100%;
				position: relative;
				float: left;
				margin-left: -5%;

				.cont {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					text-align: center;

					.num,
					.cn,
					.en {
						text-align: center;
						display: block;
						color: #b9985e;
					}

					.num {
						font-size: 1.8rem;
						margin-bottom: 30px;
					}

					.cn {
						font-size: 3.6rem;
						margin-bottom: 4px;

					}

					.en {
						font-size: 1.6rem;
						width: 100px;
						margin: 0 auto;
						margin-bottom: 34px;
						text-transform: uppercase;
						font-weight: normal;
						margin-top: 15px;
					}
				}
			}
		}

		.imgs {
			flex: 1;

			// background-size: cover;
			.pic {
				width: 100%;
				height: 100%;
				background-image: url(../../assets/cooperation/03.jpg);
				background-size: 34%;
				background-attachment: fixed;
			}
		}

	}


	.cir {
		.box {
			width: 200px;
			height: 200px;
			border-radius: 50%;
			background-color: lightgoldenrodyellow;
			background-image: linear-gradient(to right, transparent 50%, lightskyblue 0);
		}

		.box:before {
			display: block;
			content: "";
			margin-left: 50%;
			height: 100%;
			border-radius: 0 100% 100% 0/50%;
			background-color: inherit;
			transform-origin: left;
			animation: spin 50s linear infinite paused,
				bg 100s step-end infinite paused;
		}

		@keyframes spin {
			100% {
				transform: rotate(180deg);
			}
		}

		@keyframes bg {
			50% {
				background-color: lightskyblue;
			}
		}

		.smaller:before {
			animation-delay: -20s;
		}

		.larger:before {
			animation-delay: -60s;
		}

		.smaller {
			cursor: pointer;
		}

		.smaller:hover {
			background-color: #fff;
		}
	}

	@media all and (max-width:1px) {
		.cirles {
			padding: 30px 15px;
			box-sizing: border-box;

			.circle-Items {
				width: 100%;
				padding: 0 0 0 0;
			}

			.cont {
				width: 50%;
				position: static;
				// top: 50%;
				// left: 50%;
				// transform: translate(-50%,-50%);
				text-align: center;

				.num,
				.cn,
				.en {
					text-align: center;
					display: block;
					color: #b9985e;
				}

				.num {
					font-size: 2rem;
					margin-bottom: 30px;
					border: 1px solid #b9985e;
					height: 40px;
					width: 40px;
					line-height: 40px;
					border-radius: 50%;
					float: left;
				}

				.cn {
					font-size: 2.6rem;
					margin-bottom: 4px;
					line-height: 40px;


				}

				.en {
					font-size: 1.6rem;
					width: 100%;
					margin: 0 auto;
					margin-bottom: 34px;
					text-transform: uppercase;
				}
			}

			.cont:nth-child(2n) {
				.num {
					float: right;
				}
			}
		}
	}

	@media all and (max-width:1400px){
		.cirles {
			height: 107.7vh;
			.circle-Items {.circleItemEleVue {.cont {.num{
			display: none;
		}}}}}
	}
	@media all and (max-width:1281px){
		.cirles {
			height: 77.7vh;
			.circle-Items {.circleItemEleVue {.cont {.num{
			display: none;
		}}}}}
	}
	@media all and (max-width:1025px){
		// .cirles .circle-Items .circleItemEleVue .cont .cn[data-v-9dadab8c]
		.cirles {
			height: 84.7vh;
			.circle-Items {.circleItemEleVue {.cont {.num{
			display: none;
		}
		.cn{
			font-size: 2.8rem;
		}
	}}}}
	}
	@media all and (max-width:769px){
		.cirles{
			padding: 15px;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			background-color: #b9985e;
			height: auto;
			padding: 30px 15px;
			.circle-Items {
				width: calc(50% - 8px);
				padding: 0 0 00 0;
				display: flex;
				flex-direction: column;
				.cont{
					width: 100%;
					background-color: rgba(255, 255, 255, 0.527);
					// background-color: rgb(184, 152, 93, 0.527);
					margin-bottom: 15px;
					border-radius: 8px;
					padding: 15px;
					box-sizing: border-box;
					text-align: center;
					.num{
						border-radius: 50%;
						padding: 8px;
						border: 1px #00543e solid;
						font-size: 2rem;
						color: #00543e;
						font-weight: 700;
						padding: 15px;
						box-sizing: border-box;
						display: inline-block;
					}
					.cn{
						font-size: 2rem;
						line-height: 2;
						color: #00543e;
					}
					.en{
						// font-size: 2.2rem;
						font-size: 1.6rem;
						line-height: 1.2;
						color: #00543e;
						height: calc(1.6rem * 1.2 * 2);
					}
				}
			}
		}
	}

</style>
