<template>
  <div class="dijia">
    <div class="title wow animate__fadeInRight">
        {{item.title}}
    </div>
    <div class="line"></div>
    <div class="desc wow animate__fadeInUp" v-html="item.desc"></div>
  </div>
</template>

<script>
export default {
    props:['item']
}
</script>

<style lang="less" scoped>
    .dijia{
        .title{
            font-size: 3.2rem;
            color: #00543e;
            margin-bottom: 9px;
            letter-spacing: 2px;
        }
        .line{
            height: 1px;
            width: 100%;
            background:#00543e;

        }
        .desc{
            width: 97%;
            color: #666;
            padding: 38px 0 0 0;
            letter-spacing: 2px;
            
            // font-size: 1.8rem;

        }
    }
    @media all and (max-width:769px){
        .dijia{
            .title{
                font-size: 2.4rem;
            }
            .desc{
                width: 100%;
                padding: 15px 0 0 0 ;

                // font-size: 1.4rem;

            }
        }
    }
</style>