<template>
	<div class="cases">
		<div class="left">
			<!-- :style="`background-image:url(${});background-size: cover;`" -->
			<div v-if="!page.isM" class="lcase">
				<div :class="'case case' + (index+1)" v-for="(item,index) in classify" :key="index">
					<router-link :to="'/plant/'+index">
					<div class="pic">
						<img :class="isActive ? 'topImg' : 'onecTran topImg'" v-lazy="item.imgUrl" alt="">
						<img class="bottomImg" v-lazy="item.imgUrl" alt="">
					</div>
					<div class="title ">
						<p class="en">{{item.en}}</p>
						<p class="cn">{{item.cn}}</p>
						<p class="name">{{item.name}}</p>
					</div>
				</router-link>
				</div>
			</div>

			<Swiper v-if="page.isM" :modules="modules" :autoplay="{
                delay: 2500,
                disableOnInteraction: false,}" :pagination="{clickable: true}" class="mcase">
				<SwiperSlide class="mcase-slide" v-for="(item,index) in classify" :key="index">
					<div :class="'case case' + (index+1)">
						<div class="pic">
							<img :class="isActive ? 'topImg' : 'onecTran topImg'" v-lazy="item.imgUrl" alt="">
							<img class="bottomImg" v-lazy="item.imgUrl" alt="">
						</div>
						<div class="title ">
							<p class="en">{{item.en}}</p>
							<p class="cn">{{item.cn}}</p>
							<p class="name">{{item.name}}</p>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
		<div class="right">
			<div class="case case4">
				<!-- <div class="pic wow animate__fadeInUp"> -->
				<div class="pic">
					<div v-if="!page.isM" :class="isActive ? 'Ani mask' : 'mask'"></div>
					<div v-if="page.isM"></div>
					<img v-lazy="require('../../assets/index/d4.png')" alt="">
				</div>
				<div v-if="page.isM" class="content">
					<p class='en wow animate__fadeInUp'>{{more.en}}</p>
					<p class='cn wow animate__fadeInUp'>{{more.cn}}</p>
					<p class='agae wow animate__fadeInUp' v-html="more.agae"></p>
					<more :url="more.url" class='more wow animate__fadeInUp'></more>
				</div>
				<div v-if="!page.isM" class="content">
					<p :class="isActive ? 'en animate__fadeInUp' : 'en'" style="animation-delay:1s ;">{{more.en}}</p>
					<p :class="isActive ? 'cn animate__fadeInUp' : 'cn'" style="animation-delay:1.2s ;">{{more.cn}}</p>
					<p :class="isActive ? 'agae animate__fadeInUp' : 'agae'" style="animation-delay:1.3s ;"
						v-html="more.agae"></p>
					<more :url="more.url" :class="isActive ? 'more animate__fadeInUp' : 'more'" style="animation-delay:1.4s ;">
					</more>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import more from '../../components/more/MoreEle.vue'

	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	import {
		Pagination,
		Autoplay
	} from 'swiper';
	import {
		mapState
	} from 'vuex'

	import ajax from '@/ajax/index.js'

	export default {
		props: ["isActive"],
		data() {
			return {
				page: {
					isM: true
				},
				classify: [{
					en: "SMART FACTORY",
					cn: "智能工厂",
					name: "日照工厂",
					imgUrl: require('../../assets/index/d1.png')
				}, {
					en: "SMART FACTORY",
					cn: "智能工厂",
					name: "郯城工厂",
					imgUrl: require('../../assets/index/d2.png')
				}, {
					en: "SMART FACTORY",
					cn: "智能工厂",
					name: "邳州工厂",
					imgUrl: require('../../assets/index/d3.png')
				}],
				more: {
					en: "SMART MANUFACTURING",
					cn: "智能制造",
					agae: `引进德国工业4.0最新设备, 德国EWK环保设备及连续平压线设备皆为国际一线水准, 
                保障了澳思柏恩产品的品质与环保性能接轨国际,
                实现了自动化、智能化绿色生产。`,
					imgUrl: require('../../assets/index/d4.png'),
					url:'/plant'
				}
			}
		},
		computed: {
			...mapState(["baseUrl",'lang'])
		},
		components: {
			more,
			Swiper,
			SwiperSlide
		},
		mounted: function() {
			let _this = this

			/* 首页智能工厂 */
			ajax(this.baseUrl + "web/showintelligentplantService").then(res => {
				let result = res;
				let showintelligentplant = [];
				/* 	{
										en: "SMART FACTORY",
										cn: "智能工厂",
										name: "郯城工厂",
										imgUrl: require('../../assets/index/d2.png')
									} */

				result.forEach(item => {
					let en = item.ename
					let cn = item.name
					if(_this.lang == 'en'){
						cn = ""
					}
					showintelligentplant.push({
						en: en,
						cn: cn,
						name: item.title,
						imgUrl: this.baseUrl + item.imgurl
					})



				});
				this.classify = showintelligentplant;

			});
			/* 首页智能工厂简介 */
			ajax(this.baseUrl + "web/showintelligentplantageService").then(res => {
				let result = res;
				let showintelligentplantage = [];
				/* {
					en: "SMART MANUFACTURING",
					cn: "智能制造",
					agae: `引进德国工业4.0最新设备, 德国EWK环保设备及连续平压线设备皆为国际一线水准, 
				保障了澳思柏恩产品的品质与环保性能接轨国际,
				实现了自动化、智能化绿色生产。`,
					imgUrl: require('../../assets/index/d4.png')
				
				} */

				result.forEach(item => {
					let en = item.ename
					let cn = item.name
					if(_this.lang == 'en'){
						en = ""
						cn = item.ename
					}
					showintelligentplantage.push({
						en: en,
						cn: cn,
						agae: item.particulars,
						url:"/plant"
					})



				});
				this.more = showintelligentplantage[0];

			});


			// 页面初次进入加载
			if (document.body.clientWidth > 769) {
				this.page.isM = false
			}
			// 宽度改变切换
			window.onresize = function() {
				if (document.body.clientWidth > 769) {
					_this.page.isM = false
				} else {
					_this.page.isM = true
				}
			}

		},
		setup() {
			return {
				modules: [Pagination, Autoplay]
			}
		}
	}
</script>

<style>
	.mcase .swiper-pagination {
		display: flex;
		margin-left: 15px;
		width: 70%;
		height: 2px;
		/* background:black; */
		margin-left: 30px;
		bottom: 9% !important;
		display: flex;
		justify-content: space-between !important;
	}

	.mcase .swiper-pagination-bullet {
		background: rgb(255, 255, 255, .5) !important;
		display: block;
		height: 100%;
		flex: 1;
		display: block;
	}

	.mcase .swiper-pagination-bullet-active {
		background: rgb(255, 255, 255, .9) !important;
	}
</style>

<style lang="less" scoped>
	.cases {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-wrap: wrap;
		// background-image: url(../../assets/03.jpg);
		background-size: 100%;
		background: #fff;

		// flex-direction: column;
		.left,
		.right {
			height: 100%;
			// flex: 1;
			width: 50%;
			display: flex;
			flex-wrap: wrap;
		}

		&>.left {
			.lcase {
				width: 100%;
				height: 100%;
				display: flex;
				flex-wrap: wrap;
			}
		}

		.case {
			// opacity: .5;
			position: relative;
			padding-left: 9.1rem;
			box-sizing: border-box;
			a{
				width: 100%;
				height: 100%;
				display: block;
				&>.pic {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;

				img {
					transition: 0.7s;
				}

				.topImg {
					opacity: 1;
					z-index: 9;

					&.onecTran {
						transform: scale(1.2) translate(-50%, -50%);
					}
				}

				.bottomImg {
					width: 200%;
					z-index: 8;

				}
			}
			}
			&:hover {
				&>.pic {
					img {
						// transform: scale(2) translate(-50%,-50%);

					}

					.topImg {
						transform: scale(1.2) translate(-50%, -50%);

						// opacity: 0;
					}
				}
			}

			

			.title {
				position: absolute;
				bottom: 70px;
				z-index: 9;

				.en,
				.cn,
				.name {
					// color: #fff;
					width: 100%;
					line-height: 1;
					width: 100%;
					color: #fff;
				}

				.en {
					height: 2rem;
					font-size: 2rem;

				}

				.cn {
					height: 2.2rem;
					font-size: 2.2rem;
					margin-top: 10px;
				}

				.name {
					height: 4.1rem;
					font-size: 4.1rem;
					margin-top: 40px;
				}
			}

			&.case1 {
				width: 50%;
				height: 50%;
				background: #111;
				order: 1;
			}

			&.case2 {
				width: 50%;
				height: 50%;
				background: #666;
				order: 2;


			}

			&.case3 {
				width: 100%;
				height: 50%;
				background: #acabab;
				order: 3;

			}

			&.case4 {
				width: 100%;
				height: 100%;
				background: #00543e;
				order: 4;
				position: relative;
				padding-left: 17%;

				.pic {
					position: absolute;
					left: 8.7%;
					top: 9.2%;
					width: 88%;
					height: 0;
					padding-top: 33.6%;
					// opacity: 0;

					.mask {
						opacity: 1;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: #00543e;
						transition: opacity 1s;
						transition-delay: 1s;

						z-index: 9;

						&.Ani {
							opacity: 0;
						}
					}

					// background-color: rgba(88, 88, 88, .8);
					img {
						mix-blend-mode: multiply;

					}

					&.Ani {
						opacity: 1;
					}


				}

				.content {
					position: absolute;
					bottom: 95px;
					// width: 100%;

					.en,
					.cn,
					more,
					.agae {
						width: 100%;
						color: #b9985e;
						opacity: 0;
					}

					.en {
						font-size: 3.4rem;
					}

					.cn {
						font-size: 5.5rem;
						line-height: 1;
						margin-top: 1.8rem;
					}

					.more {
						margin-top: 100px;
						display: inline-block;
						color: #b9985e;

					}
					
					.agae {
						width: 50%;
						font-size: 1.6rem;
						line-height: 1.65;
						// margin-top: 6.5rem;
						margin-top: 3rem;
						letter-spacing: 2px;

						-webkit-line-clamp: 6;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

			}
		}
	}

	@media all and (max-width:1100px) {

		.cases {

			.left,
			.right {}

			.case {
				padding-left: 30px;

				&.case1 {}

				&.case2 {}

				&.case3 {}

				&.case4 {
					padding-left: 30px;

					.pic {
						position: absolute;
						left: 30px;
						top: 15.2%;
						width: 92%;
						height: 0;
						padding-top: 33.6%;
					}

				}

				.content {
					.en {}

					.cn {}

					.agae {}

					.more {}
				}

			}
		}
	}

	@media all and (max-width:800px) {
		.cases {

			.left,
			.right {}

			.case {
				padding-left: 30px;

				.title {
					.en {
						font-size: 1.8rem;
					}

					.name {
						font-size: 3.4rem;
					}
				}

				&.case4 {
					padding-left: 30px;

					.pic {
						position: absolute;
						left: 30px;
						top: 15.2%;
						width: 92%;
						height: 0;
						padding-top: 33.6%;
					}

				}
			}
		}
	}

	@media all and (max-width:769px) {
		.mcase {
			width: 100%;
			height: 100%;

			.mcase-slide {
				width: 100%;

				.case {
					width: 100%;
					height: 100%;
				}
			}
		}

		.cases {
			height: auto;
			flex-wrap: wrap;

			&>.left {
				display: flex;
				height: 50vh;
				order: 2;
			}

			&>.right {
				// padding-bottom: 30px;
				order: 1;
			}

			.left,
			.right {
				width: 100%;
			}

			.case {
				padding: 15px;

				&.case1 {}

				&.case2 {}

				&.case3 {}

				&.case4 {
					padding: 15px;
					padding-bottom: 30px;
					padding-top: 60px;
					padding-bottom: 80px;

					.pic {
						left: 0;
						top: -10px;
						width: calc(100%);
						position: relative;
						margin-bottom: 30px;
						padding-top: 50.6%;
					}

					.content {
						position: static;

						.en {
							font-size: 1.8rem;
							opacity: 1;
						}

						.cn {
							font-size: 2.8rem;
							opacity: 1;
						}

						.agae {
							width: 100%;
							margin-top: 30px;
							opacity: 1;
							font-size: 1.4rem;
						}

						.more {
							margin-top: 30px;
							opacity: 1;

						}
					}

				}
			}
		}
	}
</style>
