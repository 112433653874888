<template>
  <div class="news-more">
    <div class="icon pic">
        <img v-if="type=='more'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAD/klEQVRoge3aXYgVZRgH8N8Zz5pr2wcRG1uCIZp9KBKG3USG2dVKUEFC0H3gvXgRRV6F94HnsrraICLZhehDCoWwG4XMPgw1MCe9CKr9arNjF+87zrCdOTvnnF07yfnDYd4z87zPM/+Zd+b9P8+8tUajIWIU+/EC1qOmOi5hCm/iXAf9NuAAxnFvB/2u4Se8j0Npml6BWiTzOI4IhHrBLPZisoLtHkxgbY8xr+DZNE1P1AUCi4nM4q+KzlZhJLbX4j3swOk2fbZEu+HCvj/QrBhzSH4RRnFkbGxsayIMrYzIz9gdT+7Oir/bsB1noo9hHFziZA4WiJzBY7i9g5gjeAZpgdD+WqPROI/7487d+GyJEynDJnyHBAu4A/Mt7NbgN6wW7sRm/NhlzN34JLYvJMLDThha3RKBs/g2tldjXYndunicQL5bIoTznY3t9Yn8rVX1GWmHq4X2SIlNcX+vMa8VfNSSHp31FQZk+hUDMv2KAZl+xYBMvyIpaXeLVYX2XIlNcf+yxkyExIpc/XaLUTwQ201cLLG7KJf6m/WWQz0ql0eXEiFDzPAONnbhdFRItDIB+TlmSmxn4nHRfkJ3hDbh3cL/qVqj0diAr+XJTlNQs1VFYCJc4YxIEztxvE2fJ/CFfJgt4Af8XTFmHQ8V+s9ia13I2ffKM78ED1d0uhhN7NOeiHh8H96K8VYL2Wc3mMPeNE3PZcwmhVT3A+EqdYomjgp35HDFPoej/VHV0+UiFoTz3ZGm6SThdmU4jeeFTHCd8nxkMeaEh7rsGWmH43gat8aYw+3Nr2M6xpxP0/T6znoLw3m9ZX/dYAbf9+rkpp00//cYkOlXtHoB3Mi32bKiSGaLUGkcl8/mVdEUJMrrlp4wixgT6nYnhLJRK2zD7zjf0sHY2C1CrfxkNsz24Cs8p3MihOG6S5Aor1Tsc7dQNPwSr5bYvIRTwmu7TCFMxLjH6sJnhQn5hNWMQa627vsvFLVZIkiU05a+Q48IJVzK1fqOuB0S6tGtivFPxu22uvB9JBOZ3wh35+wSJ7IY9wgXZKdA6A1hZm+HTr7/tENSbIwXDryscyJwWRCrma57SpAoNxSJ/IvVNE724OuyXJIkygvnK4biPFM1l2iHovqtKhqXDTfVpDkg068YkOlXDMj0KwZk+hWJPI8Y0rv4Gyq0p3v01TESYXUQQTnv6sHXRjwY2wvKC+crhkRY5pThbUtL91bYjg/lw3ZK66UmK4o6DgnSfxT34VOdrWpKhM8hGebwWoV+nZZky4TwdT+JuF4rbjOsFbLAKr8ikVm8qP3yrAyncCG2p0psPhIy3l9xrMQmG1kf1/7jlYBrhKVZV9rY3IU/lVd/akJO9ss/t+HYxck+2JcAAAAASUVORK5CYII=" alt="">
        <img v-if="type=='back'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAoCAYAAABOzvzpAAAD4klEQVRogeWaXYhVVRTHf2ODZGaijknqg2KKJgpGaqRWDz0IPlZQKmnkg/SggkpKLzHqiPTUWw8hOneECIKekxI/MGcs8DOkUNQINHwZadJR6x9b1pXD6Z6z9zln37k3/MO5HM4+a929/mettdf+6JBEJKwCjgB/lVA3DlgAnIzVmVB0RtJTA94AppWUPwpcaQUBoyLo6AHWAr8B/5SQ7wMWAZcj9KUwqhLQDey0+9sl5HuBNXZ/r2JfSqFKCPQkjHd4oqB8zTynjhjeWBhlCdidMr4oDgGrR9bUxijDunP7jxs87wiU720X4x2KekDa7ZOYHCDfl4j5tkARD9jlcfsvPPK1djP+IVwhFHDtVj4+8ujo9cgrQEdTrhClPZ6Ob/fIHwow3mG/pK52I6Db0+mdHvlaoPF13JL0k6Q+SR9KmtdKAvZ6OrstsvFZuGhe+NJIElA15vsiGZ/GSUnrmk3ALk8ndniUhiS8qrgg6f1mEFDV7Zv15bNwStKKWARU/fKxYr4MuqsSsMfzp3kx39Fi4+s4JmlqGQI2exR/4lFycORs9OKGpEVFCHCl8PfApZxi8XVgUk77N62ayzfAFOAHYHmwhDExTtKZHHqvS5qWw+QSSXfaxAsc7lufvB6QXBQda2tyCzO4ugq8DNzMaF8KHANGV/uI0TAEzAeu5SlMzgadwKvAhYx3Z5h7PZfR3m+ud7e1dj+C+6Df+qb86enwoH3lMxnvzwROAVMz2k8Dr7VRTphj0/BsZMSGywnnc2LssqTJObG1VNLdEYx5H94NyQFpPAMcz8kJ14FXgN8z2hdbTngy5ictiWHg2UYr176doacs7rNIuGYhcyOj3SXGE4FLb18DXwJdFppu6J1guWciMAuYXoGEg8D6/zwNGCrGB4TDlAhD5FZPP8ZKmivpLUmf2jzgQcFQWJA3F8i7QuqEWR4SfDlhT4lafqakTZIOBxLwXVkC3PW0pHM5yg975F1iHI5MQPJyCyafB3jFwnQpHIo/gRXAxYz3x3v09Jv8nQpxnIcfgY3AC7bxkoUdyedFN0YGLbGdbdA2FCA/YHOL+0WtK4BfbMvN1SPnGoi9bUn1IcrsDPkqRh8GrGIcjml1A7gh+EXgs1RTZ3I0KLsh6cbTZSmGi5y0GLBwaHbZ/DewBXgv9fyd+k2VHdnbVgjVSSh61OR0KhweVOiLDzX7r3qYLrYyufKWdD0c/rAjLkXRb57k8HwzLE/AnUJZkgi9le4nxp78oMX0V8CYEvLOEzYAv0boiw8/myc4fOB+Yh6SwtYC2mUmmId1wAHgzdgE/J+wz51qeZwJ6ARm/wtGIJioWkdNRQAAAABJRU5ErkJggg==" alt="">
    </div>
    <p>{{desc}}</p>
  </div>
</template>

<script>
import { ref} from 'vue';
export default {
    props:['desc','type'],
    setup(props){
        const lang = ref(props.desc)
        return {
            lang
        }
    }
}
</script>


<style lang="less" scoped>
.news-more{
    width: fit-content;
    display: flex;
    padding: 22px 40px;
    border-radius: 44px;
    border: 1px solid #999;
    margin: 85px auto ;
    cursor: pointer;

    

    .icon{
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 15px;
    }
    p{
        font-size: 2.4rem;
        color: #999;
        line-height: 1;
    }
    
}

@media all and (max-width:769px){
    .news-more{
        padding: 12px 25px;
        margin: 0 auto ;
        margin-top: 50px ;

        

        .icon{
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 15px;
        }
        p{
            font-size: 1.6rem;
            color: #999;
            line-height: 1;
        }
        
    }
}
</style>