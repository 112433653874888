<template>
    <navigationEleVue :isActive="true"></navigationEleVue>

  <div class="coop">
    <bannerEleVue class="bannerEleVue" :item="title" ></bannerEleVue>
    <!-- 八大支持 -->
    <supportEle ref="supportEle" class="coopEle" ></supportEle>
    <!-- 合作流程 -->
    <circleEle ref="circleEle" class="coopEle" ></circleEle>
    <!-- search -->
    <serchEle ref="serchEle" class="serchEle coopEle"></serchEle>

    <!-- 底部 -->
    <skipEleVue :id="6"></skipEleVue>
    <footerEleVue></footerEleVue>
    

  </div>
</template>

<script>
import bannerEleVue from '@/components/public/bannerEle.vue'; 
import supportEle from '@/components/cooperation/supportEle.vue'; 
import circleEle from '@/components/cooperation/circleEle.vue'; 
import serchEle from '@/components/cooperation/serchEle.vue'; 
import navigationEleVue from '@/components/public/navigationEle.vue'; 


import footerEleVue from '@/components/public/footerEle.vue';
import skipEleVue from '@/components/public/skipEle.vue';
export default {
    components:{bannerEleVue,supportEle,circleEle,serchEle,footerEleVue,skipEleVue,navigationEleVue},
    setup(){
        // const route = useRoute();
        const title = {
            titleCn:"招商合作",
            titleEn:"Investment Promotion And Cooperation",
            desc:"定义高端制造的生活方式",
            imgurl:require('../assets/cooperation/coop.jpg'),
            video:{
                cover:"require('../assets/about/video.jpg')",
                url:""
            }
        }
        // 底部上面的板块
        const skip = {
            imgurl:require('../assets/brandDetail/db.jpg'),
            url:"/contact",
            title:"对我们的产品感兴趣<br />可以联系我们哦!"
        }
        // const supportEle = ref(null)
        // const circleEle = ref(null)
        // const serchEle = ref(null)

        // onMounted(()=>{
        //     // // document.querySelectorAll(".coop")[route.params.id].scrollIntoView({behavior: "smooth"})
        //     // console.log(document.getElementsByClassName("coopEle"))
        //     switch(route.params.id) {
        //         case '0':
        //             // supportEle.value.$el.scrollIntoView({behavior: "smooth"})
        //             supportEle.value.$el.parentElement.scrollIntoView({behavior: "smooth"})
        //             break;
        //         case '1':
        //             console.log(circleEle.value.$el)    
        //             circleEle.value.$el.parentElement.scrollIntoView({behavior: "smooth"})
        //             break;
        //         case '2':
        //             serchEle.value.$el.scrollIntoView({behavior: "smooth"})
        //             break;
        //         default:
        //             break;
        //     } 

        // })
        
        

        return {
            title,skip,supportEle,circleEle,serchEle,
        }
    },
    metaInfo() {
      return {
        title: this.titleName, // set a title
        meta: [
          {
            // set meta
            name: "keywords",
            content: this.keyword,
          },
          {
            name: "Description",
            content: this.description,
          },
        ],
      };
    },
}
</script>



<style lang="less" scoped>
.coop{
    &>img{
        width: 100%;
        position: absolute;
        z-index: -1;
        // background: #fff;
    }
    .serchEle{
        background-color: #EFEFEF;
    }
}


</style>
<style lang="less" >
.bannerEleVue .title-en{
    width: 60% !important;
}
</style>