<template>
    <navigationEleVue :isActive="true"></navigationEleVue>
    <div class="brand">
        <Swiper @swiper='setBrand' :modules="modules" :effect="'fade'" :direction="'vertical'" :mousewheel="true"
            :controller="{ control: circleSwiper }" @transitionEnd="slideChangeTransitionEnd"
            @slideChangeTransitionStart="slideChangeTransitionStart" class="brand-swiper">
            <SwiperSlide class="brand-slide" v-for="(item, index) in datas" :key="index">
                <div class="pic"><img :src="item.imgurl" alt=""></div>
                <div class="name ani animate__fadeInUp">{{ item.name }}</div>
                <!-- <div class="cont ani animate__fadeInUp" v-html="item.en_canpname"></div> -->
                <div class="cont">
                    <div class="en ani animate__fadeInUp">{{ item.en }}</div>
                    <div class="canpname ani animate__fadeInUp">{{ item.canpname }}</div>
                    <!-- <div class="en ani animate__fadeInLeft">{{ item.en }}</div>
                    <div class="canpname ani animate__fadeInLeft">{{ item.canpname }}</div> -->
                </div>
                <ul class="merits">
                    <li :class="{ merit: true, ani: true, animate__fadeInUp: true, merit_wid: item.merits.lang }"
                        v-for="(merit, index) in item.merits.merits" :style="{ animationDelay: index / 10 + 's' }"
                        :key="index">
                        <div class="icon pic"><img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAADTklEQVRYhd2ZTUhVQRTH/yo+JcRNb9OiXdlCfQ+zL1sY2SKCaFlQ4apNy1rVJogg2qSgiwpcBUK0SJSKpK9l7cRUCF0aBGGRJIWf/xj4D9zm3e9333vagcNjZu6Z83tn5s6cmVtHEiklB6AHwEkABQBtAPYAaFF3KwC+ApgH8AnAewAfAKylcmdAE2oXyUckvzO5/JBtV1K/SSJaBHAXwBkAdaqbBfBWkfoMYFGRhCK7F8ABAMcBnALQYeMD4BWAmwCms4poM8kBkuuK34rKnSlGo1O2K+rL9DkoH6G2UR3vIzmlTjdIDpHMpwB0dbf62lDfU/KVCvQwyW/qaF7lcgFdPaS+jSyRPJoU1Bj8UgcvSbZWANJqq3xQPn1hg4bbRvIJycYKQlptlC/Kd8k0cA2aPXNygmSuCpBWc/Jp52xzGOiAHlyo8HCHTYMFMdwPAi1quTBvYncNIK12i8GwFPxAX+ifDFcRymw4d3zm5JBYnrugZkvb0luXxToZF/KBgL44sHmxbNnt1jaMyGCwBpAMgLXvy4gFbSL5U5UdVYBsUGLiJ4skW/Rcu9oNW5Op6FXFbJUgRwMgN0lecZ6fUVtvPYA+5Sevk6SHKaQBwGMAF31MtwD0Axhx6t/ot69e6ZuRjzWGHPVps0xFE945hbcQY+jM7tGTcLiNzbOQ4b4UYlvQc3NQ1sIYy5Ld4tZInksAOREAuREBCaWDRpZMYVWFpgQO12I4CYOM+2dzen7VCxqUgAQ5NMPWH2CzKwPIEtCooTcPPw1wauSqD+S7DCBLhj7OyxS2/hm5XgHIkpdpTIULEUZRsLdCIH+TPJ0Q0uh52Y/Ve46rxyLWwk0AlwE8DGi/rcsIV/4AOAtgMt6S+4/0qDANzxY6E/Nf+iUUQWIi2ZciklbtFnoCTlLSngB2sMKQNilZtkkJPGneQMLO7gVALpcJCb80z+hBVaZJnF3Y5bDzeUzNe47r3XSOIpNqSHMUuZEhJDxHkUlb520s93B3LSNI7+Gu6AeKbXJctlc8gcdlOBcQ4zW4gBiX78gLCOyUKx2rO+KSzOoRT2Y1ryvCrCHLvna0ut8zZ9crcJG77pmTbWE2cTrdFlfj/9XHBle3/ecbV6r3QQzAXykKuRQ83281AAAAAElFTkSuQmCC"
                                alt=""></div>
                        <p>{{ merit }}</p>
                        <div class="claer"></div>
                    </li>
                </ul>
                <inMoreVue class="inmore" :url="('/brand/' + item.id + '/' + item.default)"></inMoreVue>
            </SwiperSlide>
        </Swiper>

        <div class="circle-contioner">
            <div class="small-contioner" :style="'transform: translate(-50%,-50%) rotate(' + circleDeg + 'deg);'"></div>
            <Swiper @swiper='setCircle' :modules="modules" :controller="{ control: brandSwiper }" class="circle-swiper">
                <SwiperSlide class="circle-slide" v-for="(item, index) in datas" :key="index">
                    <div class="pic">
                        <img :src="item.icon" alt="">
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>

        <div class="pagination">
            <div v-for="(item, index) in datas" :key="index" @click="slideTo(index)"
                :class="{ bullet: true, active: index == pagiActive }">
                <span class="name">{{ item.name }}</span>
                <div class="cir">
                    <div class="cir1"></div>
                    <div class="cir2"></div>
                    <div class="cir3"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref,onUpdated,onMounted } from 'vue';
import { useStore } from "vuex";

import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectFade, Controller, Pagination, Mousewheel } from 'swiper';
import 'swiper/css';
import "swiper/css/effect-fade";

import inMoreVue from '@/components/public/inMore.vue';
import navigationEleVue from '@/components/public/navigationEle.vue';
import ajax from '@/ajax';
import { useRoute } from 'vue-router';

export default {
    components: { Swiper, SwiperSlide, inMoreVue ,navigationEleVue},
    setup() {
        // 基础数据
        const datas = ref([
            {
                id: 0,
                name: "饰能板",
                en: "ENERGY VENEER",
                canpname: '澳思柏恩饰能板',
                icon: '',
                imgurl: require('../assets/brand/b1.jpg'),
                merits: ['松木细料超平整', '南北通用饰面抗龟裂', '吸水膨胀率低', ' 精准铺装偏差小'],
                child: [
                    { name: "澳思柏恩饰能板" }
                ]
            }, {
                id: 0,
                name: "LSB系列",
                en: "ENERGY VENEER",
                canpname: '澳思柏恩饰能板',
                icon: '',
                imgurl: require('../assets/brand/b2.jpg'),
                merits: ['松木细料超平整', '南北通用饰面抗龟裂', '吸水膨胀率低', ' 精准铺装偏差小'],
                child: [
                    { name: "LSB高定专享板" },
                    { name: "LSB高定专享板" },
                    { name: "LSB高能家居板" },
                    { name: "锌效抗菌LSB高能家居板" },
                ]
            }, {
                id: 0,
                name: "澳松板系列",
                en: "ENERGY VENEER",
                canpname: '澳思柏恩饰能板',
                icon: '',
                imgurl: require('../assets/brand/b3.jpg'),
                merits: ['松木细料超平整', '南北通用饰面抗龟裂', '吸水膨胀率低', ' 精准铺装偏差小'],
                child: [
                    { name: "澳松板" },
                    { name: "锌效抗菌澳松板" },
                ]
            }, {
                id: 0,
                name: "OSB",
                en: "ENERGY VENEER",
                canpname: '澳思柏恩饰能板',
                icon: "",
                imgurl: require('../assets/brand/b3.jpg'),
                merits: ['松木细料超平整', '南北通用饰面抗龟裂', '吸水膨胀率低', ' 精准铺装偏差小'],
                child: [
                    { name: "实木切片OSB" },
                    { name: "难燃OSB" },
                ]
            }
        ])
        const store = useStore();
        const baseUrl = store.state.baseUrl
        const route = useRoute();
        const init = async function () {
            let aDatas = []
            const aBanner = await ajax(baseUrl + 'web/brandbannerService')
            // const aTage = await ajax(banner + 'web/brandbannerlabelService',{bannerId,aBanner})
            console.log(aBanner)

            for (let index = 0; index < aBanner.length; index++) {
                const item = aBanner[index];
                // const aTage = await ajax(baseUrl + 'web/brandbannerlabelService', { bannerId: item.id })
                // console.log(aTage, "======================================")
                let merits = {
                    lang: false,
                    merits: []
                }
                // aTage.forEach(item => {
                //     console.log(item.label.length)
                //     if (item.label.length > 9) {
                //         merits.lang = true
                //     }
                //     merits.merits.push(item.label)
                // })


                const brand_pro = await ajax(baseUrl + 'web/brandproductService', { brid: item.id })
                aDatas.push({
                    id: item.id,
                    // default: brand_pro.length <= 0 ? brand_pro[0].id : '0',
                    default: brand_pro[0].id,
                    name: item.name,
                    en: item.brandAgeen,
                    canpname: item.brandAge,
                    icon: baseUrl + item.productImg,
                    imgurl: baseUrl + item.backgroundImg,
                    merits: merits,
                    child: [
                        { name: "澳思柏恩饰能板" }
                    ]
                })
                console.log(aDatas, "brand_pro")
            }
            datas.value = aDatas
        }

        init();

        const brandSwiper = ref(null)
        const circleSwiper = ref(null)
        const pagiActive = ref(0) // 处于那一张slide
        const circleDeg = ref(0) // 小圆球旋转角度


        const setBrand = swiper => {
            brandSwiper.value = swiper
        }
        const setCircle = swiper => {
            circleSwiper.value = swiper
        }

        const slideChangeTransitionStart = function () {
            let avgDeg = 360 / datas.value.length
            circleDeg.value = avgDeg * brandSwiper.value.activeIndex
            circleSwiper.value.slideTo(brandSwiper.value.activeIndex)
            console.log(circleDeg.value)


            const slide = document.querySelectorAll(".brand-slide");
            let index = 0

            let interval = setInterval(() => {
                if (slide[circleSwiper.value.activeIndex].querySelectorAll('.ani')[index]) {
                    slide[circleSwiper.value.activeIndex].querySelectorAll('.ani')[index].classList.remove("animate__animated")
                    slide[circleSwiper.value.activeIndex].querySelectorAll('.ani')[index].classList.remove("animate__fadeInUp")
                    index++
                } else {
                    clearInterval(interval)
                }
            }, 16.4)
            // [].forEach.call(slide[circleSwiper.value.activeIndex].querySelectorAll('.ani'),(item)=>{
            //     console.log(item)
            //     item.classList.remove("animate__animated")
            //     item.classList.remove("animate__fadeInUp")
            // })

        }
        const slideChangeTransitionEnd = function () {
            console.log("结果测试")
            pagiActive.value = circleSwiper.value.activeIndex;
            console.log(circleSwiper.value.activeIndex, "slide_end");
            // const slide = document.querySelectorAll(".brand-slide");
            const slide = document.querySelectorAll(".brand-slide");
            console.log(slide)
            let index = 0

            let interval = setInterval(() => {
                if (slide[circleSwiper.value.activeIndex].querySelectorAll('.ani')[index]) {
                    slide[circleSwiper.value.activeIndex].querySelectorAll('.ani')[index].classList.add("animate__animated")
                    slide[circleSwiper.value.activeIndex].querySelectorAll('.ani')[index].classList.add("animate__fadeInUp")
                    index++
                } else {
                    clearInterval(interval)
                }
            }, 16.4)
        }
        // pagetion 点击跳转
        const slideTo = function (index) {
            brandSwiper.value.slideTo(index)
            pagiActive.value = index
        }

        const SwiperControl = (function () {
            let time = 0
            return function (e) {
                let now = new Date().getTime()
                console.log()
                if (time - now > 1300)
                    if (e.deltaY > 0) {
                        console.log("上滑")
                    } else if (e.deltaY < 0) {
                        console.log("下滑")
                    }
            }
        })()
        const title = ref('title');
        const keyword = ref('keyword');
        const description = ref('description');
        const getDate = async() => {
            let slideInfo = await ajax(baseUrl + "web/brandbannerService");
            console.log(route.params.id);
            let url = ""
            let data = []
            if(route.params.id){
              url = slideInfo[route.params.id].name
              data = await ajax(baseUrl + `web/seoyhxqService?title=${url}`);
            }else{
              url = store.state.langPack[store.state.lang].navigation[1].name
              data = await ajax(baseUrl + `web/seoyhService?title=${url}`)
            }
            title.value =  data[0].title;
            keyword.value =  data[0].keyword;
            description.value =  data[0].description;
        }
        getDate();
        onUpdated(async() => {
            getDate()
        })
        onMounted(()=>{
            getDate();
        })

        return {
            modules: [EffectFade, Controller, Pagination, Mousewheel], datas, SwiperControl, setBrand,
            setCircle,
            slideChangeTransitionStart,
            slideChangeTransitionEnd,
            slideTo,
            circleDeg,
            pagiActive,
            title,
            keyword,
            description
        }
    },
    metaInfo() {
      return {
        title: this.title, // set a title
        meta: [
          {
            // set meta
            name: "keywords",
            content: this.keyword,
          },
          {
            name: "Description",
            content: this.description,
          },
        ],
      };
    },
}
</script>

<style>
.brand-slide {
    background: #ccc;
}
</style>

<style lang="less" scoped>
.brand {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    &>img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -11;
    }

    .brand-swiper {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .brand-slide {
            // padding: 17.3% 0% 0% 8%;
            padding: 10.3% 0% 0% 8%;
            box-sizing: border-box;
            width: 100%;
            box-sizing: border-box;

            .ani {
                opacity: 0;
            }

            &>.pic {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -11;

                img {
                    transform: translate(-50%, -50%);
                }
            }

            .name {
                color: #fff;
                font-size: 2.4rem;
                letter-spacing: 5px;
                width: fit-content;
                font-weight: 700;


                &::after {
                    content: "";
                    display: block;
                    width: 130%;
                    height: 2px;
                    background-color: #fff;
                    margin-top: 9px;
                }
            }

            .en {
                text-transform: uppercase;
                color: #fff;
                font-size: 6.2rem;
                font-weight: 700;
                letter-spacing: 3px;
            }

            .canpname {
                font-size: 2.0rem;
                color: #fff;
                letter-spacing: 3px;
                margin-top: 2rem;
            }

            .merits {
                list-style: none;
                width: 29%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 75px;
                margin-bottom: 100px;

                .merit {
                    color: #fff;
                    width: 50%;
                    padding: 12px 0;
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    align-content: center;

                    &.merit_wid {
                        width: 100%;
                    }

                    .icon {
                        width: 20px;
                        height: 20px;
                        float: left;
                        margin-right: 10px;

                        img {
                            object-fit: contain;
                        }
                    }

                    p {
                        float: left;
                        color: #fff;
                        font-size: 2.0rem;
                        letter-spacing: 3px;
                        line-height: 24px;
                        width: calc(100% - 30px);
                        white-space: pre-wrap;

                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .inmore {
                margin-top: 100px;
                background-color: #b9985e;
                color: #fff;
                transition: .3s;
                border-color: transparent;

                &:hover {
                    background-color: rgba(0, 84, 61, 1);
                }
            }
        }

    }

    .circle-contioner {
        position: absolute;
        width: 24.4vw;
        height: 24.4vw;
        background: rgba(255, 255, 255, 0.5);
        right: 24.7%;
        top: 35.3%;
        z-index: 9;
        // overflow: hidden;


        padding: 25px;
        box-sizing: border-box;
        border-radius: 50%;

        .small-contioner {
            position: absolute;
            left: 50%;
            top: 50%;


            width: 100%;
            height: 100%;
            border-radius: 50%;
            transition: 1s;

            &::before,
            &::after {
                content: "";
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%, -50%);
                z-index: 999;
            }

            &::before {
                background: rgba(255, 255, 255, 0.7);
            }

            &::after {
                width: 23px;
                height: 23px;
                background: #00543e;

            }
        }

        .circle-swiper {
            width: 100%;
            height: 100%;
            background-image: url(../assets/brand/ib.png);
            background-size: 100%;
            background-position: center;
            overflow: hidden;
            border-radius: 50%;

            .circle-slide {
                width: 100%;
                height: 100%;

                .pic {
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

    }

    .pagination {
        position: fixed;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;

        .bullet {
            position: relative;
            padding-right: 20px;
            margin: 10px 0;
            text-align: right;


            .cir {
                display: block;
                width: 20px;
                height: 20px;
                // border-radius: 50%;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);

                // background: rgba(255, 255, 255, 0.7);
                // z-index: 999;
                // animation: cirSal 1s infinite;
                .cir1,
                .cir2 {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: rgba(255, 255, 255, 0.7);

                    // animation: cirSal 2s infinite;

                }

                .cir2 {
                    animation: cirSal 2s infinite;
                    display: none;

                }

                .cir3 {
                    content: "";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: #00543e;
                    z-index: 999;

                }
            }

            span {
                color: #b9985e;
                font-size: 1.6rem;
                line-height: 20px;
                margin-right: 10px;
                opacity: 0;
                transition: 1s;
                // position: absolute;
                // top: 50%;
                // transform: translateY(50%);
                // left: 100%;
            }

            &.active {
                .cir {
                    .cir1 {
                        animation: cirSal1 2s infinite;

                    }

                    .cir3 {
                        background-color: #b9985e;
                        transform: translate(-50%, -50%) scale(1.2);
                    }
                }

                span {
                    opacity: 1;
                }
            }
        }

    }
}

@media all and (max-width:769px) {
    .brand {
        .brand-swiper {
            .brand-slide {
                width: 100%;
                padding: 30px;
                box-sizing: border-box;
                padding-top: 90px;

                .merits {
                    width: 100%;
                    margin: 30px 0 60px 0;

                    .merit {
                        width: 100%;
                        padding: 0 0 0 0;

                        p {}
                    }
                }
            }
        }

        .circle-contioner {
            width: 30.4vw;
            height: 30.4vw;
            // right: 5.7%;
            // top: 21.3%;
            right: 20px;
            top: 30px;
            padding: 5px;

            opacity: 0;
            pointer-events: none;

            .small-contioner {
                // display: none;

            }
        }

        .pagination {
            .bullet {
                span {
                    opacity: 0 !important;
                }
            }
        }
    }
}

@keyframes cirSal1 {
    0% {
        width: 10px;
        height: 10px;
        opacity: 0;
    }

    100% {
        width: 20px;
        height: 20px;
        opacity: 1;
    }
}

@keyframes cirSal2 {
    0% {
        width: 10px;
        height: 10px;
        opacity: 0;
    }

    100% {
        width: 20px;
        height: 20px;
        opacity: 1;
    }
}

@media all and (max-width:420px) {
    .brand {
        .en {
            font-size: 3.5rem !important;
        }

        .brand-swiper {
            .brand-slide {
                width: 100%;
                padding: 30px;
                box-sizing: border-box;
                padding-top: 90px;

                .canpname {
                    margin-bottom: 30px;
                    font-size: 1.6rem;
                    // display: none;
                }

                .merits {
                    // display: none;
                    margin-top: 0;
                    width: 100%;

                    .merit {
                        width: 100%;

                        .icon {
                            width: 16px;
                            height: 16px;
                            margin: 4px 0;
                            margin-right: 8px;

                        }

                        p {
                            font-size: 1.4rem;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            line-clamp: 2;
                            -webkit-line-clamp: 2; //显示几行
                        }
                    }
                }

                .inmore {
                    padding: 8px 15px;

                }
            }
        }
    }
}

@media all and (max-height:521px) {
    .brand {
        .brand-swiper {
            .brand-slide {
                position: relative;

                .merits {
                    display: none;
                }

                .inmore {
                    position: absolute;
                    bottom: 60px;

                }
            }
        }

        .pagination {
            right: 15px;

            .bullet {
                span {
                    opacity: 0 !important;
                }
            }
        }
    }

}

@media all and (min-height:711px) {
    .brand {
        .brand-swiper {
            .brand-slide {
                position: relative;

                .merits {
                    display: flex;
                }

                .inmore {
                    // position:absolute;
                    // bottom: 60px;
                }
            }
        }

        .pagination {
            right: 15px;

            .bullet {
                &.active {
                    span {
                        // opacity: 0 ;
                    }
                }
            }
        }
    }

}
</style>

<style lang="less">
.brand {
    .en {
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;
        letter-spacing: 3px;
        font-size: 3.5rem;
    }

    .canpname {
        font-size: 2.0rem;
        color: #fff;
        letter-spacing: 3px;
        margin-top: 2rem;
    }
}
</style>