<template>
    <div class="brief">
        <Swiper :modules="modules" :pagination="{clickable: true}" :autoplay="{delay: 3000,disableOnInteraction: false}" class="brief-swiper">
            <SwiperSlide v-for="(item,index) in imgs" :key="index" class="brief-slide">
                <div class="pic">
                    <img :src="item.imgurl" alt="">
                </div>
            </SwiperSlide>
        </Swiper>
        <div class="cont">
            <!-- <div class="cont-text">
                <div class="title wow animate__fadeInRight"> 
                    <span>澳思柏恩</span>是千年舟集团旗下<br />
                        专注于居家环保建材的<br />
                        <span>研发</span>、<span>制造</span>、<span>销售</span>和<span>服务</span>的品牌!<br />
                </div>
                <div class="desc wow animate__fadeInRight">
                    澳思柏恩品牌创建于 2016 年，已成立<span>三大智能工厂</span>。形成以日照、郯城、
                    邳州三大智能工厂为中心，辐射全国的产品供应体系。澳思柏恩品牌成立
                    以来，屡获殊荣，获得“ <span>十大创花板品牌</span>”、“<span>板材优秀制造企业</span>”、“ <span>日
                    照市林业产业龙头企业</span>”等荣誉。
                    <br />
                    <br />
                    澳思柏恩践行可持续发展理念，从研发创新、生产销售、管理服务等方面
                    完善体系,不断为澳思柏恩战略定位和发展路径赋予新的内涵。
                    澳思柏恩推进行业绿色进程为己任，建立完善的海外优质原材采购体系
                    引进德国辛北尔康普完整生产线、德国帕尔曼长材创片机、意大利意玛帕
                    尔三层超级筛、德国 GRECON 在线厚度检测仪、德国 EWK 喷淋湿电一体
                    化尾气处理系统等先进工业设备;以完善产品研发体系，持续加强产品
                    创新力和竞争力，提升产品市场占有率，目前澳思柏恩拥有千年舟 CNAS
                    <br />
                    <br />
                    澳思柏恩践行可持续发展理念，从研发创新、生产销售、管理服务等方面
                    完善体系,不断为澳思柏恩战略定位和发展路径赋予新的内涵。
                    澳思柏恩推进行业绿色进程为己任，建立完善的海外优质原材采购体系
                    引进德国辛北尔康普完整生产线、德国帕尔曼长材创片机、意大利意玛帕
                    尔三层超级筛、德国 GRECON 在线厚度检测仪、德国 EWK 喷淋湿电一体
                    化尾气处理系统等先进工业设备;以完善产品研发体系，持续加强产品
                    创新力和竞争力，提升产品市场占有率，目前澳思柏恩拥有千年舟 CNAS
                    <br />
                    <br />
                    澳思柏恩践行可持续发展理念，从研发创新、生产销售、管理服务等方面
                    完善体系,不断为澳思柏恩战略定位和发展路径赋予新的内涵。
                    澳思柏恩推进行业绿色进程为己任，建立完善的海外优质原材采购体系
                    引进德国辛北尔康普完整生产线、德国帕尔曼长材创片机、意大利意玛帕
                    尔三层超级筛、德国 GRECON 在线厚度检测仪、德国 EWK 喷淋湿电一体
                    化尾气处理系统等先进工业设备;以完善产品研发体系，持续加强产品
                    创新力和竞争力，提升产品市场占有率，目前澳思柏恩拥有千年舟 CNAS
                </div>
            </div> -->
            <div class="cont-text" v-html="desc">
            
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide} from 'swiper/vue';
import {Autoplay,Pagination} from 'swiper';

import 'swiper/css';
import "swiper/css/pagination";
import ajax from '@/ajax';

import { ref } from 'vue';
import { useStore } from 'vuex';





export default {
    components:{Swiper,SwiperSlide},
    setup(){
        const imgs = ref([
            {imgurl:require('../../assets/about/b1.jpg')},
            {imgurl:require('../../assets/about/b1.jpg')},
            {imgurl:require('../../assets/about/b1.jpg')},
            {imgurl:require('../../assets/about/b1.jpg')},
        ])
 
        const store = useStore();
        const baseUrl = store.state.baseUrl

        const desc = ref(null)

        const init = async function(){
            const aImgs = await ajax(baseUrl + 'web/aboutusimgService')
            const aDesc = await ajax(baseUrl + 'web/aboutusService')
            imgs.value = []
            aImgs.forEach(item => {
                imgs.value.push({imgurl:baseUrl+item.imgurl})
            });
            desc.value = aDesc[aDesc.length-1].particulars
        }

        init();
        return {
            imgs,desc,
            modules:[Autoplay,Pagination]
        }
    }
}
</script>

<style lang="less">

    .brief{
        .swiper-pagination{
            width: 100%;
            bottom: 30px;
            text-align: left;
            padding-left: 200px;
            box-sizing: border-box;
        }
        .swiper-pagination-bullet{
            width: 80px;
            height:2px;
            background-color: #fff;
            border-radius: 0;
        }
        .swiper-pagination-bullet-active{
            
        }

        .cont-text{
            line-height: 1.6;
            padding-right: 21%;
            width: 100%;
            flex: 1;
            overflow: hidden;
            height: 100%;
            box-sizing: border-box;
            // max-height: 539px;

            overflow-y: scroll;
            // 火狐滚动条
            scrollbar-color: #00543e #cccccc;
            scrollbar-width: thin;
            // -webkit- 滚动条 谷歌 edge
            &::-webkit-scrollbar {
                width: 2px;
            }
            // 滚动条两端的按钮
            &::-webkit-scrollbar-button {
                display: none;
            }
            // 外层轨道
            &::-webkit-scrollbar-track {
            }
            // 内层轨道，滚动条中间部分(除去)
            &::-webkit-scrollbar-track-piece {
                background-color: #cccccc;

            }
            // 滚动条里面可以拖动的那个
            &::-webkit-scrollbar-thumb {
                background-color: #00543e;
            }
            // 边角
            &::-webkit-scrollbar-corner {

            }
            // 定义右下角拖动块的样式
            &::-webkit-resizer {

            }

            // scrollbar-3dlight-color:#D4D0C8; /*- 最外左 -*/
            // scrollbar-highlight-color:#fff; /*- 左二 -*/
            // scrollbar-face-color:#E4E4E4; /*- 面子 -*/
            // scrollbar-arrow-color:#666; /*- 箭头 -*/
            // scrollbar-shadow-color:#808080; /*- 右二 -*/
            // scrollbar-darkshadow-color:#D7DCE0; /*- 右一 -*/
            // scrollbar-base-color:#D7DCE0; /*- 基色 -*/
            // scrollbar-track-color:#000;/*- 滑道 -*/
            // 滚动条整体部分，可以设置宽度啥的 
            

            

        }
        .title{
            font-size: 3.2rem;
            // letter-spacing: 5px;
        }
        span{
            font-weight: 700;
            color: #00543e;
        }
        .desc{
            margin-top: 6rem;
            
            // margin-left: 5px;
            letter-spacing: 2.2px;
            margin-right: 15px;
            
            // font-size: 1.9rem;
            // line-height: 1.8;

        }
    }
    @media screen and (max-width: 769px){
        .brief{
            .cont{
                .title{
                    font-size: 1.9em;
                }
                .desc{
                    margin-top: 2rem;
                    // font-size: 1.4rem;
                }
            }
            .swiper-pagination{
                padding-left: 30px;
            }
            .swiper-pagination-bullet{
                width: 40px;
            }
        }
    }
    

</style>

<style lang="less" scoped>
.brief{
    width: 100%;
    // background-color: rgba(0, 0, 0, .5);
    position: relative;
    z-index: 9;
    display: flex;
    justify-content: flex-start;
    // opacity: .5;
    position: relative;
    .brief-swiper{
        width: 50%;
        margin: 0 0 0 0;
        .brief-slide{
            width: 100%;
            color: #fff;
            .pic{
                width: 100%;
                padding-top: 85.2%;
            }
        }

    }
    .cont{
        position: absolute;
        left: 50%;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 8.1% 1.2% 6.2% 7.4%;
        box-sizing: border-box;
        .cont-text{
            line-height: 1.6;
            padding-right: 21%;
            width: 100%;
            flex: 1;
            overflow: hidden;
            height: 100%;
            box-sizing: border-box;
            // max-height: 539px;

            overflow-y: scroll;
            // 火狐滚动条
            scrollbar-color: #00543e #cccccc;
            scrollbar-width: thin;
            // -webkit- 滚动条 谷歌 edge
            &::-webkit-scrollbar {
                width: 2px;
            }
            // 滚动条两端的按钮
            &::-webkit-scrollbar-button {
                display: none;
            }
            // 外层轨道
            &::-webkit-scrollbar-track {
            }
            // 内层轨道，滚动条中间部分(除去)
            &::-webkit-scrollbar-track-piece {
                background-color: #cccccc;

            }
            // 滚动条里面可以拖动的那个
            &::-webkit-scrollbar-thumb {
                background-color: #00543e;
            }
            // 边角
            &::-webkit-scrollbar-corner {

            }
            // 定义右下角拖动块的样式
            &::-webkit-resizer {

            }

            // scrollbar-3dlight-color:#D4D0C8; /*- 最外左 -*/
            // scrollbar-highlight-color:#fff; /*- 左二 -*/
            // scrollbar-face-color:#E4E4E4; /*- 面子 -*/
            // scrollbar-arrow-color:#666; /*- 箭头 -*/
            // scrollbar-shadow-color:#808080; /*- 右二 -*/
            // scrollbar-darkshadow-color:#D7DCE0; /*- 右一 -*/
            // scrollbar-base-color:#D7DCE0; /*- 基色 -*/
            // scrollbar-track-color:#000;/*- 滑道 -*/
            // 滚动条整体部分，可以设置宽度啥的 
            

            

        }
        .title{
            font-size: 2.4  rem;
            // letter-spacing: 5px;
        }
        span{
            font-weight: 700;
            color: #00543e;
        }
    }
    .desc{
        margin-top: 6rem;
        margin-left: 5px;
        letter-spacing: 2.2px;

        // font-size: 1.9rem;
        // line-height: 1.8;

    }
}

@media screen and (max-width: 769px){
    .brief{
        flex-wrap: wrap;
        .brief-swiper{
            width: 100%;
        }
        .cont{
            width: 100%;
            padding: 15px;
            position: static;
            .cont-text{
                padding: 0 0 0 0;
                max-height: 548px;
                .title{
                    // font-size: 2.4rem;
                }
            }
        }
    }
    
}

</style>