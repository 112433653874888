<template>
    <div class="product">
        <Swiper :modules="modules" class="product-swiper" @swiper="setSwiper"
            :autoplay="{ delay: 3000, disableOnInteraction: false }"
            @slideChangeTransitionStart="slideChangeTransitionStart"
            @slideChangeTransitionEnd="slideChangeTransitionEnd">
            <SwiperSlide class="product-slide" v-for="(item, index) in swiperData.imgs" :key="index">
                <div class="pic">
                    <img :src="item" alt="">
                </div>
            </SwiperSlide>
            <div class="pagination">
                <span>{{ swiperPagData.activeIndex > 10 ? swiperPagData.activeIndex + 1 : '0' +
                        (swiperPagData.activeIndex + 1)
                }}</span>
                <div class="line">
                    <div :class="{ jin: true, leftSlide: active }"></div>
                </div>
                <span>{{ swiperPagData.allNum > 10 ? swiperPagData.allNum : '0' + swiperPagData.allNum }}</span>
            </div>
        </Swiper>
        <div class="cont">
            <h1 class="name wow animate__fadeInUp">{{ swiperData.name }}</h1>
            <div class="line green wow animate__fadeInUp"></div>
            <div class="brief wow animate__fadeInUp" v-html="swiperData.brief"></div>
            <div class="line gray wow animate__fadeInUp"></div>
            <div class="desc wow animate__fadeInUp" v-html="swiperData.desc"></div>
        </div>
    </div>
</template>

<script>
import { ref, onUpdated } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper';
// import { Swiper,SwiperSlide } from 'swiper';

export default {
    components: { Swiper, SwiperSlide },
    props: ["id", 'item'],
    setup(props) {
        // props =toRefs(props)
        const swiperData = ref({
            name: "LSB高能家居板",
            brief: `
                <span>产品名称：</span>LSB高能家居板<br />
                <span>产品芯材：</span>优质原材进口松木<br />
                <span>环保等级：</span>ENF级 / F☆☆☆☆级 / E0级 <br />
                <span>产品规格：</span>2440/2800*1220*18/25/定制 <br />
            `,
            desc: "LSB高能家居板是全球家居建材领域新一代高科技健康板材，以精选优质进口松木为原料，配合使用环保胶水，经过脱油、干燥、施胶、定向铺装、热压成型等十二道工艺层层精制而成，板材“芯”层采用纵横交错定向铺装方式，芯层呈横向排列，表面呈纵向排列，再加上上下细料层，让板材具备握钉力强、吸水膨胀率低、易加工不易变形特点的同时，板面超平，可比拟中高密度纤维板，满足各种纸张及工艺压贴需求，被广泛应用于建筑结构材料、建筑装修材料、各类成品家具与定制家具等。表面也可直接涂刷清漆或粘贴饰面板。",
            imgs: [require("../../assets/brandDetail/p1.jpg"), require("../../assets/brandDetail/p2.jpg")]
        })
        const swiperPagData = ref({ allNum: swiperData.value.imgs.length, activeIndex: 0 })
        const active = ref(false)

        const productSwiper = ref(null)

        onUpdated(() => {
            if (props.item) {
                swiperData.value = props.item
                swiperPagData.value = { allNum: swiperData.value.imgs.length, activeIndex: 0 }
            }
        })

        /**
         * method
         */
        // swiper
        const setSwiper = swiper => {
            productSwiper.value = swiper
        }
        const slideChangeTransitionStart = function () {
            active.value = false
        }
        const slideChangeTransitionEnd = function () {
            active.value = true
            swiperPagData.value.activeIndex = productSwiper.value.activeIndex
        }


        return {
            swiperData, swiperPagData, active,

            /**methods */
            // swiper
            modules: [Autoplay],
            setSwiper, slideChangeTransitionStart, slideChangeTransitionEnd
        }
    }
}
</script>

<style lang="less">
.product {
    display: flex;
    background: #f8f8f8;

    .product-swiper {
        width: 50%;
        margin: 0;
        position: relative;

        .product-slide {
            width: 100%;

            .pic {
                // padding-top: 94%;
                height: 100%;
            }
        }

        .pagination {
            position: absolute;
            top: 89.7%;
            left: 13.4%;
            left: 50%;
            transform: translateX(-50%);
            width: 250px;
            display: flex;
            justify-content: space-between;
            z-index: 1;

            span {
                color: #00543e;
                font-size: 2.0rem;

            }

            .line {
                position: absolute;
                width: calc(100% - 2rem * 4);
                height: 2px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: rgb(255, 255, 255);
                overflow: hidden;

                .jin {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: #00543e;
                    transform: translateX(-100%);
                    // animation-name: leftSlide;
                    animation-duration: 3s;
                }
            }
        }
    }

    .cont {
        flex: 1;
        box-sizing: border-box;
        padding: 5.7% 0 5.7% 5.9%;

        .name {
            font-size: 3.2rem;
            letter-spacing: 5px;
            color: #00543e;
            line-height: 1;
        }

        .line {
            width: 100%;
            height: 2px;

            &.green {
                background-color: #00543e;
                margin-top: 12px;
                margin-bottom: 15px;
            }

            &.gray {
                background-color: #ccc;

            }
        }

        .brief {
            font-size: 1.8rem;
            padding-top: 20px;
            letter-spacing: 3px;
            margin-bottom: 32px;

            span {
                font-weight: 700;
                line-height: 1.8;
            }
        }

        .desc {
            width: 85%;
            padding-top: 40px;
            line-height: 1.5;
            letter-spacing: 3px;

            // font-size: 2.0rem;

        }
    }
}

.leftSlide {
    animation-name: leftSlide;
}

// @media all and (max-width: 769px) {
//     .product {
//         flex-wrap: wrap;

//         .product-swiper {
//             width: 100%;
//         }

//         .cont {
//             width: 100%;
//             padding: 15px 0;

//             .desc {
//                 width: 100%;
//             }
//         }
//     }
// }

@media all and (max-width: 769px) {
    .product {
        flex-wrap: wrap;

        .product-swiper {
            width: 100%;
        }

        .cont {
            width: 100%;
            padding: 15px 0;
            .name{
                font-size: 2.4rem;
                padding: 0 15px;
            }
            .line.green{
                margin-top: 6px;
                margin-bottom: 8px
            }
            .brief{
                font-size: 1.4rem;
                padding-top: 0;
                padding: 0 15px;
                margin-bottom: 15px;
            }
            .desc {
                width: 100%;
                padding: 15px;
                box-sizing: border-box;

                // font-size: 1.4rem;

            }
        }
    }
}

@keyframes leftSlide {
    0% {
        transform: translate(-100%);
    }

    100% {
        transform: translate(0%);
    }
}
</style>