<template>
    <div class="history" @mouseup="setmouseup" @mousedown="setmousedown" :style="'background-image: url(' + titData.backImg + ');'">
        <div class="tit wow animate__fadeIn">
            <div class="pic">
                <img :src="titData.imgurl" alt="">
            </div>
            <div class="h3">{{ titData.h3 }}</div>
            <div class="h2">{{ titData.h2 }}</div>
            <div class="clear"></div>
        </div>
        <!-- {{ swiperConfig }} -->
        <div class="his wow animate__fadeIn">
            <div class="line">
                <div class="circle"></div>
                <div class="line-y"></div>
            </div>
            <!-- :autoplay="{delay: 1500,disableOnInteraction: false}" -->
            <div class="his-swiper">
                <div class="swiper-wrapper" :style="'transform: translateY(-' + swiperConfig.activeSlideTran + 'px);'">
                    <div v-for="(item, index) in hisData"
                        :class="{ 'his-slide': true, active: swiperConfig.activeSlide == index }"
                        :data-active="swiperConfig.activeSlide" :data-index="index" ref="hisSlide" :key="index">
                        <div class="date">{{ item.date }}</div>
                        <div class="desc">
                            <span>{{ item.desc }}</span>
                            <img v-if="index % 2" src="@/assets/about/o2.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <button @click="slideNext" style="position: fixed; top: 200px; left: 200px; z-index: 9999;">下一张</button>
        <button @click="slidePrev" style="position: fixed; top: 200px; left: 250px; z-index: 9999;">shang一张</button> -->
    </div>
</template>
  
<script>
import { Autoplay } from 'swiper';

import ajax from '@/ajax';
import { useStore } from 'vuex';
import { ref } from 'vue';
import { getTime } from '@/js/uitl'

export default {
    setup() {
        const titData = ref({
            imgurl: require('../../assets/about/h1.png'),
            h3: "澳思柏恩大事记",
            h2: "不畏艰难 · 共创辉煌",
        })

        const hisData = ref([
            {
                date: 2021.08,
                desc: '与中国人民保险签订产品承保品牌保单'
            },
            {
                date: 2021.08,
                desc: '与中国人民保险签订产品承保品牌保单'
            },
            {
                date: 2021.08,
                desc: '与中国人民保险签订产品承保品牌保单'
            },
            {
                date: 2021.08,
                desc: '与中国人民保险签订产品承保品牌保单'
            },
            {
                date: 2021.08,
                desc: '与中国人民保险签订产品承保品牌保单'
            },
            {
                date: 2021.08,
                desc: '与中国人民保险签订产品承保品牌保单'
            },
            {
                date: 2021.08,
                desc: '与中国人民保险签订产品承保品牌保单'
            },
            {
                date: 2021.08,
                desc: '与中国人民保险签订产品承保品牌保单'
            },
            {
                date: 2021.08,
                desc: '与中国人民保险签订产品承保品牌保单'
            }

        ])

        const store = useStore();
        const baseUrl = store.state.baseUrl

        const swiperConfig = ref({
            activeSlideTran: 0,
            activeSlide: 0,
            swiperHeight: null,
        })
        let interval = null; // eslint-disable-line
        const init = async function () {

            const aTitData = await ajax(baseUrl + 'web/aboutdevelopingService');
            titData.value = {
                imgurl: baseUrl + aTitData[aTitData.length - 1].icon,
                h3: aTitData[aTitData.length - 1].title,
                h2: aTitData[aTitData.length - 1].name,
                backImg: baseUrl + aTitData[aTitData.length - 1].backImg
            }

            const aHisData = await ajax(baseUrl + 'web/aboutdevelopinglistService');
            let rHisData = []
            aHisData.forEach(item => {
                let { year, month } = getTime(item.datame)
                rHisData.push({
                    date: year + '.' + (month < 10 ? "0" + month : month),
                    desc: item.name
                })
            });
            hisData.value = rHisData
            if(document.body.clientWidth > 769){
                setTimeout(() => {

                setSwiper(swiperConfig);
                // slideTran();
                interval = setInterval(() => {
                    slideNext();
                }, 1300)
                }, 0)
            }
            
        }

        init();
        const hisSlide = ref(null)

        const setSwiper = function (swiperConfig) {
            let prev = 0
            hisSlide.value.forEach((item, index) => {
                if (!swiperConfig.value.swiperHeight) {
                    swiperConfig.value.swiperHeight = []
                }
                if (index == hisSlide.value.length - 1) {
                    return false
                }
                prev = prev + item.clientHeight
                // if(swiperConfig.value.swiperHeight[index - 1]){
                //     prev = swiperConfig.value.swiperHeight[index - 1]
                // }
                swiperConfig.value.swiperHeight.push(prev - item.clientHeight)
            })
            if (!hisSlide.value.activeSlideTran) {
                swiperConfig.value.activeSlideTran = swiperConfig.value.swiperHeight[0]
            }
            if (!hisSlide.value.activeSlide) {
                swiperConfig.value.activeSlide = 0
            }
        }
        const slideTran = function (index) {
            // swiperConfig.value.activeSlide = active.dataset
            swiperConfig.value.activeSlide = index
            swiperConfig.value.activeSlideTran = swiperConfig.value.swiperHeight[swiperConfig.value.activeSlide]
        }
        const slideNext = function () {

            let index = parseInt(swiperConfig.value.activeSlide) + 1

            if (index >= swiperConfig.value.swiperHeight.length) {
                swiperConfig.value.activeSlide = 0
                slideTo(0)
            } else {
                slideTo(index)
            }
        }
        const slidePrev = function () {

            let index = swiperConfig.value.activeSlide--

            if (index < 0) {
                swiperConfig.value.activeSlide = 0
                slideTo(0)
            } else {
                slideTo(index)
            }
        }
        const slideTo = function (index) {
            slideTran(index)
        }

        const setmousedown = function (ev) {
            ev = ev || window.event;
            // 获取鼠标相对于盒子的坐标
            swiperConfig.value.x = ev.offsetX;
            swiperConfig.value.y = ev.offsetY;
        }

        // 4.鼠标松开事件
        const setmouseup = function (ev) {
            ev = ev || window.event;
            console.log(swiperConfig.value.y - ev.offsetY, 0, Math.abs(swiperConfig.value.y - ev.offsetY), 100)
            if (swiperConfig.value.y - ev.offsetY < 0 && Math.abs(swiperConfig.value.y - ev.offsetY) > 100) {
                clearInterval(interval)
                interval = setInterval(() => {
                    slideNext();
                }, 1300)
            } else {
                clearInterval(interval)
                interval = setInterval(() => {
                    slidePrev();
                }, 1300)
            }

        }
        const settouchstart = function(e){
            e.preventDefault();
            swiperConfig.value.startX = e.originalEvent.changedTouches[0].pageX,
            swiperConfig.value.startY = e.originalEvent.changedTouches[0].pageY;
        }
        const settouchmove = function(e){
            e.preventDefault();
            let moveEndY = e.originalEvent.changedTouches[0].pageY,
            Y = moveEndY - swiperConfig.value.startY;
            
            if (Y > 0) {
                alert("top 2 bottom");
            }
            else if (Y < 0) {
                alert("bottom 2 top");
            }
            else {
                alert("just touch");
            }
        }

        return {
            modules: [Autoplay],
            titData, hisData, hisSlide, swiperConfig, slideTran, slideNext, slidePrev,
            setmousedown, setmouseup,settouchstart,settouchmove
        }
    }
}
</script>
  
<style lang="less" scoped>
.history {
    position: relative;
    // z-index: 99;
    padding: 6% 0% 0% 9%;

    background-size: cover;

    // pointer-events: none;
    .tit {
        .pic {
            width: 120px;
            height: 120px;
            float: left;
        }

        .h3 {
            font-size: 2.1rem;
            color: #b9985e;
            line-height: 60px;
        }

        .h2 {
            font-size: 3.2rem;
            color: #ffffff;
            line-height: 10px;
            letter-spacing: 3px;
        }

        .clear {
            clear: both;
        }

    }

    .his {
        display: flex;
        overflow: hidden;
        height: 71.4vh;

        .line {
            margin-left: 6.9%;
            width: 30px;
            height: 100%;

            .circle {
                width: 30px;
                height: 30px;
                background: #ffffff;
                border-radius: 50%;
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    width: 15px;
                    height: 15px;
                    background: #b9985e;
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .line-y {
                width: 3px;
                height: 100%;
                background-color: #b9985e;
                margin: 0 auto;
            }
        }

        .his-swiper {
            flex: 1;
            margin-left: -15px;
            margin-top: 80px;
            max-height: calc(100px * 6);
            overflow: hidden;

            .swiper-wrapper {
                display: flex;
                flex-direction: column;
                transition: .7s;
            }

            .his-slide {
                width: 100%;
                // height: 100% !important;
                // margin-top: 80px;
                height: auto;
                min-height: 100px;
                flex-shrink: 0;
                pointer-events: none !important;
                padding-top: 15px;

                // max-height: 100%;
                // max-height: 120px;  
                .date {
                    padding-left: 30px;
                    position: relative;
                    font-size: 2.0rem;
                    color: #b9985e;
                    font-weight: 700;
                    float: left;

                    &::before {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 3px;
                        background-color: #b9985e;
                        position: absolute;
                        left: 0;
                        top: 50%;
                    }
                }

                span {
                    pointer-events: none;
                    display: block;
                    width: 80%;
                    white-space: pre-wrap;

                }

                .desc {
                    color: #ffffff;
                    letter-spacing: 1px;
                    text-indent: 4em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    /* 超出部分省略号 */
                    word-break: break-all;
                    /* break-all(允许在单词内换行。) */
                    display: -webkit-box;
                    /* 对象作为伸缩盒子模型显示 */
                    -webkit-box-orient: vertical;
                    /* 设置或检索伸缩盒对象的子元素的排列方式 */
                    -webkit-line-clamp: 2;

                    font-size: 2.0rem;

                    /* 显示的行数 */
                    img {
                        display: block;
                        width: 60%;
                        margin-top: 15px;
                        margin-left: 4em;

                    }
                }
            }

        }
    }

}

@media all and (max-width:769px) {
    .history {
        background-size: cover;
        background-position: center center;
        padding: 15px;

        .tit {
            .pic {
                width: 100px;
            }

            .h3 {
                font-size: 1.8rem;
            }

            .h2 {
                margin-top: -15px;
                line-height: 1.2;
                font-size: 2.2rem;
            }
        }

        .his {
            margin-left: -20px;
            height: 60vh;

            .his-swiper {
                margin-top: 50px;
                .swiper-wrapper{
                    width: 100%;
                    height: 100%;
                    overflow-y: scroll;
                    padding-right: 15px;
                    padding-bottom: 15px;
                }
                .his-slide {
                    // max-height: 80px;
                    height: auto;
                    .desc {
                        width: 100%;
                        float: left;
                        margin-left: 30px;
                        text-indent: 0;
                        margin-top: 5px;
                        
                        // font-size: 1.4rem;
                        img{
                            margin-left: 0;
                        }
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
}
</style>