<template>
    <div class="products">
        <!-- <img src="../../assets/02h.jpg" alt=""> -->
        <!-- :style="'height:'+page.productHeight[index].height" -->
        <Swiper @swiper="setThumbsSwiper"  :spaceBetween="10" :slidesPerView="'auto'" :freeMode="true"
            :watchSlidesProgress="true" :modules="modules" class="thumbs">

            <!-- <Swiper class="thumbs" :modules="[Thumbs]" :slidesPerView="4" :spaceBetween="2" @swiper="setThumbsSwiper"> -->
            <SwiperSlide v-for="(item, index) in data" :key="index" class="thumbs-slide" @click="clickTab(index)">
                <div ref="thiumbs" class="circle pic">
                    <img v-lazy="item.iconUrl" alt="">
                </div>
            </SwiperSlide>
        </Swiper>
        <Swiper :spaceBetween="0" :modules="modules" :effect="'fade'" :speed="1000" :navigation="true"
            :pagination="{ clickable: true }" @swiper="setProductSwiper"
            @slide-change-transition-start="slideChangeTransitionEnd" class="product-swiper">
            <!-- <Swiper @swiper="setProductSwiper" :thumbs="{ swiper: thumbsSwiper }" :modules="[Thumbs]" class="product-swiper"> -->
            <SwiperSlide class="product-slide" v-for="(item, index) in data" :key="index">
                <div @click="mProductAni" :data-index="index" class="product">
                    <div class="pic">
                        <img v-lazy="item.backImg" alt="">
                    </div>
                    <div class="content">
                        <div ref="mProductAni" v-if="!page.isM" class="cont-hover ">
                            <div class="circle pic">
                                <img v-lazy="item.iconUrl" alt="">
                            </div>
                            <h1 class="title">{{ item.title }}</h1>
                            <ul>
                                <!-- <li v-for="(link, index) in item.link" :key="index">{{ link.title }}</li> -->
                                <li v-for="(link, index) in item.link" :key="index">
                                    <router-link :to="link.url || '/'"> 
                                        {{ link.title }}
                                    </router-link>
                                </li>
                            </ul>
                            <more :url="item.url || '/'" class="more"></more>
                        </div>
                        <div ref="mProductAni" v-if="page.isM" class="cont-hover wow  animate__fadeInUp">
                            <div class="circle pic">
                                <img v-lazy="item.iconUrl" alt="">
                            </div>
                            <h1 class="title ">{{ item.title }}</h1>
                            <ul>
                                <li v-for="(link, index) in item.link" :key="index">
                                    <router-link :to="link.url || '/'"> 
                                        {{ link.title }}
                                    </router-link>
                                </li>
                            </ul>
                            <more :url="item.url || '/'" class="more"></more>
                        </div>
                        <div v-if="page.isM" class="cont">
                            <div class="circle pic wow animate__fadeInUp">
                                <img v-lazy="item.iconUrl" alt="">
                            </div>
                            <div class="line"></div>
                            <h1 class="title wow animate__fadeInUp"> {{ item.title }}</h1>
                        </div>
                        <div v-if="!page.isM" class="cont">
                            <div class="circle pic">
                                <img v-lazy="item.iconUrl" alt="">
                            </div>
                            <div class="line"></div>
                            <h1 class="title">{{ item.title }}</h1>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>
<script>

import more from '../more/MoreEle.vue'
// import wow from '../../js/'
import { Swiper, SwiperSlide } from 'swiper/vue';
// import {Thumbs} from 'swiper';
// import {FreeMode,Navigation,Thumbs} from 'swiper';
import { EffectFade, Pagination } from "swiper";
import { ref } from 'vue'
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import ajax from '@/ajax';
import { mapState } from 'vuex';

// const wow = require('../../js/wow.min.js')
export default {
    data() {
        return {
            page: {
                isM: true
            },
            data: [{
                title: "饰能板",
                backImg: require("../../assets/index/c1.png"),
                iconUrl: require("../../assets/index/ci1.png"),
                link: [{
                    title: "澳思柏恩饰能板"
                }],
            }, {
                title: "LSB系列",
                backImg: require("../../assets//index/c2.png"),
                iconUrl: require("../../assets//index/ci2.png"),
                link: [{
                    title: "LSB高定专享板"
                }, {
                    title: "LSB高能家居板"
                }, {
                    title: "锌效抗菌LSB高能家居板"
                }],
            }, {
                title: "澳松板系列",
                backImg: require("../../assets//index/c3.png"),
                iconUrl: require("../../assets//index/ci3.png"),
                link: [{
                    title: "澳松板"
                }, {
                    title: "锌效抗菌澳松板"
                }],
            }, {
                title: "OSB系列",
                backImg: require("../../assets//index/c4.png"),
                iconUrl: require("../../assets//index/ci4.png"),
                link: [{
                    title: "实木切片OSB"
                }, {
                    title: "难燃OSB"
                }],
            }]
        }
    },
    computed:{
        ...mapState(["baseUrl"])
    },
    components: {
        more, Swiper, SwiperSlide
    },
    mounted: function () {
        if (document.body.clientWidth > 769) {
            this.page.isM = false
        }

        ajax(this.baseUrl + "web/brandtypeService").then(res => {
			let result = res;
			let coreData = [];

			/* {
				 title:"饰能板",
				 backImg:require("../../assets/index/c5.jpg"),
				 iconUrl:require("../../assets/index/ci1.png"),
				 link:[{
					 title:"澳思柏恩饰能板"
				 }],
			 } */
			result.forEach((item,index) => {
				let title1 = [];

				ajax(this.baseUrl + "web/brandproductService", {
					'brid': item.id
				}).then(res1 => {
					let result1 = res1;


					result1.forEach(item1 => {
						title1.push({
							title: item1.name,
                            url:"/brand/"+item.id+'/'+item1.id
						});
					});
				})


				coreData.push({
					title: item.name,
					backImg: this.baseUrl + item.imgurl,
					iconUrl: this.baseUrl + item.icon,
					link: title1,
					url: "/brand/"+index
				})

			});

			this.data = coreData

		});

        // new wow.WOW().init({
        //     boxClass: 'wow',
        //     animateClass: 'animate__animated',
        //     offset: 0,
        //     mobile: true,
        //     live: true,
        //     callback: null,
        //     scrollContainer: null
        //     })

    },

    setup() {
        const thumbsSwiper = ref(null)
        const productSwiper = ref(null)
        const thiumbs = ref(null)
        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper
        }
        const setProductSwiper = (swiper) => {
            productSwiper.value = swiper
        }
        const clickTab = function (index) {
            thiumbs.value.forEach(element => {
                element.style.background = '#00543e'
            });
            thiumbs.value[index].style.background = '#b8985d';

            productSwiper.value.slideTo(index)
        }
        let slideChangeTransitionEnd = function () {
            let index = productSwiper.value.activeIndex
            thiumbs.value.forEach(element => {
                element.style.background = '#00543e'
            });
            thiumbs.value[index].style.background = '#b8985d';

            new WOW().init() // eslint-disable-line
        };
        // onMounted(()=>{
        //     console.log(thiumbs.value)




        // })
        return {
            thumbsSwiper, productSwiper, setThumbsSwiper, setProductSwiper, clickTab, modules: [EffectFade, Pagination], slideChangeTransitionEnd, thiumbs
        }
    }
}
</script>
<style>
.products .swiper-pagination{
    display: none;
}
.products .swiper-pagination-bullet {
    background-color: #00543e !important;
    opacity: .8 !important;
    display: none;
}

.products .swiper-pagination-bullet-active {
    background-color: #b8985d !important;

}

.products .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 150px;
}

.products .swiper-slide {
    pointer-events: none;
}

.products .swiper-slide-active {
    pointer-events: auto;
}
</style>
<style lang="less" scoped>
.product-swiper {
    width: 100%;
    height: 100%;

}

.thumbs {
    width: 100%;
    position: absolute;
    bottom: 55px;
    z-index: 99;

    .thumbs-slide {
        width: 55px;
        margin-left: 25px;
        pointer-events: auto;

        &:nth-child(1) {
            .pic {
                background: #b8985d;
            }
        }

        .pic {
            transition: .3s;
        }
    }
}

.products {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;

    &>img {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: 1;
    }

    .product {
        // flex: 1;
        width: 25%;
        cursor: pointer;
        transition: width 1s;
        // opacity: .5;
        position: relative;
        overflow: hidden;
        background: #000;

        // &:nth-child(1){
        // }
        * {
            pointer-events: auto;
        }

        .pic {
            width: 101%;
            height: 101%;
        }

        .content {
            position: absolute;
            bottom: 0;
            width: 100%;


            .cont {
                position: absolute;
                width: 100%;
                bottom: 0px;
                opacity: 1;
                transition: opacity .3s;

                &>.title,
                .line,
                .ul {
                    transition: opacity .7s;
                }

                .line {
                    width: 2px;
                    height: 150px;
                    margin: 0 auto;
                    background: #fff;
                    margin-bottom: 30px;
                    margin-top: 30px;
                    opacity: 1;
                }

                &>.title {
                    color: #fff;
                    font-size: 3.2rem;
                    font-weight: 400;
                    text-align: center;
                    letter-spacing: 2px;
                    margin-bottom: 125px;
                    opacity: 1;
                    transition: opacity .7s;
                }
            }

            .cont-hover {
                opacity: 0;
                // height: 500px;   
                height: 0;

                .title {
                    font-size: 3.8rem;
                    line-height: 3.6;
                    font-weight: 400;
                    color: #fff;
                }

                ul {
                    padding-left: 30px;
                    margin-top: -12px;

                    li {
                        font-size: 1.8rem;
                        line-height: 2.4;
                        color: #fff;
                        *{
                            color: #fff;
                        }

                    }
                }

                .more {
                    color: #fff;
                    margin-top: 30px;
                }
            }

        }
    }
}

@media all and (max-width:1100px) and (any-hover:hover) {
    .products {
        .product {
            &:hover {
                .content {
                    padding-left: 40px;

                }
            }

            .cont-hover {}
        }
    }
}

@media all and (min-width:769px) {
    .product {
        height: 100vh !important;

        .content {
            .cont-hover {
                // animation-name: none;
            }
        }
    }
}

@media all and (max-width:769px) {
    .products {
        width: 100%;
        flex-wrap: wrap;
        height: auto;

        .product {
            width: 100%;
            // height: 32vh;
            height: 100vh;
            transition: height 1s;

            &:hover {
                width: 100%;
            }

            .content {
                position: absolute;
                top: 0;
                display: flex;
                flex-direction: column;
                padding: 25px;
                justify-content: flex-end;
                box-sizing: border-box;
                padding-bottom: 220px;

                .cont {
                    display: flex;
                    position: static;
                    top: 15px;
                    bottom: auto;
                    left: 15px;
                    order: 1;
                    flex-direction: column;

                    // z-index: 9;
                    .circle {
                        // 原版
                        width: 80px;
                        height: 80px;
                        // 产品经理建议
                        width: 55px;
                        height: 55px;
                        margin: 0 0 0 0;
                        margin-right: 15px;
                        opacity: 0 !important;
                        display: none;
                        // display: none;
                    }

                    .line {
                        display: none;
                    }

                    &>.title {
                        margin-bottom: 0;
                        line-height: 90px;
                        text-align: left;

                    }
                }

                .cont-hover {
                    order: 2;
                    opacity: 1;
                    position: static;
                    bottom: 220px;
                    left: 40px;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    // animation-name: none;
                    margin-top: 30px;

                    ul {
                        // margin-top: 55px;
                        margin-left: -10px;
                        margin-left: -10px;
                            margin-top: -40px;
                            // background: #000;
                    }

                    .circle {
                        display: none;
                    }

                    .title {
                        display: none;
                    }
                }
            }
        }

    }
}

@media (any-hover: hover) {
    .product {
        &:hover {
            width: 78.7%;

            .content {
                padding-left: 140px;
                box-sizing: border-box;
                padding-bottom: 140px;

                .circle {
                    margin: 0 0 0 0;
                    // margin-left: 140px;
                }

                .cont {
                    opacity: 0;


                    &>.circle {
                        // opacity: 0;
                    }

                    .line,
                    &>.title {
                        // opacity: 0;
                    }

                }

                .cont-hover {
                    opacity: 1;
                    max-height: 600px;
                    height: 2000px;
                    position: relative;

                    .circle,
                    .title,
                    ul {
                        opacity: 0;
                        animation-name: fadeInLeft;
                        animation-delay: .8s;
                        animation-fill-mode: both;
                        -webkit-animation-duration: 1s;
                        animation-duration: 1s;

                    }

                    .more {
                        position: absolute;
                        bottom: 30px;
                        color: #fff;
                    }
                }

            }

        }
    }
}

.animate__fadeOutLeft {
    animation-name: fadeOutLeft;
}

@keyframes fadeOutLeft {
    from {
        opacity: 0;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.circle {
    width: 55px;
    height: 55px;
    background: #00543e;
    border-radius: 50%;
    margin: 0 auto;

    img {
        max-width: 70%;
        // height: auto;
        // width: auto;
        max-height: 60%;
        overflow: auto;
        object-fit: contain;
    }
}
</style>