<template>
  <div class="skip" :style="'background-image:url('+skip.imgurl+')'">

    <div class="titl" v-html="skip.title"></div>
    <inMoreVue class="sinmore" :moreTitle="skip.moreTitle" :url="skip.url"></inMoreVue>
  </div>
</template>

<script>
import ajax from '@/ajax'
import { useStore } from 'vuex'
// import { toRefs } from 'vue'
import inMoreVue from './inMore.vue'
import { ref } from '@vue/reactivity'
export default {
    props:["moreTitle",'id'],
    components:{inMoreVue},
    setup(props){
        const store = useStore();
        const baseUrl = store.state.baseUrl;
        
        const skip = ref({
            imgurl:require('@/assets/skip.jpg'),
            url:"/",
            title:"我猜您还想<br />了解我们的产品"
        })

        ajax(baseUrl + 'web/bottomimgService',{type:props.id}).then(result=>{
            let item = result[result.length - 1]
            skip.value = {
                imgurl:baseUrl + item.imgurl,
                url:item.url,
                title:item.agae,
                moreTitle:item.title
            }
        })

        return {
            skip
        }
    }
}
</script>

<style lang="less">

    .sinmore{
        // .imore{
        //     position: absolute;
        // }
    }
</style>

<style lang="less" scoped>

.skip{
    position: relative;
    z-index: 99;
    background-attachment: fixed;
    font-size: 4.3rem;
    padding-left: 15.5%;
    padding-top: 5.8%;
    padding-bottom: 11%;
    background-size: 100%;

    // background-image: none !important;
    // background-color: #000;
    .titl{
        font-weight: 700;
        color: #fff;
        margin-bottom: 5px;
    }
    .sinmore{
        border-color: #fff;
        color: #fff;
        transition: .3s;
        &:hover{
            background-color: #00543e;
            border-color: rgba(255, 255, 255, 0);
        }
        
    }
}
@media all and (max-width:769px){
    .skip{
        padding-bottom: 50%;
        padding-top: 25.8%;

        padding-left: 30px;
        background-size: cover;
        background-position: center center;

        font-size: 3.3rem;
        color: #fff;
        
        .titl {
            margin-bottom: 70px;
        }
        .sinmore{
            margin-top: 80px;
            padding: 5px 10px;
            
            span{
                font-size: 1.4rem;
            }
        }   
    }
}
</style>