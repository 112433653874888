<template>
  <div class="courese">
    <mapTiltleEleVue :item="titleData" class="mapTiltleEleVue"></mapTiltleEleVue>
    <div class="courese-item">
        <div v-for="(item,index) in coureseData" :key="index" :class="{item:true,active:item.active,wow:true,animate__fadeInRight:true}" :style="{animationDelay: index / 20+'s'}">
            <div class="icon pic">
                <img :src="item.icon" alt="">
                <div class="bottom-slide">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAASCAYAAACuLnWgAAABnElEQVQ4ja3UT4iOURQG8PMNplEThWJhw5RMSkrZIAsNK3bYWNBsLGRno0ZZTKFEygZZKRuxUxaKlLKyESWpWVBmSkpNxoefxb1vvXN7v39jbt26nec599xz7nNOCxER1yLiQkQsxPKuAxGxMbABP/Eyn2OZ9mks4Epl2IdZfMKO/7x8CFeldRfDdXAL3uI7Di8xwCge4zfOV/aStAZPMunsgAE24w1+4EgdayKvwI2c7i2s7CPAHnzBDHaWeHWYaHA8g194irVdAhzDPF5hU4Gtx+5KXX9xCa2CdBDf8A5jBdbCVPZ9gJEC346PuF6RL9bIqwvyNnzAHPZn2wjuZ5+phsdN5Me9x1gdOJHTft2Q9jo8k3R/LpdmHsc7lLldL3O3D9xVYKtwW1qfM7cUzM2MLxLMQFLMezJzmqTf1iD9bk31SNFUHXa9iQ81cbo5D+FyTv9OLlfJ2YuvkorGO93Vq8kCp6QB+lwSQGU/me0v9Bis/QQJaYDOSVIex7Qk33sY7uXfb5DA1lz7Nv708VdLChKS7h/i6CB+/wBFRFARMKo3nAAAAABJRU5ErkJggg==" alt="">
                </div>
            </div>
            <div class="name">{{item.name}}</div>
        </div>
    </div>
  </div>
</template>

<script>
import { ref,onUpdated } from 'vue';

import mapTiltleEleVue from './mapTiltleEle.vue';

export default {
    components:{mapTiltleEleVue},
    props:["item"],
    setup(props){
        // props = toRefs(props)
        // 标题
        const titleData = {
            en:"Main production process",
            cn:"主要生产流程"
        }
        // 流程
        const coureseData = ref([
            {
                name:"严选原木",
                icon:require("../../assets/brandDetail/01.png"),
                active:false
            },
            {
                name:"严选原木",
                icon:require("../../assets/brandDetail/02.png"),
                active:false
            },
            {
                name:"严选原木",
                icon:require("../../assets/brandDetail/03.png"),
                active:false
            },
            {
                name:"严选原木",
                icon:require("../../assets/brandDetail/04.png"),
                active:false
            },
            {
                name:"严选原木",
                icon:require("../../assets/brandDetail/05.png"),
                active:false
            },
            {
                name:"严选原木",
                icon:require("../../assets/brandDetail/06.png"),
                active:false
            },
            {
                name:"严选原木",
                icon:require("../../assets/brandDetail/07.png"),
                active:false
            },
            {
                name:"严选原木",
                icon:require("../../assets/brandDetail/08.png"),
                active:false
            },
        ])
        // 每个一秒切换流程高亮
        const setCourese = function(index){
            if(coureseData.value[index]){
                coureseData.value[index].active = true
            }
        }

        const init = function(){
            let index = 0
            setInterval(()=>{
                if(index >=coureseData.value.length){
                    index = 0
                    coureseData.value.forEach((item)=>{
                        item.active = false
                    })
                }
                setCourese(index)
                index++
            },1000)
        
        
        }
        onUpdated(()=>{
            if(props.item){
                coureseData.value = props.item
            }
        })



        // 页面初始化
        init();

        return {
            titleData,coureseData
        }
    }
}
</script>

<style lang="less" scoped>
// b9985e 00543e
.courese{
    padding: 5.5% 5.4% 5.4% 6.1%;
    background-color: #00543e;
    
    .mapTiltleEleVue{
        color: #b9985e;
        margin-bottom: 18px;
    }
    .courese-item{
        border-top: 1px #b9985e solid;
        display: flex;
        padding: 5.7% 0% 0% 0.8%;
        justify-content: space-between;
        .item{
            position: relative;

            &.active{
                .icon{
                    background-color:#b9985e;
                    .bottom-slide{
                        img{
                            animation-name: flicker;
                        }
                    }
                }
                
            }

            .icon{
                width: 72px;
                height: 72px;
                background: #fff;
                border-radius: 50%;
                overflow: visible;
                transition: 1s;
                margin: 0 auto;
                &>img{
                    width: 60%;
                    height: 60%;
                    object-fit: contain;
                }
                .bottom-slide{
                    width: 55px;
                    height: 35px;
                    border-radius: 18px;
                    // border: 1px #fff solid;
                    position:absolute;
                    top: 50%;
                    left: 125%;
                    transform: translateY(-70%);
                    z-index: 99;
                    // background: #fff;
                    img {
                        width: 15px;
                        height: auto;
                        color: red;
                        margin: 0 auto;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%) rotate(-90deg);
                        animation-duration: 2s;
                        animation-iteration-count: infinite;
                        z-index: 9;
                    }
            }
            }
            .name{
                font-size: 2.4rem;
                color: #fff;
                margin-top: 2.4rem;
                letter-spacing: 2px;
                transition: 1s;
                text-align: center;

                color: #b9985e;
                width: calc(2.4rem * 5);
                overflow: hidden;

            }
            
        }
        .item:last-child{
            .bottom-slide{
                display: none;
            }
        }
    }
}

@media all and (max-width:769px){
    .courese{
        display: none;
    }
}

@keyframes flicker {
    0%{
        left: 25%;
        opacity: 1;

    }
    100%{
        left: 75%;
        opacity: 0;
    }
}
</style>