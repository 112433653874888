<template>
  <div class="info">
    <div class="pic">
        <img :src="data.imgurl" alt="">
    </div>
    <div class="cont">
        <dijia class="dijia" :item="data"></dijia>
    </div>
  </div>
</template>

<script>
import dijia from '../public/dijiaEle.vue'
import { useStore } from 'vuex'
import ajax from '@/ajax'
import { ref } from '@vue/reactivity'
export default {
    components:{dijia},
    setup(){
        const data = ref({
            title : "智能设备",
            desc : `
            互联网助力中国制造，让“中国制造2025”加快步伐。为顺应时代科技发展趋势，澳思柏恩以技改项目为抓手，引
            进<span>自动化、信息化、智能化设备</span>，促进公司信息化和工业化的深度结合，打造<span>全新智能工厂</span>。
            <br />
            <br />
            为适应国内外市场严苛环境，澳思柏恩运营团队积极了解国内外新兴生产工艺，引进德国EWK喷淋湿电一体化
            尾气处理系统，使其制造排放量远低于国家标准排放值，达到欧洲排放标准。在确保生产工艺的稳定和产品的品
            质的同时，打造一条会思考的生产线，以<span>创新引领变革，用绿色缔造健康</span>。
            `
        })
        const store = useStore()
        const baseUrl = store.state.baseUrl
        const init = async function(){
            const aData = await ajax(baseUrl + 'web/inteequService')

            let item = aData[aData.length - 1]
            data.value = {
                title:item.name,
                desc:item.particulars,
                imgurl:baseUrl + item.imgurl
            }
        }
        init()

        return {
            data
        }

    }
    
}
</script>

<style lang="less" scoped>
.info{
    display: flex;
    .pic{
        width: 32.4%;
        padding-top: 23.9%;
    }
    .cont{
        flex: 1;
        .dijia{
            width: 100%;
            height: 100%;
            padding: 5.6% 0% 0% 5.8%;
            box-sizing: border-box;
        }
    }
}
@media all and (max-width:769px){
.info{
    flex-wrap: wrap;
    .pic{
        width: 100%;
        padding-top: 74.9%;
    }
    .cont{
        .desc{
            width: 100%;
        }
        padding: 15px;
    }
}
}
</style>


<style lang="less">

.info{
    background: #fff;
    .cont{
        .desc{
            width: 78%;
            letter-spacing: 0.7px;
            line-height: 1.6;
            span{
                color: #00543e;
                font-weight: bold;
            }
        }
    }
}
@media all and (max-width:769px){

    .info{
        .cont{
            .dijia{
                padding: 0 0 0 0 !important;
                .desc{
                    width: 100%;
                    letter-spacing: 0.7px;
                    line-height: 1.6;
                    span{
                        color: #00543e;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

</style>
