<template>
    <div class="hot">
        <div class="imgs">
            <Swiper :modules="modules" :autoplay="{delay: 3000,disableOnInteraction: false}" class="imgs-swiper" @swiper="setImgs" @slideChangeTransitionStart="slideChangeTransitionStart" @slideChangeTransitionEnd="slideChangeTransitionEnd">
                <SwiperSlide class="imgs-slide" v-for="(item, index) in hot" :key="index">
                    <newsItemVue :item="item" class="newsItemVue" type="hot"></newsItemVue>
                </SwiperSlide>
                <div class="pagetions">
                    <div class="prev" @click="prevSlide">《</div>
                    <div class="page">
                        <div class="activeIndex">{{pages.activeIndex < 9 ? "0"+(pages.activeIndex+1) : pages.activeIndex+1}}</div>
                        <div :class="{line:true}">
                            <div :class="{leftSlide:pages.active}"></div>
                        </div>
                        <div class="alls">{{pages.alls < 9 ? "0"+pages.alls : pages.alls}}</div>
                    </div>
                    <div class="next" @click="nextSlide" >》</div>
                </div>
            </Swiper>

        </div>
    </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Controller , Lazy , Autoplay,Pagination} from 'swiper';
import 'swiper/css'
import newsItemVue from './newsItem.vue';
import { ref , onUpdated} from 'vue';

export default {
    props: ["hot"],
    components: { Swiper, SwiperSlide, newsItemVue },
    setup(props){
        const imgs = ref(null)
        console.log(props)
        const pages = ref({
                activeIndex:0,
                alls:props.length,
                active:true
            })
        const setImgs =(swiper)=>{
            imgs.value = swiper
        }
        const slideChangeTransitionStart = function(){
            pages.value.active = false
        }
        const prevSlide= function(){
            imgs.value.slidePrev()
        }
        const nextSlide= function(){
            imgs.value.slideNext()
        }
        const slideChangeTransitionEnd = function(){
            pages.value.activeIndex =  imgs.value.activeIndex
            pages.value.active = true
        }
        onUpdated(()=>{
            pages.value.alls = props.length
        })
        return {
            setImgs,pages,slideChangeTransitionEnd,nextSlide,prevSlide,
            modules:[Autoplay,Pagination,Controller,Lazy],slideChangeTransitionStart
        }
        
    }
    // mounted(){

    // }
}
</script>

<style lang="less">
.hot {
    .newsItemVue{
        .pic{
            flex: 0 0 auto;
        }
    }
    .pagetions{
        width: 33.8%;
        height: 80px;
        background-color: #B9985E;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 9;
        display: flex;
        padding: 0 80px;
        box-sizing: border-box;
        font-size: 1.8rem;
        color: #fff;
        align-items: center;
        

        .page{
            display: flex;
            font-size: 1.8rem;
            color: #fff;
            flex: 1;
            align-items: center;
            .line{
                flex: 1;
                max-height: 2px;
                height: 2px;
                background-color: rgba(255, 255, 255, .5);
                margin: 0 60px;
                overflow: hidden;
                div{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #00543D;
                    animation-duration: 3s;
                }


            }
        }
        .prev{
            margin-right: 30px;
            cursor: pointer;
        }
        .next{
            margin-left: 30px;
            cursor: pointer;
        }
    }
    .title {
        width: 80%
    }

    .add-icon {

        &::before,
        &::after {
            background-color: #00543D !important;
        }
    }
}
.leftSlide  {
    animation-name: leftSlide;
}

@keyframes leftSlide{
    0%{
        transform: translate(-100%);
    }
    100%{
        transform: translate(0%);
    }
}
</style>

<style lang="less" scoped>
.hot {
    width: 100%;

    .imgs-swiper {
        width: 100%;

        .imgs-slide {
            width: 100%;
        }
    }
}
</style>