<template>
    <navigationEleVue :isActive="true"></navigationEleVue>

    <bannerEleVue id="4"></bannerEleVue>
    <div v-if="ResizeValue" class="plant">
        <!-- <img src="../assets/demo/202211231040.jpg" alt=""> -->

        <div class="swiper-contioner por-none">
            <Swiper class="title-swiper" :modules="modules" :effect="'fade'" @swiper="setTitle" :controller="{control:videoSwiper}" >
                <SwiperSlide class="title-slide" v-for="(item,index) in plantData" :key="index">
                    <daina class="daina" plant="plant" :item="item"></daina>
                </SwiperSlide>
            </Swiper>

            <Swiper class="video-swiper" :modules="modules" @swiper="setVideo" :mousewheel="true" :controller="{control:titleSwiper}" :direction="'vertical'" >
                <SwiperSlide class="video-slide" v-for="(item,index) in plantData" :key="index">
                    <div class="video">
                        <div class="pic">
                            <img :src="item.video.imgurl" alt="">
                            <swiperButtonEleVue @click="videoFn.play($event,index)" class="swiperButtonEleVue-play" type="play"></swiperButtonEleVue>
                        </div>
                        <div class="pause">
                        <!-- <div class="pause" @click="videoFn.pause(index)"> -->
                            <swiperButtonEleVue @click="videoFn.pause($event,index)" class="swiperButtonEleVue-pause" :type="'stop'"></swiperButtonEleVue>
                        </div>
                        {{item}}
                        <video ref="video" :data-url="item.video.url"  :src="item.video.url"></video>
                    </div>
                    <Swiper class="imgs-swiper" :slidesPerView="2" @swiper="SetImgsSwiper[index]" >
                        <swiperButtonEleVue @click="prevSlide(index)" class="swiperButtonEleVue-prev" type="prev"></swiperButtonEleVue>

                        <SwiperSlide class="imgs-slide" v-for="(img,index) in item.imgs" :key="index" >
                            <div class="pic">
                                <img :src="img" alt="">
                            </div>
                        </SwiperSlide>
                        <swiperButtonEleVue @click="nextSlide(index)" class="swiperButtonEleVue" type="next"></swiperButtonEleVue>
                    </Swiper>
                </SwiperSlide>
            </Swiper>
        </div>
        
    </div>
    <div v-if="!ResizeValue"  class="plant">
        <div class="gongchang" v-for="(item,index) in plantData" :key="index">
            <!-- <div class="video">
                <div class="pic"  >
                    <img :src="item.video.imgurl" alt="">
                    <swiperButtonEleVue @click="videoFn.play($event,index)" class="swiperButtonEleVue-play" :type="'play'"></swiperButtonEleVue>
                </div>
                <video ref="video" :src="item.video.url"></video>
            </div> -->
            <div class="video">
                <div class="pic">
                    <img :src="item.video.imgurl" alt="">
                    <swiperButtonEleVue @click="videoFn.play($event,index)" class="swiperButtonEleVue-play" type="play"></swiperButtonEleVue>
                </div>
                <div class="pause">
                <!-- <div class="pause" @click="videoFn.pause(index)"> -->
                    <swiperButtonEleVue @click="videoFn.pause($event,index)" class="swiperButtonEleVue-pause" :type="'stop'"></swiperButtonEleVue>
                </div>
                <video ref="video" :data-url="item.video.url"  :src="item.video.url"></video>
            </div>
            <daina class="daina" :item="item"></daina>
        </div>
    </div>
    <skipEle :id="5"></skipEle>
    <footerEleVue></footerEleVue>
</template>

<script>

import {ref,onMounted,onUpdated} from 'vue'

import bannerEleVue from '@/components/public/bannerEle.vue';

import { Swiper,SwiperSlide } from 'swiper/vue';
import "swiper/css"
import { Parallax ,EffectFade ,Mousewheel,Controller} from "swiper";
import daina from '../components/public/dainaEle.vue'
import swiperButtonEleVue from '@/components/public/swiperButtonEle.vue';
import skipEle from '@/components/public/skipEle.vue';
import footerEleVue from '@/components/public/footerEle.vue';
import navigationEleVue from '@/components/public/navigationEle.vue';
import { useStore } from 'vuex';
import ajax from '@/ajax/index.js'
import { useRoute } from 'vue-router';

export default {
    components:{
        navigationEleVue,
        Swiper,SwiperSlide,swiperButtonEleVue,
        bannerEleVue,daina,skipEle,footerEleVue
    },
    setup(){
        const title = {
            titleCn:"资源优势",
            titleEn:"Resource Advantages",
            desc:"定义高端制造的生活方式",
            imgurl:require('../assets/core/b2.jpg'),
            video:{
                cover:"require('../assets/about/video.jpg')",
                url:""
            }
        }

        const plantData = ref([
            {
                classify:"资源优势",
                title:"原材优势",
                en:"osborne Rizhao Factory",
                address:"山东省日照市岚山区木材贸易加工示范区",
                tel:"0633-2667333",
                desc:"日照市澳思柏恩装饰材料有限公司，坐落于全国最大木材贸易加工集散基地——日照岚山。澳思柏恩于2016年进驻日照市岚山区，依托于国家一类开放港口岚山港原材进口优势，大力发展性能更加稳定的松木刨花板。一直以来，澳思柏恩始终以客户为中心，致力于提供更加优质的产品及健全的售后服务。",
                video:{
                    url:require('@/video/661.mp4'),
                    imgurl:require("../assets/plant/01.jpg")
                },
                imgs:[require("../assets/plant/01-3.jpg"),require("../assets/plant/01-2.jpg"),require("../assets/plant/01-4.jpg")]
            },
            {
                classify:"资源优势",
                title:"澳思柏恩郯城工厂",
                en:"osborne Tancheng Factory",
                address:"山东省临沂市郯城县红花镇壮口社区",
                tel:"0539-2659789",
                desc:"澳思柏恩装饰材料有限公司，坐落于中国板材之都临沂的最南端——郯城。郯城工厂位于山东、江苏木材产业圈的中心地带，紧靠南北交通要道205国道、G2京沪高速。澳思柏恩郯城工厂采用进口智能的生产设备，是行业内首家引进德国辛北尔康普生产线的智能工厂，遵循高效严谨的生产流程，制造绿色可持续发展的环保板材。",
                video:{
                    url:"",
                    imgurl:require("../assets/plant/02.jpg")
                },
                imgs:[require("../assets/plant/02-3.jpg"),require("../assets/plant/02-2.jpg"),require("../assets/plant/02-4.jpg")]
            },
            {
                classify:"资源优势",
                title:"澳思柏恩邳州工厂",
                en:"osborne Pizhou Factory",
                address:"江苏省徐州市邳州市官湖镇上海路木制品木结构产业园",
                tel:"400-680-1881",
                desc:`江苏澳思柏恩装饰材料有限公司，坐落于江苏文明最早的起源之地——邳州。邳州从80年代初就形成的原木市场闻名全国，是苏、鲁、豫、皖接壤地区最大的木材集散地，邳州市距南京、蚌埠、合肥、济南、郑州、青岛等大中城市均在300公里左右，拥有巨大的市场空间。
                      <br />
                      江苏澳思柏恩装饰材料有限公司，工厂配备意大利、德国等先进生产设备，自建木制品木结构产业园，拥有三条智能化生产线，是目前最具规模的人造板智能化工厂之一。`,
                video:{
                    url:"",
                    imgurl:require("../assets/plant/03.jpg")
                },
                imgs:[require("../assets/plant/03-3.jpg"),require("../assets/plant/03-2.jpg"),require("../assets/plant/03-4.jpg")]
            },
        ])

        // swiper
        const titleSwiper = ref(null)
        const videoSwiper = ref(null)
        const imgsSwiper = ref([])
        const SetImgsSwiper = ref([])
        const titleName = ref('titleName');
        const keyword = ref('keyword');
        const description = ref('description');
        
        const route = useRoute()
        const store = useStore();
        const baseUrl = store.state.baseUrl;
        

        const init = async function(){
            const aPlantData = await ajax(baseUrl + 'web/intelligentbriefService')
            let rPlantData = [];
            for (let index = 0; index < aPlantData.length; index++) {
                const item = aPlantData[index];
                let imgs = []
                const aPlant_img = await ajax(baseUrl + 'web/intelligentlistService',{type:item.id})
                aPlant_img.forEach(item=>{imgs.push(baseUrl + item.imgurl)})

                rPlantData.push({
                    classify:item.title,
                    title:item.title,
                    en:item.enTitle,
                    address:item.name,
                    tel:item.name,
                    desc:item.particulars,
                    video:{
                        url:baseUrl + item.video,
                        imgurl:baseUrl + item.poster
                    },
                    imgs:imgs
                })
            }

            plantData.value = rPlantData

            // useRoute().params.id
            console.log(route,"===============")
            if(route.params.id){
                titleSwiper.value.slideTo(route.params.id)
            }
        }
        init();
        
        plantData.value.forEach(()=>{
            SetImgsSwiper.value.push(swiper=>{
                imgsSwiper.value.push(swiper)
            })
        })
        

        /**
         * methods
         */
        const setImgs = swiper=>{
            imgsSwiper.value = swiper
        }
        const setVideo = swiper=>{
            videoSwiper.value = swiper
        }
        const setTitle = swiper=>{
            titleSwiper.value = swiper
        }
        const nextSlide = (index)=>{
            imgsSwiper.value[index].slideNext();
        }
        const prevSlide = (index)=>{
            imgsSwiper.value[index].slidePrev();
        }

        // (播放 或 暂停)视频
        const video = ref(null)

        const videoFn = (function(){
           let state = {}

            return {
                play:function(e,index){
                    if(!state || !state[index]){
                        state[index] = {
                            state:true,
                            playEle:{
                                btn:e.currentTarget,
                                img:e.currentTarget.parentElement
                            },
                        }
                    }
                    
                    for (const key in state) {
                        state[key].playEle.btn.style.opacity = 1
                        state[key].playEle.img.style.opacity = 1
                        state[key].playEle.btn.style.pointerEvents = 'auto'
                        state[key].playEle.img.style.pointerEvents = 'auto'
                        video.value[key].pause();
                    }
                    state[index].playEle.btn.style.opacity = 0
                    state[index].playEle.img.style.opacity = 0
                    state[index].playEle.btn.style.pointerEvents = 'none'
                    state[index].playEle.img.style.pointerEvents = 'none'
                    video.value[index].play();
                },
                pause:function(e,index){
                    state[index].playEle.btn.style.opacity = 1
                    state[index].playEle.img.style.opacity = 1
                    state[index].playEle.btn.style.pointerEvents = 'auto'
                    state[index].playEle.img.style.pointerEvents = 'auto'
                    video.value[index].pause();  
                }
            }
        })()

        onMounted(()=>{
            // 禁止页面滚动
            window.addEventListener('scroll',function(){
                let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                let flagHeight = document.querySelector('.swiper-contioner').offsetTop
                // console.dir(flagHeight)
                if(scrollTop>=flagHeight ){
                    document.querySelector(".swiper-contioner").classList.remove("por-none");
                    document.querySelector(".swiper-contioner").classList.add("por-auto");
                }
            })
        })
        
        onUpdated(async() => {
            let slideInfo = await ajax(baseUrl + "web/intelligentbriefService");
            let url = ""
            let data = []
            if(route.params.id){
              url = slideInfo[route.params.id].title
              data = await ajax(baseUrl + `web/seoyhxqService?title=${url}`);
            }else{
              url = store.state.langPack[store.state.lang].navigation[3].name
              data = await ajax(baseUrl + `web/seoyhService?title=${url}`)
            }
            titleName.value =  data[0].title;
            keyword.value =  data[0].keyword;
            description.value =  data[0].description;
        })
        //     // 解除页面滚动
        // const setScroll = function(){
        //         let dom = document.getElementById("app");
        //         dom.style.position = "static";
        //         document.body.scrollTop = 956
        //     // }
        // }

        
        
        // 监听屏幕宽度变化
        /**
         * ResizeValue ==> true ==> 大于769
         *                ==> false => 小于769
         */
        const ResizeValue = ref(document.body.clientWidth > 769)
        window.addEventListener('resize',()=>{
            if(document.body.clientWidth < 769){
                ResizeValue.value = false
            }else {
                ResizeValue.value = true
            }
        })
        

        // 底部上面的板块
        const skip = {
            imgurl:require('../assets/brandDetail/db.jpg'),
            url:"/contact",
            title:"对我们的产品感兴趣<br />可以联系我们哦!"
        }
        return {
            title,plantData,ResizeValue,skip,
            modules: [Parallax,EffectFade,Mousewheel,Controller],setVideo,setTitle,setImgs,nextSlide,titleSwiper,imgsSwiper,video,videoFn,
            SetImgsSwiper,prevSlide,titleName,keyword,description
        }

    },
    metaInfo() {
      return {
        title: this.titleName, // set a title
        meta: [
          {
            // set meta
            name: "keywords",
            content: this.keyword,
          },
          {
            name: "Description",
            content: this.description,
          },
        ],
      };
    },
}
</script>
<style lang="less">
.plant{
    .swiper-contioner{
        .title-swiper{
            .title-slide:nth-child(2n+1){
            .daina{background: #00543e;}
            }
            .title-slide:nth-child(2n+2){
                .daina{background: #b9985e;}
                .classify,.title,.en,.address,.tel,.desc{
                    color: #00543e;
                }
            }
            .title-slide:nth-child(2n+3){
                .daina{background: #cfcfcf;}
                .classify,.title,.en,.address,.tel,.desc{
                    color: #666666;
                }
            }
        }
    }
}
.daina {
    position: relative;
    .desc{
        position: absolute;
        bottom: 30px;
    }   
}
</style>
<style lang="less" scoped>
.por-auto{
    pointer-events: auto;
}
.por-none{
    pointer-events: none;
}
.plant{
    &>img{
    width: 100%;
    position: absolute;
    z-index: -1;
    // background: #fff;
  }
  .swiper-contioner{
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    .title-swiper{
        margin:0 0 0 0;
        width: 28.5%;

        .title-slide{
            .daina{background: transparent;}
        }
        .title-slide:nth-child(2n+1){
            .daina{background: #00543e;}
        }
        .title-slide:nth-child(2n+2){
            .daina{background: #b9985e;}
        }
        .title-slide:nth-child(2n+3){
            .daina{background: #cfcfcf;}
        }
    }
    .video-swiper{
        flex: 1;
        display: flex;
        
        .video-slide{
           pointer-events: auto !important;
            .video{
                width: 100%;
                height: 50%;
                overflow: hidden;
                position: relative;
                .pic{
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                    transition: .7s;
                    img{
                        transition: .7s;
                    }
                    .swiperButtonEleVue-play{
                        width: 60px;
                        height: 60px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        z-index: 9;
                    }
                    
                }
                .pause{
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 8;
                    .swiperButtonEleVue-pause{
                        display: block;
                        max-height: 60px;
                        max-width: 60px;
                        height: 60px;
                        transition: .7s;
                        opacity: 0;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                    &:hover{
                        .swiperButtonEleVue-pause{
                            opacity: 1;
                        }
                    }
                }
                video{
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    z-index: 7;

                }
            }
            &>.pic{
                width: 50%;
                height: 50%;
            }
            .imgs-swiper{
                width: 100%;
                height: 50%;
                .swiperButtonEleVue{
                    position: absolute;
                    top: 50%;
                    right: 30px;
                    left: auto;
                    transform: translateY(-50%);
                }
                .swiperButtonEleVue-prev{
                    position: absolute;
                    right: auto;
                    left: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .imgs-slide{
                    width: 50%;
                    height: 100%;
                    .pic{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    &>.swiperButtonEleVue{
        position: absolute;
        top: 75%;
        right: 0;
        transform: translate(-50%);
    }
  }
}
</style>

<style lang="less">
    @media all and (max-width:769px) {
    .plant{
        .gongchang{
            .video{
                width: 100%;
                height: 30vh;
                overflow: hidden;
                position: relative;
                background: #000;
                .pic{
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                    transition: .7s;
                    img{
                        transition: .7s;
                    }
                    .swiperButtonEleVue-play{
                        width: 60px;
                        height: 60px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        z-index: 9;
                    }
                    
                }
                .pause{
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 8;
                    .swiperButtonEleVue-pause{
                        display: block;
                        max-height: 60px;
                        max-width: 60px;
                        height: 60px;
                        transition: .7s;
                        opacity: 0;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                    &:hover{
                        .swiperButtonEleVue-pause{
                            opacity: 1;
                        }
                    }
                }
                video{
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    z-index: 7;

                }
            }
        }
        .gongchang:nth-child(2n+1){
            .daina{
                background: #00543e;
                .classify,.en,.address,.desc,.tel{
                    color: #b9985e;
                }
                .title{
                    color: #fff;
                }
            }
        }
        .gongchang:nth-child(2n+2){
            .daina{
                background: #b9985e;
                .classify,.title,.en,.address,.desc,.tel{
                    color: #00543e;
                }
            }
        }
        .gongchang:nth-child(2n+3){
            .daina{
                background: #cfcfcf;
                .classify,.title,.en,.address,.desc,.tel{
                    color: #666666;
                }
            }
            

        }
        
        .daina{
            box-sizing: border-box;            
            // padding-bottom:30px;

            // padding-left: 15px;
            // padding-right: 15px;
            .title{
                font-size: 2.4rem;
            }
            .desc{
                margin-top: 30px;
                // font-size: 1.4rem;
            }
            padding: 15px;
            .classify,.en{
                display: none;
            }
            .address{
                margin-top: 30px;
            }
            .desc{
                width: 100%;
                margin-right: 0;
            }
        }
        .video{
            position: relative;
            height: 30vh;
            &>.pic{
                padding-top: 0;
                height: 100%;
                .swiperButtonEleVue-play{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
            }
            video{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }
}
</style>