<template>
  <div class="search">
    <div class="input-contioner">
      <div class="input">
        <input type="text" v-model="inputValue" :placeholder="lang.input" />
      </div>
      <div class="btn">
        <button @click="search">
          <span>{{ lang.button }}</span>
          <svg
            t="1669707242508"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1646"
            width="200"
            height="200"
          >
            <path
              d="M469.333 192c153.174 0 277.334 124.16 277.334 277.333 0 68.054-24.534 130.411-65.216 178.688L846.336 818.24l-48.341 49.877L630.4 695.125a276.053 276.053 0 0 1-161.067 51.542C316.16 746.667 192 622.507 192 469.333S316.16 192 469.333 192z m0 64C351.51 256 256 351.51 256 469.333s95.51 213.334 213.333 213.334 213.334-95.51 213.334-213.334S587.157 256 469.333 256z"
              p-id="1647"
              fill="#b9985e"
            ></path>
          </svg>
        </button>
      </div>
    </div>
    <div class="canys">
      <div class="scroll">
        <ul class="th-ul">
          <li class="th id">{{ lang.table.id }}</li>
          <li class="th conpany">{{ lang.table.conpany }}</li>
          <li class="th date">{{ lang.table.date }}</li>
          <li class="th begave">{{ lang.table.begave }}</li>
          <!-- selct -->
        </ul>
        <ul class="th-ul tr-ul" v-for="(item, index) in canypany" :key="index">
          <li class="th id">{{ item.id }}</li>
          <li class="th conpany">{{ item.conpany }}</li>
          <li class="th date">{{ item.date }}</li>
          <li class="th begave" @click="select(index)">
            {{ lang.table.selct }}
          </li>
        </ul>
      </div>
    </div>

    <div
      v-on:click.stop="closeAlertData($event)"
      :class="{ alert: true, active: alertData.show }"
    >
      <!-- <div class="pic"> -->
      <img v-if="alertData.show" :src="alertData.imgurl" alt="" />
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { showText } from "@/js/uitl";
import ajax from "@/ajax/index.js";
import { useRoute } from "vue-router";
export default {
  setup() {
    // const store = ref(useStore())
    // 查看证书
    const alertData = ref({
      show: false,
      imgurl: "",
    });

    // 搜索查询
    const inputValue = ref(null);
    let showText_o = null;
    const search = function () {
      console.dir(new Boolean(inputValue.value.trim()));
      if (inputValue.value == null || !inputValue.value.trim()) {
        if (!showText_o) {
          showText_o = new showText();
        }
        showText_o.add("请输入授权序列号或者公司名称");
        return false;
      }

      const baseUrl = storer.state.baseUrl;
      ajax(baseUrl + "web/query123Service", {
        serialNum: inputValue.value,
      }).then((res) => {
        let result = res;
        let query = [];
        /* cn: "洽谈意向",
				en: "Negotiation intention" */
        if (result == "未搜索到内容") {
          if (!showText_o) {
            showText_o = new showText();
          }
          showText_o.add("抱歉! 暂未查询到您搜索的内容");
        } else {
          result.forEach((item) => {
            /* id:"201561",
						conpany:"重庆市****有限公司",
						date:"2022-10-31", */
            let { year, month } = getTime(item.mandate);
            let imgUrl = item.ccie.replace("/ausbourne-admin/", "");
            query.push({
              id: item.serialNum,
              conpany: item.name,
              date: year + "-" + month,
              imgurl: baseUrl + imgUrl,
            });

            function getTime(str) {
              // eslint-disable-line
              let date = new Date(str);
              const year = date.getFullYear();
              const month = date.getMonth() + 1;
              const day = date.getDate();
              const hour = date.getHours();
              const min = date.getMinutes();
              const sec = date.getSeconds();

              return {
                year,
                month,
                day,
                hour,
                min,
                sec,
              };
            }
          });
          canypany.value = query;
          console.log(canypany.value);
        }
      });
    };

    const storer = reactive(useStore());

    const lang = ref(storer.state.langPack[storer.state.lang].coop.search);
    const tablang = function (key) {
      storer.commit("setLang", key);
      lang.value = storer.state.langPack[storer.state.lang].coop.search;
    };

    // 认证数据
    let canypany = ref([
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
      {
        id: "201561",
        conpany: "重庆市****有限公司",
        date: "2022-10-31",
      },
    ]);

    // copy 认证数据
    // const copyCanypany = reactive(null)
    const baseUrl1 = storer.state.baseUrl;
    /*  */
    ajax(baseUrl1 + "web/queryService").then((res) => {
      let result = res;
      let query = [];
      /* cn: "洽谈意向",
			en: "Negotiation intention" */

      result.forEach((item) => {
        /* id:"201561",
				conpany:"重庆市****有限公司",
				date:"2022-10-31", */
        let { year, month } = getTime(item.mandate);
        let imgUrl = item.ccie.replace("/ausbourne-admin/", "");
        query.push({
          id: item.serialNum,
          conpany: item.name,
          date: year + "-" + month,
          imgurl: baseUrl1 + imgUrl,
        });
        function getTime(str) {
          // eslint-disable-line
          let date = new Date(str);
          const year = date.getFullYear();
          const month = date.getMonth() +1;
          const day = date.getDate();
          const hour = date.getHours();
          const min = date.getMinutes();
          const sec = date.getSeconds();

          return {
            year,
            month,
            day,
            hour,
            min,
            sec,
          };
        }
      });
      console.log(query);
      canypany.value = query;
    });

    // 查看证书
    const select = function (id) {
      alertData.value.show = true;
      alertData.value.imgurl = canypany.value[id].imgurl;
    };
    // 证书滚动
    // onMounted(() => {
    // let scorllHeight = .querySelector('.canys').scorllHeight
    // let index = 0
    // let dataIndex = 0
    // let time = 1
    // let autoScroll = null;

    // window.onscroll = function () {
    // 	let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    // 	let dom = document.querySelector('.canys')
    // 	// 页面滚动到该位置 启动自动滚动
    // 	if (scrollTop >= dom.offsetTop && autoScroll == null && !0) {

    // 		autoScroll = setInterval(() => {
    // 			let scorllHeight = document.querySelector('.canys').offsetHeight
    // 			if (time * scorllHeight - index <= copyCanypany.length) {
    // 				if (dataIndex < copyCanypany.length) {
    // 					canypany.value.push({
    // 						id: "201561",
    // 						conpany: "重庆市****有限公司",
    // 						date: "2022-10-31",
    // 					})
    // 					if (dataIndex % 2 != 0) {
    // 						canypany.value.splice(0, 1)
    // 					}

    // 					dataIndex++
    // 				}
    // 				if (dataIndex >= copyCanypany.length) {
    // 					dataIndex = 0
    // 					time++
    // 				}
    // 			}

    // 			document.querySelector('.scroll').scrollTop = index++
    // 		}, 16.7)
    // 	}

    // 	if (scrollTop >= dom.offsetTop + dom.offsetHeight) {
    // 		clearInterval(autoScroll)
    // 	}
    // }

    // // 用户滑动 取消自动滚动
    // document.querySelector('.canys').addEventListener("mousewheel", () => {
    // 	clearInterval(autoScroll)
    // })
    // })
    // 关闭弹窗
    const closeAlertData = function (e) {
      if (e.currentTarget == e.target) {
        alertData.value.show = false;
      }
    };

    watch(
      () => storer.state.lang,
      (val, old) => {
        console.log(val, old);
        lang.value = storer.state.langPack[val].coop.search;
      }
    );
    const route = useRoute();
    onMounted(() => {
      setTimeout(() => {
        if (route.params.id == "2") {
          document
            .querySelector(".search")
            .scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    });
    return {
      inputValue,
      search,
      tablang,
      lang,
      canypany,
      select,
      closeAlertData,
      alertData,
    };
  },
};
</script>

<style lang="less" scoped>
.search {
  padding: 6.5% 1.3% 6.5% 9.4%;

  .input-contioner {
    display: flex;

    .input {
      flex: 1;
      padding: 8px 0;

      input {
        width: 100%;
        line-height: 2;
        font-size: 2.4rem;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        border: none;
        background: #bebebe;
        color: #fff;
        text-indent: 1em;

        &::placeholder {
          color: rgb(255, 255, 255);
        }
      }

      background: #bebebe;
    }

    .btn {
      width: fit-content;

      button {
        height: 100%;
        margin: 0;
        padding: 0 15px;
        border: none;
        background-color: #00543e;
        color: #fff;
        font-size: 2.4rem;
        cursor: pointer;
      }

      svg {
        display: none;
      }
    }
  }

  .canys {
    padding: 23px 32px 23px 38px;
    background-color: #fff;

    .scroll {
      max-height: calc(1.8rem * 3.5 * 7 + 6px);
      overflow-y: scroll;

      // 火狐滚动条
      scrollbar-color: #00543e #cccccc;
      scrollbar-width: thin;

      // -webkit- 滚动条 谷歌 edge
      &::-webkit-scrollbar {
        width: 2px;
      }

      // 滚动条两端的按钮
      &::-webkit-scrollbar-button {
        display: none;
      }

      // 外层轨道
      &::-webkit-scrollbar-track {
      }

      // 内层轨道，滚动条中间部分(除去)
      &::-webkit-scrollbar-track-piece {
        background-color: #cccccc;
      }

      // 滚动条里面可以拖动的那个
      &::-webkit-scrollbar-thumb {
        background-color: #00543e;
      }

      // 边角
      &::-webkit-scrollbar-corner {
      }

      // 定义右下角拖动块的样式
      &::-webkit-resizer {
      }
    }

    .th-ul {
      display: flex;
      list-style: none;
      width: calc(100% - 30px);

      border-bottom: 1px solid #ccc;

      .th {
        font-size: 1.8rem;
        font-weight: 600;
        color: #333;
        line-height: 3.5;
      }

      .date {
        // text-indent: .5em;
      }

      .begave {
        text-align: center;
      }
    }

    .tr-ul {
      .th {
        font-weight: 400;
      }

      .date {
        text-indent: 0em;
      }

      .begave {
        text-indent: 0em;
        text-align: center;
        cursor: pointer;
      }
    }

    .id {
      width: 13.2%;
    }

    .conpany {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .date {
      // width: 30%;
      width: 16.3%;
      // text-indent:0.5rem
    }

    .begave {
      width: 15%;

      // width: 30%;
    }
  }

  .alert {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;

    &.active {
      pointer-events: auto;
      opacity: 1;
      transition: 1s;
    }

    // .pic {
    // 	width: 80%;
    // 	height: 80%;
    // 	img{
    // 		object-fit:contain;
    // 	}
    // }
    img {
      width: auto;
      height: 80%;
    }
  }
}

@media all and (max-width: 1200px) {
  .search {
    .input-contioner {
      .input {
        width: 79%;
      }
    }

    .canys {
      .th-ul {
        .date {
          display: none;
        }
        .begave {
          width: 30%;
        }
      }
    }
  }
}

@media all and (max-width: 769px) {
  .search {
    padding: 15px;
    box-sizing: border-box;

    .input-contioner {
      .input {
        width: 84%;

        input {
          font-size: 1.4rem;
        }
      }

      .btn {
        button {
          font-size: 1.8rem;

          svg {
            display: block;
            width: 2.4rem;
            height: 2.4rem;
            margin: 0 15px;
          }

          span {
            display: none;
          }
        }
      }
    }

    .canys {
      padding: 15px;

      .th-ul {
        width: calc(100% - 15px);

        .id {
          display: none;
        }

        .th {
          font-size: 1.4rem;
        }

        .date {
          display: none;
        }
        .begave {
          width: 25%;
        }
      }
    }
  }
}
</style>
