<template>
    <footer v-if="ResizeValue" class="footer">
        <div class="top">
            <div :class="{links:true,en:lang == 'en'}">
                <ul>
                    <li v-for="(item,index) in footer.links"  class="wow animate__fadeInLeft" :key="index">
                        <h4 class="title" @mousemove="setMouserMove(index)" @mouseout="setMouseLeave(index)" :alt="item.title">{{item.title}}</h4>
                        <img @mousemove="setMouserMove(index)" @mouseout="setMouseLeave(index)" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD0AAAA9CAYAAAAeYmHpAAAAeElEQVRoge3ZwQmAUAwFQRX7b/l7UewgCrPTwGPJMftaa/vIM7xPDx/Tg39QtKJoRdGKohVFK4pWFK0oWlG0omhF0YqiFUUryOhzex9pXxnfZy89/iq99aqdVLSiaEXRiqIVRSuKVhStKFpRtKJoRdGKohVFK4pWXNKYB3qdp+5mAAAAAElFTkSuQmCC" alt="">
                        <!-- <ul class="second">
                            <li v-for="(link,index) in item.links" :key="index">
                                <router-link :to="link.url">{{link.name}}</router-link>
                            </li>
                        </ul> -->
                    </li>
                </ul>
                <ul class="second" v-for="(item,index) in footer.links" ref="second" :key="index" @mousemove="setMouserMove(index)" @mouseout="setMouseLeave(index)" >
                    <li v-for="(link,index) in item.links" :key="index">
                        <router-link :to="link.url">{{link.name}}</router-link>
                    </li>
                </ul>
            </div>
            <div class="line"><div class="line"></div></div>
            <div :class="{contact:true,en:lang == 'en'}">
                <div class="adds" style="animation-delay:1.2s;">
                    <div class="title wow animate__fadeInUp">
                        <div class="icon pic">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAoCAYAAAAG0SEsAAADjklEQVRYhbXYbYhVZRAH8J/XIFEhSdMKLCEKxTAjohKyQDNKiQLJEAoCQ6I3itIo4jCQklFmlBFRfQqCXqgwotiU7MU0SipdM4LCD2qRUCHSFtT24dy73c7Ovffsrv2/nf/Mmf9zz8wzzzx33ODgoBqYjKW4CudhNiY0bYP4FfuwC+9GRF+doON6iE/GA1iFU+oEbGIfNkbEC6MVX4FNOHUEolXswuqI+Gok4o/jnjGIVrEyIl6uI/4ibu4RbBCHWzFwWo0FrKqmoSq+AWu6BHgVr+FzHGhyDczFJcpUXdbl/UURsS0TX4xOVdqHe/F11RAR/3kuimIZnsKsJM4ApkfE0daqYTyG5aSJDViSCWeIiLcxDx8l5gl4vvXQEr8D0xLnzbi/jmhlAUcjYiH6E/P1RVHMaRe/L3Hqx+1dNCbh9B7rWKIszirWUOZ8IbYnDhcr92kV5yvzfwVOxvd4Bw+1ctmOoijWKRtVO45hWgNXJwK7Owgva9pWKjveeJyNu7CnKIoZyTuP4a8KNwkLGpifvPBKws3AloRv4czMHhG/4MPE/8IGZiaGLxOuW/6HAhZFsSDhdybcWQ2clBgOJ9xFNcTJm8yPCTexkZCdUOvs7eA3LnNs4FDCZ1vo05riHyRcVogHG/gsMWRFuBl/9xD+OCKy/GZ18EkDbySGFQn3M67U+fN/h+uqZFEUU7GwQg9gawNbDS+I+bg0EXgf5+Il/ITfsB/rMD8ijiTvrDU8569HxLETmg9P4+GKw3OYkwTbhxtxIiY293GKoihmKrthFRv5t7dvwp8Vh9l4tlNg/IFuwg28Z/iv3hERu9vFj+GRJMZqPNllAZ2Ep2CH/MsNjWft+3x9cxFV3IltuKCm8HLslTelvogYOjOqY9Qtylx3wlvKUWqHsvp/xxScgUVY3kG0hVkR0Rq/0gFyr3Im64Ujyi81VTnf98KjEbG2ncjE5zYXcDxxOCKGdc2st/fLi28sWJ6R3W4s3+Kc4yD8TETcNlLx2fhmjMIHImJWJ2O3I3W/sV+ZlnYz9jrPn9D5ItELd0dENjoPodcVmXLQP6icVOtiS0Rc08upziQzoJy/6+IQrq3jWHeM+gK31vRdHBG9ho4RiVOecF3/acANEVF7h9TJeRU75f17fUQ8OJJAoxGfiB8wvY17MyKGjVD/hzhlA+pXpm1PRMwbTZCR5Lwd+3GTsgNePsoY/gFvPAwb3Tpf4wAAAABJRU5ErkJggg==" alt="">
                        </div>
                        <span> Adds</span>
                    </div>
                    <ul>
                        <li v-for="(item,index) in rightData.adds" class="wow animate__fadeInUp" :key="index" :alt="item">{{item}}</li>
                    </ul>
                </div>
                <div class="tel adds" style="animation-delay:1.4s;">
                    <div class="title wow animate__fadeInUp">
                        <div class="icon pic">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAApCAYAAADNjfKJAAACzElEQVRYhbWYTUgVURTHf740WrTJKIgkMyLTqMBdkKugFhVIEkVlLUKtCAIjKA3u3MCFlWG0qEgk7WNhZEhRIbXpY9OqCIqwhD6JJAqqRfZxWtw38Rzu3Jn33swf3mLO+V9+9867c+6ZKRERQlQLHAF+AlvDTFrrsFSkSi2xRUALsD8n9gY4VDAlRJnA9RRgRwAMcBCYnyb8KPAU6ADeWbyX04JXAAeAGmAtsNriXQE0pgFvz4ldA54Dgxb/ecxfkxi8DNiUEysDzgRivqYDPUnClwPlgXgrMBdotozZC1QnBV8ckrsL9AIvLblENl8GmBmSqwa2AWssuTrsdyVvuGsDXQDGgD5L7lcS8O8RngFgZ871a2ALZucXpVLgfYSnCbiCKbl1wD5goliwD38Rw7cLU3wSVQZ4Bbx1eP4Au5MG+3ABHjg8f4GPacEBRhyeMqA+Tfj1CF9rmvDPwE2HbyOm3KYCBzgZ4e1PEz6CvYnwtQp7qU0EDqZhdGkgTfg54JPDPxvoTAsO0V1qO1CZFrwP0yq7dDEtOEw+xWxaCWxOC34HuB0xth+YUwzc9sbiazvuzTcV02rV2pJKqSqgC9MRNWutHwU9YSsHGMccpS7VKKVuBaAVSqkuYBRTGZcBN2yDSxwvir7uEX2wPAROYO7UN+CxxdOptT6cL3wG8AGYFmXMqgKzGY9bcpVa6/9Pkuu2+/oCrI8JBhjWWndjb88mPaJxVu6rDeiO6d2A6ZCeWHKNWushAEQkn98piaffnuchIoOW3FcRyXielzccEemPOYHT2QnY1FMoHBHpjTmBeSLSEpKrKxSOiHTEgD/Lrn7MkhsqBo6IrBOR8YgJNInIwkBsQkQaioUjIuUictZFz67+avbykohUeZ6X16Nmlf8pTCm1BNiDKamzArb7QANQr7Ue9oOJwX0ppRZgyvFSzKn3AxjVWh8Ljv0HX1r7rAHxqSAAAAAASUVORK5CYII=" >
                        </div>
                         <span>Tel</span>
                    </div>
                    <p class="cont wow animate__fadeInUp">{{rightData.tel}}</p>
                </div>
                <div v-if="false" class="fax adds" style="animation-delay:1.4s;">
                    <div class="title">
                        <div class="icon pic"><img src="" alt=""></div> <span>Fax</span>
                    </div>
                    <p class="cont">{{rightData.fax}}</p>
                </div>
            </div>
            <div class="line"><div class="line"></div></div>
            <div class="right wow animate__fadeInRight">
                <div class="bottom">
                    <div :class=" isActive ? 'friend animate__fadeInLeft' : 'friend'" :style="'animation-delay:1.'+(2-index)+'s;'"  v-for="(item,index) in footer.bottomData" :key="index">
                        <p class="title" v-html="item.title"></p>
                        <p class="desc">{{item.desc}}</p>
                        <div class="btn"><router-link to="/add">{{item.btnName}}</router-link></div>
                    </div>
                </div>
                <!-- <div class="qrs " style="animation-delay:1.6s;">
                    <div class="gzh">
                        <div class="gzh-qr pic"><img v-lazy="rightData.gzh" alt=""></div>
                        <p>{{footer.index.gzh}}</p>
                    </div>
                    <div class="wx">
                        <div class="gzh-qr pic"><img v-lazy="rightData.wx" alt=""></div>
                        <p>{{footer.index.wx}}</p>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="bottom">
            <div class="beian wow animate__fadeInUp" style="animation-delay:1s;">
                <div class="left">
                    <p class="beian-cont">
                        {{footer.index.beian}}
                        <a href="https://beian.miit.gov.cn/">{{beian.icp}}</a>
                    </p>
                    <div class="zhichi">
                        Website design : <a href="http://www.manpinvip.com/indexp.html">满品传媒</a>
                    </div>
                    <div class="links">
                        <span>{{footer.index.friendLink.title}}:</span>
                        <a v-for="(item,index) in beian.link" :key="index"
                            :href="item.url">{{item.name}}</a>
                    </div>
                </div>
                <div class="right wow animate__fadeInUp">
                    <div class="menu">
                        <ul>
                            <li>
                                <div class="pic icon1"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon2"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon3"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon4"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon5"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <MfiveEle v-if="!ResizeValue" :topData="topData"></MfiveEle>
</template>
<script>

import {ref} from 'vue'
import MfiveEle from '../index/MfiveEle.vue'
import ajax from '@/ajax'
import { mapState } from 'vuex'


export default {
    data(){
        return {
            rightData: {
                commpanyCn: "澳思柏恩装饰材料有限公司",
                commpanyEn: "Osborne Decoration Materials Co., Ltd",
                adds: ["总部: 澳思柏恩装饰材料有限公司", "地址: 浙江省杭州市余杭区良渚街道好运街152号"],
                tel: "400-6801881",
                fax: "0633-2667333",
                gzh: require('../../assets/index/f2.png'),
                wx: require('../../assets/index/f3.png')
            },
            bottomData: [{
                title: "合作加盟<br/>申请成为我们的合作伙伴",
                // desc: "我们致力于提供令客户满意的世界级的产品和服务。",
                btnName: "申请加盟"
            }, {
                title: "人才招聘<br/>申请加入我们的大家庭",
                // desc: "拥有思维开拓、经营管理、销售技能、精益管理等众多课程等您来学习！",
                btnName: "提交简历"
            }],
            pageClass:{
                top:false
            },
            topData: [
                {
                    title: '媒体中心',
                    links: [{
                        name: '工厂动态',
                        url: '/news/0'
                    }, {
                        name: '产品动态',
                        url: '/news/1'
                    }, {
                        name: '新闻动态',
                        url: '/news/2'
                    }, {
                        name: '视频中心',
                        url: '/video/3'
                    }]
                }, {
                    title: '加入我们',
                    links: [{
                        name: '人才招聘',
                        url: '/add'
                    }, {
                        name: '企业文化',
                        url: '/add'
                    }]
                }, {
                    title: '联系我们',
                    links: [{
                        name: '联系电话',
                        url: '/content'
                    }, {
                        name: '公司地址',
                        url: '/content'
                    }, {
                        name: '微信',
                        url: '/content'
                    }]
                }
            ],
            beian:{
                link:[],
                icp:""
            }
        }
    },
    created(){
        // const store = useStore();
        // const baseUrl = store.state.baseUrl
        // const init = async function(){
        //     const afooter = await ajax(baseUrl + 'web/bottommessageService')
        // }
        // init();
        let tits = []
        if(this.$store.state.lang == 'en'){
            tits = ['SITE']
        }else if(this.$store.state.lang == 'cn'){
            tits = ['地址']
        }
        let _this = this
        ajax(_this.baseUrl + 'web/bottommessageService').then(result=>{

            let item =result[result.length - 1]
            _this.rightData = {
                commpanyCn: item.name,
                commpanyEn: item.ename,
                // adds: [tits[0]+': ' + item.headquarters, tits[1]+": " + item.adds],
                adds: [tits[0]+": " + item.adds],
                tel: item.tel,
                fax: item.fax,
                gzh:_this.baseUrl + item.tencent,
                wx: _this.baseUrl + item.weChat
            }
            _this.beian = {
                link:[{
                    name:item.link,
                    url:item.link
                }],
                icp:item.icp
            }
        })
    },
    computed:{
        ...mapState(['baseUrl','lang']),
        footer(){
            return this.$store.state.langPack[this.$store.state.lang].footer
        },    
        // navgation() {
        //     return this.$store.state.langPack[this.$store.state.lang].navigation
        // },
    },
    methods:{
        setMouserMove(index){
            this.$refs.second[index].classList.add("active")
        },
        setMouseLeave(index){
            this.$refs.second[index].classList.remove("active")

        }
    },
    components:{MfiveEle},
    mounted(){
        
    },
    setup(){
        // 监听屏幕宽度变化
        /**
         * ResizeValue ==> true ==> 大于769
         *                ==> false => 小于769
         */
         const ResizeValue = ref(document.body.clientWidth > 769)
        window.addEventListener('resize',()=>{
            if(document.body.clientWidth < 769){
                ResizeValue.value = false
            }else {
                ResizeValue.value = true
            }
        })

        
        return {
            ResizeValue
        }
    }
}
</script>
<style lang="less" scoped>
    .footer{
        width: 100vw;
        overflow: hidden;
        // background: rgba(0, 0, 0, .5);
        background-color:#00543e;
        // position: fixed;
        top: 40.7%;
        width: 100%;
        position: relative;
        z-index: 9999;
        .top{
            display: flex;
            padding: 78px 150px;
            padding-bottom: 65px;
            justify-content:space-between;
            // height: 100%;
            .links{
                width: fit-content;
                position: relative;
                width: 25%;
                // padding-right: 8.2%;
                ul{
                    position: relative;

                    & > li{
                        display: flex;
                        color: #fff;
                        align-items: center;
                        align-content: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        position: static;
                        // z-index: ;

                        .title{
                            font-size: 2.8rem;
                            font-weight: 400;
                            line-height: 3.1;
                            position: relative;
                            z-index: 9;
                        }
                        img{
                            width: 3.2rem;
                            // height: 3rem;
                            flex-shrink: 0;
                            transition: .5s;
                            transform: rotate(0deg);
                            margin-left: 30px;
                            z-index: 9;
                            
                        }
                        &:hover{
                            img{
                                transform: rotate(45deg);
                            }
                            .second{
                                    pointer-events: auto;
                                    opacity: 1;
                                    transform: translateY(0px);
                                    z-index: 9999;

                                }
                            }
                        
                    }
                }

                .second{
                    position: absolute;
                    top: 100%;
                    width: calc(2.8rem * 4 + 3.2rem + 30px);
                    background: #b9985e;
                    border-radius: 8px;
                    box-sizing: border-box;
                    padding: 15px;
                    padding-left: 1.6rem;
                    z-index: 9;
                    transition: .5s;

                    pointer-events: none;
                    opacity: 0;
                    transform: translateY(-20px);
                    z-index: 9999;
                    &.active{
                        pointer-events: auto;
                        opacity: 1;
                        transform: translateY(0px);
                    }

                    
                    li{
                        position: relative;
                        z-index: 9999;
                        a{
                            color: #fff;
                            line-height: 2;
                            transition: .7s;
                            font-size: 1.6rem;
                            &:hover{
                                color: #00543e;
                            }
                        }
                    }
                }
                .second:nth-child(2){
                    top: calc(2.8rem  *  3 * 1);
                }
                .second:nth-child(3){
                    top: calc(2.8rem  *  3 * 2);
                }
                .second:nth-child(4){
                    top: calc(2.8rem  *  3 * 3);
                }
            }
            .contact{
                // padding-left: 109px;
                // padding-top: 17px;
                // padding-right: 170px;


                // border-left: solid 1px rgba(255, 255, 255, .5);
                // border-right: solid 1px rgba(255, 255, 255, .5);
                .adds {
                    margin-bottom: 80px; 
                    padding-top: 20px;
                    .title {
                        padding-left: 3px;
                        position: relative;
                        padding-left: 35px;

                        span {
                            font-size: 1.8rem;
                            color: #fff;
                        }

                        .icon {
                            position: absolute;
                            left: 2px;
                            width: 15px;
                            height: 24px;
                            // background: rgba(255, 255, 255, 0.6);
                            display: inline-block;
                            // margin-top: 5px;
                            margin-right: 10px;
                            // background-image: url(../../assets/icons_01.png);
                            background-position: -66px -32px;
                            background-size: 130px;
                            img {
                                width: 100%;
                                // display: none;
                                object-fit: contain;
                            }
                        }
                    }

                    ul {
                        padding-left: 35px;
                        margin-top: 10px;

                        li {
                            font-size: 1.4rem;
                            color: #fff;
                            list-style: none;
                            line-height: 1.8;
                        }
                    }

                    .cont {
                        padding-left: 35px;
                        margin-top: 5px;
                        font-size: 1.8rem;
                        color: #fff;
                        list-style: none;
                        line-height: 1.8;
                    }
                }
                .tel {
                    float: left;
                    margin-bottom: 0;
                    .title {
                        .icon {
                            width: 20px;
                            height: 24px;
                            top: -5px;
                            background-position: -21px -32px;
                            background-size: 130px;
                        }
                    }

                    .cont {
                        line-height: 1;
                    }
                }
                .fax {
                    float: left;
                    margin-left: 38px;

                    .title {
                        .icon {
                            width: 21px;
                            height: 22px;
                            top: -5px;
                            left: 0;
                            background-position: 43px 2px;
                            background-size: 130px;
                        }
                    }

                    .cont {
                        line-height: 1;
                    }
                }
            }
            .right{
                padding-top: 2rem;
                // padding-left: 6.3%;
                display: flex;
                // flex: 1;
                .bottom {
                    
                    display: flex;
                    // padding-bottom: 11%;
                    flex-direction: column;

                    .friend {
                        flex: 1;
                        margin-bottom: 30px;
                        .btn{
                            color: #fff;
                            *{
                                color: inherit;
                            }
                        }
                    }

                    .title {
                        // width: calc(2.4rem * 7);
                        width: fit-content;
                        font-size: 2.1rem;
                        line-height: 1.5;
                        color: #fff;
                    }

                    .desc {
                        display: none;
                        margin-top: 1.5rem;
                        line-height: 2rem;
                        color: #fff;
                        height: calc(1.4rem * 2 * 2);

                        // font-size: 1.4rem;
                    }

                    .btn {
                        font-size: 1.8rem;
                        color: #fff;
                        padding: 11px 36px;
                        border: 1px #fff solid;
                        display: inline-block;
                        margin-top: 7px;
                        border-radius: 30px;
                        margin-left: 2px;
                        cursor: pointer;
                        background-image: linear-gradient(to left,transparent,#b9985e);
                        background-size: 400px;
                        background-repeat: no-repeat;
                        background-position: -400px ;
                        transition: .7s;
                        &:hover{
                            background-position: 0px ;
                        }
                        // display: ;
                    }
                }
                .qrs {
                    // width: 50%;
                    flex: 1;
                    display: flex;
                    box-sizing: border-box;
                    padding-left: 37px;
                    // margin-top: 22px;
                    justify-content: space-between;
                    margin-left: 68px;
                    // padding-right: 8.9%;

                    .gzh{
                        margin-right: 20px;   
                    }

                    .pic {
                        width: 150px;
                        height: 150px;
                        margin: auto;
                    }

                    p {
                        color: #fff;
                        font-size: 1.9rem;
                        text-align: center;
                        margin-top: 20px;
                    }
                }
            }
            .line{
                // border-left: solid 1px rgba(255, 255, 255, .5);
                flex: 1;
                overflow: hidden;
                .line{
                    width: 1px;
                    height: 100%;
                    background-color: rgba(255, 255, 255, .5);
                    margin: auto;
                }
            }
            
        }
        & > .bottom{
            border-top: solid 1px rgba(255, 255, 255, .5);

            .beian {
                width: 100%;
                height: 182px;
                box-sizing: border-box;
                padding-top: 54px;
                padding-left: 8%;
                display: flex;
                justify-content: space-between;
                padding-right: 19.45%;

                * {
                    color: #fff;
                }

                p {
                    color: #fff;
                    font-size: 1.4rem;
                    white-space: pre;
                }

                a {
                    color: #fff;
                }

                .zhichi {
                    font-size: 1.4rem;
                    line-height: 2.5;
                    display: inline;
                }

                .links {
                    display: inline;
                    margin-left: 40px;

                    span {
                        font-size: 1.4rem;
                    }

                    a {
                        font-size: 1.4rem;
                        padding: 0 12px;
                        position: relative;

                        &::after {
                            content: "";
                            display: block;
                            height: 1.4rem;
                            width: 1px;
                            background: #fff;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translate(50%, -50%);

                        }

                    }

                    a:last-child {
                        &::after {
                            display: none;
                        }
                    }
                }
                .right{
                    width: 14.7%;
                    .menu{
                        width: 100%;
                    }
                    ul{
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        li{
                            width: 20px;
                            height: 20px;
                            // background: #fff;
                            list-style: none;
                            margin-top: 15.4px;
                            // opacity: .5;
                            .pic{
                                width: 100%;
                                height: 100%;
                                background-image: url(../../assets/icons_01.png);
                                filter: brightness(10);
                                transition: .3s;
                                cursor: pointer;
                                &:hover{
                                    filter: brightness(1);

                                }
                                img{
                                    display: none;
                                }
                            }
                            .icon1{
                                background-size: 136px;
                                background-position: -93px -141px;
                            }
                            .icon2{
                                width: 18px;
                                background-image: url('@/assets/index/facebook.png');
                                background-size: contain;
                                background-repeat: no-repeat;
                                // background-position: 0px 0px;
                            }
                            .icon3{
                                background-size: 136px;
                                background-position: -17px 1px;
                            }
                            .icon4{
                                background-size: 136px;
                                background-position: -43px 3px;
                            }
                            .icon5{
                                background-size: 136px;
                                background-position: -66px 3px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width:1920px){
        .footer {
            .top {
                padding: 78px 100px;
                .contact{
                    // padding-right:70px;
                }
                .right {
                    .qrs{
                        margin-left: 0;
                    }
                }
            }
        }
    }
    @media all and (max-width:1450px){
        .footer {
            .top {
                padding: 78px 100px;
                .links{
                    width: 15%;
                    // flex: 1;
                    &.en{
                        width: 30%;
                    }
                    // border-right: 1px solid rgba(255, 255, 255, .5);
                    li{
                        width: calc(100% - 30px);
                        justify-content: space-between;
                    }
                }
                .contact{
                    padding-left: 30px;
                    display: block;
                    &.en{
                      display: none;

                    }
                    .adds{
                        margin-bottom: 35px;
                    }
                }
                .right {
                    .qrs{
                        margin-left: 0;
                    }
                }
                .line{
                    display: none;
                }
            }
        }
    }
    @media all and (max-width:1367px){
        .footer {
            .top {
                padding: 78px 100px;
                .links{
                    width: 30%;
                    // flex: 1;

                    // border-right: 1px solid rgba(255, 255, 255, .5);
                    li{
                        width: calc(100% - 30px);
                        justify-content: space-between;
                    }
                }
                .contact{
                    padding-left: 30px;
                    display: none;
                    .adds{
                        margin-bottom: 35px;
                    }
                }
                .right {
                    flex: 1;
                    .bottom{
                        width: 50%;
                        // flex-direction: row;
                    }
                    .qrs{
                        margin-left: 0;
                    }
                }
                .line{
                    display: none;
                }
            }
        }
    }
    @media all and (max-width:1071px){
        .footer {
            .top {
                padding: 78px 100px;
                .links{
                    flex: 1;

                    // border-right: 1px solid rgba(255, 255, 255, .5);
                    li{
                        width: calc(100% - 30px);
                        justify-content: space-between;
                    }
                }
                .contact{
                    padding-left: 30px;
                    display: none;
                }
                .right {
                    .bottom{
                        width: 100%;

                        flex-direction: row;
                        justify-content: space-between;
                        .friend{
                            width: calc(50% - 30px);
                            flex: 0 1 auto;
                        }
                    }
                    .qrs{
                        margin-left: 0;
                        display: none;

                    }
                }
            }
        }
    }

    @media all and (max-width:1071px){
        .footer {
            .top {
                padding: 78px 100px;
                .links{
                    flex: 1;

                    // border-right: 1px solid rgba(255, 255, 255, .5);
                    li{
                        width: calc(100% - 30px);
                        justify-content: space-between;
                    }
                }
                .contact{
                    padding-left: 30px;
                    display: block;
                }
                .right {
                    .bottom{
                        width: 100%;

                        flex-direction:column;
                        .friend{
                            width: 100%;
                            flex: 0 1 auto;
                        }
                    }
                    .qrs{
                        margin-left: 0;
                        display: none;

                    }
                }
            }
        }
    }

</style>