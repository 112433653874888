<template>
    <!-- 

        :autoplay="{ delay: 2500, disableOnInteraction: false,}"
     -->
    <div class="one-ele">
        <Swiper ref="one" :autoplay="{ delay: 2500, disableOnInteraction: false,}" class="outer" :modules="[Controller,Lazy,EffectCreative,Autoplay,Pagination]"  :pagination="{clickable: true}" :effect="'creative'"
        :creativeEffect="{
      prev: {
        shadow: true,
        translate: ['-20%', 0, -1],
      },
      next: {
        translate: ['100%', 0, 0],
      },
    }"   :speed="1000" @swiper="setouterSwiper"
            :controller="{control:coreSwiper}">
            <SwiperSlide v-for="(item,index) in data" :key="index" class="outer-slide">
                <!-- 背景 -->
                <div class="pic">
                    <!-- <img :src="item.pic" class="swiper-lazy" alt=""> -->
                    <img v-lazy="item.pic" alt="">
                </div>
                <!-- 标题 -->
                <div class="title" >
                    <div :class="isActive ? 'title-en animate__fadeInLeft' : 'title-en animate__animated animate__fadeInLeft'"
                        style="animation-delay:1s ;" v-html="item.title.en"></div>
                    <div :class=" isActive ? 'title-cn animate__fadeInLeft' : 'title-cn animate__animated animate__fadeInLeft' "
                        style="animation-delay:1.1s ;">
                        <h1 v-html="item.title.h1"></h1>
                        <p v-html="item.title.p"></p>
                    </div>
                </div>
                <!-- <div v-if="item.inter" class="title" v-html="item.title" >
                </div> -->
                <!-- 下方内容 -->

            </SwiperSlide>
        </Swiper>
        <!-- <Swiper ref="one" :modules="[Controller]" @swiper="setcoreSwiper" :controller="{control:outerSwiper}">
            <SwiperSlide>sdfghjk</SwiperSlide>
            <SwiperSlide>sdfghjk</SwiperSlide>
            <SwiperSlide>sdfghjk</SwiperSlide>
            <SwiperSlide>sdfghjk</SwiperSlide>
        </Swiper> -->

        <div :class=" isActive ? 'core animate__fadeInUp' : 'core animate__animated animate__fadeInUp'">
            <div class="buttons">
                <div class="btn-next" style="animation-delay:1.2s" @click="nextSlide"><i class="fa fa-angle-right"></i>
                </div>
                <div class="btn-prev" style="animation-delay:1.3s" @click="prevSlide"><i class="fa fa-angle-left"></i>
                </div>
            </div>

            <div class="content">
                <Swiper ref="one" class="core-swiper" :modules="[Controller]" @swiper="setcoreSwiper" :controller="{control:outerSwiper}">
                    <SwiperSlide class="core-slide" v-for="(item,index) in product" :key="index" >
                        <div v-if="item.inter" class="title" v-html="item.desc1">
                        </div>
                        <div v-if="!item.inter" class="title">
                            <div class="title-en animate__animated " style="animation-delay:1.2s">{{item.en}}</div>
                            <div class="title-cn animate__animated " style="animation-delay:1.3s">{{item.cn}}</div>
                        </div>
                        <moreEle class="more" url="/"></moreEle>
                        <div class="product" :style="{backgroundImage:'url('+item.imgUrl+')'}" >
                            <div v-if="item.inter" class="title" v-html="item.desc2">
                            </div>
                            <div v-if="!item.inter" class="title">
                                <p class="cn1">{{item.proName1}}</p>
                                <p class="cn2">{{item.proName2}}</p>
                            </div>
                            <div class="play-video" @click="setVideo({videoUrl:item.video})">
                                <i class="fa fa-play" aria-hidden="true"></i>
                            </div>
                            <div class="pic">
                                <!-- <img v-lazy="product.imgUrl" alt=""> -->
                                <img :src="item.imgUrl" alt="">
                            </div>
                        </div>
                    </SwiperSlide>
            </Swiper>
                
            </div>
        </div>
    </div>
</template>
  

<script>
import { Swiper, SwiperSlide} from 'swiper/vue';
import { Controller , Lazy , EffectCreative , Autoplay,Pagination} from 'swiper';
import { ref } from 'vue'
import {mapState} from 'vuex'

import ajax from '@/ajax/index.js'

import "swiper/css/pagination";

import moreEle from '../more/MoreEle.vue'

import 'swiper/css';
export default {
    props: ["isActive"],
    data() {
        return {
            data: [{
                // pic:require('../../assets/000.jpg'),
                pic: require('../../assets/index/b1.jpg'),
                // pic:"http://127.0.0.1:59605/01.jpg",
                title: {
                    en: "interlligent <br /> manufacturing",
                    h1: '智能制造·绿色科技',
                    p: '好板材·好技术·缔造好生活',
                },

            }, {
                pic: require('../../assets/index/b2.png'),
                // pic:"http://127.0.0.1:59605/01.jpg",
                title: {
                    en: "interlligent <br /> manufacturing",
                    h1: '智能制造·绿色科技',
                    p: '好板材·好技术·缔造好生活',
                },
                core: {
                    en: "Global Priority Wood Raw Materials",
                    cn: "全球优先木材原料",
                    proName1: "智能设备",
                    proName2: "德国辛北尔康普热压机",
                    MP4Url: "",
                    // imgUrl: require("../../assets/index/b4.png")
                    imgUrl: require("../../assets/index/p0.jpg")
                }
            }, {
                pic: require('../../assets/index/b3.png'),
                // pic:"http://127.0.0.1:59605/01.jpg",
                title: {
                    en: "interlligent <br /> manufacturing",
                    h1: '智能制造·绿色科技',
                    p: '好板材·好技术·缔造好生活',
                },
                core: {
                    en: "Global Priority Wood Raw Materials",
                    cn: "全球优先木材原料",
                    proName1: "智能设备",
                    proName2: "德国辛北尔康普热压机",
                    MP4Url: "",
                    imgUrl: require("../../assets/index/p0.jpg")

                }
            }],
            product: [{
                en: "Global Priority Wood Raw Materials",
                cn: "全球优先木材原料",
                proName1: "智能设备",
                proName2: "德国辛北尔康普热压机",
                MP4Url: "",
                // imgUrl: require("../../assets/index/b9.png")
                imgUrl: require("../../assets/index/p0.jpg")
            }]
        }
    },
    computed:{
        ...mapState(["baseUrl"])
    },
    components: {
        Swiper, SwiperSlide, moreEle
    },
    methods:{
        setVideo(option){
            this.$emit('video',option)
        }
    },
    mounted(){
        ajax(this.baseUrl + "web/homebannerService").then(res=>{
            let result = res;
            let arr = [];
            let coreData = [];
            // {
            //     en: "Global Priority Wood Raw Materials",
            //     cn: "全球优先木材原料",
            //     proName1: "智能设备",
            //     proName2: "德国辛北尔康普热压机", 
            //     MP4Url: "",
            //     // imgUrl: require("../../assets/index/b9.png")
            //     imgUrl: require("../../assets/index/p0.jpg")
            // }
            result.forEach(item => {
                arr.push({
                    pic:this.baseUrl + item.bannerImg,
                    title:{
                        en: item.particularse,
                        h1: item.particulars,
                        p: item.particularstitle
                    },                    
                })
                coreData.push({
                    inter:true,
                    desc1:item.bannerLeftPar,
                    desc2:item.bannerRightPar,
                    imgUrl:this.baseUrl+item.bannerRightImg,
                    video:this.baseUrl+item.bannerRightvideo
                })
            });

            this.data = arr
            this.product = coreData
        })
    },
    setup() {

        

        /** 轮播图 */
        const outerSwiper = ref(null)
        const coreSwiper = ref(null)

        const setouterSwiper = swiper => {
            outerSwiper.value = swiper
        }
        const setcoreSwiper = swiper => {
            coreSwiper.value = swiper
        }
        const nextSlide = function () {
            outerSwiper.value.slideNext()
        }
        const prevSlide = function () {
            outerSwiper.value.slidePrev()
        }
        // /** vuex */
        // const mutations = mapMutations(["setVideo"])
        return {
            // ...mutations,

            Controller,
            outerSwiper,
            coreSwiper,
            setouterSwiper,
            setcoreSwiper,
            nextSlide,
            prevSlide,Lazy,EffectCreative,Autoplay,Pagination
        }
    },
}
</script>



<style lang="less" scoped>
.one-ele {
    width: 100%;
    height: 100vh;
    position: relative;

    &>.pic {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
    }

    .outer {
        width: 100%;
        height: 100%;

        .outer-slide {
            width: 100%;
            height: 100%;
            position: relative;
            padding: 0 25px;
            box-sizing: border-box;
            background-color: #000;


            &>.pic {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                &:hover{
                    img{
                        transform:translate(-50%, -50%);
                    }
                }
            }

            &>.title {
                // opacity: 0;

                position: absolute;
                left: 8%;
                top: 28.3%;
                width: 100%;

                .title-en {
                    text-transform: uppercase;
                    color: #fff;
                    font-size: 4.8rem;
                }

                .title-cn {
                    color: #fff;

                    h1 {
                        font-size: 5.9rem;
                        font-weight: 100;
                    }

                    p {
                        font-size: 2.6rem;
                        font-weight: 100;
                        line-height: 55px;
                    }
                }
            }



        }
    }
    .core-swiper{
        width: 100%;
        display: flex;
        .core-slide{
            width: 100% !important;
            display: flex;
            justify-content: space-between;
            &>.title {
                padding-left: 8.8%;

                .title-en {
                    font-size: 2.2rem;
                    line-height: 2.5;
                    color: #333;
                }

                .title-cn {
                    font-size: 2.2rem;
                    margin-top: -7px;
                    letter-spacing: 1px;
                    color: #333;

                }
            }
        }
    }

    .core {
        position: absolute;
        width: calc(100% - 50px);
        bottom: 40px;
        display: flex;
        border-radius: 8px;
        overflow: hidden;
        // background: #ffffff55;
        z-index: 9;
        left: 25px;
        

        .buttons {
            // width: 7.8%;
            // height: 100%;
            flex: 1;
            .btn-next,
            .btn-prev {
                padding: 53px 54px;
                background: rgba(0, 0, 0, 0);
                text-align: center;
                cursor: pointer;
                position: relative;
                height: 50%;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-items: center;
                justify-content: center;
                // -webkit-animation-duration: 1s;
                // animation-duration: 1s;
                // -webkit-animation-duration: var(--animate-duration);
                // animation-duration: var(--animate-duration);
                // -webkit-animation-fill-mode: both;
                // animation-fill-mode: both;
                &:hover{
                    &.btn-next {
                        // animation-name: bounceIn;

                        // box-shadow: inset 0px 0px 8px 1px rgb(133 100 37 / 79%);

                        .fa{
                            transform: scale(2.5);
                            // animation-name: zoomIn;
                            // animation-name: fadeInLeftBig;
                            // animation-iteration-count: infinite;
                            
                        }
                        // &::after{
                        //     // animation-name: zoomIn;
                        //     // opacity: 1;
                        //     height: 50%;
                        // }
                        // &::before{
                        //     height: 50%;

                        //     // animation-name: zoomIn;
                        //     // opacity: 1;
                        // }
                    }
                    &.btn-prev {
                        // animation-name: bounceIn;
                        // box-shadow: inset 0px 0px 8px 1px rgb(133 100 37 / 79%);
                        .fa{
                            transform: scale(2.5);

                            // animation-name: zoomIn;
                            // animation-name: fadeInRightBig;
                            // animation-iteration-count: infinite;
                        }
                        // &::after{
                        //     height: 50%;
                        //     top: 0;

                        //     // animation-name: zoomIn;
                        //     // opacity: 1;
                        // }
                        // &::before{
                        //     height: 50%;
                        //     bottom: 0;
                        //     // animation-name: zoomIn;
                        //     // opacity: 1;
                        // }
                    }
                    

                    // &::after{
                    //     width: 100% !important;
                    // }
                }
                &::after,&::before{
                    content: "";
                    display: block;
                    // background-color: rgba(184, 152, 93, 1);
                    width: 100%;
                    height: 50%;
                    // filter: blur(5px);
                    position: absolute;
                    left: 0;
                    transition: .3s;
                    -webkit-animation-duration: 1s;
                    animation-duration: 1s;
                    -webkit-animation-duration: var(--animate-duration);
                    animation-duration: var(--animate-duration);
                    -webkit-animation-fill-mode: both;
                    animation-fill-mode: both;
                }
                &.btn-next {
                    background-color: rgba(0, 84, 61, 1);
                    &::after{
                        // background-color: rgba(0, 84, 61, 1);
                        
                        background-color: rgba(184, 152, 93, 1);
                        top: 0;
                        height: 0;
                    }
                    &::before{
                        background-color: rgba(184, 152, 93, 1);
                        bottom: 0;
                        height: 0;

                    }
                }

                &.btn-prev {
                    background-color: rgba(184, 152, 93, 1);

                    // background-color: rgba(184, 152, 93, 1);

                    &::after{
                        // background-color: rgba(0, 84, 61, 1);
                        
                        background-color: rgba(0, 84, 61, 1);
                        top: 0;
                        height: 0;
                    }
                    &::before{
                        background-color: rgba(0, 84, 61, 1);
                        height: 0;
                        bottom: 0;
                    }
                }

                .fa {
                    font-size: 1.8rem;
                    line-height: 1;
                    color: #fff;
                    position: relative;
                    z-index: 9;
                    transition: .3s;
                    transform: scale(1);
                }
            }

        }

        .content {
           width: 93.2%;
            box-sizing: border-box;
            padding-top: 15px;
            padding-bottom: 15px;
            display: flex;
            justify-content: space-between;
            position: relative;
            background: #fff;

            &>.title {
                padding-left: 8.8%;
                padding-top: 30px;
                .title-en {
                    font-size: 2.2rem;
                    line-height: 2.5;
                    color: #333;
                }

                .title-cn {
                    font-size: 2.2rem;
                    margin-top: -7px;
                    letter-spacing: 1px;
                    color: #333;
                }
            }
            
            .more {
                display: block;
                position: absolute;
                left: 8.8%;
                bottom: 25px;
                color: #333;

                .fa {
                    color: #333;

                }
            }

            .product {
                width: 45.8%;
                // background: #33333333;
                box-sizing: border-box;
                padding-left: 25px;
                padding-top: 20px;
                position: relative;
                // background-image: linear-gradient(to right, #ccc, transparent 80%);
                background-image:url(../../assets/index/p0.jpg);
                border-radius: 8px;
                background-size: 100%;
                display: flex;
                justify-content: space-between;
                position: relative;
                margin-right: 31px;

                .cn1 {
                    font-size: 2.4rem;
                    color: #333;

                }

                .cn2 {
                    font-size: 1.6rem;
                    color: #333;

                }

                .play-video {
                    width: 60px;
                    height: 60px;
                    position: absolute;
                    bottom: 15px;
                    background: #ffffffb7;
                    // padding: 25px;
                    line-height: 60px;
                    text-align: center;
                    border-radius: 50%;
                    color: #333;
                    cursor: pointer;
                    &:hover{
                        animation-name: heartBeat;
                    }
                }

                &>.pic {
                    width: 52.8%;
                    padding-top: 30.5%;
                    max-height: 100%;
                    margin-top: -5px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: none;
                    bottom: 0;
                    img{
                        object-fit: contain;
                        mix-blend-mode:lighten;
                    }
                }
            }
        }

    }
}

@media all and (max-width:1500px) {
    .one-ele {.outer {.outer-slide {&> .title .title-cn {h1{
        font-size: 3.9rem;
    }}}}}
}
@media all and (max-width:1100px) {
    .one-ele {
        background: #000;

        .outer {
            .outer-slide {}
        }
        .core {
            flex-wrap: wrap;
            .core-swiper {
                .core-slide {
                    & > .title{
                        padding-left: 30px;
                    }
                }
            }
            .buttons {
                order: 2;
                width: 100%;
                display: flex;
                // flex-wrap: nowrap;
                // flex-direction: row;
                .btn-next,
                .btn-prev {
                    width: 50%;
                    box-sizing: border-box;
                    padding: 26px 54px;
                    &.btn-next {
                        order: 2;
                    }

                    &.btn-prev {
                        order: 1;
                    }
                }
            }

            .content {
                order: 1;
                width: 100%;
                
                &>.title {
                    padding-left:30px;
                    .title-en {
                    }

                    .title-cn {
                    }

                }
                .more{
                    left: 30px;
                }
                .product {
                    width: 50%;
                    min-height: 178px;
                    .pic{
                        // padding-top: 44.5%;
                        padding-top: 0;
                        height: 100%;
                    }
                }
            }
        }
    }

    
}

@media all and (max-width:769px) {
    html,body,.home{
        height: auto !important;
        overflow-x: hidden !important;
    }
    .one-ele {
        // height: auto;
        .outer {
            .outer-slide {
                & > .title{
                    opacity: 1;
                    top: auto;
                    bottom: 11%;
                    .title-en{
                        font-size: 2.6rem;
                    }
                }
            }
        }
        .core {
            display: none;

            .core-swiper {
                
                .core-slide{
                    
                    flex-wrap: wrap;
                    padding-bottom: 60px;
                    height: auto;
                    & > .title{
                        width: 100%;
                        padding:30px 0 0px 0;
                        padding-left: 0px;
                        .title-en{
                            line-height: 1.5;
                            margin-bottom: 15px;
                        }
                        .title-cn{
                            margin-bottom: 15px;
                        }
                    }
                    .product {
                        .pic{
                            display: none;
                        }
                    }
                    .more{
                        left: 0;
                    }
        }
    }
        }
            

        .core {
            background-color: #fff;
            flex-wrap: wrap;
            .buttons {
                order: 2;

                width: 100%;
                display: flex;

                .btn-next,
                .btn-prev {
                    padding: 25px 54px;
                    width: 100%;
                    height: 100%;
                    &.btn-next {
                        order: 2;
                    }

                    &.btn-prev {
                        order: 1;
                    }
                }
            }

            .content {
                order: 1;

                width: 100%;
                flex-wrap: wrap;
                padding: 0 15px;
                box-sizing: border-box;
                padding-bottom: 0px;

                &>.title {
                    width: 100%;
                    padding-left: 0;
                    padding-top: 25px;

                    .title-en {
                        line-height: 1.5;
                        margin-bottom: 15px;
                    }

                    .title-cn {
                        margin-bottom: 15px;

                    }
                }

                .product {
                    width: 100%;
                    height: 200px;
                    margin-right: 0;
                }
            }
        }

    }
}

</style>


<style lang="less">
    .outer .swiper-pagination{
        width: 70%;
        height: 2px;
        /* background:black; */
        margin-left: 30px;
        bottom: 9% !important;
        display: flex;
        justify-content: space-between !important;
    }
    .outer .swiper-pagination-bullet{
        background: rgb(255, 255, 255,.5) !important;
        border-radius: 0 !important;
        height: 100%;
        width: 33%;
    }
    .outer .swiper-pagination-bullet-active{
        background: rgb(255, 255, 255,.8) !important;
    }

    .one-ele {
        .outer {

            .title {
                position: absolute;
                left: 8%;
                top: 28.3%;

                .title-en {
                    text-transform: uppercase;
                    color: #fff;
                    font-size: 4.8rem;
                    width: 100%;
                }

                .title-cn {
                    color: #fff;
 
                    h1 {
                        font-size: 5.9rem;
                        font-weight: 100;
                        width: 82%;
                        font-weight: 500 !important;

                    }

                    p {
                        font-size: 2.6rem;
                        font-weight: 100;
                        line-height: 55px;
                    }
                }
            }
        }
    }
    .core {
        .title {
                // padding-left: 8.8%;
                // padding-top: 30px;
                .title-en {
                    font-size: 2.2rem;
                    line-height: 2.5;
                    color: #333;
                }

                .title-cn {
                    font-size: 2.2rem;
                    margin-top: -7px;
                    letter-spacing: 1px;
                    color: #333;
                }
            }
    }
    
</style>