<template>
    <div class="scene">
        <mapTiltleEleVue class="mapTiltleEleVue" :item="title"></mapTiltleEleVue>
        <Swiper @swiper="setScene" class="scene-swiper">
            <SwiperSlide class="scene-slide" v-for="(item, index) in sceneData" :key="index">
                <div class="pic">
                    <img :src="item.imgurl" alt="">
                </div>
                <div class="desc">
                    {{ item.desc }}
                </div>
            </SwiperSlide>
        </Swiper>
        <div class="next">
            <swiperButtonEle @click="nextSlide" class="next-slide" type="next"></swiperButtonEle>
        </div>
        <div class="prev">
            <swiperButtonEle @click="prevSlide" class="prev-slide" type="prev"></swiperButtonEle>
        </div>
    </div>
</template>

<script>
import mapTiltleEleVue from './mapTiltleEle.vue';
import swiperButtonEle from '../public/swiperButtonEle.vue'

import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref, onUpdated } from 'vue';
export default {
    props: ["id", "item"],
    components: {
        Swiper, SwiperSlide,
        mapTiltleEleVue, swiperButtonEle
    },
    setup(props) {
        const title = {
            en: "Application scenario",
            cn: '应用场景'
        }
        const sceneData = ref([
            {
                imgurl: require('../../assets/brandDetail/s1.jpg'),
                desc: '适用于通高柜体、推拉门等各类大尺寸产品，无需拉直器'
            },
            {
                imgurl: require('../../assets/brandDetail/s2.jpg'),
                desc: '适用于通高柜体、推拉门等各类大尺寸产品，无需拉直器'
            }
        ])
        onUpdated(() => {
            if (props.item) {
                sceneData.value = props.item
            }
        })
        const sceneSwiper = ref(null)
        // methods
        const setScene = swiper => {
            sceneSwiper.value = swiper
        }
        // 下一张
        const nextSlide = function () {
            sceneSwiper.value.slideNext();
        }
        const prevSlide = function () {
            sceneSwiper.value.slidePrev();
        }
        return {
            title, sceneData,
            // methods
            setScene, nextSlide, prevSlide
        }
    }
}
</script>

<style lang="less" scoped>
.scene {
    width: 100%;
    position: relative;
    .mapTiltleEleVue {
        margin-bottom: 37px;
    }
    .scene-swiper {
        .scene-slide {
            position: relative;
            .pic {
                width: 100%;
                padding-top: 63.4%;
            }
            .desc {
                position: absolute;
                bottom: 0;
                color: #fff;
                font-size: 2.5rem;
                letter-spacing: 1.5px;
                line-height: 1.5;
                padding: 2.6% 3% 2.2% 3%;
                width: 100%;
                background-color: #00543E;
                box-sizing: border-box;
            }
        }
    }
    .next {
        position: absolute;
        width: 30%;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 99;
        cursor: pointer;
        .next-slide {
            width: 60px;
            height: 60px;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity .7s;
        }
        &:hover {
            .next-slide {
                opacity: 1;
            }
        }
    }
    .prev {
        position: absolute;
        width: 30%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 99;
        cursor: pointer;

        .prev-slide {
            width: 60px;
            height: 60px;
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity .7s;
        }
        &:hover {
            .prev-slide {
                opacity: 1;
            }
        }
    }
}
@media all and (max-width:769px){
    .scene{
        .mapTiltleEleVue{
            margin-bottom: 30px;
        }
        .scene-swiper{
            .scene-slide{
                .desc{
                    width: 100%;
                    font-size: 1.6rem;
                }
            }
        }
        .next,.prev{
            display: none;
        }
    }
}
</style>