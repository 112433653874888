<template>
    <navigationEle :isActive="true"></navigationEle>
    <div class="detail">
        <!-- <img src="../assets/demo/20221121.jpg" alt=""> -->
        <!-- banner -->
        <bannerEleVue id="1"></bannerEleVue>
        <div class="contact">
            <!-- 产品详情 -->
            <brandDetailEleVue :id="brand_id" :item="brand" class="brandDetailEleVue"></brandDetailEleVue>
            <!-- 生产流程 -->
            <courseEleVue :id="brand_id" :item="Dbrand_flow" class="courseEleVue"></courseEleVue>
            <!-- 核心优势 -->
            <coreEleVue :item="Dbrand_core" class="courseEleVue"></coreEleVue>
            <!-- 应用场景 -->
            <sceneEleVue :id="id" :item="Dband_app" class="sceneEleVue"></sceneEleVue>
        </div>
        <catalogueEleVue @slide="setDetail" @skip="setPush" ref="catalogueEleVue" :items="classify" type="product" :id="id"></catalogueEleVue>
        <skipEle class="skip" :id="2"></skipEle>
        <footerEleVue style="display:none"></footerEleVue>
    </div>
</template>

<script>
import { ref,reactive,onUpdated  } from 'vue'
import { useRouter,  } from 'vue-router'
import { useStore } from 'vuex'

import navigationEle from '@/components/public/navigationEle.vue'
import bannerEleVue from '@/components/public/bannerEle.vue'

import brandDetailEleVue from '@/components/brandDetail/brandDetailEle.vue'
import courseEleVue from '@/components/brandDetail/courseEle.vue'
import coreEleVue from '@/components/brandDetail/coreEle.vue'
import sceneEleVue from '@/components/brandDetail/sceneEle.vue'

import skipEle from '../components/public/skipEle.vue'
import footerEleVue from '@/components/public/footerEle.vue'
import catalogueEleVue from '@/components/brandDetail/calgEle.vue'
import ajax from '@/ajax'
export default {
    components:{navigationEle,bannerEleVue,brandDetailEleVue,courseEleVue,coreEleVue,sceneEleVue,skipEle,footerEleVue,catalogueEleVue},
    metaInfo() {
      return {
        title: this.title, // set a title
        meta: [
          {
            // set meta
            name: "keywords",
            content: this.keyword,
          },
          {
            name: "Description",
            content: this.description,
          },
        ],
      };
    },
    setup(){
        // 获取路由参数
        const router = useRouter()
        // const route = useRoute()
        // const route = useRoute()
        const params = router.currentRoute.value.params
        // 获取vuex库里数据
        const store = reactive(useStore())
        const baseUrl = store.state.baseUrl
        const id = ref(params.id)
        const brand_id = ref(params.brand_id||1)

        const bannerData = ref({
            titleCn:"品牌产品",
            titleEn:"Brand Products",
            desc:"定义高端制造的生活方式",
            imgurl:require('../assets/brandDetail/bdetail.jpg'),
            video:{
                cover:"require('../assets/about/video.jpg')",
                url:""
            }
        })

        // 获取服务器数据
        // const brand_id = ref(null)  //品牌产品
        const brand =ref(null) // 品牌产品
        const Dbrand_flow = ref([]) //生产流程
        const Dbrand_core = ref({  //核心优势
            name:null,
            solgin:null,
            name_solgin:null,
            cores:[],
            imgs:[]
        }) //生产流程
        const Dband_app =ref([])
        // 产品分类
        const classify = ref(null)
        const catalogueEleVue = ref(null)
        // 记录导航id所在的下标
        let idIndex = 0 // eslint-disable-line
        
        const init = async function(){
            // 产品分类
            /**
             * id number
             * name String
             * child: Array
             */
            // if(!store.state.brand){
                const aClassify = await ajax(baseUrl + 'web/brandbannerService')
                let rClassify = []
                let deid = id.value || aClassify[0].id
                aClassify.forEach((item,index)=>{
                    // idIndex = item.id == id.value ? index : 0
                    if(item.id == id.value){
                        idIndex = index 
                    }
                    rClassify.push({
                        id:item.id,
                        name:item.name,
                        default:deid,
                        child:[],
                    })
                })
                classify.value = rClassify
                // catalogueEleVue.value.setOncePostion(classify);
                // 默认显示

            // }else{
            //     classify.value = store.state.brand
            // }

            

            let core = {  //核心优势 临时变量储存
                name:null,
                solgin:null,
                name_solgin:null,
                cores:{
                    lang:false,
                    merit:[]
                },
                imgs:[]
            }
            // 品牌产品
            const brand_pro = await ajax(baseUrl + 'web/brandproductService',{brid:id.value})
            let brand_item = null
            brand_pro.forEach(item=>{
                if(item.id == brand_id.value){
                    brand_item =item
                }
            })
            core.name_solgin = brand_item.labelPar 

            brand_id.value = brand_item.id
            // 获取品牌产品图片
            const brand_img = await ajax(baseUrl + 'web/brandproductimgService',{brid:brand_id.value})
            let brand_img_arr = []
            brand_img.forEach(item=>{
                brand_img_arr.push(baseUrl + item.brandImg)
            })
            brand.value = {
                name:brand_item.name,
                brief:brand_item.productPar,
                desc:brand_item.productDetail,
                imgs:brand_img_arr
            }

            // 生产流程
            const brand_flow= await ajax(baseUrl + 'web/brandworkflowService',{brid:brand_id.value})
            Dbrand_flow.value = []
            brand_flow.forEach(item=>{
                Dbrand_flow.value.push({
                    name:item.name,
                    icon:baseUrl + item.icon,
                    active:false
                })
            })
            
            // 核心优势标签
            const brand_tag = await ajax(baseUrl + 'web/brandlabelService',{branId:brand_id.value})
            brand_tag.forEach(item=>{
                // console.log(item)
                if(item.label.length > 9){
                    core.cores.lang = true    
                }
                core.cores.merit.push(item.label)
            })
            // // 核心优势图片
            const brand_adv_img = await ajax(baseUrl + 'web/brandimgService',{branId:brand_id.value})
            brand_adv_img.forEach(item=>{
                core.imgs.push(baseUrl + item.branImgurl)
            })

            // 核心优势
            Dbrand_core.value = core

            // 应用场景
            const brand_app = await ajax(baseUrl + 'web/brandapplicationService',{applicationId:brand_id.value})
            let app = []
            brand_app.forEach(item=>{
                app.push({
                    imgurl:baseUrl + item.applicationImg,
                    desc:item.applicationName
                })
            })
            Dband_app.value = app

            catalogueEleVue.value.setSlide(classify.value[idIndex],idIndex)
        }
        init();

        const setDetail = function(obj){
            // if(obj.child.length == 0){
                ajax(baseUrl + 'web/brandproductService',{brid:obj.id}).then((result)=>{
                    let arr = []
                    
                    result.forEach((item)=>{
                        let flag = false
                        if(obj.id==item.brid && brand_id.value == item.id){
                            flag= true
                        }
                        console.log(result);
                        arr.push({
                            id:item.id,
                            name:item.name,
                            active:flag
                        })
                    })
                    obj.child = arr 
                })
            // }
        }

        const setPush = function(item,index){
            router.push(`/brand/${item.id}/${index}`)
        }
        // watch(
        //     () => route.params,
        //     (newValue,oldValue) => {
        //         window.scrollTo(0,0)

        //         id.value=newValue.id
        //         brand_id.value=newValue.brand_id
        //         init();
        //     }
        // );
        // 判读有没有数据
        // if(!store.state.brand){
           
        //     // 重新请求数据
        //     classify.value = [
        //         {
        //             id: 0,
        //             name: "饰能板",
        //             en: "ENERGY VENEER",
        //             canpname: '澳思柏恩饰能板',
        //             icon: require('../assets/brand/b1.png'),
        //             imgurl: require('../assets/brand/b1.jpg'),
        //             merits: ['松木细料超平整', '南北通用饰面抗龟裂', '吸水膨胀率低', ' 精准铺装偏差小'],
        //             child:[
        //                 {name:"澳思柏恩饰能板"}
        //             ]
        //         }, {
        //             id: 0,
        //             name: "LSB系列",
        //             en: "ENERGY VENEER",
        //             canpname: '澳思柏恩饰能板',
        //             icon: require('../assets/brand/b2.png'),
        //             imgurl: require('../assets/brand/b2.jpg'),
        //             merits: ['松木细料超平整', '南北通用饰面抗龟裂', '吸水膨胀率低', ' 精准铺装偏差小'],
        //             child:[
        //                 {name:"LSB高定专享板"},
        //                 {name:"LSB高定专享板"},
        //                 {name:"LSB高能家居板"},
        //                 {name:"锌效抗菌LSB高能家居板"},
        //             ]
        //         }, {
        //             id: 0,
        //             name: "澳松板系列",
        //             en: "ENERGY VENEER",
        //             canpname: '澳思柏恩饰能板',
        //             icon: require('../assets/brand/b3.png'),
        //             imgurl: require('../assets/brand/b3.jpg'),
        //             merits: ['松木细料超平整', '南北通用饰面抗龟裂', '吸水膨胀率低', ' 精准铺装偏差小'],
        //             child:[
        //                 {name:"澳松板"},
        //                 {name:"锌效抗菌澳松板"},
        //             ]
        //         }, {
        //             id: 0,
        //             name: "OSB",
        //             en: "ENERGY VENEER",
        //             canpname: '澳思柏恩饰能板',
        //             icon: require('../assets/brand/b4.png'),
        //             imgurl: require('../assets/brand/b3.jpg'),
        //             merits: ['松木细料超平整', '南北通用饰面抗龟裂', '吸水膨胀率低', ' 精准铺装偏差小'],
        //             child:[
        //                 {name:"实木切片OSB"},
        //                 {name:"难燃OSB"},
        //             ]
        //         }
        //     ]
            
        // }else{
        //     classify.value = store.state.brand
        // }
        
        const skip = {
            imgurl:require('../assets/brandDetail/db.jpg'),
            url:"/",
            title:"我猜您还想<br />了解我们的产品"
        }
        const title = ref('title');
        const keyword = ref('keyword');
        const description = ref('description');
       
        onUpdated(async() => {
            const id = params.id
            const brand_id = params.brand_id
            const brand_pro = await ajax(baseUrl + 'web/brandproductService',{brid:id})
            let name = ""
            brand_pro.map((item) => {
                if(item.id == brand_id){
                    name = item.name
                }
            })
            let slideInfo = await ajax(baseUrl + `web/seoyhxqService?title=${name}`)
            console.log(slideInfo);
            title.value =  slideInfo[0].title;
            keyword.value =  slideInfo[0].keyword;
            description.value =  slideInfo[0].description;
        })
        return{
            brand_id,brand,
            id,bannerData,skip,classify,
            Dbrand_flow,Dbrand_core,Dband_app,
            setDetail,setPush,catalogueEleVue,title,keyword,description
        }
    }
}
</script>

<style lang="less" scoped>
.detail{
    position: relative;
    &>img{
        width: 100%;
        position: absolute;
        z-index: -1;
    }
    .contact{
        padding-left: 16.6%;
        padding-top: 3.2%;
        padding-right: 25px;
        .brandDetailEleVue{
            margin-bottom: 85px;
        }
        .courseEleVue{
            margin-top: 85px;
        }
        .sceneEleVue{
            margin-top: 85px;
        }
    }
    .skip{
        margin-top: 85px;
    }
}
@media all and (max-width:1025px){
.detail{
    .contact{
        padding: 15px;
    }
}
}
@media all and (max-width:769px){
    .detail{
        .contact{
        .brandDetailEleVue{
            margin-bottom: 30px;
        }
        .courseEleVue{
            margin-top: 30px;
        }
        .sceneEleVue{
            margin-top: 30px;
        }
    }
    .skip{
        margin-top: 30px;
    }
    }
    
}
</style>