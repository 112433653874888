<template>
    <div :class="{newsItem:true,hot:type=='hot'}">
            <div class="pic">
                <img :src="item.imgurl" alt="">
            </div>
        <div class="conts">
            <div class="date " :title="item.date[0]+'-'+item.date[1]+'-'+item.date[2]">
                <div class="year">{{item.date[0]}}</div>
                <div class="mm">{{item.date[1] < 10 ? "0" + item.date[1] : item.date[1]}}-{{item.date[2] < 10 ? "0" + item.date[2] : item.date[2]}}</div>
            </div>
            <h1 class="title wow animate__pulse"> <router-link :to="'/news/detail/' + item.id" >{{item.title}}</router-link></h1>
            <div class="clear"></div>
            <div class="line"></div>
            <p class="desc wow animate__pulse" v-html="item.desc"></p>
            <inMoreVue class="inMoreVue" :url="'/news/detail/' + item.id"></inMoreVue>
        </div>
    </div>
    
</template>

<script>
import inMoreVue from '../public/inMore.vue';

export default {
    props:['item','type'],
    components:{inMoreVue},
    setup(){

    }
}
</script>

<style lang="less">
.newsItem{
    .conts{
        .inMoreVue{
           
            .add-icon{
                &::before{
                    background: #00543D;
                }
                &::after{
                    background: #00543D;
                }
            }
            
        }
    }
}
</style>
<style lang="less" scoped>
.newsItem{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 85px 30px 00px 41px;
    
    .pic{
        flex: 1;
        // padding-top: 18%;
        // height: 100%;
    }
    .conts{
        width: 78.9%;
        box-sizing: border-box;
        padding: 0 0 0 43px;
        margin-top: -10px;
        .date{
            float: left;
            .year{
                font-size: 3.6rem;
                font-weight: 700;
            }
            .mm{
                font-size: 3.0rem;

            }
        }
        .title{
            width: 56%;
            font-size: 3.6rem;
            color: #00543D;
            font-weight: 300;
            float: left;
            margin-left: 30px;
            padding-left: 40px;
            letter-spacing: 2.2px;
            line-height: 1.3;
            position: relative;

            overflow: hidden;
            // white-space: nowrap;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            *{
                color: inherit;
            }
            &::before{
                content: "";
                display: block;
                width: 2px;
                height: calc(3.6rem * 1.3 * 2 - 10px);
                position: absolute;
                background-color: #00543D;
                left: 0;
            }
        }
        .line{
            width: 100%;
            height: 1px;
            background-color: #ccc;
            margin: 27px 0 27px 0;
        }
        .desc{
            padding: 0 40px 0 0 ;
            line-height: 1.8;
            margin-bottom: 54px;

            display: -webkit-box;  /*弹性伸缩盒子*/
            -webkit-box-orient: vertical;/*垂直排列*/
            line-clamp: 2;
            -webkit-line-clamp: 2;  /*只显示6行*/
            overflow: hidden;   /*溢出隐藏*/
            text-overflow: ellipsis;   /*省略号代替*/

            // font-size: 1.8rem;

        }
        .inMoreVue{
            font-size: 1.8rem;
            color: #00543D;
            margin-top: 30px;
            padding: 8px 12px;
            transition: .7s;
            &:hover{
                color: #fff;
                background-color: #00543D;
            }
        }
    }

    &.hot{
        padding: 0px 0px 0px 0px;

        .pic{
            width: 33.8%;
            padding-top: 30.6%;
        }
        .conts{
            flex: 1;
            box-sizing: border-box;
            padding: 5% 0% 3.2% 2%;
            margin-top: 0px;
            .desc{
                line-clamp: 6;
                -webkit-line-clamp: 6;  /*只显示6行*/
            }
        }
    }

}

@media all and (max-width:1400px){
    .newsItem{
        .conts{
            width: 68.5%;
        }
    }
}
@media all and (max-width:989px){
    .newsItem{
        .conts{
            width: 68.5%;
            .date{
                float: none;
                width: 100%;
                display: flex;
                .year{
                    font-size: 2.4rem;
                    &::after{
                        content: '-';
                    }
                }
                .mm{
                    font-size: 2.4rem;
                }
            }
            .title{
                &::before{
                    height: 2px;
                    width: 200px;
                    top: 0;
                }
                width: 100%;
                float: none;
                padding: 8px 0 0 0 ;
                margin: 8px 0 0 0 ;
                font-size:2.6rem;
            }
            .line{
                margin: 8px 0;
            }
        }
    }
}
@media all and (max-width:769px){
    .newsItem{
        flex-wrap: wrap;
        padding:0 0 0 0 ;
        background: #fff;
        .pic{
            width: 100%;
            padding-top: 55%;
            flex: 0 1 auto;
        }
        .conts{
            width: 100%;
            box-sizing: border-box;
            padding: 30px 15px;
            .date{
                float: none;
                width: 100%;
                display: none;
                .year{
                    font-size: 2.4rem;
                    &::after{
                        content: '-';
                    }
                }
                .mm{
                    font-size: 2.4rem;
                }
            }
            .title{
                &::before{
                    height: 2px;
                    width: 200px;
                    top: 0;
                    display: none;
                }
                width: 100%;
                float: none;
                padding: 8px 0 0 0 ;
                margin: 8px 0 0 0 ;
                font-size:2rem;
            }
            .line{
                margin: 8px 0;
                display: none;
            }
            .desc{
                display: none;
            }
            .inMoreVue{
                display: none;
            }
        }
    }
}

</style>