<template>
  <div class="equ">
    <div class="pic"><img :src="item.imgurl" :alt="item.name"></div>
    <div class="cont">
        <div :title="item.name" class="name">{{item.name}}</div>
        <div :title="item.desc" class="desc" >{{item.desc}}</div>
    </div>
  </div>
</template>

<script>
export default {
    props:["item"]
}
</script>

<style lang="less" scoped>

.equ{
    cursor: pointer;
    &.active{
        .pic{
            img{
                transform: translate(-50%,-50%) scale(1.2);
            }
        }
        .cont{
            background-color: #00543e;
            .name{
                color: #fff;
                
            }
            .desc{
                color: #fff;
            }
        }
    }
    .pic{
        width: 100%;
        padding-top:72%;
        img{
            transition: .7s;
        }
    }
    .cont{
        padding: 18px 30px 30px 32px ;
        background: #fff;
        transition: .7s;

        height: 300px;


        .name{
            font-size: 3.0rem;
            transition: .7s;

            
        }
        .desc{
            transition: .7s;

            margin-top: 20px;
            line-height: 1.6;
            letter-spacing: 1.4px;
            color: #666;

            -webkit-line-clamp: 7;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            
            overflow: hidden;

            // font-size: 1.8rem;

        }
    }
}
@media (any-hover:hover){
    .equ:hover{
        .pic{
            img{
                transform: translate(-50%,-50%) scale(1.2);
            }
        }
        .cont{
            background-color: #00543e;
            .name{
                color: #fff;
                
            }
            .desc{
                color: #fff;
            }
        }
    }
}

@media all and (max-width:769px){
    .equ{
        .cont{
            padding: 15px;
            .name{
                font-size: 2.0rem;
            }
            .desc{
                // font-size: 1.4rem;
                height: auto;
            }
        }
    }
}
</style>