<template>     
    <div class="title">
        <span :class="{cn:true,enNone:lang == 'en'}" > {{item.cn}} </span>``
        <span :class="{x:true,enNone:lang == 'en'}" > / </span>
        <span class="en" > {{item.en}} </span>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props:["item"],
    computed:{
        ...mapState(['lang'])
    }
}
</script>

<style lang="less" scoped>

 
.title{
    font-size: 2.6rem;
    line-height: 1;
    
    .cn,.en,.x{
        font-size: inherit;
        color: inherit;
    }
    .cn{
        font-weight: 700;
        margin-right: 8px;
        letter-spacing: 2px
    }
    .en{
        margin-left: 8px;
        text-transform: uppercase;
    }

    .enNone{
        display: none;
    }
}
@media all and (max-width:769px){
    .title{
        font-size: 2.4rem;
        .en,.x{
            display: none;
        }
    }
}
</style>