<template>
    <div class="buttons">
        <div class="item" v-on:click.stop="pushkf" data-id="0">
            <div class="pic">
                <img src="../../assets/buttons1.png" alt="" srcset="">
            </div>
            <span>在线咨询</span>
        </div>
        <div class="item" data-id="1" v-on:click.stop="setShow($event);opentable();">
            <div class="pic">
                <img src="../../assets/buttons2.png" alt="" srcset="">
            </div>
            <span>留言提交</span>
        </div>
        <div class="item" data-id="2" v-on:click.stop="setShow">
            <div class="pic">
                <img src="../../assets/buttons3.png" alt="" srcset="">
            </div>
            <span>400-6801881</span>
        </div>
    </div>
    <transition name="fade" mode="out-in" enter-active-class="animate__fadeIn" leave-active-class="animate__fadeOut" :duration="{ enter: 300, leave: 300 }">
        <div class="table" ref="table" v-if="tableTab">
                <div class="title">{{ lang.title }}</div>
                <div class="info" v-html="lang.info"></div>
                <div class="from">
                    <div :class="{ product: true, active: active, wow: true, animate__fadeInUp: true }"
                        v-on:click.stop="productClick">
                        <span class="hint">{{ lang.product }}</span>
                        <span class="value">{{ selectProduct.name }}</span>
                        <div class="icon pic">
                            <img src="data:image/svg+xml;base64,PHN2ZyANCiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciDQogeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiDQogd2lkdGg9IjI2cHgiIGhlaWdodD0iMTRweCI+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBmaWxsPSJyZ2IoMTAyLCAxMDIsIDEwMikiDQogZD0iTTEzLjAwMCwtMC4wMTQgQzYuMjc3LC0wLjAxNCAwLjg2MywwLjE0OSAwLjk4MiwwLjM0OCBDMS42NjAsMS40NzkgMTIuNjgyLDE0LjAxMyAxMy4wMDAsMTQuMDEzIEMxMy4zMTcsMTQuMDEzIDI0LjM0MCwxLjQ3OSAyNS4wMTcsMC4zNDggQzI1LjEzNywwLjE0OSAxOS43MjIsLTAuMDE0IDEzLjAwMCwtMC4wMTQgTDEzLjAwMCwtMC4wMTQgWiIvPg0KPC9zdmc+"
                                alt="">
                        </div>
                        <div class="contioner">
                            <ul class="products">
                                <li v-for="(item, index) in product" :key="index" @click="selectClick(index)">{{ item.name }}
                                </li>
                            </ul>
                        </div>
                        <input type="hidden" v-model="fromData.product">

                    </div>
                    <div class="name">
                        <span class="hint">{{ lang.name }}</span>
                        <input type="text" class="value" v-model="fromData.name" :placeholder="lang.iptName">

                    </div>
                    <div class="name tel ">
                        <span class="hint">{{ lang.tel }}</span>
                        <input type="text" v-model="fromData.tel" class="value" :placeholder="lang.iptTel">
                    </div>
                    <div class="name adds">
                        <span class="hint">{{ lang.adds }}</span>
                        <input type="text" v-model="fromData.adds" class="value" :placeholder="lang.iptAdds">
                    </div>
                    <div class="textere">
                        <textarea name="" v-model="fromData.textarea" :placeholder="lang.idear"></textarea>
                    </div>
                    <button class="submit" @click="submitBtn">
                        {{lang.btnText}}
                    </button>
                </div>
                <div class="close" @click="closetable">

                </div>
        </div>
    </transition>
</template>
<script>
import { useStore } from 'vuex'
import ajax from '@/ajax'
import { ref } from '@vue/reactivity'
import { showText } from '@/js/uitl'
export default {
    data: function () {
        return {
            flag: {}
        }
    },
    methods: {
        setShow: function (e) {
            let key = e.currentTarget.dataset.id
            if (this.flag[key] == undefined) {
                this.flag[key] = {
                    flag: true,
                    ele: e.currentTarget
                }
            }
            for (const key in this.flag) {
                this.flag[key].ele.classList.remove("active")
                this.flag[key].flag = true
            }
            if (this.flag[key].flag) {
                e.currentTarget.classList.add("active")
            } else {
                e.currentTarget.classList.remove("active")
            }
            this.flag[key].flag = !this.flag[key].flag
        },
        pushkf:function(){
            // this.$router.push("http://mxm.manpinwx.cn/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=9&groupid=0&special=14")
            // window.open("http://mxm.manpinwx.cn/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=9&groupid=0&special=14")
            window.open("https://treezo.s4.udesk.cn/im_client/?web_plugin_id=52790")
        }

    },
    mounted: function () {
        let _this = this
        document.addEventListener('click', function () {
            for (const key in _this.flag) {
                _this.flag[key].ele.classList.remove("active")
                _this.flag[key].flag = true
            }
        })
    }, 
    setup() {
        const store = useStore();
        const baseUrl = store.state.baseUrl
        const lang = ref(store.state.langPack[store.state.lang].add);
        const imgUrl = ref({
            img: '../../assets/add/02.jpg'
        })
        const product = ref([{
            name: 'LSB系列'
        },
        {
            name: '难燃系列'
        },
        {
            name: 'OSB系列'
        },
        {
            name: '难燃系列'
        },
        ])
        ajax(baseUrl + 'web/brandbannerService').then(res => {
					product.value = res
					selectProduct.value = product.value[0]
				})

        // 表单提交
        const fromData = ref({
            product: "",
            name: "",
            tel: "",
            adds: "",
            textarea: ""
        })

        // 记录下拉状态
        const active = ref(false)
        // 记录当前选择产品
        const selectProduct = ref(product[0])

        // 产品选择下拉
        const productClick = function () {
            active.value = !active.value
        }
        // 产品选择
        const selectClick = function (index) {
            selectProduct.value = product[index]
        }
        // 关闭下拉
        const closeSelect = function (e) {
            if (e.target != e.currentTarget) {
                active.value = false;
            }
            
        }
        document.body.addEventListener('click', closeSelect)
        //图片

        ajax(baseUrl + "web/applicationageService").then(res => {
            let result = res;
            let applicationage = [];
            result.forEach(item => {

                applicationage.push({
                    img: baseUrl + item.imgurl,
                });
            });

            imgUrl.value = applicationage[0];
        });

        
        let ShowText = null
        // 提交数据
        const submitBtn = function () {
            if(!ShowText){
                ShowText =new showText()
            }
            for (const key in fromData.value) {
                if (key == 'tel') {
                    if (!fromData.value[key]) {
                        ShowText.add("请输入电话");
                        return false;
                    } else {
                        const regex = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                        if (!regex.test(fromData.value[key])) {
                            ShowText.add("手机号格式错误");
                            return false;
                        }
                    }
                }
                if (key == 'adds') {
                    if (!fromData.value[key]) {
                        ShowText.add("请输入地址")
                        return false;
                    }

                }


            }

            let Product = selectProduct.value.name;
            let name = fromData.value['name'];
            let tel = fromData.value['tel'];
            let adds = fromData.value['adds'];
            let textarea = fromData.value['textarea'];

            ajax(baseUrl + "web/applicationsubService", {
                'productIne': Product,
                'name': name,
                'phone': tel,
                'address': adds,
                'age': textarea
            }).then(res => {

                if (res == '200') {
                    ShowText.add("信息提交成功");
                }

            });

        }

        // 打开关闭提交表单
        const tableTab = ref(false)
        const closetable =function(){
            tableTab.value = false
        }
        const opentable =function(){
            tableTab.value = true
        }

        return {
            lang,
            product,
            active,
            selectProduct,
            fromData,
            imgUrl,
            tableTab,
            productClick,
            selectClick,
            submitBtn, closeSelect,closetable,opentable
        }
    }
}
</script>
<style lang="less" scoped> 
.buttons {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;

    .item {
        // display: flex;
        // align-items: center;
        padding: 5px;
        padding-right: 30px;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 30px 30px;
        margin: 5px 0;
        // transform: translateX(30px);
        transform: translateX(calc(100% - 55px));
        transition: .7s;

        cursor: pointer;
        width: auto;
        padding-left: 40px;
        // float: left;
        position: relative;
        flex-shrink: 0;
        pointer-events: auto;
        outline: none;
        -webkit-tap-highlight-color: transparent;

        &.active {
            transform: translateX(30px);
        }

        .pic {
            width: 35px;
            height: 35px;
            background-color: #00543d;
            border-radius: 50%;
            padding: 5px;
            box-sizing: border-box;
            position: absolute;
            left: 5px;

            img {
                width: 60%;
                height: 60%;
                object-fit: contain;
            }

        }

        span {
            line-height: 1;
            font-size: 1.6rem;
            color: #fff;
            margin-left: 15px;
            margin-right: 30px;
            line-height: 35px;
        }
    }

    .clear {
        clear: both;
    }
}

.table {
    padding: 3.4% 4.3% 3.7% 5.2%;
    box-sizing: border-box;
    background: #fff;
    width: 70%;
    height: 80%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    border-radius: 15px;
    border: rgba(173, 173, 173, 0.5) 5px solid;
    .close{
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 999;
        cursor: pointer;
        width: 30px;
        height: 30px;

        transform: rotate(45deg);
        
        transition: .7s;
        &:hover{
        transform: rotate(135deg);

        }
    
   
        &::before,&::after{
            display: block;
            content: "";
            
            background: #ccc;
            border-radius: 1px;
            // transform-origin: center center;
            position: absolute;
        }
        &::before{
            width: 20px;
            height: 2px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
        &::after{
            width: 2px;
            height: 20px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .title {
        font-size: 3.0rem;
    }

    .info {
        margin-top: 30px;
        font-size: 1.5rem;
        line-height: 1.5;
        letter-spacing: 1px;
        color: #999;
    }

    .from {
        margin-top: 75px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .product {
            width: calc(50% - 8px);
            border: 1px solid #ccc;
            font-size: 1.5rem;
            line-height: 45px;
            position: relative;
            box-sizing: border-box;
            padding-left: 15px;
            cursor: pointer;
            z-index: 9;

            justify-items: flex-end;

            .hint {
                color: #999;
                letter-spacing: 2px;
                line-height: 45px;

            }

            .value {
                color: #333;
                letter-spacing: 2px;
                padding-left: 15px;
                line-height: 45px;

            }

            .icon {
                width: 20px;
                height: 100%;
                float: right;
                margin-right: 15px;

                img {
                    object-fit: contain;
                }
            }

            .contioner {
                font-size: inherit;
                line-height: inherit;
                position: absolute;
                right: 0;
                width: 75%;
                z-index: 9;
                // border-radius: 5px;
                margin-top: 10px;
                overflow: hidden;
                padding: 10px;
                padding-top: 0;

                top: 90%;
                opacity: 0;
                pointer-events: none;
                transition: .3s;

                .products {
                    list-style: none;
                    font-size: inherit;
                    line-height: inherit;
                    background-color: #fff;
                    border-radius: 8px;
                    cursor: pointer;
                    overflow: hidden;
                    z-index: 9999;
                    position: relative;

                    box-shadow: 0px 0px 15px #dfdfdf;

                    // padding: 15px;
                    li {
                        padding: 0 15px;
                        transition: .3s;
                        cursor: pointer;
                    }
                }
            }

            &.active {
                .contioner {
                    top: 100%;
                    opacity: 1;
                    pointer-events: all;
                }

            }

        }

        &>.name {
            width: calc(50% - 8px);
            border: 1px solid #ccc;
            font-size: 1.5rem;
            line-height: 45px;
            position: relative;
            box-sizing: border-box;
            padding-left: 15px;
            cursor: pointer;

            .hint {
                color: #999;
            }

            .value {
                line-height: 45px;
                border: none;
                margin: 0 0 0 0;
                padding: 0 0 0 0;
                background-color: transparent;
                text-indent: 1.5rem;
                font-size: inherit;

                &::placeholder {
                    color: #333;
                    text-indent: 1.5rem;
                    font-size: inherit;

                }
            }
        }

        .adds,
        .tel {
            margin-top: 30px;
        }

        .textere {
            margin-top: 30px;
            width: 100%;
            border: 1px solid #ccc;
            box-sizing: border-box;
            padding: 10px;

            textarea {
                width: 100%;
                border: none;
                font-size: 1.5rem;
                height: 150px;
                resize: none;

                &::placeholder {
                    color: #333;
                    font-size: inherit;
                    font-size: 1.5rem;

                }
            }
        }

        .submit {
            font-size: 1.6rem;
            padding: 10px 45px;
            margin-top: 67px;
            background-color: #00543e;
            border: none;
            border-radius: 30px;
            color: #fff;
            cursor: pointer;
            transition: .7s;
            &:hover{
                background-color: #b8985d;
            }
        }

    }
}
@media (any-hover:hover) {
    .join {}

    .table {
        .from {
            .product {
                .contioner {
                    .products {
                        li:hover {
                            box-sizing: border-box;
                            background-color: #ccc;
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width:989px) {
    .join {
        &>.pic {
            display: none;
        }
    }

    .table {
        .from {
            flex-wrap: wrap;
            .product,
            .name {
                width: 100%;
                margin-top: 15px;
            }
        }
    }

}

@media (any-hover:hover) {
    .buttons {
        .item:hover {
            transform: translateX(30px);
        }
    }
}

@media all and (max-width:789px) {
    .table {
        width: 91%;
        overflow-y: scroll;
        z-index: 99999;
        .from{
            margin-top: 0;
            .textere{
                margin-top: 15px;
            }
            .submit{
                margin-top: 15px;
            }
        }
    }
    .buttons {
        top: 32%;
    }
}
</style>