<template>
    <navigationEleVue></navigationEleVue>
    <videoEle :option="video"></videoEle>

    <rightButtonsVue></rightButtonsVue>
    <div class="contitoner">
        <Swiper class="Swiper" :speed="1000" ref="swiperDom" @swiper="setSwiper" direction="vertical" @transitionStart="transitionStart" @transitionEnd="transitionEnd" @slideChangeTransitionStart="slideChangeTransitionEnd">
            <SwiperSlide class="SwiperSlide">
                <OneEle :isActive="slideCoreAni[0]" @video="setVideo"></OneEle>
            </SwiperSlide>
            <SwiperSlide class="SwiperSlide">
                <TwoEle :isActive="slideCoreAni[1]"></TwoEle>
                <!-- <TwoEle :isActive="slideCoreAni[1]"></TwoEle> -->
            </SwiperSlide>
            <SwiperSlide class="SwiperSlide">
                <!-- <ThereEle :isActive="slideCoreAni[2]"></ThereEle> -->
                <ThereEle :isActive="slideCoreAni[2]"></ThereEle>
            </SwiperSlide>
            <SwiperSlide class="SwiperSlide">
                <!-- <FourEle :isActive="slideCoreAni[3]"></FourEle> -->
                <FourEle :isActive="slideCoreAni[3]"></FourEle>
            </SwiperSlide>
            <SwiperSlide  class="SwiperSlide">
                <!-- <FiveEle :isActive="slideCoreAni[4]"></FiveEle> -->
                <FiveEle :isActive="slideCoreAni[4]"></FiveEle>
            </SwiperSlide>
        </Swiper>
    </div>

    <div class="qiehuan">
      <div class="prev">
        <i class="fa fa-angle-up" @click="setPrev" aria-hidden="true"></i>
      </div>
      <div class="next">
        <i class="fa fa-angle-down" @click="setNext" aria-hidden="true"></i>
      </div>
    </div>
    <div class="change">
        {{ title }}
    </div>
</template>
<script>
// import mapEleVue from '@/components/content/mapEle.vue'
// import inMore from '../components/public/inMore.vue'
import {ref,onMounted,onUpdated} from 'vue'
import {Swiper,SwiperSlide} from 'swiper/vue'

import OneEle from "../components/index/OneEle.vue"
import TwoEle from '../components/index/TwoEle.vue'
import ThereEle from '../components/index/ThereEle.vue'
import FourEle from '../components/index/FourEle.vue'
import FiveEle from '../components/index/FiveEle.vue'
import navigationEleVue from '@/components/public/navigationEle.vue'
import rightButtonsVue from '@/components/public/rightButtons.vue'
import VideoEle from '@/components/public/videoEle.vue'
import { useRoute, useRouter } from 'vue-router'
import ajax from '@/ajax';
import { useStore } from "vuex";
export default {
    components:{Swiper,SwiperSlide,OneEle,TwoEle,ThereEle,FourEle,FiveEle,navigationEleVue,rightButtonsVue,VideoEle},    
    data(){
        return {
            html : "",
        }
    },
    methods:{
        transitionEnd(){
            [].forEach.call(document.querySelectorAll(".SwiperSlide"),(item)=>{
                item.style.transform="scale(1)"
            })
        },

    },
    setup(){
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const baseUrl = store.state.baseUrl;
        console.log('baseUrl',baseUrl);
        window.onresize = function () {
            if(document.body.clientWidth < 769 && (route.name=='m'||route.name=='home')){
                router.push("/m")
            }

        }
        window.addEventListener("resize", function() {
            if(document.body.clientWidth < 769 && (route.name=='m'||route.name=='home')){
                router.push("/m")
            }
        });
        if(document.body.clientWidth < 769 && (route.name=='m'||route.name=='home')){
            router.push("/m")
        }
        const homeSwiper = ref(null)
        const swiperDom  = ref(null)
        const slideCoreAni = ref([true,false,false,false,false])
        

        const video = ref(null)

        const setVideo = function(option){
            video.value = option
        }

        const setSwiper = swiper=>{
            homeSwiper.value = swiper
        }
        const each_slide = function(){
            [].forEach.call(document.querySelectorAll(".SwiperSlide"),(item)=>{
                item.style.transform="scale(0.95)"
            })
        }
        const setPrev = function(){
            each_slide();
            setTimeout(()=>{
                homeSwiper.value.slidePrev();
            },300)

        }
        const setNext = function(){
            each_slide();
            setTimeout(()=>{
                homeSwiper.value.slideNext();
            },300)
        }
        let mouseWheel = (function(){
                let time = 0
                return function(e){
                    let now = new Date().getTime();
                    if(now-time>1500){
                        if(e.deltaY > 0){
                            if(homeSwiper.value.activeIndex >= 4){
                                return false
                            }
                            each_slide();
                            setTimeout(()=>{
                                
                                homeSwiper.value.slideNext();
                            },300)
                        }else{
                            if(homeSwiper.value.activeIndex <= 0){
                                return false
                            }
                            each_slide();
                            setTimeout(()=>{
                                homeSwiper.value.slidePrev();
                            },300)
                        }
                        time = now
                    }
                }

        })()
        const title = ref("");
        const keyword = ref("");
        const data = ref("");
        const description = ref("");
        const setData = async() => {
            console.log(store.state.lang);
            let name = "";
            if(store.state.lang=='cn'){
                name = "首页"
            }else if(store.state.lang=='en'){
                name = "HOME"
            }
            data.value = await ajax(baseUrl + `web/seoyhService?title=${name}`)
            console.log(data.value);
            title.value = data.value[0].title;
            keyword.value = data.value[0].keyword;
            description.value = data.value[0].description;
        }
        setData();

        let slideChangeTransitionEnd = function(){
            slideCoreAni.value[homeSwiper.value.activeIndex] = true
        }
        onUpdated(() => {
            setData();
        })
        onMounted(()=>{
            document.querySelector("head").innerHTML +=`<meta name="baidu-site-verification" content="codeva-D4jklpZ1if">`
            document.querySelector("head").innerHTML +=`<meta name="bytedance-verification-code" content="TMtuuPWtqlLjU/Usj4d8"/>`
            document.querySelector("head").innerHTML +=`<meta name="360-site-verification" content="9272e80bf19908d07d0dce6ee2d8530b"/>`
            document.querySelector('.Swiper').addEventListener("mousewheel",mouseWheel);
        })
        
        return {
            homeSwiper,setSwiper,swiperDom,slideCoreAni,slideChangeTransitionEnd,video,setVideo,setPrev,setNext,title,keyword,description
        }
    },
    metaInfo() {
      return {
        title: this.title, // set a title
        meta: [
          {
            // set meta
            name: "keywords",
            content: this.keyword,
          },
          {
            name: "Description",
            content: this.description,
          },
        //   {
        //     name:"baidu-site-verification",
        //     content:"codeva-D4jklpZ1if"
        //   }
        ],
      };
    },
    // components:{mapEleVue},
    // mounted(){
    //     let str = `
    //         <p>
    //             <img src="/junsheng-admin/upload/040a2187-8ac9-493e-8896-dc3df03bf448.png" alt="" />
    //         </p>
    //         <p>
    //             <img src="/junsheng-admin/upload/040a2187-8ac9-493e-8896-dc3df03bf448.png" alt="" />
    //         </p>
    //         <p>
    //             <img src="/junsheng-admin/upload/040a2187-8ac9-493e-8896-dc3df03bf448.png" alt="" />
    //         </p>
    //         <p>
    //             <img src="/junsheng-admin/upload/040a2187-8ac9-493e-8896-dc3df03bf448.png" alt="" />
    //         </p>
    //         <p>
    //             iuhfgahfgkahgfkahfgkasuehfgkasfhkghusgbsukefhsnkfheiafuhiakfuhea
    //         </p>
    //         `

    //         // str = ` <img src="/junsheng-admin/upload/040a2187-8ac9-493e-8896-dc3df03bf448.png" alt="" width="200" height="122" title="" align="" />`
    //         this.html = str.setEditor();
    //         this.$nextTick(function(){
    //             setImg()
    //         })

          
    // }
}
</script>
<style>
    .ed{
        
        width: 200px;
    }
</style>
<style lang="less" scoped>
.change{
    position: absolute;
    top: 0;
    height: 1px;
    background:#33333300;
}
.Swiper{
    width: 100vw;
    height: 100vh;
}
.SwiperSlide{
    transition: .3s;
}
.SwiperSlide:nth-child(1){
    width: 100vw;
    height: 100vh;
}
.SwiperSlide:nth-child(2){
    width: 100vw;
    height: 100vh;
}
.SwiperSlide:nth-child(3){
    width: 100vw;
    height: 100vh;
}             
.SwiperSlide:nth-child(4){
    width: 100vw;
    height: 100vh;
}
.SwiperSlide:nth-child(5){
    width: 100vw;
    height: 100vh;
}
img{
    width: 200px;
    // opacity: .5;
    // position: fixed;
    // top: 0;
    // left: 0;

}
.isMore{
    position: fixed;
    z-index: 99;
    top:550px;
}
.m{
    width: 100%;
    height: 100vh;
}
.qiehuan{
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 999;
  // width: 30px;
  // height: 80px;
  transform: translateY(-50%);
  // border-radius: 20px;
  // overflow: hidden;
  @media all and (max-width: 769px){
    display: none;
  }
  .prev,.next{
    color: #fff;
    text-align: center;
    font-size: 1.8rem;
    cursor: pointer;
    margin: 5px 0;
    transition: .7s;
    background: rgba(0, 0, 0, .6);
    border-radius: 0 30px 30px 0;
    padding: 5px;
    box-sizing: border-box;
    
    .fa{
      width: 35px;
      height: 35px;
      background: #00543d;
      border-radius: 50%;
      line-height: 35px;
      

    }

    &:hover{
      background: #ccc;
    }

  }
  .prev{
    width: 100%;
  }
  .next{
    width: 100%;
  }
}
</style>