<template>
  <navigationEleVue :isActive="true"></navigationEleVue>
  <catalogueEle
    class="catalogueEle"
    @slide="slidebtn"
    :items="slideInfo"
    ref="slide"
  ></catalogueEle>

  <div class="about">
    <!-- <img src="../assets/demo/20221116.jpg" alt=""> -->
    <bannerEle id="24"></bannerEle>
    <!-- 企业简介 -->
    <briefEle class="briefEle" ref="briefEle"></briefEle>
    <!-- 战略格局 -->
    <cultureEle class="cultureEle" ref="cultureEle"></cultureEle>
    <!-- 企业初心 -->
    <originalEle class="originalEle" ref="originalEle"></originalEle>
    <!-- 社会责任 -->
    <dutyEle class="dutyEle" ref="dutyEleVue"></dutyEle>
    <!-- 资质荣誉 -->
    <horroEle class="horroEle" ref="horroEle"></horroEle>
    <!-- 发展历利 -->
    <historyEle class="historyEle" ref="historyEle"></historyEle>

    <skipEle item="111" :id="1"></skipEle>
    <footerEle style="display: block"></footerEle>
  </div>
</template>
<script>
import navigationEleVue from "@/components/public/navigationEle.vue";
import bannerEle from "../components/public/bannerEle.vue";

import briefEle from "../components/about/briefEle.vue";
import cultureEle from "../components/about/cultureEle.vue";
import originalEle from "../components/about/originalEle.vue";
import dutyEle from "../components/about/dutyEle.vue";
import horroEle from "../components/about/horroEle.vue";
import historyEle from "../components/about/TestEle.vue";

import skipEle from "../components/public/skipEle.vue";
import footerEle from "../components/public/footerEle.vue";
import catalogueEle from "../components/about/calEle.vue";

import { ref, onMounted,onUpdated } from "vue";
import ajax from "@/ajax";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
// import { useRouter } from 'vue-router';
export default {
  data() {
    return {
      // banner:{
      //     titleCn:"关于我们",
      //     titleEn:"About Us",
      //     desc:"定义高端制造的生活方式",
      //     imgurl:require('../assets/about/banner.jpg'),
      //     video:{
      //         cover:"require('../assets/about/video.jpg')",
      //         url:""
      //     }
      // },
      catalogue: [
        {
          name: "公司简介",
        },
        {
          name: "企业文化",
        },
        {
          name: "社会责任",
        },
        {
          name: "资质荣誉",
        },
        {
          name: "发展历利",
        },
      ],
      skip: {
        imgurl: require("../assets/skip.jpg"),
        url: "/",
        title: "我猜您还想<br />了解我们的产品",
      },
    };
  },
  components: {
    navigationEleVue,
    briefEle,
    cultureEle,
    bannerEle,
    footerEle,
    catalogueEle,
    originalEle,
    dutyEle,
    horroEle,
    historyEle,
    skipEle,
  },
  setup() {
    const route = useRoute();

    const store = useStore();
    const baseUrl = store.state.baseUrl;

    // 获取 所有组件dom
    const briefEle = ref(null);
    const cultureEle = ref(null);
    const originalEle = ref(null);
    const dutyEleVue = ref(null);
    const horroEle = ref(null);
    const historyEle = ref(null);
    const slideInfo = ref([]);
    const slide = ref(null);

    const banner = ref(null);
    const routeIndex = ref(0);

    const slidebtn = function (item) {
      item.ele.$el.scrollIntoView({ behavior: "smooth" });
    };
    const init = async function () {
      // const aBanner = await ajax(baseUrl+'web/aboutbannerService')
      // // let rbanner = {
      // //     imgurl:baseUrl + aBanner[aBanner.length-1].imgurl,
      // //     desc: aBanner[aBanner.length-1].particulars,
      // //     video:{
      // //         cover:baseUrl + aBanner[aBanner.length-1].vidImg,
      // //         url:baseUrl + aBanner[aBanner.length-1].video
      // //     },
      // //     about:true
      // // }
      // aBanner[aBanner.length-1].about =true
      // banner.value = aBanner[aBanner.length-1].about
    };

    init();
    const title = ref('title');
    const keyword = ref('keyword');
    const description = ref('description');
    
    onUpdated(async() => {
        let slideInfo = await ajax(baseUrl + "web/aboutbannerlistService");
        console.log(route.params.lc);
        let url = ""
        let data = []
        if(route.params.lc!= ""){
          url = slideInfo[route.params.lc].name
          data = await ajax(baseUrl + `web/seoyhxqService?title=${url}`);
        }else{
          url = store.state.langPack[store.state.lang].navigation[0].name
          data = await ajax(baseUrl + `web/seoyhService?title=${url}`)
        }
        title.value =  data[0].title;
        keyword.value =  data[0].keyword;
        description.value =  data[0].description;
    })
    onMounted(() => {
      ajax(baseUrl + "web/aboutbannerlistService").then((res) => {
        slideInfo.value = [
          {
            name: "公司简介",
            ele: briefEle,
            scroll: briefEle.value.$el.offsetTop,
          },
          {
            name: "企业文化",
            ele: originalEle,
            scroll: originalEle.value.$el.offsetTop,
          },
          {
            name: "社会责任",
            ele: dutyEleVue,
            scroll: dutyEleVue.value.$el.offsetTop,
          },
          {
            name: "资质荣誉",
            ele: horroEle,
            scroll: horroEle.value.$el.offsetTop,
          },
          {
            name: "发展历利",
            ele: historyEle,
            scroll: historyEle.value.$el.offsetTop,
          },
        ];
        slideInfo.value.forEach((item, index) => {
          item.name = res[index].name;
        });
        setTimeout(() => {
          if (route.params.lc) {
            slidebtn(slideInfo.value[route.params.lc]);
            routeIndex.value = route.params.lc;
            slide.value.setSlide(route.params.lc);
          }
        }, 300);
      });
    });

    return {
      banner,

      briefEle,
      cultureEle,
      originalEle,
      dutyEleVue,
      horroEle,
      historyEle,
      slideInfo,
      slidebtn,
      slide,
      routeIndex,
      title,
      keyword,
      description
    };
  },
  metaInfo() {
    return {
      title: this.title, // set a title
      meta: [
        {
          // set meta
          name: "keywords",
          content: this.keyword,
        },
        {
          name: "Description",
          content: this.description,
        },
      ],
    };
  },
};
</script>
<style>
html,
body {
  /* overflow: scroll; */
}
</style>
<style lang="less" scoped>
.about {
  position: relative;
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
@media all and (max-width: 769px) {
  .catalogueEle {
    display: none;
  }
}
</style>
