<template>
	<navigationEleVue :isActive="true"></navigationEleVue>
	<bannerEleVue :item="bannerData" class="bannerEleVue" :id="6"></bannerEleVue>
	<catalogueEleVue @slide="setSlide" ref="catalogueEleVue" class="catalogueEleVue" :items="catalogue">
	</catalogueEleVue>
	<div class="news">
		<!-- <img src="../assets/demo/202211241440.jpg" alt=""> -->
		<hotEleVue v-if="ResizeValue" :hot="hot" type="hot"></hotEleVue>
		<div v-if="ResizeValue" class="newss">
			<newsItemVue class="newsItemVue" v-for="(item, index) in noHot" :key="index" :item="item"></newsItemVue>
		</div>
		<div v-if="!ResizeValue" class="newss">
			<div class="colum">
				<div class="contioner" v-for="(item, index) in noHot" :key="index">
					<newsItemVue class="newsItemVue" :item="item" v-if="(index % 2) == 0"></newsItemVue>
				</div>
			</div>
			<div class="colum">
				<div class="contioner" v-for="(item, index) in noHot" :key="index">
					<newsItemVue class="newsItemVue" :item="item" v-if="(index % 2) != 0"></newsItemVue>
				</div>
			</div>
		</div>
		<div class="newss m375">
			<newsItemVue class="newsItemVue" v-for="(item, index) in noHot" :key="index" :item="item"></newsItemVue>
		</div>
		<newsMoreEleVue type="more" class="newsMoreEleVue" :desc="moreDesc" @click="setMore"></newsMoreEleVue>
	</div>
	<skipEleVue :id="7"></skipEleVue>
	<footerEleVue></footerEleVue>
</template>
<script>
import {
	useRoute,
	useRouter
} from 'vue-router';


import bannerEleVue from '@/components/public/bannerEle.vue'
import {
	useStore
} from 'vuex';
import hotEleVue from '@/components/media/hotEle.vue';
import newsItemVue from '@/components/media/newsItem.vue';
import newsMoreEleVue from '@/components/public/newsMoreEle.vue';
import catalogueEleVue from '@/components/media/calgEle.vue';
import ajax from '@/ajax/index.js';

import {
	ref, onMounted, watch,onUpdated
} from 'vue'
import skipEleVue from '@/components/public/skipEle.vue';
import footerEleVue from '@/components/public/footerEle.vue';
import navigationEleVue from '@/components/public/navigationEle.vue';

export default {
	components: {
		hotEleVue,
		bannerEleVue,
		newsItemVue,
		newsMoreEleVue,
		catalogueEleVue,
		skipEleVue,
		footerEleVue,
		navigationEleVue
	},
	setup() {
		const store = useStore();
		const baseUrl = store.state.baseUrl
		const router = useRouter();
		const route = useRoute();
		const bannerData = {
			titleCn: "媒体中心",
			titleEn: "Media Center",
			desc: "定义高端制造的生活方式",
			imgurl: require('../assets/media/m1.jpg'),
			video: {
				cover: "require('../assets/about/video.jpg')",
				url: ""
			}
		}
		const hot = ref([]);
		const noHot = ref([]);
		const catalogueEleVue = ref(null)
		let lnoHot = []
		const news = ref([{
			id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。<br /><br />签约现场，双方负责人均表示对此次战略合作充满了期待和信心。广州轩怡装饰集团供应链总经理谢新仁表示：轩怡装饰坚持“客户满意是我们最大的幸福”为企业使命。在质量参差、劣质材料、假冒名牌、收费混乱等负面信息频出的家装行业，真正要把客户的满意度做好并不容易，此次希望通过与千年舟的合作，把绿色健康环保板材推荐给更多的消费者，期望后续双方加强沟通交流，全面赋能整装行业。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 1,
		},
		{
			id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。<br /><br />签约现场，双方负责人均表示对此次战略合作充满了期待和信心。广州轩怡装饰集团供应链总经理谢新仁表示：轩怡装饰坚持“客户满意是我们最大的幸福”为企业使命。在质量参差、劣质材料、假冒名牌、收费混乱等负面信息频出的家装行业，真正要把客户的满意度做好并不容易，此次希望通过与千年舟的合作，把绿色健康环保板材推荐给更多的消费者，期望后续双方加强沟通交流，全面赋能整装行业。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 1,
		},
		{
			id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 2,
		},
		{
			id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 2,
		},
		{
			id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 2,
		},
		{
			id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 2,
		},
		{
			id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 2,
		},
		{
			id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 2,
		},
		{
			id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 2,
		},
		{
			id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 2,
		},
		])
		onMounted(() => {
			if (route.params.id) {
				catalogueEleVue.value.setSlide(route.params.id)
			}
			document.querySelector('.bannerEleVue').scrollIntoView({ behavior: "smooth" })
		})
		news.value.forEach(item => {
			if (item.type == 1) {
				hot.value.push(item)
			} else {
				noHot.value.push(item)
			}
		})

		const catalogue = ref([{
			name: '工厂动态',
			url: '/news/0',
			id: '1'
		},
		{
			name: '产品动态',
			url: '/news/1',
			id: '2'

		},
		{
			name: '新闻动态',
			url: '/news/2',
			id: '3'
		},
		{
			name: '媒体中心',
			url: '/video/3'
		}
		])

		if (store.state.lang == 'en') {
			catalogue.value = [{
				name: 'Factory dynamics',
				url: '/news/0',
				id: '1'
			},
			{
				name: 'Product dynamics',
				url: '/news/1',
				id: '2'

			},
			{
				name: 'News and trends',
				url: '/news/2',
				id: '3'
			},
			{
				name: 'media center',
				url: '/video/3'
			}
			]
		}

		const setSlide = function (item) {
			router.push(item.url)
			newsfind(item.id)
		}

		/* 	id: 0,
			date: ["2022", '05', "09"],
			title: "重磅！千年舟集团与广州轩怡装饰集团签署战略合作协议",
			desc: `10月27日，千年舟集团与广州轩怡装饰集团在广州轩怡装饰总部举行了战略合作签约仪式，携手共奏品牌战略联盟最强音。后续双方将通力合作、携手前行，于家装领域中开辟共进互赢新路径。<br /><br />签约现场，双方负责人均表示对此次战略合作充满了期待和信心。广州轩怡装饰集团供应链总经理谢新仁表示：轩怡装饰坚持“客户满意是我们最大的幸福”为企业使命。在质量参差、劣质材料、假冒名牌、收费混乱等负面信息频出的家装行业，真正要把客户的满意度做好并不容易，此次希望通过与千年舟的合作，把绿色健康环保板材推荐给更多的消费者，期望后续双方加强沟通交流，全面赋能整装行业。`,
			imgurl: require("../assets/media/01.jpg"),
			type: 1, */

		function newsfind(index) {
			ajax(baseUrl + "web/newsService", {
				'type': index
			}).then(res => {
				console.log(res);
				let result = res;
				let newsy = [];
				result.forEach(item => {
					let datey = [];
					let {
						year,
						month,
						day
					} = getTime(item.newsDate)
					datey.push(year, month+1, day);
					newsy.push({
						id: item.id,
						date: datey,
						title: item.title,
						desc: item.brief,
						imgurl: baseUrl + item.imgurl,
						type: item.elite,
					});
				});

				function getTime(str) { // eslint-disable-line
					let date = new Date(str)
					const year = date.getFullYear()
					const month = date.getMonth()
					const day = date.getDate()
					const hour = date.getHours()
					const min = date.getMinutes()
					const sec = date.getSeconds()
					return {
						year,
						month,
						day,
						hour,
						min,
						sec
					}
				}
				news.value = newsy;

				let lim5 = []
				let lnot = []

				news.value.forEach((item) => {
					if (item.type == 1) {
						lnot.push(item)

					} else {
						lnoHot.push(item)
						if (lim5.length < 5) {
							lim5.push(item)
						}
					}
				})
				hot.value = lnot
				noHot.value = lim5
			});
		}
		const setMore = function () {
			noHot.value = lnoHot
		}
		let params = router.currentRoute.value.params.id;
		newsfind(++params);
		// 底部上面的板块
		const skip = {
			imgurl: require('../assets/brandDetail/db.jpg'),
			url: "/contact",
			title: "对我们的产品感兴趣<br />可以联系我们哦!"
		}
		// 监听屏幕宽度变化
		/**
		 * ResizeValue ==> true ==> 大于769
		 *                ==> false => 小于769
		 */
		const ResizeValue = ref(document.body.clientWidth > 769)
		window.addEventListener('resize', () => {
			if (document.body.clientWidth < 769) {
				ResizeValue.value = false
			} else {
				ResizeValue.value = true
			}
		})

		const moreDesc = ref(store.state.langPack[store.state.lang].news.more)
		watch(() => store.state.lang, (val, old) => {
			console.log(moreDesc.value, old)
			moreDesc.value = store.state.langPack[val].news.more
		})
		const title = ref('title');
        const keyword = ref('keyword');
        const description = ref('description');
        
        onUpdated(async() => {
            let url = ""
            let data = []
			url = store.state.langPack[store.state.lang].footer.links[0].links[route.params.id].name
			data = await ajax(baseUrl + `web/seoyhxqService?title=${url}`);
            title.value =  data[0].title;
            keyword.value =  data[0].keyword;
            description.value =  data[0].description;
        })
		return {
			bannerData,
			hot,
			noHot,
			ResizeValue,
			catalogue,
			setSlide,
			skip, setMore, catalogueEleVue,
			moreDesc,
			title,
			keyword,
			description
		}
	},
	metaInfo() {
      return {
        title: this.title, // set a title
        meta: [
          {
            // set meta
            name: "keywords",
            content: this.keyword,
          },
          {
            name: "Description",
            content: this.description,
          },
        ],
      };
    },
}
</script>
<style lang="less">
.news {
	.newss {
		.newsItemVue:last-child {
			padding-bottom: 85px;
		}
	}
}

.catalogueEleVue {
	&>ul {
		li {
			&.active {
				span {
					color: #00543D;
				}
			}

			&:hover {
				span {
					color: #00543D;
				}
			}

			color: #fff;
		}

	}
}
</style>
<style lang="less" scoped>
.catalogueEleVue {
	background-color: #B9985E;
}

.news {
	background-color: #f0f0f0;
	padding-bottom: 85px;

	&>img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: -99;
	}

	.newss {
		// background-color: #fff;
		width: 100%;
		padding: 4.2% 2.6% 30px 9%;
		box-sizing: border-box;

		&.m375 {
			display: none;
		}

		.newsItemVue {
			width: 100%;
			background: #fff;
		}

	}

	.newsMoreEleVue {
		margin-bottom: 0;
	}
}

@media all and (max-width:769px) {
	.news {
		.newss {
			padding: 15px;
			box-sizing: border-box;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.colum {
				display: flex;
				width: calc(50% - 8px);

				flex-direction: column;

				.newsItemVue {
					width: 100%;
					margin-top: 15px;

				}

				.newsItemVue:last-child {
					padding-bottom: 0;
				}
			}

		}
	}
}

@media all and (max-width:481px) {
	.news {
		.newss {
			// background-color: #fff;
			width: 100%;
			padding: 30px 15px;
			display: none;

			&.m375 {
				display: block;
				padding-bottom: 0;

				.newsItemVue {
					margin-top: 15px;
				}
			}

			.newsItemVue:last-child {
				padding-bottom: 0;
			}
		}
	}
}
</style>
