<template>
  <navigationEleVue isActive="true"></navigationEleVue>
  <!-- <bannerEleVue :item="bannerData" id="10" ></bannerEleVue> -->
  <bannerEleVue id="10" class="bannerEleVue" ></bannerEleVue>

  <div class="add">
    <!-- <img src="../assets/demo/202211281057.jpg" alt=""> -->
    <joinEle></joinEle>
    <!-- <jobEle></jobEle> -->
  </div>
  <!-- 底部 -->
  <skipEleVue :id="11"></skipEleVue>
  <footerEleVue></footerEleVue>
</template>

<script>

import navigationEleVue from '@/components/public/navigationEle.vue';
import bannerEleVue from '@/components/public/bannerEle.vue';
import joinEle from '@/components/add/joinEle.vue'
// import jobEle from '@/components/add/jobEle.vue'
import skipEleVue from '@/components/public/skipEle.vue';
import footerEleVue from '@/components/public/footerEle.vue';

import { onMounted,ref,reactive,onUpdated } from 'vue';
import { useStore } from "vuex";
import ajax from "@/ajax";

export default {
    components:{navigationEleVue,bannerEleVue,joinEle,skipEleVue,footerEleVue},
    setup(){
        const store = reactive(useStore())
        const baseUrl = store.state.baseUrl
        const bannerData = {
            titleCn:"加入我们",
            titleEn:"Join Us",
            desc:"定义高端制造的生活方式",
            imgurl:require('../assets/add/banner.jpg'),
            video:{
                cover:"require('../assets/about/video.jpg')",
                url:require('@/video/97.mp4')
            }
        }
        // 底部上面的板块
        const skip = {
            imgurl:require('../assets/skip.jpg'),
            url:"/brand",
            title:"我猜您还想<br />了解我们的产品!"
        }
        const title = ref('title');
        const keyword = ref('keyword');
        const description = ref('description');
        const getDate = async() => {
          let url = "";
          let data = []
          url = store.state.langPack[store.state.lang].footer.links[1].title
          data = await ajax(baseUrl + `web/seoyhService?title=${url}`);
          title.value =  data[0].title;
          keyword.value =  data[0].keyword;
          description.value =  data[0].description;
        }
        getDate();
        onUpdated(async() => {
          getDate();
        })
        onMounted(()=>{
            getDate();
            document.querySelector('.bannerEleVue').scrollIntoView({behavior: "smooth"})
        })
        return {
            bannerData,skip,title,keyword,description
        }
    },
    metaInfo() {
      return {
        title: this.title, // set a title
        meta: [
          {
            // set meta
            name: "keywords",
            content: this.keyword,
          },
          {
            name: "Description",
            content: this.description,
          },
        ],
      };
    },
}
</script>

<style lang="less" scoped>
.add{
    &>img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -99;
    }
}

</style>