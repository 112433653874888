<template>
    <navigationEleVue :isActive="true"></navigationEleVue>

  <div class="intel">
    <!-- <img src="../assets/demo/202211221430.jpg" alt=""> -->
    <bannerEleVue id="2"></bannerEleVue>

    <!-- 设备简介 -->
    <infoele></infoele>
    <!-- 设备 -->
    <div class="equs">
      <equEle v-for="(item,index) in equData" :item="item" :key="index" :class="{equEle:true,active:(id && id == index),wow:true,animate__fadeInUp:true}"></equEle>
    </div>
  </div>
  <skipEleVue class="skip" :id="3"></skipEleVue>
  <footerEleVue></footerEleVue>
</template>

<script>
import { useRoute } from 'vue-router';

import navigationEleVue from '@/components/public/navigationEle.vue';
import bannerEleVue from '@/components/public/bannerEle.vue';

import infoele from '../components/core/infoEle.vue'
import equEle from '../components/core/equEle.vue'
import skipEleVue from '@/components/public/skipEle.vue';

import footerEleVue from '@/components/public/footerEle.vue';
import { ref,onUpdated } from 'vue';
import { useStore } from 'vuex';
import ajax from '@/ajax';

export default {
  components:{navigationEleVue,bannerEleVue,infoele,equEle,footerEleVue,skipEleVue},
  setup(){
    const route = useRoute().params
    // 判断路由是否携带参数
    const id = route.id ? route.id : undefined
    
    const bannerData = {
      titleCn:"智能制造",
      titleEn:"Intelligent Manufacturing",
      desc:"定义高端制造的生活方式",
      imgurl:require('@/assets/core/b1.jpg'),
      video:{
          cover:"require('../assets/about/video.jpg')",
          url:""
      }
    }

    const equData = ref([
      {
        name:'德国帕尔曼长材刨片机',
        desc:'刨片质量的好坏对定向结构刨花板的质量起决定性作用，澳思柏恩引进德国帕尔曼长材刨片机，通过旋切方式，将进口松木整木加工成40-100mm长、5-20mm宽、0.3-0.7mm厚的细长刨片，木材利用率高，刨片质量好，形态更均匀，性能更强韧。',
        imgurl:require('../assets/core/e1.jpg'),
      },
      {
        name:'德国帕尔曼长材刨片机',
        desc:'刨片质量的好坏对定向结构刨花板的质量起决定性作用，澳思柏恩引进德国帕尔曼长材刨片机，通过旋切方式，将进口松木整木加工成40-100mm长、5-20mm宽、0.3-0.7mm厚的细长刨片，木材利用率高，刨片质量好，形态更均匀，性能更强韧。',
        imgurl:require('../assets/core/e2.jpg'),
      },
      {
        name:'德国帕尔曼长材刨片机',
        desc:'刨片质量的好坏对定向结构刨花板的质量起决定性作用，澳思柏恩引进德国帕尔曼长材刨片机，通过旋切方式，将进口松木整木加工成40-100mm长、5-20mm宽、0.3-0.7mm厚的细长刨片，木材利用率高，刨片质量好，形态更均匀，性能更强韧。',
        imgurl:require('../assets/core/e3.jpg'),
      },
      {
        name:'德国帕尔曼长材刨片机',
        desc:'刨片质量的好坏对定向结构刨花板的质量起决定性作用，澳思柏恩引进德国帕尔曼长材刨片机，通过旋切方式，将进口松木整木加工成40-100mm长、5-20mm宽、0.3-0.7mm厚的细长刨片，木材利用率高，刨片质量好，形态更均匀，性能更强韧。',
        imgurl:require('../assets/core/e4.jpg'),
      },
      {
        name:'德国帕尔曼长材刨片机',
        desc:'刨片质量的好坏对定向结构刨花板的质量起决定性作用，澳思柏恩引进德国帕尔曼长材刨片机，通过旋切方式，将进口松木整木加工成40-100mm长、5-20mm宽、0.3-0.7mm厚的细长刨片，木材利用率高，刨片质量好，形态更均匀，性能更强韧。',
        imgurl:require('../assets/core/e5.jpg'),
      },
      {
        name:'德国帕尔曼长材刨片机',
        desc:'刨片质量的好坏对定向结构刨花板的质量起决定性作用，澳思柏恩引进德国帕尔曼长材刨片机，通过旋切方式，将进口松木整木加工成40-100mm长、5-20mm宽、0.3-0.7mm厚的细长刨片，木材利用率高，刨片质量好，形态更均匀，性能更强韧。',
        imgurl:require('../assets/core/e6.jpg'),
      },
      {
        name:'德国帕尔曼长材刨片机',
        desc:'刨片质量的好坏对定向结构刨花板的质量起决定性作用，澳思柏恩引进德国帕尔曼长材刨片机，通过旋切方式，将进口松木整木加工成40-100mm长、5-20mm宽、0.3-0.7mm厚的细长刨片，木材利用率高，刨片质量好，形态更均匀，性能更强韧。',
        imgurl:require('../assets/core/e7.jpg'),
      },
      {
        name:'德国帕尔曼长材刨片机',
        desc:'刨片质量的好坏对定向结构刨花板的质量起决定性作用，澳思柏恩引进德国帕尔曼长材刨片机，通过旋切方式，将进口松木整木加工成40-100mm长、5-20mm宽、0.3-0.7mm厚的细长刨片，木材利用率高，刨片质量好，形态更均匀，性能更强韧。',
        imgurl:require('../assets/core/e8.jpg'),
      },
      {
        name:'德国帕尔曼长材刨片机',
        desc:'刨片质量的好坏对定向结构刨花板的质量起决定性作用，澳思柏恩引进德国帕尔曼长材刨片机，通过旋切方式，将进口松木整木加工成40-100mm长、5-20mm宽、0.3-0.7mm厚的细长刨片，木材利用率高，刨片质量好，形态更均匀，性能更强韧。',
        imgurl:require('../assets/core/e9.jpg'),
      },
    ])

    const store = useStore()
    const baseUrl = store.state.baseUrl

    const init = async function(){
      let core_data = []
      const aInt = await ajax(baseUrl + 'web/devicelistService')
        aInt.forEach(item => {
          core_data.push({
            name:item.name,
            desc:item.particulars,
            imgurl:baseUrl + item.imgurl
          })
        });
      // if(store.state.core){
      //   let core = store.state.core
      //   core.equ.forEach(item => {
      //     core_data.push({
      //       name:item.name,
      //       desc:item.particulars,
      //       imgurl:baseUrl + item.imgurl
      //     })
      //   });
      // }else{
        
      // }
      equData.value = core_data

    }
    init();

    
    
    const skip = {
            imgurl:require('../assets/brandDetail/db.jpg'),
            url:"/contact",
            title:"对产品感兴趣<br />可以联系我们哦!"
    }
    const title = ref('title');
    const keyword = ref('keyword');
    const description = ref('description');
    onUpdated(async () => {
      let url = store.state.langPack[store.state.lang].navigationSec.core[0]
      let data = await ajax(baseUrl + `web/seoyhxqService?title=${url}`)
      title.value =  data[0].title;
      keyword.value =  data[0].keyword;
      description.value =  data[0].description;
    })

    return {
      bannerData,equData,skip,id,title,keyword,description
    }

  },
  metaInfo() {
      return {
        title: this.title, // set a title
        meta: [
          {
            // set meta
            name: "keywords",
            content: this.keyword,
          },
          {
            name: "Description",
            content: this.description,
          },
        ],
      };
    },
}
</script>

<style lang="less" scoped>
  .intel{
    background-color: #F4F4F4;
    img{
      position: absolute;
      z-index: -1;
      width: 100%;
    }
    .equs{
      width: 100%;
      margin-top: 92px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      background-color: #f4f4f4;
      .equEle{
        width: calc(33% - 4px);
        margin-top: 30px;
      }
    }
  }
@media all and (max-width:769px){
  .intel{
    .equs{
      padding: 15px;
      flex-wrap: wrap;
      box-sizing: border-box;
      margin: 0 0 0 0;
      .equEle{
        width: calc(50% - 8px);
        margin-top: 15px;
      }
    }
  }
}
@media all and (max-width:421px){
  .intel{
    .equs{
      padding: 15px;
      flex-wrap: wrap;
      box-sizing: border-box;
      margin: 0 0 0 0;
      .equEle{
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}
</style>