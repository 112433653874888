<template>
  <navigationEleVue :isActive="true"></navigationEleVue>
  <div class="advant">
    <!-- <img src="../assets/demo/202211221039.jpg" alt=""> -->
    <!-- <navEle></navEle> -->
    <div class="advant-nav">
      <div class="pic">
        <img :class="{ imgCut: imgCut }" :src="data[0].imgurl" alt="" />
        <img :class="{ imgCut: !imgCut }" :src="data[1].imgurl" alt="" />
      </div>
      <div class="cont">
        <div
          v-for="(item, index) in data"
          :key="index"
          @mouseover="setMousesmove(index)"
          :class="{
            item: true,
            wow: true,
            animate__fadeInLeft: index % 2 == 0,
            animate__fadeInRight: index % 2 != 0,
          }"
        >
          <div class="content">
            <div class="name">{{ item.name }}</div>
            <div class="icon pic"><img :src="item.icon" alt="" /></div>
            <div class="clear"></div>
            <div class="en">{{ item.en }}</div>
            <ul class="equ">
              <li v-for="(equ, index) in item.equ" :key="index">
                <div class="d"></div>
                <router-link
                  :to="'/advant/' + item.url + '/' + index"
                  class="route"
                  >{{ equ.name }}</router-link
                >
              </li>
            </ul>
            <inMoreVue :url="'/advant/' + item.url" class="imore"></inMoreVue>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navigationEleVue from "@/components/public/navigationEle.vue";
// import navEle from "../components/core/navEle.vue";
// import {  } from "@vue/reactivity";
import {ref, onUpdated,onMounted } from "vue";
import inMoreVue from "@/components/public/inMore.vue";
import ajax from "@/ajax";
import { useStore } from "vuex";
export default {
  components: { navigationEleVue,inMoreVue },
  // components: { navEle, navigationEleVue },
  setup() {
    const data = ref([
      {
        name: "智能制造",
        icon: require("@/assets/core/i1.png"),
        en: "Intelligent Manufacturing",
        imgurl: "",
        url: "intel",
        equ: [
          {
            id: "/equ",
            name: "德国帕尔曼长材刨片机",
          },
          {
            name: "布特纳气流干燥机",
          },
          {
            name: "意大利意玛帕尔三层超级筛",
          },
          {
            name: "加拿大COIL滚筒式拌胶机",
          },
          {
            name: "辛北尔康普第九代连续压机",
          },
          {
            name: "辛北配套EWS质量监控系统",
          },
          {
            name: "辛北尔康普冷却翻板系统",
          },
          {
            name: "苏福马砂光机",
          },
        ],
      },
      {
        name: "智能制造",
        icon: require("@/assets/core/i1.png"),
        en: "Resource Advantages",
        imgurl: "",
        url: "reso",
      },
    ]);
    const store = useStore();
    const baseUrl = store.state.baseUrl;
    const init = async function () {
      const aData_zz = await ajax(baseUrl + "web/intemanService");
      const aData_ys = await ajax(baseUrl + "web/resadvService");
      const aInt = await ajax(baseUrl + "web/devicelistService");
      const ays = await ajax(baseUrl + "web/advantageService ");
      let item1 = aData_zz[0];
      let item2 = aData_ys[0];
      if (store.state.lang == "en") {
        ays.forEach((item, index) => {
          ays[index].name = item.ename;
        });
      }
      data.value = [
        {
          id: item1.id,
          name: item1.name,
          icon: baseUrl + item1.icon,
          en: item1.ename,
          imgurl: baseUrl + item1.backImg,
          url: "intel",
          equ: aInt,
        },
        {
          id: item2.id,
          name: item2.name,
          icon: baseUrl + item2.icon,
          en: item2.ename,
          imgurl: baseUrl + item2.backImg,
          equ: ays,
          url: "reso",
        },
      ];
      store.commit("setCore", data.value);
    };

    init();

    const imgCut = ref(true);

    const setMousesmove = (function () {
      let daina = undefined;

      return function (index) {
        if (daina == index) {
          return;
        }
        imgCut.value = !imgCut.value;
        daina = index;
      };
    })();
    const title = ref("title");
    const keyword = ref("keyword");
    const description = ref("description");
    const getDate = async () => {
      let url = store.state.langPack[store.state.lang].navigation[2].name;
      console.log(url);
      let data = [];
      data = await ajax(baseUrl + `web/seoyhService?title=${url}`)
      console.log(data);
      title.value = data[0].title;
      keyword.value = data[0].keyword;
      description.value = data[0].description;
    }
    getDate();

    onUpdated(async () => {
      getDate();
    });
    onMounted(()=>{
        getDate();
    });

    return {
      data,
      imgCut,
      title,
      keyword,
      description,
      setMousesmove,
    };
  },
  metaInfo() {
    return {
      title: this.title, // set a title
      meta: [
        {
          // set meta
          name: "keywords",
          content: this.keyword,
        },
        {
          name: "Description",
          content: this.description,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.advant {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  & > img {
    width: 100%;
    position: absolute;
    z-index: -1;
  }
}
.advant-nav {
  width: 100%;
  height: 100vh;

  // overflow:  ;
  & > .pic {
    width: 100%;
    height: 100%;
    position: absolute;

    img {
      opacity: 0;
      transition: 0.7s;

      &.imgCut {
        opacity: 1;
      }
    }
  }

  .cont {
    display: flex;
    height: 100vh;
    cursor: pointer;

    .item {
      position: relative;
      width: 50%;
      height: 100%;
      border-left: 1px rgba(255, 255, 255, 0.5) solid;
      transition: 1s;

      .content {
        position: absolute;
        top: 71.4%;
        // top: 48.6%;
        left: 15.8%;
        transition: 0.7s;

        .name {
          color: #fff;
          font-size: 2.4rem;
          font-weight: 700;
          width: fit-content;
          float: left;
          line-height: 50px;
          letter-spacing: 2px;
          margin-right: 20px;
        }

        .icon {
          width: 50px;
          height: 50px;
          float: left;
        }

        .en {
          margin-top: 10px;
          color: #fff;
          font-size: 6rem;
          font-weight: 700;
          letter-spacing: 0;
          line-height: 1;
        }

        .equ {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-top: 46px;
          // list-style-position: inside;

          opacity: 0;
          transition: 0.7s;

          li {
            width: 50%;
            box-sizing: border-box;
            padding-left: 20px;
            list-style: none;
            position: relative;

            .d {
              color: #fff;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              position: absolute;
              top: calc(1.4rem * 1.8 / 2);
              left: 10px;
              transform: translate(-50%, -50%);
              background: #fff;
            }

            .route {
              color: #fff;
              font-size: 1.4rem;
              line-height: 1.8;
              text-transform: capitalize;
              text-transform: lowercase;
            }
          }
        }

        .imore {
          background: #00543e;
          color: #fff;
          font-size: 1.8rem;
          position: absolute;
          padding: 6px 13px;
          margin-top: 21%;

          opacity: 0;
          transition: 0.7s;
        }
      }
    }
  }
}

@media all and (max-width: 769px) {
  .advant-nav {
    .cont {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 100%;
        height: 50vh;
        border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
        overflow: hidden;

        .content {
          top: 49%;

          .en {
            font-size: 3rem;
          }

          .equ {
            display: none;
          }

          .imore {
            margin-top: 11%;
            opacity: 1;
            transition: 0.3s;
          }
        }
      }
    }
  }
}

@media all and (max-width: 376px) {
  .advant-nav {
    .cont {
      flex-wrap: wrap;

      .item {
        width: 100%;
        height: 50vh;
        border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
        overflow: hidden;

        .content {
          top: 40%;
          left: 15px;

          .en {
            font-size: 3rem;
          }

          .equ {
            display: none;
          }

          .imore {
            opacity: 1;
          }

          .imore {
            margin-top: 11%;
          }
        }
      }
    }
  }
}

@media (any-hover: hover) {
  .advant-nav {
    .cont {
      .item:hover {
        background: rgba(0, 0, 0, 0.3);

        .content {
          top: 33.6%;

          .equ {
            opacity: 1;
          }

          .imore {
            opacity: 1;
          }
        }
      }
      .item {
        .content {
          .imore:hover {
            background-color: #b9985e;
          }
        }
      }
    }
  }
}
</style>
