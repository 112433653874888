<template>
  <div style="color:#fff" class="pattern">
    <!--</Swiper>:autoplay="{delay: 3000,disableOnInteraction: false}"--> 
    <Swiper @swiper="setConts" :speed="1000"  :modules="modules" @slideChangeTransitionStart="slideChangeTransitionStart" @slideChangeTransitionEnd ="slideChangeTransitionEnd" :controller="{control:imgsSwiper}" class="conts">
        <SwiperSlide class="conts-slide" v-for="(item,index) in data" :key="index">
            <div class="icon pic wow animate__fadeInLeft">
                <img :src="item.icon" :alt="item.name">
            </div>
            <div class="name wow animate__fadeInLeft">{{item.name}}</div>
            <div class="en wow animate__fadeInLeft">{{item.en}}</div>
            <div class="claer"></div>
            <div class="desc wow animate__fadeInLeft" v-html="item.desc"></div>
        </SwiperSlide>
    </Swiper>
    <Swiper @swiper="setImgs" :modules="modules" :controller="{control:contsSwiper}" class="imgs">
        <SwiperSlide class="imgs-slide" v-for="(item,index) in data" :key="index">
            <div class="pic">
                <img :src="item.imgurl" :alt="item.name">
            </div>
        </SwiperSlide>
    </Swiper>

    <div @click="nextSlide" class="next-slide pic">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAXCAYAAAA7kX6CAAABXElEQVQ4jY3Uv0uXQRwH8Jc/SGwpIQUpaLBJbS0wEBMrgpoUHAK3Qjf/DFsaJYjAhMLB5QvhIKLSEBFBg43pVIjQUmCoEMbJPfDhy/d7fj/Tc++7F/c8d89dm6lReIg+rOBYC9WOaaxhCZu41CrsD+0RrKOnFfgKH0N2Cx/Qex48xD1shXwY27hWgqn+4hHeh77BEm4PzwlPohayAXzCjRJMdZLxu5BdzTMPlWCqf5jB6wb4ZglW+ClehuxKXu3bJZjqFHN4HrLLeZ+HOgxeL9iz2sA4qoFd2C/NWNUCRkM77XutswDa8ALzIfuD+9hpBhNaxGzIfuVT9CU1GsEOvMGTOpS+c6cK6uEFLOejVtVPjOF7HNhZh1bxOGR7eFCPIryY/9GJ0LebZ/rRaBHSdnTnUxHRN9xphir4DHdD9jUvxEEzVL1qHPA579PvEqpgutnSWUxXxVscnYfgP4SCQFxYTKq2AAAAAElFTkSuQmCC" alt="">
    </div>
    <div @click="prevSlide" class="prev-slide pic">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAXCAYAAAA7kX6CAAABTUlEQVQ4jY3Uv0sXYRwH8JcZhg2VUIPo2GS2GipU/tpqiEKFwM3QrU3/hJzaQoimqMlF8RcOGg5BIjjUqE41lC0KNghiPHAHH477nvfZnvdzr3s+99xz1+TFQzXrGsbxB2tXa6KbWEVfNh6vA9uwjp6QtV8G72AT3SH7ivdXKlAnvhTQFkZw2gjmqCtky3iCf2lQ1urdDHWEbBGjOMuD4or3StBnPI+oCO+XoA+YwHmxrRw+wDZuh7l5TJahHKb2NnAr5HOYxkUZyuEz3AhZWnm2EYgw7dhpyNLhfVMHfscQTkI+g7doqoKpvmX4b5h7jXeNcHwduxgs4Cl8RHMVlLXdi18he4lPaKmCqfbxGIchG8NCxI0OecKPcBCyp1jB9SqY6if68SNkw9lX0loFU/3ONmwvZAN4dRlMdZRdvBNvWPdndZw9c9rhIyz9B2SDPjHC6KzzAAAAAElFTkSuQmCC" alt="">
    </div>
    <div class="pagination">
        <span>{{swiperData.activeIndex > 10 ? swiperData.activeIndex + 1 : '0' + (swiperData.activeIndex + 1)}}</span>
        <div class="line">
            <div :class="{jin:true , leftSlide:actcve}"></div>
        </div>
        <span>{{swiperData.allNum > 10 ? swiperData.allNum : '0' + swiperData.allNum}}</span>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide,} from 'swiper/vue';
import { Controller , Lazy , Autoplay,Pagination} from 'swiper';
import { ref} from 'vue'
//reactive,toRefs   toRefs , toRef
import 'swiper/css';
import "swiper/css/pagination";
import { useStore } from 'vuex';
import ajax from '@/ajax';


export default {
    components:{Swiper,SwiperSlide},
    setup(){
        // 数据定义
        const data = ref([
            {
                icon:require('../../assets/about/c1.png'),
                name:"战略格局",
                en:"STRATEGIC PATTERN",
                desc:"澳思柏恩践行可持续发展理念，从研发创新、生产销售、管理服务等方面完善体系,不断为澳思柏恩战略定位和发展路径赋予新的内涵。",
                imgurl:require('../../assets/about/c1.jpg')
            },
            {
                icon:require('../../assets/about/c2.png'),
                name:"战略格局",
                en:"STRATEGIC PATTERN",
                desc:"澳思柏恩践行可持续发展理念，从研发创新、生产销售、管理服务等方面完善体系,不断为澳思柏恩战略定位和发展路径赋予新的内涵。",
                imgurl:require('../../assets/about/c2.jpg')
            },
            {
                icon:require('../../assets/about/c3.png'),
                name:"战略格局",
                en:"STRATEGIC PATTERN",
                desc:"澳思柏恩践行可持续发展理念，从研发创新、生产销售、管理服务等方面完善体系,不断为澳思柏恩战略定位和发展路径赋予新的内涵。",
                imgurl:require('../../assets/about/c3.jpg')
            },
            {
                icon:require('../../assets/about/c4.png'),
                name:"战略格局",
                en:"STRATEGIC PATTERN",
                desc:"澳思柏恩践行可持续发展理念，从研发创新、生产销售、管理服务等方面完善体系,不断为澳思柏恩战略定位和发展路径赋予新的内涵。",
                imgurl:require('../../assets/about/c4.jpg')
            },
            {
                icon:require('../../assets/about/c5.png'),
                name:"战略格局",
                en:"STRATEGIC PATTERN",
                desc:"澳思柏恩践行可持续发展理念，从研发创新、生产销售、管理服务等方面完善体系,不断为澳思柏恩战略定位和发展路径赋予新的内涵。",
                imgurl:require('../../assets/about/c5.jpg')
            },
        ])
        const contsSwiper = ref(null)
        const imgsSwiper = ref(null)
        const swiperData = ref({ allNum: data.value.length, activeIndex: 0 })

        const actcve = ref(true)
        
        const store = useStore();
        const baseUrl = store.state.baseUrl
        
        const init = async function(){
            const aData = await ajax(baseUrl + 'web/aboutstrategyService')
            let rData = [];
            aData.forEach(item=>{
                let name = item.name
                let ename = item.emname
                if(store.state.lang == 'en'){
                    ename = ''
                    name = item.emname
                }
                rData.push({
                    icon:baseUrl + item.icon,
                    name:name,
                    en:ename,
                    desc:item.particulars,
                    imgurl:baseUrl + item.imgurl,
                })
            })
            data.value = rData

        }
        init();
        /**
         * methods
         */
        //swiper
        const count = ref(0)
        const setConts = (swiper)=>{
            contsSwiper.value = swiper
            // contsSwiper.value.
        }
        const slideChangeTransitionStart = function(){
            actcve.value =false
        }
        const slideChangeTransitionEnd = function(){
            actcve.value = true
            swiperData.value.activeIndex = contsSwiper.value.activeIndex
        }
        const setImgs = (swiper)=>{
            imgsSwiper.value = swiper
        }
        // 点击切换
        const nextSlide = function(){
            contsSwiper.value.slideNext()
        }
        const prevSlide = function(){
            contsSwiper.value.slidePrev()
        }

        return {
            data,
            modules:[Autoplay,Pagination,Controller,Lazy],contsSwiper,imgsSwiper,setConts,setImgs,slideChangeTransitionEnd,slideChangeTransitionStart,actcve,
            // 方法
            nextSlide,prevSlide,swiperData,count
        }
    }
}
</script>

<style>
</style>
<style lang="less" scoped>
.pattern{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    background-color: #00543e;

    position: relative;
    .conts{
        margin: 0;
        width: 72.1%;
        // background-color: rgba(0, 0, 0, .5);
        
        flex: 1;
        .conts-slide{
            padding: 7.3% 13%;
            box-sizing: border-box;
            .icon{
                width: 61px;
                height: 61px;
                border-radius: 50%;
                border: solid 1px #b9985e;
                box-sizing: border-box;
                float: left;
                img{
                    width: 40px;
                    object-fit: contain;
                }
            }
            .name{
                float: left;
                line-height: 1;
                font-size: 3.8rem;
                width: 70%;
                margin-left: 17px;
                letter-spacing: 6px;
            }
            .en{
                float: left;
                text-transform: uppercase;
                line-height: 18.5px;
                font-size: 1.8rem;
                // width: fit-content;
                width: 70%;
                margin-left: 17px;
                margin-top: 5px;
            }
            .claer{
                clear: both;
            }
            .desc{
                margin-top: 15px;
                margin-left: 80px;
                width: 60%;
                letter-spacing: 2.9px;

                // font-size: 1.9rem;
                // line-height: 1.8;

            }
            *{
                color:#b9985e;
            }
        }
        
    }
    .imgs{
        width: calc(100% - 72.1%);
        .imgs-slide{
            width: 100%;
            height: 100%;
            .pic{
                width: 100%;
                padding-top: 91%;
            }
        }
    }
    .next-slide,.prev-slide{
        width: 60px;
        height: 60px;
        position: absolute;
        border-radius: 50%;
        left: 60%;
        z-index: 99;
        cursor: pointer;
        &:hover{
            img{
                transition: .3s;
                transform: translate(-50%,-50%) scale(1.5);
            }
        }
        img{
            width: 15%;
            object-fit: contain;
        }
    }
    .next-slide{
        
        left: 70.3%;
        top: 34.3%;
        background: #b9985e;

    }
    .prev-slide{
        left: 70.3%;
        top: 50.3%;
        background: #ffffff;

    }
    .pagination {
        position: absolute;
        top: 72%;
        left: 13.4%;
        width: 13.9%;
        display: flex;
        justify-content: space-between;

        span{
            color: #b9985e;
            font-size: 2.0rem;
            
        }
        .line{
            position: absolute;
            width: calc(100% - 2rem * 4);
            height: 2px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background: rgba(255, 255, 255 ,.5);
            overflow: hidden;
            
            .jin{
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background: #b9985e;
                transform: translateX(-100%);
                // animation-name: leftSlide;
                animation-duration: 3s;
            }
        }
    }
}

@media all and (max-width:1441px){
    .pattern{
        .conts{
            width: 72.1%;
            flex: 0 1 auto;
            .conts-slide{
                padding-left: 180px;
                .desc{
                    margin-left: 78px;
                    margin-right:0;
                    width: 100%;
                }

            }
        }
        .imgs{
            flex: 1;
            .imgs-slide {
                .pic{
                    padding-top: 0;
                    height: 100%;
                }
            }
        }
        .pagination{
            width: 200px;
            top: auto;
            bottom: 15px;
        }
    }
}
@media all and (max-width:769px){
    .pattern{
        .conts{
            width: 100%;
            .conts-slide{
                padding: 15px;
                .name[data-v-44299a3c] {
                    float: left;
                    line-height: 36px;
                    font-size: 3.1rem;
                    font-weight: 700;
                    margin-left: 17px;
                    letter-spacing: 6px;
                }
                .en{
                    width: 100%;
                    margin-left: 78px;
                    margin-top: -20px;
                }
                .desc{
                    width: auto;
                    margin: 0;
                    margin-top: 15px;
                    margin-left: 12%;
                    margin-bottom: 80px;


                    margin: 15px 0 80px 0;

                    // font-size: 1.4rem;
                }
                .pagination{
                    left: 15px;
                }
            }
        }
        
        .imgs{
            display: none;
        }
        .next-slide,.prev-slide{
            display: none;
        }
    }
}
.leftSlide  {
    animation-name: leftSlide;
}

@keyframes leftSlide{
    0%{
        transform: translate(-100%);
    }
    100%{
        transform: translate(0%);
    }
}

</style>