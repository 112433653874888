<template>
	<div v-if="ResizeValue" class="support">
		<Swiper class="back-swiper" :modules="modules" :effect="'fade'" @swiper="setBack"
			:controller="{ control: videoSwiper }">
			<SwiperSlide class="back-slide" v-for="(item, index) in data" :key="index">
				<div class="pic">
					<img :src="item.imgurl" alt="">
				</div>
				<div class="cont">
					<h1 class="name" :title="item.name">{{ item.name }}</h1>
					<h3 class="en" :title="item.en">{{ item.en }}</h3>
					<div class="clear"></div>
					<p class="desc" :title="item.desc" v-html="item.desc"></p>
				</div>
			</SwiperSlide>
		</Swiper>
		<div class="titles">
			<div :class="{ 'title-item': true, animated_back: true, wow: true }" v-for="(item, index) in data"
				@mousemove="slideTo(index)" :key="index">
				<div class="hover wow animated_top">
					<h1 class="num">{{ index + 1 > 10 ? index + 1 : "0" + (index + 1) }}</h1>
					<h1 class="en">{{ item.en }}</h1>
					<p class="p">{{ eight.eight }}</p>
				</div>
				<h1 class="name">{{ item.name }}</h1>
				<div class="add-icon"></div>
			</div>
		</div>
	</div>
	<!-- <div v-if="ResizeValue" class="support ">
		<Swiper class="back-swiper" :modules="modules" :effect="'fade'" @swiper="setBack"
			:autoplay="{ delay: 3000, disableOnInteraction: false }" :controller="{ control: videoSwiper }"
			@slideChangeTransitionStart="slideChangeTransitionStart"
			@slideChangeTransitionEnd="slideChangeTransitionEnd">
			<SwiperSlide class="back-slide" v-for="(item, index) in data" :key="index">
				<div class="pic">
					<img :src="item.imgurl" alt="">
				</div>
				<div class="cont">
					<h1 class="name" :title="item.name">{{ item.name }}</h1>
					<h3 class="en" :title="item.en">{{ item.en }}</h3>
					<div class="clear"></div>
					<p class="desc" :title="item.desc">{{ item.desc }}</p>
				</div>
			</SwiperSlide>
		</Swiper>
		<div class="pagination">
			<span>{{ swiperData.activeIndex > 10 ? swiperData.activeIndex + 1 : '0' + (swiperData.activeIndex +1)}}</span>
			<div class="line">
				<div :class="{ jin: true, leftSlide: actcve }"></div>
			</div>
			<span>{{ swiperData.allNum > 10 ? swiperData.allNum : '0' + swiperData.allNum }}</span>
		</div>
	</div> -->
	<div v-if="!ResizeValue" class="support ">
		<div class="colume">
			<div class="back-slide" v-for="(item, index) in data" :key="index">
				<div class="pic">
					<img :src="item.imgurl" alt="">
				</div>
				<div class="cont">
					<h1 class="name" :title="item.name">{{ item.name }}</h1>
					<h3 class="en" :title="item.en">{{ item.en }}</h3>
					<div class="clear"></div>
					<p class="desc" :title="item.desc" v-html="item.desc"></p>
				</div>
			</div>
		</div>
		<!-- <div class="colume">
			<div class="back-slide" v-for="(item, index) in data" :key="index">
				<div v-if="index % 2 == 1" class="pic">
					<img :src="item.imgurl" alt="">
				</div>
				<div v-if="index % 2 == 1" class="cont">
					<h1 class="name" :title="item.name">{{ item.name }}</h1>
					<h3 class="en" :title="item.en">{{ item.en }}</h3>
					<div class="clear"></div>
					<p class="desc" :title="item.desc" v-html="item.desc"></p>
				</div>
			</div>
		</div> -->
		
	</div>
</template>

<script>
import {
  onMounted,
	ref,
	onUpdated
} from 'vue';

import {
	Swiper,
	SwiperSlide
} from 'swiper/vue';
import {
	EffectFade,
	Autoplay
} from "swiper";
import {
	useStore
} from 'vuex';
import 'swiper/css'
import ajax from '@/ajax/index.js';
import { useRoute } from 'vue-router';

export default {
	components: {
		Swiper,
		SwiperSlide
	},
        
	setup() {
		const data = ref([{
			name: "物料支持",
			en: "Material support",
			desc: "公司统一的物料支持体系，从展厅建造到运营过程中所需的产品折页、产品手册、推广道具、销售道具等进行支持，降低经营成本。",
			imgurl: require('../../assets/cooperation/02.jpg'),
		},
		{
			name: "渠道支持",
			en: "Material support",
			desc: "公司统一的物料支持体系，从展厅建造到运营过程中所需的产品折页、产品手册、推广道具、销售道具等进行支持，降低经营成本。",
			imgurl: require('../../assets/cooperation/02.jpg'),
		},
		{
			name: "渠道支持",
			en: "Material support",
			desc: "公司统一的物料支持体系，从展厅建造到运营过程中所需的产品折页、产品手册、推广道具、销售道具等进行支持，降低经营成本。",
			imgurl: require('../../assets/cooperation/02.jpg'),
		},
		{
			name: "渠道支持",
			en: "Material support",
			desc: "公司统一的物料支持体系，从展厅建造到运营过程中所需的产品折页、产品手册、推广道具、销售道具等进行支持，降低经营成本。",
			imgurl: require('../../assets/cooperation/02.jpg'),
		},
		{
			name: "渠道支持",
			en: "Material support",
			desc: "公司统一的物料支持体系，从展厅建造到运营过程中所需的产品折页、产品手册、推广道具、销售道具等进行支持，降低经营成本。",
			imgurl: require('../../assets/cooperation/02.jpg'),
		},
		{
			name: "渠道支持",
			en: "Material support",
			desc: "公司统一的物料支持体系，从展厅建造到运营过程中所需的产品折页、产品手册、推广道具、销售道具等进行支持，降低经营成本。",
			imgurl: require('../../assets/cooperation/02.jpg'),
		},
		{
			name: "渠道支持",
			en: "Material support",
			desc: "公司统一的物料支持体系，从展厅建造到运营过程中所需的产品折页、产品手册、推广道具、销售道具等进行支持，降低经营成本。",
			imgurl: require('../../assets/cooperation/02.jpg'),
		},
		{
			name: "渠道支持",
			en: "Material support",
			desc: "公司统一的物料支持体系，从展厅建造到运营过程中所需的产品折页、产品手册、推广道具、销售道具等进行支持，降低经营成本。",
			imgurl: require('../../assets/cooperation/02.jpg'),
		},
		])

		const backSwiper = ref(null)
		const store = useStore()
		const setBack = function (swiper) {
			backSwiper.value = swiper
		}

		const slideTo = function (index) {
			backSwiper.value.slideTo(index)
		}
		const eight = store.state.langPack[store.state.lang].coop

		const baseUrl = store.state.baseUrl;

		ajax(baseUrl + "web/eightsupportService").then(res => {
			let result = res;
			let eightsupport = [];

			

			result.forEach(item => {
				let name = item.headline
				let enname = item.enHeadline 
				if(store.state.lang == 'en'){
					name = item.enHeadline 
					enname = ""
				}
				eightsupport.push({
					name: name,
					en: enname,
					desc: item.brief,
					imgurl: baseUrl + item.bakImg
				});

			});
			data.value = eightsupport;
		});



		//  手机端分页器 
		const swiperData = ref({
			allNum: data.value.length,
			activeIndex: 0
		})
		const actcve = ref(true)
		const slideChangeTransitionStart = function () {
			actcve.value = false
		}
		const slideChangeTransitionEnd = function () {
			actcve.value = true
			swiperData.value.activeIndex = backSwiper.value.activeIndex
		}
		// 监听屏幕宽度变化
		/**
		 * ResizeValue ==> true ==> 大于769
		 *                ==> false => 小于769
		 */
		const ResizeValue = ref(document.body.clientWidth > 769)
		window.addEventListener('resize', () => {
			if (document.body.clientWidth < 769) {
				ResizeValue.value = false
			} else {
				ResizeValue.value = true
			}
		})
		const route = useRoute();
		const titleName = ref('title');
        const keyword = ref('keyword');
        const description = ref('description');
		onMounted(()=>{
			setTimeout(()=>{
				if(route.params.id == '0'){	
					document.querySelector(".support").scrollIntoView({behavior: "smooth"})
				}
			},0)
		}),
		
        
        onUpdated(async() => {
			let url = store.state.langPack[store.state.lang].navigationSec.coop[route.params.id]
            let data = []
            if(route.params.id){
              data = await ajax(baseUrl + `web/seoyhxqService?title=${url}`);
            }else{
              url = store.state.langPack[store.state.lang].navigation[4].name
              data = await ajax(baseUrl + `web/seoyhService?title=${url}`)
            }
            titleName.value =  data[0].title;
            keyword.value =  data[0].keyword;
            description.value =  data[0].description;
        })

		return {
			modules: [EffectFade, Autoplay],
			setBack,
			slideTo,
			slideChangeTransitionStart,
			slideChangeTransitionEnd,
			swiperData,
			actcve,
			data,
			ResizeValue,
			eight,
			titleName,
			keyword,
			description
		}
	},
	metaInfo() {
    return {
      title: this.titleName, // set a title
      meta: [
        {
          // set meta
          name: "keywords",
          content: this.keyword,
        },
        {
          name: "Description",
          content: this.description,
        },
      ],
    };
  },
}
</script>

<style lang="less" scoped>
.support {
	width: 100%;
	height: 95.3vh;
	position: relative;

	.back-swiper {

		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;

		.back-slide {
			width: 100%;
			height: 100%;
			position: relative;

			.pic {
				width: 100%;
				height: 100%;
			}

			.cont {
				position: absolute;
				top: 0%;
				left: 0%;
				top: 36.2%;
				left: 12.7%;
				color: #fff;

				.name {
					font-size: 3.6rem;
					font-weight: 500;
					letter-spacing: 3px;
					float: left;
				}

				.en {
					font-size: 1.6rem;
					text-transform: uppercase;
					font-weight: 400;
					width: 100px;
					float: left;
					margin-top: 7px;
					margin-left: 7px;
				}

				.desc {
					margin-top: 30px;
					width: 35%;
					line-height: 1.6;

					// font-size: 1.8rem;

				}
			}
		}
	}

	.titles {
		width: 52.6%;
		height: 100%;
		display: flex;
		float: right;
		flex-wrap: wrap;
		border-right: rgba(255, 255, 255, 0) solid 1px;
		border-left: rgba(255, 255, 255, 0.527) solid 1px;

		.title-item {
			width: 25%;
			height: 50%;
			color: #fff;
			font-weight: normal;
			box-sizing: border-box;
			padding-left: 30px;
			position: relative;
			// opacity: 0;
			background: transparent;
			border-right: rgba(255, 255, 255, 0.527) solid 1px;
			border-top: rgba(255, 255, 255, 0.527) solid 1px;

			&:nth-child(1) {
				border-top: rgba(255, 255, 255, 0) solid 1px;
			}

			&:nth-child(3) {
				border-top: rgba(255, 255, 255, 0) solid 1px;
			}

			&:nth-child(2) {
				border-top: rgba(255, 255, 255, 0) solid 1px;
			}

			&:nth-child(4) {
				border-top: rgba(255, 255, 255, 0) solid 1px;
			}

			.hover {
				position: absolute;
				top: 60%;
				transition: .7s;

				.num {
					font-size: 3rem;
					font-weight: 400;

				}

				.en {
					font-size: 1.8rem;
					opacity: 0;
					transition: .7s;
					text-transform: uppercase;
					font-weight: 400;
					width: 100px;
					margin: 15px 0 0px 0;
				}

				.p {
					font-size: 1.8rem;
					transition: .7s;
					opacity: 0;

				}
			}

			.name {
				font-size: 3rem;
				position: absolute;
				bottom: 15%;
				font-weight: 400;
				transition: .7s;
				width: 80%;

			}

			.add-icon {
				position: relative;
				width: 25px;
				height: 25px;
				position: absolute;
				left: 16%;
				top: 89%;

				// transform: translate(-50%,-50%);
				&::before {
					content: "";
					display: block;
					width: 25px;
					height: 2px;
					// background: #fff;
					background: rgba(255, 255, 255, 0.527);

					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}

				&::after {
					content: "";
					display: block;
					height: 25px;
					width: 2px;
					// background: #fff;
					background: rgba(255, 255, 255, 0.527);

					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
}

@media (any-hover:hover) {
	.support {
		.titles {
			.title-item:hover {
				background: rgba(185, 152, 94, 0.7) !important;

				.hover {
					top: 22%;

					.en {
						opacity: 1;
					}

					.p {
						opacity: 1;

					}
				}

				.name {
					bottom: 23%;
				}

				.add-icon {
					&::after {
						opacity: 0;
					}
				}
			}
		}


	}
}

@media all and (max-width:1200px) {
	.support {
		.titles {
			.title-item {
				padding-left: 15px;

				.add-icon {
					left: 15px;
				}
			}
		}
	}
}

@media all and (max-width:989px) {
	.support {
		.titles {
			.title-item {
				.name {
					font-size: 2.2rem;
				}
			}
		}
	}
}

@media all and (max-width:769px) {
	.support {
		padding: 30px 15px;
		box-sizing: border-box;
		display: flex;
		position: relative;
		justify-content: space-between;
		height: auto;
		.colume{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.back-slide{
				width: calc(50% - 8px);
				position: static;
				// margin: 15px 0;
				margin-top: 15px;
				.pic{
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: -9;
				}
				.cont{
					width: 100%;
					padding: 15px;
					box-sizing: border-box;
					background-color: rgba(255, 255, 255, 0.527);
					border-radius: 8px;
					.name{
						font-size: 2.4rem;
					}
					.en{
						font-size: 1.8rem;
					}
					.desc{
						margin-top: 15px;
						//超出两行省略号
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						line-clamp: 7;
						-webkit-line-clamp: 7; //显示几行
						height: calc(1.4rem * 12);
					}
				}
			}
		}
		

		.pagination {
			position: absolute;
			top: 72%;
			left: 50%;
			transform: translate(-50%);
			width: 250px;
			display: flex;
			justify-content: space-between;

			span {
				color: #b9985e;
				font-size: 2.0rem;

			}

			.line {
				position: absolute;
				width: calc(100% - 2rem * 4);
				height: 2px;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				background: rgb(0, 0, 0);
				overflow: hidden;

				.jin {
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					background: #b9985e;
					transform: translateX(-100%);
					// animation-name: leftSlide;
					animation-duration: 3s;
				}
			}
		}
	}
}
@media all and (max-width:400px) {
	.support {
		flex-wrap: wrap;
		.colume{
			width: 100%;
			.back-slide{
				width: 100%;
				.cont{
					.desc{
						text-align: left;
					}
				}
			}
		}
		.colume:last-child{
			padding-top: 0;
			margin-top: -15px;
		}
	}
}

.leftSlide {
	animation-name: leftSlide;
}

@keyframes leftSlide {
	0% {
		transform: translate(-100%);
	}

	100% {
		transform: translate(0%);
	}
}

.animated_back {
	animation-name: animated_back;
}

@keyframes animated_back {
	0% {
		background: rgba(185, 152, 94, .7);
	}

	100% {
		background: rgba(185, 152, 94, 0);
	}
}

.animated_top {
	animation-name: animated_top;
}

@keyframes animated_top {
	0% {
		transform: translateY(-80%);
	}

	100% {
		transform: translate(0%);
	}
}
</style>
