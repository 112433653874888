<template>
    <div class="core">
        <mapTiltleEleVue :item="titleData" class="mapTiltleEleVue"></mapTiltleEleVue>
        <div class="content">
            <div class="cont">
                <h1 v-if="coreData.name" class="name wow animate__fadeInUp">{{ coreData.name }}</h1>
                <h2 v-if="coreData.solgin" class="solgin wow animate__fadeInUp">{{ coreData.solgin }}</h2>
                <!-- v-html="coreData.name_solgin" -->
                <div v-if="coreData.name_solgin" class="desc" v-html="coreData.name_solgin">
                </div>

                <ul :class="{merits:true,lang:coreData.cores.lang}">
                    <li v-for="(merit, index) in coreData.cores.merit" :key="index" :class="{merit:true,wow:true,animate__fadeInUp:true}">
                        <div class="icon pic"><img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAE1UlEQVRYhb3ZW4hVVRzH8c85akoXg0qkojezy1ijWKg9dLpIUDRFMYfsghBRWZJQD5XYBYusHqLCsDtRUSHblzKwzLL9kg5laRcq80GIisiIJsO8TLuHtbaz53TOmXNmzswPhj17r73+/+/Ze+21/v//Kuk93wh1BBbgQpyNmTgRR8f2vfgFO/ElNmMLDhSNZEnakrOJIwCcgyXoxXFN7jsu/nXhKjyIP5DgOXzRjtN2QLuxCpeiFK99jQ+FJ/UdfhSeJOHJnoLTcB4uxizcgpvxHpZjRyvOSy28+ikR8A7hh/2NF/AKvmrFSUFn4cYIexQO4Rksz5L0n9GAzhBe1WwMYA0ewp42AWt1vDAUbscEbEc1S9JdjTqUmxg7F59EyB+ED2dZByDh92hrfrQ9G1tL1cq8dkHn4SNMwwacg087AFirz6LtDcJT3tQIth7oDKwXPoa1uBL9YwAJsiTtjz7WRp/rS9XKjOFApwhjclqEXYyDYwWZK0vSg9HX+ug7KVUrU5qBrhLGyy7coGZyHktlSXog+twVGR4pthdBu4UpaACLjOHrbqQ4DBZFhmWlauXsvK0IukqYJ5/FtnFiK5WqlYeLYzJL0m3CNDgxMg0BnSOsOHuxcrwgI9B9+LjmA3ooslxWqlbmFEGXxo4v6cw82Srkknh+chE2S9I9eDHetzQHnSwEGPDyOEBOEIKSJTXXT8bmUrWSR185S2+pWplcFib3Y/GNEGSMNeRrwlpfq3+xMkvSvZAlac5zLOaVcVG88YNxgryuAeTiLElfqrm+KR4vKgvTEmwdGz60BvlGnbacqbssRObwbQsO86i+HR0hrHbtQhaZZpYxPZ783ILDdUhxRRuQ64QIv1YDw0DCT/E4vYxj4slfLTjswaT4//UtQvbUaTuIq9EMssh0THFlytpwOEkYc4sb9DlyGMhevDMM5BCVDVJPbXJfvTShjFdxWx3IdzsEefhtl/FrPDmpwc0HcC3ebNC+BnfVQF7YAUjCIgC/ThTy7jNxhpB/19OAwddc7+t9Qgh6L2gAuU/4oN5vAxJOj8edZYPp6vxhOg0I8eJzDdpXNoG8fASQRaYdZSE3goUtdMyEzLERbK1yyI+Gu7GBcqbNZfThT6E40NVC5xz2qbGELFUrXUIdoB9by9gvTCVwU4t2MtyJxxu0948GsoYlyZJ0fz6PronHm3FCG8bu9X/YflxiFJClauWEyELIOA4Hzp9jo/DlPtCm3XuFGlIRsm+kkFH3R5aNMTUZkjPdI9SCbsfcNg0/Jsylo4YsVStzhaj+EO7OrxdBt2O1EJK9pflKVU9PdgByavQ9AauzJD1c6avN61cIwKfidWFNHxeVqpVJ0eepwty+otheC7oPVfwmhHKvGQfYAuQV0XdvlqT7moESKhU9Qh10Ed7W/jBoB3Jq9HFN9NlTr/zYqJrXJ1SIfxfy/bzq1mmdE21fGn1dnCVp3XHerD7aJ6Qd+ZjdgqeF8uBodXy0tSXa3o7zGkEOB8pgATdfLpdhtxAtzRoBYFfsuzvaEm0vyJJ0Z7OOrdTwc3XjUeE15fpaSGm34Hths+Fw+iBsNswUNhsWGvrjNgi1+45tNtSq1e2besq3b54XVsMx3Wf6ArcKJcrihthp6m+Ifa/Jhlir+g/9QlhyjNRbRQAAAABJRU5ErkJggg=="
                                alt=""></div>
                        <p>{{ merit }}</p>
                        <div class="claer"></div>
                    </li>
                </ul>

            </div>

            <Swiper @swiper="setImgs" class="imgs-swiper">
                <SwiperSlide class="imgs-slide" v-for="(item, index) in coreData.imgs" :key="index">
                    <div class="pic">
                        <img :src="item" alt="">
                    </div>
                </SwiperSlide>
                <div class="next">
                    <swiperButtonEle @click="nextSlide" class="next-slide" type="next"></swiperButtonEle>
                </div>
                <div class="prev">
                    <swiperButtonEle @click="prevSlide" class="prev-slide" type="prev"></swiperButtonEle>
                </div>
            </Swiper>
        </div>
    </div>
</template>

<script>

import mapTiltleEleVue from './mapTiltleEle.vue'
import swiperButtonEle from '../public/swiperButtonEle.vue'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref, onUpdated } from 'vue'

export default {
    props: ["id", 'item'],
    components: {
        Swiper, SwiperSlide,
        mapTiltleEleVue, swiperButtonEle
    },
    setup(props) {
        // 标题
        const titleData = {
            en: "Core advantages",
            cn: "核心优势"
        }

        const coreData = ref({
            name: "",
            solgin: "",
            cores: "",
            imgs: ""
        })

        onUpdated(() => {
            if (props.item) {
                coreData.value = props.item
            }
        })
        const imgsSwiper = ref(null)

        // swiper 双向绑定
        const setImgs = swiper => {
            imgsSwiper.value = swiper
        }
        /**
         *  nextSlide
         *  轮播图下一张
         */
        const nextSlide = function () {
            imgsSwiper.value.slideNext();
        }
        const prevSlide = function () {
            imgsSwiper.value.slidePrev();
        }

        return {
            titleData, coreData,
            setImgs, nextSlide, prevSlide
        }
    }
}
</script>

<style lang="less">
.core {
    .content {
        .cont {
            .name {
                font-size: 4rem;
                color: #00543E;
                letter-spacing: 2px;
            }

            .solgin {
                font-size: 2.8rem;
                font-weight: 500;
                line-height: 2;
                color: #00543E;
            }
        }
    }
}
</style>
<style lang="less" scoped>
.core {
    .mapTiltleEleVue {
        color: #666;
        margin-bottom: 30px;
    }

    .content {
        display: flex;
        justify-content: flex-end;
        position: relative;
        background: #b9985e;

        .cont {
            flex: 1;
            box-sizing: border-box;
            padding: 6% 3% 3% 3%;

            .name {
                font-size: 4rem;
                color: #00543E;
                letter-spacing: 2px;
            }

            .solgin {
                font-size: 2.8rem;
                font-weight: 500;
                line-height: 2;
                color: #00543E;
            }

            .merits {
                list-style: none;
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 75px;
                margin-bottom: 100px;
                

                .merit {
                    color: fff;
                    width: 50%;
                    padding: 24px 0;
                    white-space: nowrap;
                    &.lang{
                        width: 100%;
                    }
                    .icon {
                        width: 22px;
                        height: calc(2.6rem * 1.2);
                        float: left;
                        margin-right: 10px;
                        margin-left: 7px;

                        // margin-top: 6px;

                        img {
                            object-fit: contain;
                            height: auto;
                        }
                    }

                    p {
                        width: 86%;
                        float: left;
                        color: #00543E;
                        font-size: 2.6rem;
                        letter-spacing: 3px;
                        font-weight: 500;
                        line-height: 1.2;
                        white-space: break-spaces;
                    }
                }
                &.lang{
                    .merit {
                        width: 100%;
                    }
                }
            }
        }

        .imgs-swiper {
            width: 56.6%;
            margin: 0 0 0 0;
            position: relative;
            &:hover{
                .pic{
                    img{
                        transform: translate(-50%,-50%) scale(1.1);
                    }
                }
            }
            .pic {
                // padding-top: 78.9%;
                height: 100%;
            }
        }

        .next {
            position: absolute;
            width: 50%;
            height: 100%;
            right: 0;
            top: 0;
            z-index: 99;
            cursor: pointer;

            .next-slide {
                width: 60px;
                height: 60px;
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                transition: opacity .7s;
            }

            &:hover {
                .next-slide {
                    opacity: 1;
                }
            }
        }

        .prev {
            position: absolute;
            width: 50%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 99;
            cursor: pointer;

            .prev-slide {
                width: 60px;
                height: 60px;
                position: absolute;
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                transition: opacity .7s;
            }

            &:hover {
                .prev-slide {
                    opacity: 1;
                }
            }
        }

    }
}

@media all and (max-width:1670px) {
    .core {
        .content {
            .cont {
                .merits {
                    .merit {
                        // width: fit-content;
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
<style lang="less">
@media all and (max-width:769px) {
    .core {
        .mapTiltleEleVue {
            color: #666;
            margin-bottom: 15px;
        }
        .content {
            flex-wrap: wrap;
            .cont {
                width: 100%;
                .name {
                    font-size: 2.2rem;
                }
                .solgin{
                    font-size: 2.0rem;
                }
                .merits{
                    margin-top: 15px !important;
                    .merit{
                        padding: 12px 0 !important;
                        .icon{
                            width: 1.4rem !important;
                            height: 1.4rem !important;
                            margin: 2px 10px 0 0 !important;
                        }
                        p{
                            font-size: 1.4rem !important;
                        }
                    }
                }
            }
            .imgs-swiper {
                // width: 100%;
                display: none;
            }
        }
    }
}
</style>