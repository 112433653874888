<template>
    <div class="duty">
        <div class="title wow animate__fadeIn" v-html="title">
        </div>
        <div class="control wow animate__fadeIn">
            <!-- <div class="pagination">
                <span>{{swiperDataPage.activeIndex > 10 ? swiperDataPage.activeIndex + 1 : '0' + (swiperDataPage.activeIndex + 1)}}</span>
                <div class="line">
                    <div :class="{jin:true , leftSlide:actcve}"></div>
                </div>
                <span>{{swiperDataPage.allNum > 10 ? swiperDataPage.allNum : '0' + swiperDataPage.allNum}}</span>
            </div> -->
            <swiperButtonEleVue type="prev" class="prev" @click="prevSlide"></swiperButtonEleVue>
            <swiperButtonEleVue type="next" @click="nextSlide"></swiperButtonEleVue>
        </div>
        
        <Swiper :slidesPerView="'auto'" @swiper="setDuty" @slideChangeTransitionStart="slideChangeTransitionStart" @slideChangeTransitionEnd="slideChangeTransitionEnd" :spaceBetween="25" class="duty-swiper wow animate__fadeIn">
            <SwiperSlide class="duty-slide" v-for="(item,index) in swiperData" :key="index" @click="setAlertData(index)" >
                <div class="cont">
                    <div class="pic">
                        <img :src="item.imgurl">
                        <div class="more">
                            <inMore class="inmore"></inMore>
                        </div>
                    </div>
                    <div class="contont">
                        <div class="title">{{item.title}}</div>
                        <div class="desc">{{item.desc}}</div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>

        <div :class="{alert:true,active:alertData.show}">
            <Swiper @swiper="setAlertSwiper" class="alert-swiper">
                <SwiperSlide class="alert-slide" v-on:click.stop="closeAlertData($event)" v-for="(item,index) in alertData.datas" :key="index">
                    <!-- <div class="pic"> -->
                        <img :src="item.imgurl" alt="">
                    <!-- </div> -->
                </SwiperSlide>
                
            </Swiper>
            <swiperButtonEleVue @click="alertNext" class="alert-next" type="next"></swiperButtonEleVue>
            <swiperButtonEleVue @click="alertPrev" class="alert-prev" type="prev"></swiperButtonEleVue>
    </div>
    </div>
    
</template>

<script>

import { ref} from 'vue';
import { Swiper, SwiperSlide} from 'swiper/vue';
import { EffectFade } from 'swiper';
import "swiper/css/effect-fade";

import inMore from '../public/inMore.vue'
import swiperButtonEleVue from '../public/swiperButtonEle.vue';
import ajax from '@/ajax';
import { useStore } from 'vuex';


export default {
    components:{Swiper, SwiperSlide,inMore,swiperButtonEleVue},
    setup() {
        /**
         * 数据定义
         */
            
        const title = ref("澳思柏恩人将以一颗感恩的心回报社会，<br />为中国走出一条真正的<br />幸福道路贡献和汇集我们的力量。<br />")
        const swiperData = ref([
            {
                title:"同舟共济·共克时艰",
                desc:"千年舟集团6000余张板材援建方舱医院",
                imgurl:require('../../assets/about/1.jpg'),
                imgs:[require('../../assets/about/1.jpg'),require('../../assets/about/1.png'),require('../../assets/about/1.jpeg'),require('../../assets/about/2.jpg')]
            },
            {
                title:"同舟共济·共克时艰",
                desc:"千年舟集团6000余张板材援建方舱医院",
                imgurl:require('../../assets/about/1.jpg'),
                imgs:[
                require('../../assets/about/1.jpg'),require('../../assets/about/1.png'),require('../../assets/about/1.jpeg'),require('../../assets/about/2.jpg')
                ]
            },
            {
                title:"同舟共济·共克时艰",
                desc:"千年舟集团6000余张板材援建方舱医院",
                imgurl:require('../../assets/about/1.jpg'),
                imgs:[
                require('../../assets/about/1.jpg'),require('../../assets/about/1.png'),require('../../assets/about/1.jpeg'),require('../../assets/about/2.jpg')
                ]
            },
            {
                title:"同舟共济·共克时艰",
                desc:"千年舟集团6000余张板材援建方舱医院",
                imgurl:require('../../assets/about/1.jpg'),
                imgs:[
                require('../../assets/about/1.jpg'),require('../../assets/about/1.png'),require('../../assets/about/1.jpeg'),require('../../assets/about/2.jpg')
                ]
            },
            
        ])
        const store = useStore();
        const baseUrl = store.state.baseUrl
        const swiperDataPage = ref({ allNum: swiperData.value.length, activeIndex: 0 })
        const init = async function(){
            // 上方标题
            const aTitle = await ajax(baseUrl + 'web/aboutsocialService')
            // 列表
            const aSwiperData = await ajax(baseUrl + 'web/aboutsociallistService')
            title.value = aTitle[aTitle.length-1].particulars
            let rSwiperData = []
            for(let i=0;i<aSwiperData.length;i++){
                let item = aSwiperData[i]
                // 图片集
                const aSwiperData_imgs = await ajax(baseUrl + 'web/aboutsociallistimgService',{aid:item.id})
                // 所有图片集加前缀
                aSwiperData_imgs.forEach(item=>{item.imgurl = baseUrl + item.imgurl })
                
                
                rSwiperData.push({
                    title:item.title,
                    desc:item.brief,
                    imgurl:baseUrl + item.imgurl,
                    imgs:aSwiperData_imgs
                }) 
            }
            swiperData.value = rSwiperData
            swiperDataPage.value.allNum = swiperData.value.length
        }

        init();

        const alertData = ref({
            show:false,
            datas:[]
        })
        const oriSwiper = ref(null)
        const alertSwiper = ref(null)
        const setAlertSwiper = (swiper)=>{
            alertSwiper.value = swiper
        }
        const alertNext = function(){
            alertSwiper.value.slideNext();
        }
        const alertPrev = function(){
            alertSwiper.value.slidePrev();
        }
        /**
         * methods
         */
        const setSwiper = swiper=>{
            oriSwiper.value = swiper
        }
        const setMouserSwiper = function(index){
            oriSwiper.value.slideTo(index)
        }

        const setAlertData = function(index){
            alertData.value.show = true;
            alertData.value.datas = swiperData.value[index].imgs
        }
        const closeAlertData = function(e){
            if(e.currentTarget  ==e.target){
                alertData.value.show = false;
            }            
        }
        // 获取swipe
        const dutySwiper = ref(null)

        const setDuty = swiper=>{
            dutySwiper.value = swiper
        }

        // page 切换
        
        const actcve = ref(true)

        const slideChangeTransitionStart = function(){
            actcve.value =false
        }
        const slideChangeTransitionEnd = function(){
            actcve.value = true
            swiperDataPage.value.activeIndex = dutySwiper.value.activeIndex
        }

        // 点击切换
        const nextSlide = function(){
            dutySwiper.value.slideNext()
        }
        const prevSlide = function(){
            dutySwiper.value.slidePrev()
        }

        return {
            swiperData,title,
            // swiper
            modules:[EffectFade],setSwiper,setMouserSwiper,alertData,setAlertData,closeAlertData,setDuty,alertSwiper,
            // page切换页
            slideChangeTransitionStart,slideChangeTransitionEnd,nextSlide,prevSlide,swiperDataPage,setAlertSwiper,alertNext,alertPrev
        }
    }
}
</script>
<style lang="less">

.duty{
    .inmore{
        a{
            color: #fff;
            svg{
                color: #fff;
            }
        }
        
    }
}
</style>
<style lang="less" scoped>
.duty{
    position: relative;
    // z-index: 9;
    box-sizing: border-box;
    padding-left: 180px;
    padding-top: 130px;
    padding-bottom: 164px;
    background-image: url(../../assets/about/db1.jpg);
    background-size: 100%;
    .title{
        font-size: 2.6rem;
        color: rgb(0, 84, 61);
        // font-weight: 700;
        letter-spacing: 3.3px;
        line-height: 1.5;
        width: 31%;
    }
    .duty-swiper{
        margin-top: 60px;
        .duty-slide{
            cursor:pointer;
            width: 32.4%;
            .cont{
                width: 100%;
                .pic{
                    width: 100%;
                    padding-top: 51%;
                    .more{
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-color: rgba(0, 0, 0, .5);
                        pointer-events: none;
                        opacity: 0;
                        transition: 1s;
                        .inmore{
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            margin: 0 0 0 0;
                            border: none;
                            color: #fff;
                        }
                    }
                }
                .contont{
                    box-sizing: border-box;
                    padding: 24px 31px 30px 37px; 
                    background-color: rgba(0, 84, 61, 1);
                    .title{
                        font-size: 2.8rem;
                        color: #fff;
                        font-weight: 500;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;

                    }
                    .desc{
                        letter-spacing: 1px;
                        color: #fff;
                        height: calc(2.0rem * 2 * 2);
                        width: 100%;

                        display:-webkit-box;				/*弹性伸缩盒子*/
                        -webkit-box-orient:vertical;		/*子元素垂直排列*/
                        // white-space:nowrap;					/*溢出不换行*/
                        line-clamp:2;
                        -webkit-line-clamp:2;				/*显示的行数*/
                        overflow:hidden;					/*溢出部分隐藏*/
                        text-overflow:ellipsis ;

                        // font-size: 2.0rem;
                        // line-height: 2;


                    }
                }
            }
        }

    }
    
    
}
@media (any-hover:hover){
    .duty{
        .duty-swiper{
            .duty-slide:hover{
                .cont{
                    .pic{
                        .more{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
@media all and (max-width:1200px){
    .duty{
        .duty-swiper {
            .duty-slide {
                width: 49%;
            }}}
}
@media all and (max-width:769px){
    .duty{
        padding: 15px;
        .title{
            width: 100%;
        
        }
        .duty-swiper {
            .duty-slide {
                .cont {
                    .contont{

                    }
                }
            }
        }
    }
}

.control{
    display: flex;
    align-items: center;
    position: absolute;
    top: 15%;
    right: 5%;
    .pagination {
        position: relative;
        // top: 15%;
        // right: 10%;
        width: 250px;
        display: flex;
        justify-content: space-between;

        span{
            color: #00543e;
            font-size: 2.0rem;
            
        }
        .line{
            position: absolute;
            width: calc(100% - 2rem * 4);
            height: 2px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background: rgb(255, 255, 255);
            overflow: hidden;
            
            .jin{
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background: #00543e;
                transform: translateX(-100%);
                // animation-name: leftSlide;
                animation-duration: 3s;
            }
        }
    }
    .prev{
        margin: 0 15px;
    }
}

@media all and (max-width:769px){
    .control{
        display: none;
    }
    .duty{
        .title{
            font-size: 1.9rem;
        }
        .duty-swiper {
            .duty-slide {
                width: 100%;
                .cont {
                    .contont{
                        padding: 15px;

                        .title{
                            font-size: 1.8rem;
                        }
                        .desc{
                            height: calc(1.4rem * 2);
                            overflow: hidden;
                            text-overflow: ellipsis;  /* 超出部分省略号 */
                            word-break: break-all;  /* break-all(允许在单词内换行。) */  
                            display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
                            -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
                            -webkit-line-clamp: 1; /* 显示的行数 */

                            // font-size: 1.4rem;

                        }
                    }
                }
            }
        }
    }
}

.alert{
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: rgba(0, 0, 0, .7);
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        pointer-events: none;
        opacity: 0;

        .alert-next,alert-prev{
            top: 50%;
            transform: translateY(-50%);
        }
        .alert-next{
            position: absolute;

            right: 30px;

        }
        .alert-prev{
            position: absolute;

            // right: 30px;
            left: 30px;

        }

        &.active{
            pointer-events: auto;
            opacity: 1;
            transition: 1s;

        }
        .alert-swiper{
            width: 100%;
            height: 100%;
            border-radius: 20px;
            position: relative;
            

            .alert-slide{
                width: 100%;
                height: 100%;
                // .pic{
                //     width: 100%;
                //     height: 100%;
                // }
                position: relative;
                img{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: auto;
                    height: 80%;
                }
            }
        }
    }

</style>