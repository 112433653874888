<template>
    <div class="banner" >
        <div class="pic" v-if="bannerData" :style="{'background-image':'url('+bannerData.imgurl+')'}"></div>
        <div v-if="bannerData" class="cont">
    
            <div v-if="!bannerData.about" class="title-cn wow animate__fadeInLeft">{{bannerData.titleCn}}</div>
            <div v-if="!bannerData.about" class="title-en wow animate__fadeInLeft">{{bannerData.titleEn}}</div>
            <div v-if="!bannerData.about" class="desc wow animate__fadeInLeft">{{bannerData.desc}}</div>

            <div v-if="bannerData.about" class="desc wow animate__fadeInLeft" v-html="bannerData.desc" ></div>
            <div class="bottom-slide">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAASCAYAAACuLnWgAAABnElEQVQ4ja3UT4iOURQG8PMNplEThWJhw5RMSkrZIAsNK3bYWNBsLGRno0ZZTKFEygZZKRuxUxaKlLKyESWpWVBmSkpNxoefxb1vvXN7v39jbt26nec599xz7nNOCxER1yLiQkQsxPKuAxGxMbABP/Eyn2OZ9mks4Epl2IdZfMKO/7x8CFeldRfDdXAL3uI7Di8xwCge4zfOV/aStAZPMunsgAE24w1+4EgdayKvwI2c7i2s7CPAHnzBDHaWeHWYaHA8g194irVdAhzDPF5hU4Gtx+5KXX9xCa2CdBDf8A5jBdbCVPZ9gJEC346PuF6RL9bIqwvyNnzAHPZn2wjuZ5+phsdN5Me9x1gdOJHTft2Q9jo8k3R/LpdmHsc7lLldL3O3D9xVYKtwW1qfM7cUzM2MLxLMQFLMezJzmqTf1iD9bk31SNFUHXa9iQ81cbo5D+FyTv9OLlfJ2YuvkorGO93Vq8kCp6QB+lwSQGU/me0v9Bis/QQJaYDOSVIex7Qk33sY7uXfb5DA1lz7Nv708VdLChKS7h/i6CB+/wBFRFARMKo3nAAAAABJRU5ErkJggg==" alt="">
            </div>
        </div>
        
        <div class="video wow animate__fadeIn" v-if="bannerData && bannerData.video.url">
            <video :src="bannerData.video.url" ref="video"></video>
            <div class="pic">
                <img :src="bannerData.video.cover" alt="">
                <swiperButtonEleVue class="swiperButtonEleVue-play" @click="videoFn.play($event)" type="play"></swiperButtonEleVue>
            </div>
            <div class="pause">
                <swiperButtonEleVue class="swiperButtonEleVue-pause" @click="videoFn.pause($event)" type="stop"></swiperButtonEleVue>
                <div v-on:click.stop="videoFn.allScreen" class="full-screen">

                </div>
            </div>
            <!-- <div class="play-video" @click="setVideo({videoUrl:'/'})">
                <i class="fa fa-play" aria-hidden="true"></i>
            </div> -->
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import swiperButtonEleVue from './swiperButtonEle.vue'
import { useStore } from 'vuex'
import ajax from '@/ajax'
export default {
    components:{swiperButtonEleVue},
    props:["id",'item'],
    setup(props){
        const store = useStore()
        const baseUrl = store.state.baseUrl
        const bannerData = ref(null)
        // 获取banner 数据
        // const aTitle = await 
        if(props.id == 24){
            ajax(baseUrl + 'web/aboutbannerService').then(result=>{
                let item = result[result.length - 1]
                bannerData.value= {
                    titleCn:item.name,
                    titleEn:item.particulars,
                    desc:item.particularsen,
                    imgurl:baseUrl + item.imgurl,
                    video:{
                        cover:item.vidImg ? baseUrl + item.vidImg :item.vidImg,
                        url:item.video ? baseUrl + item.video :item.video
                    }
                }
            })
        }else{
            // web/aboutbannerService
            ajax(baseUrl + 'web/insidepagebannerService',{type:props.id}).then(result=>{
                let item = result[result.length - 1]
                bannerData.value= {
                    titleCn:item.name,
                    titleEn:item.ename,
                    desc:item.brief,
                    imgurl:baseUrl + item.banImg,
                    video:{
                        cover:item.vidimg ? baseUrl + item.vidimg :item.vidimg,
                        url:item.videofide ? baseUrl + item.videofide :item.videofide
                    }
                }
            })
        }
        
        
        // {
        //     "titleCn": "联系我们",
        //     "titleEn": "Contact Us",
        //     "desc": "定义高端制造的生活方式",
        //     "imgurl": "img/02.a2747496.jpg",
        //     "video": {
        //         "cover": "require('../assets/about/video.jpg')",
        //         "url": ""
        //     }
        // }
        
        // 视频
        // (播放 或 暂停)视频
        const video = ref(null)
        const videoFn = (function(){
           let state = {}

            return {
                play:function(e){
                    if(!state || !state.state){
                        state = {
                            state:true,
                            playEle:{
                                btn:e.currentTarget,
                                img:e.currentTarget.parentElement
                            },
                        }
                    }



                    state.playEle.btn.style.opacity = 0
                    state.playEle.img.style.opacity = 0
                    state.playEle.btn.style.pointerEvents = 'none'
                    state.playEle.img.style.pointerEvents = 'none'

                    video.value.play();
                },
                pause:function(e){
                    if(!state || !state.state){
                        state.pauseEle ={
                            btn:e.currentTarget,
                            img:e.currentTarget.parentElement
                        }
                    }
                    state.playEle.btn.style.opacity = 1
                    state.playEle.img.style.opacity = 1
                    state.playEle.btn.style.pointerEvents = 'auto'
                    state.playEle.img.style.pointerEvents = 'auto'
                    video.value.pause();  
                },
                allScreen:function(){
                    // video.value.webkitRequestFullScreen();
                    if (video.value.RequestFullScreen) {
                        video.value.RequestFullScreen()
                        //兼容Firefox
                    } else if (video.value.mozRequestFullScreen) {
                        video.value.mozRequestFullScreen()
                        //兼容Chrome, Safari and Opera等
                    } else if (video.value.webkitRequestFullScreen) {
                        video.value.webkitRequestFullScreen()
                        //兼容IE/Edge
                    } else if (video.value.msRequestFullscreen) {
                        video.value.msRequestFullscreen()
                    }
                }
            }
        })()

        return {
            video,
            videoFn,
            bannerData
        }

    }
}
</script>
<style lang="less">
html,body{
    /* overflow: scroll; */
}
.banner{
    .title-cn{
            color: #fff;
            font-size: 2.8rem;
            letter-spacing: 1.5px;
            &::after{
                content: "";
                display: block;
                width: calc(4 * 2.8rem);
                height: 2px;
                background: #fff;
                margin-top: 5px;
            }
        }
        .title-en{
            width: 100px;
            color: #fff;
            font-size: 6.5rem;
            font-weight: 700;
            text-transform: uppercase;
        }
        .desc{
            color: #fff;
            margin-top: 2rem;
            letter-spacing: 2.9px;

            // font-size: 2rem;

        }
}
</style>
<style lang="less" scoped>
    .banner{
        // background: rgba(0, 0, 0, .5);
        width: 100%;
        // height: 100vh;
        height: 114.2vh;
        height: 100vh;
        overflow: hidden;
        position: relative;
        z-index: 100;
        
        &>.pic{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: cover;
            background-attachment: fixed;
            background-position: center;

        }
        .cont{
            position: absolute;
            // top:65.5%;
            // top:30%;
            bottom: 80px;
            left:150px;
            .bottom-slide{
                margin-top: 100px;
                width: 35px;
                height: 55px;
                border-radius: 18px;
                border: 1px #fff solid;
                position: relative;
                img {
                    width: 12px;
                    color: red;
                    margin: 0 auto;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    animation: flicker;
                    animation-duration: 2s;
                    animation-iteration-count: infinite;
                }
            }
            
        }
        
        .video{
            width: 31%;
            height: 31%;
            background: #000;
            position: absolute;
            left: 50%;
            // top: 66%;
            bottom: 150px;
            border-radius: 10px;
            border: rgba(255, 255, 255, .5) solid 5px;
            .pic{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                z-index: 9;
                transition: .7s;
                
                .swiperButtonEleVue-play{
                    transition: .7s;

                    width: 60px;
                    height: 60px;
                    position: absolute;
                    left: 50%;
                    right: 50%;
                    transform: translate(-50%,-50%);
                }
            }
            .pause{
                position: absolute;
                z-index: 8;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                &:hover{
                    .swiperButtonEleVue-pause{
                        opacity: 1;
                    }    
                }

                .swiperButtonEleVue-pause{
                    opacity: 0;
                    width: 60px;
                    height: 60px;
                    position: absolute;
                    left: 50%;
                    right: 50%;
                    transform: translate(-50%,-50%);
                }
            }
            video{
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 7;
            }
            .play-video {
                    width: 60px;
                    height: 60px;
                    position: absolute;
                    background: #ffffffb7;
                    // padding: 25px;
                    line-height: 60px;
                    text-align: center;
                    border-radius: 50%;
                    color: #333;
                    cursor: pointer;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    &:hover{
                        animation-name: heartBeat;
                    }
            }
            
        }
    }
    @media all and (max-width:769px){
        .banner{
            .cont{
                left: 30px;
                bottom: 80px;
                .title-cn{
                    color: #fff;
                    font-size: 2.8rem;
                    letter-spacing: 1.5px;
                    &::after{
                        content: "";
                        display: block;
                        width: calc(4 * 2.8rem);
                        height: 2px;
                        background: #fff;
                        margin-top: 5px;
                    }
                }
                .title-en{
                    width: 100px;
                    color: #fff;
                    font-size: 3.5rem;
                    font-weight: 700;
                    text-transform: uppercase;
                }
                .desc{
                    color: #fff;
                    margin-top: 2rem;
                    letter-spacing: 2.9px;

                    // font-size: 2rem;

                }
                .bottom-slide{
                    display: none;
                }
            }
            .video{
                display: none;
            }
        }
    }

    @keyframes heartBeat {
    0% {
        -webkit-transform: scale(1) translate(-50%,-50%);
        transform: scale(1) translate(-50%,-50%);
    }

    14% {
        -webkit-transform: scale(1.3) translate(-50%,-50%);
        transform: scale(1.3) translate(-50%,-50%);
    }

    28% {
        -webkit-transform: scale(1) translate(-50%,-50%);
        transform: scale(1) translate(-50%,-50%);
    }

    42% {
        -webkit-transform: scale(1.3) translate(-50%,-50%);
        transform: scale(1.3) translate(-50%,-50%);
    }

    70% {
        -webkit-transform: scale(1) translate(-50%,-50%);
        transform: scale(1) translate(-50%,-50%);
    }
    }
    @keyframes flicker {
        // 0%,50%,100%{
        //     opacity: 1;

        // }
        // 25%,75%{
        //     opacity: 0;
        // }
        0%{
            top: 25%;
            opacity: 1;

        }
        // 25%{
        //     top: 25%;
        // }
        // 50%{
        //     top: 50%;
        // }
        // 75%{
        //     top: 75%;
        // }
        100%{
            top: 75%;
            opacity: 0;
        }
    }
    .full-screen{
        width: 35px;
        height: 25px;
        border: 2px solid #fff;
        border-radius: 5px;
        position: absolute;
        right: 15px;
        bottom: 15px;
        filter: brightness(.7);
        cursor: pointer;
        transition: .7s;
        &:hover{
            filter: brightness(1);
        }
        &::before{
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 0 3px 0 0;

            border-right: 2px solid #fff;
            border-top: 2px solid #fff;
            position: absolute;
            right: 2px;
            top: 2px;

        }
        &::after{
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 0 0 0 3px;

            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            position: absolute;
            left: 2px;
            bottom: 2px;

        }
    }
</style>